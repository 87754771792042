import React, { useState,useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Table,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  Form,
  FormGroup,
  Label,
  CardBody,
  CardTitle,
  //CardSubtitle,
  Button,
  Modal,
  UncontrolledTooltip,
  //FormFeedback,
} from "reactstrap"
//import Select from "react-select"
//import { Link } from "react-router-dom"
// import HelpPages from "../HelpPage/index"
import classnames from "classnames"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useForm } from "react-hook-form";
import api from '../../helpers/api_helper';
import { toast } from "react-toastify";
import firebase from "firebase/compat/app";
import CryptoJS from "crypto-js";
import Switch from "react-switch";
import Slider from "react-rangeslider"
import "react-rangeslider/lib/index.css"
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { telegramMsg,telegramIDMsg,telegramChatIDMsg,MinimumTakeProfit,TaillingTakeProfit,MidPrize,Deltahedgingmsg } from "../../helpers/help-msg";
import { trendFormValidation } from "../../services/common.service";
import parse from 'html-react-parser';
import deltalogo from "../../assets/images/delta.png"
import binancelogo from "../../assets/images/binance.png"
// const { default: axios } = require("axios");
import axios from "axios"
import Bot_settings_default from '../../helpers/Common/Bot_default_settings_values.json';
import { set } from "lodash";
import { getServerIP } from "helpers/auth_helper"

const Settings = () => {
 
document.title = "Settings"; 
firebase.auth();
  const { register, handleSubmit, trigger, setValue, reset, control, formState: { errors } } = useForm();
  const { register : register2, handleSubmit : handleSubmit2, trigger: trigger2, setValue:setValue2, control: control2, formState: { errors:errors2 } } = useForm();
  const { register : register3, handleSubmit : handleSubmit3, setValue:setValue3, control: control3, formState: { errors:errors3 } } = useForm();
  const { register : register4, handleSubmit : handleSubmit4, setValue:setValue4, control: control4, formState: { errors:errors4 } } = useForm();
  const { register : register5, handleSubmit : handleSubmit5, trigger: trigger5,  setValue:setValue5, control: control5, formState: { errors:errors5 } } = useForm();
  const { register : register6, handleSubmit : handleSubmit6, setValue:setValue6, control: control6, formState: { errors:errors6 } } = useForm();
  const { register : register7, handleSubmit : handleSubmit7, setValue:setValue7, control: control7, formState: { errors:errors7 } } = useForm();  
   //const [selectedGroup, setselectedGroup] = useState(null);
  const [allowAdminStatus, setAllowAdminStatus] = useState(false);
  const [allowAdminTrendStatus, setAllowAdminTrendStatus] = useState(false);
  const [admin_diagonal_qty, setAdminDiagonalQty] = useState("");
  const [admin_straddle_qty, setAdminStraddleQty] = useState("");
  const [admin_trend_following_qty, setAdminTrendFollowingQty] = useState("");
  const [form1_loader, setForm1Loader] = useState({ loading: false });
  const [form2_loader, setForm2Loader] = useState({ loading: false });
  const [form3_loader, setForm3Loader] = useState({ loading: false });
  const [form4_loader, setForm4Loader] = useState({ loading: false });
  const [form5_loader, setForm5Loader] = useState({ loading: false });
  const firestoreDb = firebase.firestore();
  const currentUser = JSON.parse(localStorage.getItem('user'));
  let uid = currentUser.uid;
  const [activeTab, setactiveTab] = useState(!currentUser.is_deltakey_updated ? "1" : "2")
  
  const [admin_strangle_qty, setAdminStrangleQty] = useState("");
  const [trend_following_qty, setTrendFollowingQty] = useState("");
  const [adminOrderStatus, setAdminOrderStatus] = useState(false);
  const [SellOrderStatus, setSellOrderStatus] = useState(false);  
  const [BuyOrderStatus, setBuyOrderStatus] = useState(false);
  const [FilledOrderStatus, setFilledOrderStatus] = useState(false);
  const [PositionOrderStatus, setPositionOrderStatus] = useState(false);
  const [TriggerOrderStatus, setTriggerOrderStatus] = useState(false);
  const [RollOrderStatus, setRollOrderStatus] = useState(false);
  const [ProfitOrderStatus, setProfitOrderStatus] = useState(false);
  const [SLOrderStatus, setSLOrderStatus] = useState(false);
  const [CancelledOrderStatus, setCancelledOrderStatus] = useState(false);
  const [StrayPosOrderStatus, setStrayPosOrderStatus] = useState(false);
  const [trendSignalStatus, setTrendSignalStatus] = useState(false);  
  const [UniverselOrderStatus, setUniverselOrderStatus] = useState(false);
  const [botTime, setBotTime] = useState("");
  const [indexPercentage, setIndexPercentage] = useState(0);
  const [triggerPercentage, setTriggerPercentage] = useState(0);
  const [stopLossPercentage, setStopLossPercentage] = useState(0);
  const [combinedSl, setCombinedSl]=useState(0);
  const [combinedTrailProfitPercentage, setCombinedTrailProfitPercentage]=useState(0);
  const [combinedTrailSl, setCombinedTrailSl]=useState(0);
  const [combinedProfitPercentage, setCombinedProfitPercentage]=useState(0);
  const [SlTriggerStrangles, setSlTriggerStrangles]=useState(true);
  const [SlTriggerStraddles, setSlTriggerStraddles]=useState(true);
  const [stopLossIndexPercentage, setStopLossIndexPercentage] = useState(0);
  const [takeProfitPercentage, setTakeProfitPercentage] = useState(0);
  const [rollPercentage, setRollPercentage] = useState(0);
  const [quantity, setQuantity] = useState("");
  const [qtyTime,setQtyTime] = useState("");  
  const [autoMarketFill, setAutoMarketFill] = useState(false);	
  const [universalStop, setUniversalStop] = useState(false);
  const [indexDistance,setIndexDistance] = useState(0);
  const [rollStatus, setRollStatus] = useState(false);
  const [rollTime,setRollTime] = useState("");
  const [visible, setVisible] = useState(false);
  const [visibleDH,setvisibleDH] = useState(false);
  const [visibleMin, setVisibleMin] = useState(false);
  const [visibleMidPrize, setVisibleMidPrize] = useState(false);
  const [visibleTailling, setVisibleTailling] = useState(false);
  const [teleMsg, setTeleMsg] = useState();
  const [teleChatIDMsg,setTeleChatIDMsg] = useState();
  const [MintakeProfitMsg,setMintakeProfitMsg] = useState();
  const [TaillingTakeProfitMsg,setTaillingTakeProfitMsg] = useState();
  const [DeltaHedgingMsg,setDeltaHedgingMsg] = useState();
  const [MidPrizeMsg,setMidPrizeMsg] = useState();
  const [visibleChatID,setVisibleChatID] = useState(false);
  const [teleIDMsg,setTeleIDMsg] = useState();
  const [visibleID,setVisibleID] = useState(false);
  const [page_loader, setPageLoader] = useState({ loading: true });
  const [enableTrendFollow,setEnableTrendFollow] = useState(false);	
  const [enableDeltaHedging,setenableDeltaHedging] = useState(false);
  const [form6_loader, setForm6Loader] = useState({ loading: false });
  const [form7_loader, setForm7Loader] = useState({ loading: false });
  const [trendStopLoss, setTrendStopLoss] = useState("");
  const [trendMinTakeProfit, setTrendMinTakeProfit] = useState("");
  const [trendTrailTakeProfit, setTrendTrailTakeProfit] = useState("");
  const [trendQuantityLimit, setTrendQuantityLimit] = useState("");
  const [noOfTrade, setnoOfTrade] = useState("");
  const [waitTimeReentry, setWaitTimeReentry] = useState("");
  const [trendIndexPer, setTrendIndexPer] = useState("");
  const [waitTimeSL, setWaitTimeSL] = useState("");
  const [indexDistanceType,setIndexDistanceType]=useState("percentage");
  const [trendClosePosition, setTrendClosePosition]= useState(false);
  const [trendChangeClosePosition, setTrendChangeClosePosition]= useState(false);
  const [close_old_orders_only_open_orders, setCloseOldOrdersOnlyOpenOrders]= useState(false);  
  const [waitTimePrice,setWaitTimePrice]=useState("");
  const [interval,setinterval]=useState("");
  const [trendSide,setTrendSide]= useState('sell');
  const [trendDay,setTrendDay]= useState('D1');
  const [strikeCount, setStrikeCount] = useState("");
  const [trendMidPrice, setTrendMidPrice] = useState("");
  const [StrangleMidPrice, setStrangleMidPrice] = useState("");
  const [StraddleMidPrice, setStraddleMidPrice] = useState("");
  const [strangleMinTakeProfit, setStrangleMinTakeProfit] = useState("");
  const [strangleTrailTakeProfit, setStrangleTrailTakeProfit] = useState("");
  const [form11_loader, setForm11Loader] = useState({ loading: false });
  const [form12_loader, setForm12Loader] = useState({ loading: false });
  const [Deltahedging,setDeltahedging]=useState(false);
  const [Deltahedging2,setDeltahedging2]=useState(false);
  const [StraddlesDeltahedging,setStraddlesDeltahedging]=useState(false);
  const [StraddlesDeltahedging2, setStraddlesDeltahedging2] = useState(false);
  const [DiagonalsDeltahedging,setDiagonalsDeltahedging]=useState(false);
  const [DiagonalsDeltahedging2, setDiagonalsDeltahedging2] = useState(false);
  const [TraillingStrangles,setTraillingStrangles]=useState(false);
  const [TraillingStraddles,setTraillingStraddles]=useState(false);
  const [advancedSettings,setAdvancedSettings]=useState(false)
  const [strangleAdvancedSettings,setStrangleAdvancedSettings]=useState(false)
  const [straddleAdvancedSettings,setStraddleAdvancedSettings]=useState(false)
  const [trendSettings, setTrendSettings]=useState({
    trend_method:"sma",
    short_term:10,
    long_term:100,
    time_frame:"15m"
  })
  const [time_to_close_orders_before_expiry, settime_to_close_orders_before_expiry] = useState(0);
  const [time_to_close_orders_before_expiry_straddles, settime_to_close_orders_before_expiry_straddles] = useState(0);
  const [minLossType,setMinLossType]=useState("percentage");
  const [minLossTypeStrangles,setMinLossTypeStrangles]=useState("percentage");
  const [minLossTypeStraddles,setMinLossTypeStraddles]=useState("percentage");
  const [minLossTypeDiagonals,setMinLossTypeDiagonals]=useState("percentage");
  const[minLossValue,setMinLossValue]=useState(0);
  const[minLossValueStrangles,setMinLossValueStrangles]=useState(0);
  const[minLossValueStraddles,setMinLossValueStraddles]=useState(0);
  const[minLossValueDiagonals,setMinLossValueDiagonals]=useState(0);

  //tooltip
  const apiUrl = `${process.env.REACT_APP_API_URL}/static/helptexts.json`;
  const [ToolTipData, setToolTipData] = useState([]);
  useEffect(()=>{
    
    try{
      const gettoottipData = async () => {
       
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/static/helptexts.json`)
          // console.log(response)
            setToolTipData(JSON.parse(JSON.stringify(response.data)))
        
        }

        gettoottipData()
      }catch(error){
          console.log(error)
      }

  },[apiUrl])
  //tooltip

  //Bot setup / settings
  const [straddleBotSettings, setStraddleBotSettings] = useState({
    stop_loss_percentage: 30,
    trigger_percentage: 10,
    stop_loss_index_percentage: 1,
    minimum_take_profit_percentage: 10,
    trailing_take_profit_percentage: 5,
    quantity: 1,
    combined_sl_percentage:0,
    combined_trailing_take_profit_percentage:0,
    combined_trailing_stop_price_percentage:0,
  });
  const [serverIP, setServerIP] = useState("");
  const validation = {
      required: "This field is required",
      validate: (value) => {
        return value > 0 || "value > 0";
      },
    }
    const [rows, setRows] = useState([
      {name:"make_limit_orders_at",botSettingsvalue:'',orgSettingsName:'Make Limit Orders x% from Mid/MarkPrice',tooltip:'visibleMidPrize',target:'ONCLICK', label: 'Make Limit Orders at', settings:StraddleMidPrice, max: 100,step:0.5,formula: <><p><b>Calls Mid Price</b> = (Calls Best Bid + Calls Best Ask)/2</p><p><b>Puts Mid Price</b> = (Puts Best Bid + Puts Best Ask)/2</p><p><b>Calls x%</b> = (Calls Best Ask - Calls Mid Price) * (Setting Values/100)</p><p><b>Puts x%</b> = (Puts Best Ask - Puts Mid Price) * (Setting Values/100)</p><p><b>Calls</b> = Calls Mid Price + Calls x%</p><p><b>Puts</b> = Puts Mid Price + Puts x%</p></> },
      {name:"trigger_percentage",botSettingsvalue:'trigger_percentage',orgSettingsName:'Stop Loss Activate Percentage',tooltip:'trigger',target:'stoploss', label: 'Activate SL at', settings:straddleBotSettings.trigger_percentage, min:0,max:100,step:0.5,formula:  <><p><b>Calls Mid Price</b> = (Calls Best Bid + Calls Best Ask)/2</p><p><b>Puts Mid Price</b> = (Puts Best Bid + Puts Best Ask)/2</p><p><b>Calls</b> = Calls Mid Price + (Calls Mid Price * (Setting Values/100))</p><p><b>Puts</b> = Puts Mid Price + (Puts Mid Price * (Setting Values/100))</p></>,validation:validation},
      {name:"stop_loss_percentage",botSettingsvalue:'stop_loss_percentage',orgSettingsName:'Market Stop Loss Percentage',tooltip:'stop_loss',target:'stoploss1', label: 'Market SL at', settings:straddleBotSettings.stop_loss_percentage, min:0,max:100,step:0.5,formula:<><p><b>Calls Mid Price</b> = (Calls Best Bid + Calls Best Ask)/2</p><p><b>Puts Mid Price</b> = (Puts Best Bid + Puts Best Ask)/2</p><p><b>Calls</b> = (Calls Sell Price * (Setting Values/100)) + Calls Sell Price</p><p><b>Puts</b> = (Puts Sell Price * (Setting Values/100)) + Puts Sell Price</p></>,validation:validation },
      {name:"stop_loss_index_percentage",botSettingsvalue:'stop_loss_index_percentage',orgSettingsName:'Index Price Range % for Stop Loss',tooltip:'index_price_range',target:'stoploss2', label: 'Activate SL if index changes to', settings:straddleBotSettings.stop_loss_index_percentage, min:0,max:100,step:0.5,formula: <><p><b>Calls</b> = ((Index Price)-((Index Price) * (Setting Values/100)))</p><p><b>Puts</b> = ((Index Price)+((Index Price) * (Setting Values/100)))</p></>},
      {name:"minimum_take_profit_percentage",botSettingsvalue:'minimum_take_profit_percentage',orgSettingsName:'Trailing Take Profit Percentage',tooltip:'visibleMin',target:'ONCLICK', label: 'Activate Trailing at', settings:straddleBotSettings.minimum_take_profit_percentage, min:0,max:100,step:0.5,formula: <><p><b>Calls</b> = ((Calls Sell Price) -(Calls Sell Price * (Setting Values/100)))</p><p> <b>Puts</b> = ((Puts Sell Price) -(Puts Sell Price * (Setting Values/100)))</p></> ,validation:validation},
      {name:"trailing_take_profit_percentage",botSettingsvalue:'trailing_take_profit_percentage',orgSettingsName:'Trailing Stop Price Percentage',tooltip:'visibleTailling',target:'ONCLICK', label: 'Trail Exit at', settings:straddleBotSettings.trailing_take_profit_percentage, min:0,max: 100,step:0.5,formula: <> <p><b>Calls</b> = ((Calls Sell Price) -(Calls Sell Price * (Setting Values/100)))</p><p> <b>Puts</b> = ((Puts Sell Price) -(Puts Sell Price * (Setting Values/100)))</p></>,validation:validation },
      {name:"Sl_trigger_take_profit",botSettingsvalue:'Sl_trigger_take_profit',orgSettingsName:'Activate trailing take profit when stop loss triggered on one leg',tooltip:'',target:'SSLTMTP', label: 'Activate Trailing', 'ATTPWSLTOOL': '(radio buttons)',check_box_label: 'After SL hits one of the position',formula: <><p><b>After SL hits one of the positions</b>– Activates trailing and take profit after stop loss hit on one leg.</p></> },
      {name:"combined_sl_percentage",botSettingsvalue:'combined_sl_percentage',orgSettingsName:'Combined Stop Loss %',tooltip:'stop_loss_combined',target:'straddles-combinedsl-trigger', label: 'Activate Combined SL at', settings:straddleBotSettings.combined_sl_percentage, min:0,max:100,step:0.5,formula: <><p>(((Calls Sell Price) + (Puts Sell Price)) + (((Calls Sell Price) + (Puts Sell Price)) * (Setting Values/100)))</p></> },
      {name:"combined_trailing_take_profit_percentage",botSettingsvalue:'combined_trailing_take_profit_percentage',orgSettingsName:'Combined Trailing Take Profit %',tooltip:'combined_trailing_profit_percentage',target:'straddles-combined-trail-pro-trigger', label: 'Activate Combined Trailing at', settings:straddleBotSettings.combined_trailing_take_profit_percentage, min:0,max:100,step:0.5,formula: <><p>(((Calls Sell Price) + (Puts Sell Price)) - (((Calls Sell Price) + (Puts Sell Price)) * (Setting Values/100)))</p></> },
      {name:"combined_trailing_stop_price_percentage",botSettingsvalue:'combined_trailing_stop_price_percentage',orgSettingsName:'Combined Trailing Stop Price %',tooltip:'combined_trailing_stop_price_percentage',target:'straddles-combined-trail-stop-trigger', label: 'Combined Trail Exit at', settings:straddleBotSettings.combined_trailing_stop_price_percentage, min:0,max:100,step:0.5,formula: <><p>(((Calls Sell Price) + (Puts Sell Price)) - (((Calls Sell Price) + (Puts Sell Price)) * (Setting Values/100)))</p></> },
    ]);

    const [stranglesRows, setStranglesRows] = useState([
      {name:"strangles_make_limit_orders_at",botSettingsvalue:'',orgSettingsName:'Make Limit Orders x% from Mid/MarkPrice',tooltip:'visibleMidPrize',target:'ONCLICK', label: 'Make Limit Orders at', settings: StrangleMidPrice, max: 1000,step:0.5,formula: <><p><b>Calls Mid Price</b> = (Calls Best Bid + Calls Best Ask)/2 </p><p> <b>Puts Mid Price</b> = (Puts Best Bid + Puts Best Ask)/2</p> <p><b>Calls x%</b> = (Calls Best Ask - Calls Mid Price) * (Setting Values/100)</p><p><b> Puts x%</b> = (Puts Best Ask - Puts Mid Price) * (Setting Values/100)</p> <p><b>Calls</b> = Calls Mid Price + Calls x%</p><p> <b>Puts</b> = Puts Mid Price + Puts x%</p></> },
      {name:"strangles_trigger_percentage",botSettingsvalue:'trigger_percentage',orgSettingsName:'Stop Loss Activate Percentage',tooltip:'trigger',target:'stoploss', label: 'Activate SL at', settings:  0, min:0,max:100,step:0.5,formula:  <><p><b>Calls</b> = ((Calls Sell Price) * (Setting Values/100)) + (Calls Sell Price)</p><p><b>Puts</b> = ((Puts Sell Price) * (Setting Values/100)) + (Puts Sell Price)</p></>,validation:validation},
      {name:"strangles_stop_loss_percentage",botSettingsvalue:'stop_loss_percentage',orgSettingsName:'Market Stop Loss Percentage',tooltip:'stop_loss',target:'stoploss1', label: 'Market SL at', settings: 0, min:0,max:100,step:0.5,formula:<><p><b>Calls</b> = ((Calls Sell Price) * (Setting Values/100)) + (Calls Sell Price)</p><p><b>Puts</b> = ((Puts Sell Price) * (Setting Values/100)) + (Puts Sell Price)</p></>,validation:validation },
      {name:"strangles_stop_loss_index_percentage",botSettingsvalue:'stop_loss_index_percentage',orgSettingsName:'Index Price Range % for Stop Loss',tooltip:'index_price_range',target:'stoploss2', label: 'Activate SL if index changes to', settings: 0, min:0,max:100,step:0.5,formula: <><p><b>Calls</b> = ((Index Price)-((Index Price) * (Setting Values/100)))</p><p><b>Puts</b> = ((Index Price)+((Index Price) * (Setting Values/100)))</p></> },
      {name:"strangles_min_take_profit_percentage",botSettingsvalue:'min_take_profit_percentage',orgSettingsName:'Trailing Take Profit Percentage',tooltip:'visibleMin',target:'ONCLICK', label: 'Activate Trailing at', settings: 0, min:0,max:100,step:0.5,formula: <><p><b>Calls</b> = ((Calls Sell Price) -(Calls Sell Price * (Setting Values/100)))</p><p><b>Puts</b> = ((Puts Sell Price) -(Puts Sell Price * (Setting Values/100)))</p></> ,validation:validation},
      {name:"strangles_trailing_take_profit_percentage",botSettingsvalue:'trailing_take_profit_percentage',orgSettingsName:'Trailing Stop Price Percentage',tooltip:'visibleTailling',target:'ONCLICK', label: 'Trail Exit at', settings:0, min:0,max: 100,step:0.5,formula: <><p><b>Calls</b> = ((Calls Sell Price) -(Calls Sell Price * (Setting Values/100)))</p><p><b>Puts</b> = ((Puts Sell Price) -(Puts Sell Price * (Setting Values/100)))</p></>,validation:validation },
      {name:"strangles_take_profit_percentage",botSettingsvalue:'take_profit_percentage',orgSettingsName:'Take Profit Percentage',tooltip:'take_profit',target:'take-profit', label: 'Take Profit at', settings:0, min:0,max: 100,step:0.5,formula: <><p><b>Calls</b> = ((Calls Sell Price) -(Calls Sell Price * (Setting Values/100)))</p><p><b>Puts</b> = ((Puts Sell Price) -(Puts Sell Price * (Setting Values/100)))</p></> },
      {name:"strangles_Sl_trigger_take_profit",botSettingsvalue:'Sl_trigger_take_profit',orgSettingsName:'Activates trailing and take profit after stop loss hit on one leg.',tooltip:'stop_loss_trigger_market_take_profit',target:'SSLTMTP', label: 'Activate Trailing', 'ATTPWSLTOOL': '(radio buttons)', check_box_label: 'After SL hits one of the position', formula: <> <p><b>After SL hits one of the positions</b>– Activates trailing and take profit after stop loss hit on one leg.</p></> },
      {name:"strangles_combined_sl_percentage",botSettingsvalue:'combined_sl_percentage',orgSettingsName:'Combined Stop Loss %',tooltip:'stop_loss_combined',target:'strangles-combinedsl-trigger', label: 'Activate Combined SL at', settings: 0,  min:0,max:100,step:0.5,formula: <><p>(((Calls Sell Price) + (Puts Sell Price)) + (((Calls Sell Price) + (Puts Sell Price)) * (Setting Values/100)))</p></> },
      {name:"strangles_combined_trailing_profit_percentage",botSettingsvalue:'combined_trailing_profit_percentage',orgSettingsName:'Combined Trailing Take Profit %',tooltip:'combined_trailing_profit_percentage',target:'strangles-combined-trail-pro-trigger', label: 'Activate Combined Trailing at', settings: 0,  min:0,max:100,step:0.5,formula: <><p>(((Calls Sell Price) + (Puts Sell Price)) - (((Calls Sell Price) + (Puts Sell Price)) * (Setting Values/100)))</p></> },
      {name:"strangles_combined_trailing_stop_price_percentage",botSettingsvalue:'combined_trailing_stop_price_percentage',orgSettingsName:'Combined Trailing Stop Price %',tooltip:'combined_trailing_stop_price_percentage',target:'strangles-combined-trail-stop-trigger', label: 'Combined Trail Exit at', settings: 0,  min:0,max:100,step:0.5,formula: <><p>(((Calls Sell Price) + (Puts Sell Price)) - (((Calls Sell Price) + (Puts Sell Price)) * (Setting Values/100)))</p></> },
      {name:"strangles_combined_take_profit_percentage",botSettingsvalue:'combined_take_profit_percentage',orgSettingsName:'Combined Take Profit %',tooltip:'combined_take_profit_percentage',target:'strangles-combined-take-profit-trigger', label: 'Combined Take Profit at', settings: 0,  min:0,max:100,step:0.5,formula: <><p>(((Calls Sell Price) + (Puts Sell Price)) - (((Calls Sell Price) + (Puts Sell Price)) * (Setting Values/100)))</p></> },
      {name:"strangles_waiting_time_to_fill_qty",botSettingsvalue:'waiting_time_to_fill_qty',orgSettingsName:'Waiting Time To Fill Quantity',tooltip:'waiting_time_to_fill_qty',target:'WTFQTY', label: 'Waiting time to fill Qty (H : M)', settings: 0},
      {name:"strangles_automatic_market_fill",botSettingsvalue:'automatic_market_fill',orgSettingsName:'Automatic Market Fill',tooltip:'automatic_market_fill',target:'automatic-market-fill', label: 'Automatic Market Fill', settings: 0},
      {name:"strangles_universal_stop",botSettingsvalue:'universal_stop',orgSettingsName:'Universal Stop',tooltip:'universal_stop',target:'universal-stop', label: 'Universal Stop', settings: 0},
    ]);
  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        No
      </div>
    );
  };
  
  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        Yes
      </div>
    );
  };
  const trendSettingSubmit =async()=>{
    try{
    setForm7Loader({
      loading: true,
    });
    if(trendSide=="both" &&  Number(strikeCount) < 2)
    {
      throw  toast.error('Strike count must be greater than or equal to 2 when you select side as "Both"')
    }else if(Number(strikeCount) > Number(trend_following_qty))
    {
      throw  toast.error('Qty must enter more than or equal strike count')
    }
    else
    {
      if(Number(trendQuantityLimit) >0 && Number(trend_following_qty) >  Number(trendQuantityLimit))
      {
        throw  toast.error('Total quantity limit must enter more than or equal qty')
      }
    }
    let errmsg=trendFormValidation(trendSettings)
    if(errmsg!=""){
      throw  toast.error(errmsg)
    }
   
    var wair_timeArr = waitTimeReentry.split(":");
    const order_get_hour = wair_timeArr[0];
    const order_get_minutes = wair_timeArr[1];
    let order_total_secs;
    if(order_get_hour == '0')
    {
      order_total_secs = Number(order_get_minutes) * 60;  
    }
    else
    {
      const total_hours = Number(order_get_hour) * 3600;
      const total_minutes = Number(order_get_minutes) * 60;
      order_total_secs = total_hours + total_minutes;
    }
    var sl_wair_timeArr = waitTimeSL.split(":");
    const sl_order_get_hour = sl_wair_timeArr[0];
    const sl_order_get_minutes =sl_wair_timeArr[1];
    let sl_order_total_secs;
    if(sl_order_get_hour == '0')
    {
      sl_order_total_secs = Number(sl_order_get_minutes) * 60;  
    }
    else
    {
      const sl_total_hours = Number(sl_order_get_hour) * 3600;
      const sl_total_minutes = Number(sl_order_get_minutes) * 60;
      sl_order_total_secs = sl_total_hours + sl_total_minutes;
    }

    var pr_wair_timeArr = waitTimePrice.split(":");
    const pr_order_get_hour = pr_wair_timeArr[0];
    const pr_order_get_minutes =pr_wair_timeArr[1];
    let pr_order_total_secs;
    if(pr_order_get_hour == '0')
    {
      pr_order_total_secs = Number(pr_order_get_minutes) * 60;  
    }
    else
    {
      const pr_total_hours = Number(pr_order_get_hour) * 3600;
      const pr_total_minutes = Number(pr_order_get_minutes) * 60;
      pr_order_total_secs = pr_total_hours + pr_total_minutes;
    }

    var st_trade_interval = interval.split(":");
    const interval_hour = st_trade_interval[0];
    const interval_minutes =st_trade_interval[1];
    let totinterval_secs;
    if(interval_hour == '0')
    {
      totinterval_secs = Number(interval_minutes) * 60;  
    }
    else
    {
      const totinterval_hours = Number(interval_hour) * 3600;
      const totinterval_minutes = Number(interval_minutes) * 60;
      totinterval_secs = totinterval_hours +totinterval_minutes;
    }

    let  mainsettings = {
      stop_loss_percentage:trendStopLoss,
      waitTimeForReEntry:order_total_secs ?order_total_secs :'0',
      waitTimeForReEntry_clock:waitTimeReentry?waitTimeReentry:"0:00:0",
      waitTimeAfterStopLoss:sl_order_total_secs ?sl_order_total_secs :'0',
      waitTimeAfterStopLoss_clock:waitTimeSL?waitTimeSL:"0:00:0",
      waitTimeForPrice:pr_order_total_secs?pr_order_total_secs :'0',
      waitTimeForPrice_clock:waitTimePrice?waitTimePrice:"0:00:0",
      same_trend_no_of_trade:noOfTrade?noOfTrade:'0',
      same_trend_trade_interval:totinterval_secs?totinterval_secs:'0',
      same_trend_trade_interval_clock:interval?interval:'0:00:0',
      index_distance:trendIndexPer?trendIndexPer:'0',
      index_distance_type:indexDistanceType?indexDistanceType:'percentage',
      enable_trend_following: enableTrendFollow,
      quantity:enableTrendFollow ? trend_following_qty : 1,
      close_old_positions:trendClosePosition? '1':'0',
      close_old_positions_trend_change:trendChangeClosePosition? '1':'0',
      close_old_orders_only_open_orders:close_old_orders_only_open_orders,
      total_quantity_limit:trendQuantityLimit?trendQuantityLimit:0,
      min_take_profit_percentage:trendMinTakeProfit,
      trailing_take_profit_percentage:trendTrailTakeProfit,
      trend_side:trendSide,
      trend_day:trendDay,
      strike_count:strikeCount,
      mid_price_percentage:trendMidPrice
    }
    let send_data = {
      ...trendSettings,
      ...mainsettings
  };
    await api({ contentType: true, auth: true }).post('/delta/update-trend-following-settings', send_data).then((res) => {
      setForm7Loader({
        loading: false,
      });
      toast.success("Trend following settings updated");
    }).catch(err => {
      throw err
    })
  } catch (err) {
    if (err.response) {
      toast.error(err.response.data.message);
    }
    setForm7Loader({
      loading: false,
    });
  }
  }
  const handleError = (err) => {
    if (err && err.response && err.response.data && err.response.data.message) {
      toast.error(err.response.data.message, { toastId: err.response.data.message });
    } else if (err.message) {
      toast.error(err.message, { toastId: err.message });
    } else {
      toast.error(err, { toastId: err });
    }
  }

  const keySettingSubmit = async(data)=>{
    try
    {
      setForm1Loader({
        loading: true,
      });

      const bodyParams = {
        appKey : data.api_key,
        appSecret : data.api_secret,
        exchangeType : data.exchange_type
      }     

      await api({ contentType: true, auth: true }).post('/delta/update-apikey-settings', bodyParams).then((res) => {
        setForm1Loader({
          loading: false,
        });
        //dispatch(updateApiKeys(bodyParams));
        let user = JSON.parse(localStorage.getItem("user"));
        user.is_deltakey_updated = true;
        user.profile.exchangeType = data.exchange_type;
        localStorage.setItem("user", JSON.stringify(user));
        toast.success("ApiKey settings updated");
      }).catch(err => {
        throw err
      })    
    }
    catch(err)
    {
      setForm1Loader({          
        loading: false,
      });      
      handleError(err);
    }
  }  
  function toHoursAndMinutes(totalSeconds) {
    const totalMinutes = Math.floor(totalSeconds / 60);
  
    const seconds = totalSeconds % 60;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
  
    return hours + ":" + minutes ;
  }
  function toSeconds(hours, minutes, seconds) {
    return hours * 3600 + minutes * 60 + seconds;
  }
  const [deltaHedgingIntervel, setDeltaHedgingIntervel] = useState(0);
  const [stranglesdeltaHedgingIntervel, setstranglesDeltaHedgingIntervel] = useState(0);
  const [strangleswait_time_reentry, setstrangleswait_time_reentry] = useState(0);
  const [straddleswait_time_reentry, setstraddleswait_time_reentry] = useState(0);
  const [reentry_enabled, setreentry_enabled] = useState(false);
  const [straddlereentry_enabled, setstraddlereentry_enabled] = useState(false);
  const [straddlesdeltaHedgingIntervel, setstraddlesDeltaHedgingIntervel] = useState(0);
  const [DiagonalsdeltaHedgingRange, setDiagonalsDeltaHedgingRange] = useState(0);
  const [DiagonalsdeltaHedgingIntervel, setDiagonalsDeltaHedgingIntervel] = useState(0);
  const [deltaHedgingRange, setDeltaHedgingRange] = useState(0);
  const [orderType, setOrderType] = useState("market_order"); 
  const [hedgeOnlyForLoss,setHedgeOnlyForLoss]=useState(false);
  const [strangleshedgeOnlyForLoss,setStranglesHedgeOnlyForLoss]=useState(false);
  const [straddleshedgeOnlyForLoss,setStraddlesHedgeOnlyForLoss]=useState(false);
  const [diagonalshedgeOnlyForLoss,setDiagonalsHedgeOnlyForLoss]=useState(false);
  const [minLoss,setMinLoss]=useState(0);
  const [stranglesdeltaHedgingRange, setstranglesDeltaHedgingRange] = useState(0);
  const [straddlesdeltaHedgingRange, setstraddlesDeltaHedgingRange] = useState(0);
  const [straddlesOrderType,setStraddlesOrderType]=useState("market_order");
  const [stranglesOrderType,setStranglesOrderType]=useState("market_order");
  const [diagonalsOrderType,setDiagonalsOrderType]=useState("market_order");
  const handleDH = (e,strategy = '') => {
    var time = e[0]
    var hours = time.getHours();
    var minutes = time.getMinutes();
    var H_M = toSeconds(hours, minutes, 0);
    if (strategy == 'strangles') {
      setstranglesDeltaHedgingIntervel(H_M)
    } else if (strategy == 'straddles') {
      setstraddlesDeltaHedgingIntervel(H_M)
      setStraddleBotSettings({ ...straddleBotSettings, delta_hedging_interval: H_M })
    } else if (strategy === 'diagonals'){
      setDiagonalsDeltaHedgingIntervel(H_M)
    }else if (strategy == ''){
      setDeltaHedgingIntervel(H_M)
    }else if (strategy == 'strangleswait_time_reentry'){
      setstrangleswait_time_reentry(H_M)
    }else if (strategy == 'straddleswait_time_reentry'){
      setstraddleswait_time_reentry(H_M)
    }else if (strategy == 'time_to_close_orders_before_expiry'){
      settime_to_close_orders_before_expiry(H_M)
    }else if (strategy == 'time_to_close_orders_before_expiry_straddles'){
      settime_to_close_orders_before_expiry_straddles(H_M)
    }
  }
  const DHSettings = async () => {
    try {
      setForm11Loader({
        loading: true,
      });
      if(deltaHedgingRange < 0.001){
        toast.error("Delta range must be greater than 0.001");
        setForm11Loader({
          loading: false,
        });
        return false;
      }
      let oktosave;
      if(enableDeltaHedging){
        oktosave=1;
      }else{
        await api({ contentType: true, auth: true }).get('/delta-hedging/delta-hedging-active-orders').then((response) => {
          let res = response.data.response.data;
          if(res.length > 0){
            if(confirm("You have futures order position, Please note that if you disable delta hedging, any future order positions will be automatically closed. Are you certain you want to proceed with disabling delta hedging?")){
              oktosave=1;
            }else{  
              oktosave=0;
              setenableDeltaHedging(true);
              setForm11Loader({
                loading: false,
              });
              return false;
            }
          }else{
            oktosave=1;
          }
        }).catch(err => {
          throw err
        })
      }
      if(oktosave==1){
      const bodyParams = {
        uid: uid,
        delta_hedging_enable : enableDeltaHedging,
        delta_hedging_interval:deltaHedgingIntervel,
        delta_hedging_range:deltaHedgingRange,
        delta_hedging_order_type:orderType,
        hedge_only_for_loss:hedgeOnlyForLoss,
        hedging_min_loss_type:minLossType,
        hedging_min_loss_value:minLossValue
      }
      await api({ contentType: true, auth: true }).post('/delta/update-delta-hedging-settings', bodyParams).then((res) => {
        setForm11Loader({
          loading: false,
        });
        toast.success("Delta Hedging settings updated");
      }).catch(err => {
        throw err
      })
     }
      
    }
    catch(err)
    {
      setForm11Loader({
        loading: false,
      });
      toast.error(err.response&&err.response.data&&err.response.data.message?err.response.data.message:'Something went wrong,please try again later');
    }
  }
  const getDHSettings = async () => {
    try {
      await api({ contentType: true, auth: true }).get('/delta/get-delta-hedging-settings').then((res) => {
        setDeltaHedgingIntervel(res.data.response.delta_hedging_settings.delta_hedging_interval ? res.data.response.delta_hedging_settings.delta_hedging_interval : Bot_settings_default.Delta_hedge.delta_hedging_interval)
        setDeltaHedgingRange(res.data.response.delta_hedging_settings.delta_hedging_range ? res.data.response.delta_hedging_settings.delta_hedging_range : Bot_settings_default.Delta_hedge.delta_hedging_range)
        setOrderType(res.data.response.delta_hedging_settings.delta_hedging_order_type ? res.data.response.delta_hedging_settings.delta_hedging_order_type : Bot_settings_default.Delta_hedge.delta_hedging_order_type)
        setenableDeltaHedging(res.data.response.delta_hedging_settings.delta_hedging_enable)
        setHedgeOnlyForLoss(res.data.response.delta_hedging_settings.hedge_only_for_loss)
        setMinLossType(res.data.response.delta_hedging_settings.hedging_min_loss_type)
        setMinLossValue(res.data.response.delta_hedging_settings.hedging_min_loss_value)
      }).catch(err => {
        throw err
      })
    }
    catch(err)
    {
      toast.error(err.response.data.message);
    }
  }
  const privacySettingSubmit = async(form_data)=>{
      try{
        setForm3Loader({
          loading: true,
        });
        const bodyParams = {
          is_allow_admin_bot : allowAdminStatus ? 1 : 0,
          admin_strangle_quantity : allowAdminStatus ? admin_strangle_qty : 1,
          admin_diagonal_quantity : allowAdminStatus ? admin_diagonal_qty : 1,
          admin_straddle_quantity : allowAdminStatus ? admin_straddle_qty : 1,
          is_allow_admin_trending_bot: allowAdminStatus?1:0,
          admin_trend_following_qty: allowAdminStatus?admin_trend_following_qty:1
        }
        await api({ contentType: true, auth: true }).post('/delta/update-privacy-settings', bodyParams).then((res) => {                
          toast.success("Deri Bot settings updated successfully");
          setForm3Loader({
            loading: false,
          });
          let user = localStorage.getItem('user')
          if(user){
            user = JSON.parse(user)
            if(user && user.profile){
               user.profile.is_allow_admin_bot = allowAdminStatus ? 1 : 0;
              user.profile.admin_strangle_quantity = allowAdminStatus ? admin_strangle_qty : 1,
              user.profile.admin_diagonal_quantity =  allowAdminStatus ? admin_diagonal_qty : 1,
              user.profile.admin_straddle_quantity =  allowAdminStatus ? admin_straddle_qty : 1,
              user.profile.is_allow_admin_trending_bot = allowAdminStatus?1:0,
              user.profile.admin_trend_following_qty =  allowAdminStatus ? admin_trend_following_qty : 1,
              localStorage.setItem("user", JSON.stringify(user)); // Ensure localStorage gets the latest user data
            }
          }
        }).catch(err => {          
          throw err
        })
      }
      catch(err)
      {
        setForm3Loader({
          loading: false,
        });        
        handleError(err);
      }
    }
    const DiagonalsSettings = async () => {
      try {
        setForm12Loader({
          loading: true,
        });
        if(DiagonalsdeltaHedgingRange < 0.0001){
          toast.error("Delta range must be greater than 0.0001");
          setForm12Loader({
            loading: false,
          });
          return false;
        }
        var diagonalbotsettings = {
          uid: uid,
          delta_hedging_enable: DiagonalsDeltahedging ? '1' : '0',
          delta_hedging_interval: DiagonalsdeltaHedgingIntervel,
          delta_hedging_range: DiagonalsdeltaHedgingRange,
          delta_hedging_on_stop_loss_in_one_leg : DiagonalsDeltahedging2 ? '1' : '0',
          delta_hedging_order_type: diagonalsOrderType,
          hedge_only_for_loss: diagonalshedgeOnlyForLoss,
          hedging_min_loss_type: minLossTypeDiagonals,
          hedging_min_loss_value: minLossValueDiagonals
        }
        await api({ contentType: true, auth: true }).post('/diagonals/update-diagonals-settings', diagonalbotsettings).then((res) => {
          setForm12Loader({
            loading: false,
          });
          toast.success("Diagonals settings updated");
        }).catch(err => {
          throw err
        })
      }
      catch(err)
      {
        setForm12Loader({
          loading: false,
        });
        toast.error(err.response.data.message);
      }
    }
    const getDiagonalsSettings = async () => {
      try {
        await api({ contentType: true, auth: true }).get('/diagonals/get-diagonals-positions').then((res) => {
          setDiagonalsDeltaHedgingIntervel(res.data.response.data[0] ?  res.data.response.data[0].data.delta_hedging_interval ? res.data.response.data[0].data.delta_hedging_interval : Bot_settings_default.Delta_hedge.delta_hedging_interval : Bot_settings_default.Delta_hedge.delta_hedging_interval)
          setDiagonalsDeltaHedgingRange(res.data.response.data[0] ? res.data.response.data[0].data.delta_hedging_range ? res.data.response.data[0].data.delta_hedging_range : Bot_settings_default.Delta_hedge.delta_hedging_range : Bot_settings_default.Delta_hedge.delta_hedging_range)
          setDiagonalsDeltahedging(res.data.response.data[0].data.delta_hedging_enable == 1 ? true : false)
          setDiagonalsDeltahedging2(res.data.response.data[0].data.delta_hedging_on_stop_loss_in_one_leg == 1 ? true : false)
          setDiagonalsOrderType(res.data.response.data[0].data.delta_hedging_order_type ? res.data.response.data[0].data.delta_hedging_order_type : Bot_settings_default.Delta_hedge.delta_hedging_order_type)
          setDiagonalsHedgeOnlyForLoss(res.data.response.data[0].data.hedge_only_for_loss)
          setMinLossTypeDiagonals(res.data.response.data[0].data.hedging_min_loss_type)
          setMinLossValueDiagonals(res.data.response.data[0].data.hedging_min_loss_value)
        }).catch(err => {
          throw err
        })
      }
      catch(err)
      {
        toast.error(err);
      }
    }
    const botSettingSubmit = async (data) => {
      try
      {        
        setForm2Loader({
          loading: true,
        });
        if(triggerPercentage > 0 && stopLossPercentage > 0 && triggerPercentage >= stopLossPercentage)
        {
          throw toast.error("Market SL % must more than Stop Loss Activate %");
        }        
        // qty time selection changes in secs
        var qty_timeArr = qtyTime.split(":");
        const qty_get_hour = qty_timeArr[0];
        const qty_get_minutes = qty_timeArr[1];
        let qty_total_secs;
        if(qty_get_hour == '0')
        {
          qty_total_secs = Number(qty_get_minutes) * 60;  
        }
        else
        {
          const total_hours = Number(qty_get_hour) * 3600;
          const total_minutes = Number(qty_get_minutes) * 60;
          qty_total_secs = total_hours + total_minutes;
        }            
        // roll time selection changes in secs
        var roll_timeArr = rollTime.split(":");
        const roll_get_hour = roll_timeArr[0];
        const roll_get_minutes = roll_timeArr[1];
        let roll_total_secs;
        if(roll_get_hour == '0')
        {
          roll_total_secs = Number(roll_get_minutes) * 60;
  
        }
        else
        {
          const roll_total_hours = Number(roll_get_hour) * 3600;
          const roll_total_minutes = Number(roll_get_minutes) * 60;
          roll_total_secs = roll_total_hours + roll_total_minutes;
        }
        const bodyParams = {
          uid: uid,
          index_percentage: indexPercentage,
          trigger_percentage: triggerPercentage,
          stop_loss_percentage: stopLossPercentage,
          stop_loss_index_percentage: stopLossIndexPercentage,  
          take_profit_percentage:takeProfitPercentage,
          trailing_take_profit_percentage:strangleTrailTakeProfit,
          min_take_profit_percentage:strangleMinTakeProfit,
          roll_percentage:!reentry_enabled && rollStatus ? rollPercentage : 0,
          quantity:quantity,
          index_distance:!reentry_enabled && rollStatus ? indexDistance : 0,
          waitTimeForFillAllQty:qty_total_secs?qty_total_secs:'1800',
          Qty_time_clock:qtyTime?qtyTime:"0:30:0",
          waitTimeForRoll:!reentry_enabled && rollStatus ? roll_total_secs : 0,
          Roll_time_clock: !reentry_enabled && rollStatus ? rollTime : "0:0:0",
          autoMarketFill: autoMarketFill == true ? 1 : 0,
          roll_status: !reentry_enabled && rollStatus == true ? 1 : 0,
          universal_stop: universalStop == true ? 1 : 0,
          deltahedging: Deltahedging == true ? 1 : 0,
          Delta_Hedging_on_Stop_Loss_in_One_Leg: Deltahedging2 == true ? 1 : 0,
          delta_hedging_interval:stranglesdeltaHedgingIntervel,
          delta_hedging_range:stranglesdeltaHedgingRange,
          // combined_sl:combinedSl,
          combined_sl_percentage:combinedSl,
          combined_trailing_profit_percentage:combinedTrailProfitPercentage,
          combined_trailing_stop_price_percentage:combinedTrailSl,
          combined_take_profit_percentage:combinedProfitPercentage,
          Sl_trigger_take_profit:SlTriggerStrangles,
          combined_trailing_profit:TraillingStrangles == true ? 1 : 0,
          reentry_enabled:reentry_enabled == true ? 1 : 0,
          wait_time_reentry:strangleswait_time_reentry,
          time_to_close_orders_before_expiry:time_to_close_orders_before_expiry,
          mid_price_percentage:StrangleMidPrice == '' ? 0 : StrangleMidPrice,
          delta_hedging_order_type:stranglesOrderType,
          hedge_only_for_loss:strangleshedgeOnlyForLoss,
          hedging_min_loss_type:minLossTypeStrangles,
          hedging_min_loss_value:minLossValueStrangles
        };
  
        await api({ contentType: true, auth: true }).post('/delta/update-order-settings', bodyParams).then((res) => {
          setForm2Loader({          
            loading: false,
          });
          toast.success("Bot settings updated");
        }).catch(err => {
          throw err
        })
      } catch (err) {
        handleError(err);
        setForm2Loader({
          loading: false,
        });
    }  
      
    }
    const telegramSettingSubmit = async(data)=>{
      try
      {
        setForm4Loader({
          loading: true,
        });

        if(data.tele_token.includes("**"))
        {

          var timeArr = botTime.split(":");
          const mismatch_get_hour = timeArr[0];
          const mismatch_get_minutes = timeArr[1];
          const mismatch_get_sec = timeArr[2];
          let mismatch_total_secs;
          const total_hours = Number(mismatch_get_hour) * 3600;
          const total_minutes = Number(mismatch_get_minutes) * 60;
          mismatch_total_secs = total_hours + total_minutes + Number(mismatch_get_sec);
          let waittime_mismatch_check_clock=mismatch_get_hour+':'+mismatch_get_minutes+':'+mismatch_get_sec;
          const bodyParams = {          
            sell_order_status: SellOrderStatus == true ? 1 : 0,
            buy_order_status: BuyOrderStatus == true ? 1 : 0,
            position_order_status: PositionOrderStatus == true ? 1 : 0,
            sl_order_status: SLOrderStatus == true ? 1 : 0,
            trigger_order_status: TriggerOrderStatus == true ? 1 : 0,
            cancelled_order_status: CancelledOrderStatus == true ? 1 : 0,
            mismatch_order_status:StrayPosOrderStatus == true ? 1 : 0,
            trend_signal_status:trendSignalStatus == true ? 1 : 0,
            universel_order_status: UniverselOrderStatus == true ? 1 : 0,
            filled_order_status: FilledOrderStatus == true ? 1 : 0,
            roll_order_status: RollOrderStatus == true ? 1 : 0,
            profit_order_status: ProfitOrderStatus == true ? 1 : 0,
            tele_token: data.tele_token,
            tele_chatid: data.tele_chatid,                  
            waittime_mismatch_check:mismatch_total_secs,
            waittime_mismatch_check_clock:waittime_mismatch_check_clock,
            admin_order_status: adminOrderStatus == true ? 1 : 0,
          }
          await api({ contentType: true, auth: true }).post('/delta/update-telegram-settings', bodyParams).then((res) => {
            setForm4Loader({
              loading: false,
            });
            toast.success("Telegram settings updated");
            const disply_tele_token = data.tele_token.slice(0,3)+'*****'+data.tele_token.slice(-3);
            setValue4('tele_token',disply_tele_token, { shouldValidate: false });
          }).catch(err => {
            throw err
          })

        }
        else
        {
          const check_status = await axios.get(`https://api.telegram.org/bot${data.tele_token}/getMe`)
            .then(async (res)=>{

              var timeArr = botTime.split(":");
              const mismatch_get_hour = timeArr[0];
              const mismatch_get_minutes = timeArr[1];
              const mismatch_get_sec = timeArr[2];
              let mismatch_total_secs;
              const total_hours = Number(mismatch_get_hour) * 3600;
              const total_minutes = Number(mismatch_get_minutes) * 60;
              mismatch_total_secs = total_hours + total_minutes + Number(mismatch_get_sec);
              let waittime_mismatch_check_clock=mismatch_get_hour+':'+mismatch_get_minutes+':'+mismatch_get_sec;
              const bodyParams = {          
                sell_order_status: SellOrderStatus == true ? 1 : 0,
                buy_order_status: BuyOrderStatus == true ? 1 : 0,
                position_order_status: PositionOrderStatus == true ? 1 : 0,
                sl_order_status: SLOrderStatus == true ? 1 : 0,
                trigger_order_status: TriggerOrderStatus == true ? 1 : 0,
                cancelled_order_status: CancelledOrderStatus == true ? 1 : 0,
                mismatch_order_status:StrayPosOrderStatus == true ? 1 : 0,
                trend_signal_status:trendSignalStatus == true ? 1 : 0,
                universel_order_status: UniverselOrderStatus == true ? 1 : 0,
                filled_order_status: FilledOrderStatus == true ? 1 : 0,
                roll_order_status: RollOrderStatus == true ? 1 : 0,
                profit_order_status: ProfitOrderStatus == true ? 1 : 0,
                tele_token: data.tele_token,
                tele_chatid: data.tele_chatid,
                waittime_mismatch_check:mismatch_total_secs,
                waittime_mismatch_check_clock:waittime_mismatch_check_clock,
                admin_order_status: adminOrderStatus == true ? 1 : 0,
              }
              await api({ contentType: true, auth: true }).post('/delta/update-telegram-settings', bodyParams).then((res) => {
                setForm4Loader({
                  loading: false,
                });                
                const disply_tele_token = data.tele_token.slice(0,3)+'*****'+data.tele_token.slice(-3);
                setValue4('tele_token',disply_tele_token, { shouldValidate: false });
                toast.success("Telegram settings updated");
              }).catch(err => {
                setForm4Loader({          
                  loading: false,
                });
                throw err
              })

            })
            .catch(err=>{
              setForm4Loader({          
                loading: false,
              });
              toast.error("Invalid token");
            })
        }
      }
      catch(err)
      {
        setForm4Loader({
          loading: false,
        });
        handleError(err);
      }
    }
    const handleStraddlesSubmitClick = async (e) => {
        e.preventDefault();
        setStraddleAdvancedSettings(true); 
    
        const isValid = await trigger5();
        if (!isValid && errors5 && Object.keys(errors5).length > 0) {
          // const field = Object.keys(errors)[0];
          // const fieldElement = document.getElementsByName(`${field}`)[0];
          // if (fieldElement) {
          //   console.log("fieldElement2", fieldElement);
          //   fieldElement.focus();
          //   fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
          // }
          return;
        }
      straddlesSettingSubmit()
    };

    const handleStranglesSubmitClick = async (e) => {
      e.preventDefault();
      setStrangleAdvancedSettings(true); 
  
      const isValid = await trigger2();
      if (!isValid && errors2 && Object.keys(errors2).length > 0) {
        // const field = Object.keys(errors)[0];
        // const fieldElement = document.getElementsByName(`${field}`)[0];
        // if (fieldElement) {
        //   console.log("fieldElement2", fieldElement);
        //   fieldElement.focus();
        //   fieldElement.scrollIntoView({ behavior: "smooth", block: "center" });
        // }
        return;
      }
      botSettingSubmit()
  };
    const straddlesSettingSubmit= async(form_datas)=>{
      try{
        if(straddleBotSettings.trigger_percentage >= straddleBotSettings.stop_loss_percentage)
        {
          throw toast.error("SL % must be more than Stop Loss Activate Percentage");
        } 
        setForm5Loader({
          loading: true,
        });
       
        const send_data =straddleBotSettings
          send_data['mid_price_percentage'] = StraddleMidPrice == '' ? 0 : StraddleMidPrice
          send_data['delta_hedging_interval'] = straddlesdeltaHedgingIntervel
          send_data['delta_hedging_range'] = straddlesdeltaHedgingRange
          send_data['delta_hedging_order_type'] = straddlesOrderType;
          send_data['reentry_enabled'] = straddlereentry_enabled == true ? 1 : 0
          send_data['wait_time_reentry'] = straddleswait_time_reentry
          send_data['time_to_close_orders_before_expiry'] = time_to_close_orders_before_expiry_straddles
          send_data['hedge_only_for_loss'] = straddleshedgeOnlyForLoss
          send_data['hedging_min_loss_type'] = minLossTypeStraddles
          send_data['hedging_min_loss_value'] = minLossValueStraddles
        await api({ contentType: true, auth: true }).post('/delta/update-straddles-settings', send_data).then((res) => {
          setForm5Loader({
            loading: false,
          });
          toast.success("Straddles settings updated");
        }).catch(err => {
          throw err
        })
      } catch (err) {
        if (err.response) {          
          handleError(err);
        }
        setForm5Loader({
          loading: false,
        });
      }

    }

  const getConfig = async () => {
    const result = await firestoreDb.collection("users").doc(uid).collection("config").doc('data');
    let config_data = false;
    await result.get().then((doc) => {
      if (doc.exists) {					
        config_data = doc.data()
      }
    });
    if (config_data) {
      var key_secret = __KEY_SECRET__;
      var decry_appkey  = CryptoJS.AES.decrypt(config_data.appKey, key_secret);
      var decryptedAppkey = JSON.parse(decry_appkey.toString(CryptoJS.enc.Utf8));        
      var decry_appsecret  = CryptoJS.AES.decrypt(config_data.appSecret, key_secret);
      var decryptedAppsecret = JSON.parse(decry_appsecret.toString(CryptoJS.enc.Utf8));
      const disply_apikey = decryptedAppkey.slice(0,3)+'******'+decryptedAppkey.slice(-3);
      const display_api_secret = decryptedAppsecret.slice(0,3)+'******'+decryptedAppsecret.slice(-3);
      setValue('api_key',disply_apikey, { shouldValidate: false });
      setValue('api_secret',display_api_secret, { shouldValidate: false });
      setValue('exchange_type',config_data.exchangeType?config_data.exchangeType:"India", { shouldValidate: false });
    }
  };
  const handleTrendSettingsChange = (e) => {
    setTrendSettings({
      ...trendSettings,
      [e.target.name]: e.target.value
  })}
   
  const getOrderSettings = async () => {
    //var get_profile_data = await firestoreDb.collection("users").doc(uid).collection("profile").doc('data');
    var get_tele_settings= await firestoreDb.collection("users").doc(uid).collection("order-settings").doc('telegramsettings-global');
    //var docRef=await firestoreDb.collection("users").doc(uid).collection("order-settings").doc('ordersettings-global');
    // var get_straddles_settings= await firestoreDb.collection("users").doc(uid).collection("order-settings").doc('straddlessettings-global');
    //var get_trend_settings =  await firestoreDb.collection("users").doc(uid).collection("order-settings").doc('trend-following-settings-global');

    // bot settings
    await api({ contentType: true, auth: true }).get('/strangles/getUserStranglesSettings')      
    .then((res_str)=>{
      if(res_str.data.response && res_str.data.response.data.toString().length > 0)
      {
        const order_settings = res_str.data.response.data;
    // docRef.get().then((doc) => {
    //   if (doc.exists) {
    //     const order_settings = doc.data();
        setIndexPercentage(order_settings.index_percentage ? order_settings.index_percentage : "10");
        setTriggerPercentage(order_settings.trigger_percentage ? order_settings.trigger_percentage :"25");
        setStopLossPercentage(order_settings.stop_loss_percentage ? order_settings.stop_loss_percentage : "30");
		    setStopLossIndexPercentage(order_settings.stop_loss_index_percentage ? order_settings.stop_loss_index_percentage : "0");  
        setTakeProfitPercentage(order_settings.take_profit_percentage ? order_settings.take_profit_percentage : "0");
        setRollPercentage(order_settings.roll_percentage ? order_settings.roll_percentage : "0");
        setQuantity(order_settings.quantity ? order_settings.quantity : "1");        
        setIndexDistance(order_settings.index_distance ? order_settings.index_distance : "0");        
		    setAutoMarketFill(order_settings.autoMarketFill == 0 ? false:true);
        setRollStatus(order_settings.reentry_enabled || order_settings.roll_status == 0 ? false:true);        
        setUniversalStop(order_settings.universal_stop == 1 ? true:false);
        setDeltahedging(order_settings.deltahedging == 1 ? true:false);
        setDeltahedging2(order_settings.delta_hedging_on_stop_loss_in_one_leg == 1 ? true:false);
        setstranglesDeltaHedgingIntervel(order_settings.delta_hedging_interval ? order_settings.delta_hedging_interval : Bot_settings_default.Delta_hedge.delta_hedging_interval);
        setStranglesOrderType(order_settings.delta_hedging_order_type ? order_settings.delta_hedging_order_type : Bot_settings_default.Delta_hedge.delta_hedging_order_type);
        setStranglesHedgeOnlyForLoss(order_settings.hedge_only_for_loss);
        setMinLossTypeStrangles(order_settings.hedging_min_loss_type);
        setMinLossValueStrangles(order_settings.hedging_min_loss_value);
        setstranglesDeltaHedgingRange(order_settings.delta_hedging_range ? order_settings.delta_hedging_range : Bot_settings_default.Delta_hedge.delta_hedging_range);
        setQtyTime(order_settings.Qty_time_clock);
        setStraddleMidPrice(order_settings.mid_price_percentage ? order_settings.mid_price_percentage : "0")
        setRollTime(order_settings.Roll_time_clock);                
        setValue2('index_distance',order_settings.index_distance, { shouldValidate: false });   
        setStrangleMinTakeProfit(order_settings.min_take_profit_percentage ? order_settings.min_take_profit_percentage : "0");   
        setStrangleTrailTakeProfit(order_settings.trailing_take_profit_percentage ? order_settings.trailing_take_profit_percentage : "0");
        setCombinedSl(order_settings.combined_sl_percentage? order_settings.combined_sl_percentage:0);
        setCombinedTrailProfitPercentage(order_settings.combined_trailing_profit_percentage? order_settings.combined_trailing_profit_percentage:0);
        setCombinedTrailSl(order_settings.combined_trailing_stop_price_percentage? order_settings.combined_trailing_stop_price_percentage:0);  
        setCombinedProfitPercentage(order_settings.combined_take_profit_percentage? order_settings.combined_take_profit_percentage:0);
        setSlTriggerStrangles(order_settings.Sl_trigger_take_profit? order_settings.Sl_trigger_take_profit:true); 
        setTraillingStrangles(order_settings.combined_trailing_profit? (order_settings.combined_trailing_profit == 1 ? true : false):false);
        setreentry_enabled(order_settings.reentry_enabled? (order_settings.reentry_enabled == 1 ? true : false):false);
        setstrangleswait_time_reentry(order_settings.wait_time_reentry? order_settings.wait_time_reentry : "0");
        settime_to_close_orders_before_expiry(order_settings.time_to_close_orders_before_expiry? order_settings.time_to_close_orders_before_expiry : "0");
        setStrangleMidPrice(order_settings.mid_price_percentage ? order_settings.mid_price_percentage : "0")
        updateStranglesRow(order_settings)
      }else{
		    setIndexPercentage("10");
        setTriggerPercentage("25");
        setStopLossPercentage("30");
		    setStopLossIndexPercentage("0");  
        setTakeProfitPercentage("0");
        setStrangleMidPrice("0")  
        setRollPercentage("0");
        setIndexDistance("0");
        settime_to_close_orders_before_expiry("0");
        setQuantity("1");        
        setRollStatus(false);        
        setUniversalStop(false);
        setDeltahedging(false);
        setDeltahedging2(false);
        setstranglesDeltaHedgingIntervel( Bot_settings_default.Delta_hedge.delta_hedging_interval);
        setstranglesDeltaHedgingRange( Bot_settings_default.Delta_hedge.delta_hedging_range);
        setQtyTime('0:30:0');
        setStraddleMidPrice("0")
        setRollTime('0:0:0');
        setAutoMarketFill(false);		 
        setValue2('index_distance',"0", { shouldValidate: true });
        setStrangleMinTakeProfit("0");
        setStrangleTrailTakeProfit("0");
        setCombinedSl(0);
        setCombinedTrailProfitPercentage(0);
        setCombinedTrailSl(0);  
        setCombinedProfitPercentage(0);
        setreentry_enabled(false);
        setstrangleswait_time_reentry("0");
	  }
    }).catch((e)=>{
      
      setPageLoader({        
          loading: false,
        });
    });

   
    
    // privacy settings
    // get_profile_data.get().then((data)=>{
    //   if(data.exists)
    //   {
    //     const profile_details = data.data();
    //     setAllowAdminStatus(profile_details.is_allow_admin_bot == 1 ? true : false);
    //     setAdminStrangleQty(profile_details.admin_strangle_quantity ? profile_details.admin_strangle_quantity : "1");
    //     setAdminDiagonalQty(profile_details.admin_diagonal_quantity ? profile_details.admin_diagonal_quantity : "1");
    //     setAdminStraddleQty(profile_details.admin_straddle_quantity ? profile_details.admin_straddle_quantity : "1");
    //     setAllowAdminTrendStatus(profile_details.is_allow_admin_trending_bot == 1 ? true : false)
    //     setAdminTrendFollowingQty(profile_details.admin_trend_following_qty ? profile_details.admin_trend_following_qty : "1");
    //   }
    //   else
    //   {
    //     setAllowAdminStatus(false);
    //     setAllowAdminTrendStatus(false);
    //     setAdminStrangleQty("1");
    //     setAdminDiagonalQty("1");
    //     setAdminStraddleQty("1");
    //     setAdminTrendFollowingQty("1")
    //   }
    // })

    const get_profile = await api({ contentType: true, auth: true }).get('/user/profile')
        .then(res => {
            if(res.data.response)
            {
              const profile_details = res.data.response.user;
              setAllowAdminStatus(profile_details.is_allow_admin_bot == 1 ? true : false);
              setAdminStrangleQty(profile_details.admin_strangle_quantity ? profile_details.admin_strangle_quantity : "1");
              setAdminDiagonalQty(profile_details.admin_diagonal_quantity ? profile_details.admin_diagonal_quantity : "1");
              setAdminStraddleQty(profile_details.admin_straddle_quantity ? profile_details.admin_straddle_quantity : "1");
              setAllowAdminTrendStatus(profile_details.is_allow_admin_trending_bot == 1 ? true : false)
              setAdminTrendFollowingQty(profile_details.admin_trend_following_qty ? profile_details.admin_trend_following_qty : "1");                              
            }
        })
        // .catch((e) => {

        //   setAllowAdminStatus(false);
        //   setAllowAdminTrendStatus(false);
        //   setAdminStrangleQty("1");
        //   setAdminDiagonalQty("1");
        //   setAdminStraddleQty("1");
        //   setAdminTrendFollowingQty("1")
        // })
    

    // straddles settings
    await api({ contentType: true, auth: true }).get('/straddles/getUserStraddlesSettings')      
    .then((res_str)=>{
      if(res_str.data.response && res_str.data.response.data.toString().length > 0)
      {
        const straddle_order_settings = res_str.data.response.data;

        //console.log(straddle_order_settings);
        setSlTriggerStraddles(straddle_order_settings.Sl_trigger_take_profit? straddle_order_settings.Sl_trigger_take_profit:true);
        setStraddleBotSettings(straddle_order_settings);   
        setTraillingStraddles(straddle_order_settings.combined_trailing_profit? (straddle_order_settings.combined_trailing_profit == 1 ? true : false):false);
        setStraddleMidPrice(straddle_order_settings.mid_price_percentage ? straddle_order_settings.mid_price_percentage : "0")
        setStraddlesDeltahedging(straddle_order_settings.deltahedging == 1 ? true:false)
        setStraddlesDeltahedging2(straddle_order_settings.delta_hedging_on_stop_loss_in_one_leg == 1 ? true:false)
        setstraddlesDeltaHedgingIntervel(straddle_order_settings.delta_hedging_interval ? straddle_order_settings.delta_hedging_interval : Bot_settings_default.Delta_hedge.delta_hedging_interval);
        setstraddlesDeltaHedgingRange(straddle_order_settings.delta_hedging_range ? straddle_order_settings.delta_hedging_range : Bot_settings_default.Delta_hedge.delta_hedging_range);
        setStraddlesOrderType(straddle_order_settings.delta_hedging_order_type ? straddle_order_settings.delta_hedging_order_type : Bot_settings_default.Delta_hedge.delta_hedging_order_type);
        setMinLossTypeStraddles(straddle_order_settings.hedging_min_loss_type)
        setMinLossValueStraddles(straddle_order_settings.hedging_min_loss_value)
        setStraddlesHedgeOnlyForLoss(straddle_order_settings.hedge_only_for_loss)
        setstraddleswait_time_reentry(straddle_order_settings.wait_time_reentry? straddle_order_settings.wait_time_reentry : "0");
        setstraddlereentry_enabled(straddle_order_settings.reentry_enabled? (straddle_order_settings.reentry_enabled == 1 ? true : false):false);
        settime_to_close_orders_before_expiry_straddles(straddle_order_settings.time_to_close_orders_before_expiry? straddle_order_settings.time_to_close_orders_before_expiry : "0");
        updateStraddlesRow(straddle_order_settings);
    }else{
        setStraddlesDeltahedging(false)
        setStraddleMidPrice("0")
        setStraddlesDeltahedging2(false)
        setstraddleswait_time_reentry("0");
        settime_to_close_orders_before_expiry_straddles("0");
        setstraddlereentry_enabled(false);
        setstraddlesDeltaHedgingIntervel(Bot_settings_default.Delta_hedge.delta_hedging_interval);
        setstraddlesDeltaHedgingRange(Bot_settings_default.Delta_hedge.delta_hedging_range);
        setStraddlesOrderType(Bot_settings_default.Delta_hedge.delta_hedging_order_type);
        updateStraddlesRow(straddleBotSettings);
    }
    })

    // telegram settings
    get_tele_settings.get().then((res) => {
    if(res.exists)
    {
      const telegram_settings = res.data();
      let disply_tele_token;
      if(telegram_settings.tele_token)
      {
        var key_secret = __KEY_SECRET__;
        var decry_tele_token  = CryptoJS.AES.decrypt(telegram_settings.tele_token, key_secret);
        var decryptedTeleToken = JSON.parse(decry_tele_token.toString(CryptoJS.enc.Utf8)); 
        disply_tele_token = decryptedTeleToken.slice(0,3)+'*****'+decryptedTeleToken.slice(-3);
      }
      setValue4('tele_token',disply_tele_token, { shouldValidate: false });
      setValue4('tele_chatid',telegram_settings.tele_chatid, { shouldValidate: false });
      setSellOrderStatus(telegram_settings.sell_order_status == 1 ? true : false);
      setBuyOrderStatus(telegram_settings.buy_order_status == 1 ? true : false);
      setPositionOrderStatus(telegram_settings.position_order_status == 1 ? true : false);
      setTriggerOrderStatus(telegram_settings.trigger_order_status == 1 ? true : false);
      setSLOrderStatus(telegram_settings.sl_order_status == 1 ? true : false);
      setCancelledOrderStatus(telegram_settings.cancelled_order_status == 1 ? true : false);
      setStrayPosOrderStatus(telegram_settings.mismatch_order_status == 1 ? true : false);
      setTrendSignalStatus(telegram_settings.trend_signal_status == 1 ? true : false);
      setUniverselOrderStatus(telegram_settings.universel_order_status == 1 ? true : false);
      setFilledOrderStatus(telegram_settings.filled_order_status == 1 ? true : false);
      setRollOrderStatus(telegram_settings.roll_order_status == 1 ? true : false);
      setProfitOrderStatus(telegram_settings.profit_order_status == 1 ? true : false);
      setAdminOrderStatus(telegram_settings.admin_order_status == 1 ? true : false);
      setBotTime(telegram_settings.waittime_mismatch_check_clock);
    }
    else
    {
      setValue4('tele_token','', { shouldValidate: false });
      setValue4('tele_chatid','', { shouldValidate: false });             
      setSellOrderStatus(false);
      setBuyOrderStatus(false);
      setPositionOrderStatus(false);
      setTriggerOrderStatus(false);
      setSLOrderStatus(false);
      setCancelledOrderStatus(false);
      setStrayPosOrderStatus(false);
      setTrendSignalStatus(false);
      setUniverselOrderStatus(false);
      setFilledOrderStatus(false);
      setRollOrderStatus(false);
      setProfitOrderStatus(false);
      setAdminOrderStatus(true);
      setBotTime("0:0:20");
    }
    }).catch((e)=>{
      console.log('telegram error :',e)
    });

    await api({ contentType: true, auth: true }).get('/trendfollowing/getUserTrendSettings')      
    .then((res_trend)=>{
      if(res_trend.data.response && res_trend.data.response.data.toString().length > 0)
      {
        const trend_settings = res_trend.data.response.data;
        setTrendSettings(trend_settings)
        setTrendStopLoss(trend_settings.stop_loss_percentage?trend_settings.stop_loss_percentage:"30");
        setTrendMinTakeProfit(trend_settings.min_take_profit_percentage?trend_settings.min_take_profit_percentage:"10");
        setTrendTrailTakeProfit(trend_settings.trailing_take_profit_percentage?trend_settings.trailing_take_profit_percentage:"5");
        setTrendQuantityLimit(trend_settings.total_quantity_limit?trend_settings.total_quantity_limit:"");
        setStrikeCount(trend_settings.strike_count?trend_settings.strike_count:1);
        setWaitTimeReentry(trend_settings.waitTimeForReEntry_clock?trend_settings.waitTimeForReEntry_clock:"0:20:0");
        setWaitTimeSL(trend_settings.waitTimeAfterStopLoss_clock?trend_settings.waitTimeAfterStopLoss_clock:"0:30:0");
        setWaitTimePrice(trend_settings.waitTimeForPrice_clock);
        setTrendIndexPer(trend_settings.index_distance);
        setIndexDistanceType(trend_settings.index_distance_type)
        setEnableTrendFollow(trend_settings.enable_trend_following ? true : false);
        setTrendClosePosition(trend_settings.close_old_positions == '1' ? true : false);
        setTrendChangeClosePosition(trend_settings.close_old_positions_trend_change == '1' ? true : false);
        setCloseOldOrdersOnlyOpenOrders(trend_settings.close_old_orders_only_open_orders ? true : false);
        setTrendFollowingQty(trend_settings.quantity ? trend_settings.quantity : "1");
        setTrendSide(trend_settings.trend_side?trend_settings.trend_side:"sell");
        setTrendDay(trend_settings.trend_day?trend_settings.trend_day:"D1");
        setTrendMidPrice(trend_settings.mid_price_percentage?trend_settings.mid_price_percentage:"0")
        setinterval(trend_settings.same_trend_trade_interval_clock)
        setnoOfTrade(trend_settings.same_trend_no_of_trade?trend_settings.same_trend_no_of_trade:"0")
      }else{
         
        setTrendStopLoss("30");
        setTrendMinTakeProfit("10");
        setTrendTrailTakeProfit("5");
        setWaitTimeReentry("0:20:0");
        setWaitTimeSL("0:30:0");
        setWaitTimePrice("0:05:0");
        setTrendIndexPer("3");
        setIndexDistanceType("percentage")
        setEnableTrendFollow(false);
        setTrendClosePosition(false);
        setTrendChangeClosePosition(false);
        setCloseOldOrdersOnlyOpenOrders(false);
        setTrendFollowingQty(1)
        setTrendSide("sell")
        setTrendDay("D1")
        setStrikeCount("1");
        setTrendMidPrice("0");
        
      }

    })
    

  }  
  const getServerDetails = async () => {
    getServerIP().then((res) => {
      if(res && res.data && res.data.response && res.data.response.server_details && res.data.response.server_details.ip_address)
      {
        if(res.data.response.server_details.ip_address !== '')
        {
          setServerIP(res.data.response.server_details.ip_address)
        }
      }
    }).catch(err => {
      console.log("err",err)
    })
  }
  useEffect(() => {
    getServerDetails();
    getDiagonalsSettings();
    getDHSettings();
    getConfig();
    getOrderSettings();
    setTeleMsg(telegramMsg());
    setTeleIDMsg(telegramIDMsg());
    setTeleChatIDMsg(telegramChatIDMsg());
    setTaillingTakeProfitMsg(TaillingTakeProfit());
    setDeltaHedgingMsg(Deltahedgingmsg())
    setMidPrizeMsg(MidPrize())
    setMintakeProfitMsg(MinimumTakeProfit())
    if(window.location.search && window.location.search.substring(1)=="trend-following"){
      setactiveTab("6")
      }
      else if(window.location.search && window.location.search.substring(1)=="straddles")
      {
        setactiveTab("5")
      }
      else if(window.location.search && window.location.search.substring(1)=="hedging")
      {
        setactiveTab("9")
      }else if (window.location.search && window.location.search.substring(1)=="diagonals"){
        setactiveTab("10")
    }else if (window.location.search && window.location.search.substring(1)=="strangles"){
      setactiveTab("2")
    }
  else if (window.location.search && window.location.search.substring(1)=="deri-bot"){
    setactiveTab("3")
  }
  }, []);

  const emptyApikey = () => {
    setValue('api_key','', { shouldValidate: false });
    setValue('api_secret','', { shouldValidate: false });
  }
  const handleModel = (e, row) =>{
    if(row.target === 'ONCLICK'){
      if(row.tooltip === 'visibleMin'){
        setVisibleMin(!visibleMin)
      }else if (row.tooltip === 'visibleTailling'){
        setVisibleTailling(!visibleTailling)
      }else if (row.tooltip === 'visibleMidPrize'){
        setVisibleMidPrize(!visibleMidPrize)
      }
    }
  }

  const updateStraddlesRow = (e,index,botSettingsvalue) =>{
    if(e && e.target){
      const updatedRows = rows.map((row, i) => {
      if (i === index) {
        let newValue = e.target.value === null || e.target.value === undefined || e.target.value === '' ? '' : Math.max(Number(e.target.getAttribute('min')), Math.min(Number(e.target.getAttribute('max')), Number(e.target.value)));
        var updatedRow = { ...row, settings: newValue };
       if(row.tooltip === 'visibleMidPrize'){
        setStraddleMidPrice(newValue)
       }else if(row.botSettingsvalue === 'Sl_trigger_take_profit'){
        updatedRow = {...updatedRow ,[botSettingsvalue]:  e.target.checked}
        setStraddleBotSettings({ ...straddleBotSettings, [botSettingsvalue]:  e.target.checked});
       }else{
        setStraddleBotSettings({ ...straddleBotSettings, [botSettingsvalue]:  newValue});
       }
       setValue5(row.name, e.target.value, { shouldValidate: true })
       trigger5(row.name)
       return updatedRow
      }
      return row
     })
      setRows(updatedRows);
    }else{
      const updatedRows = rows.map((row, i) => {
        const updatedRow = { ...row};

        if (row.tooltip === 'visibleMidPrize'){
          updatedRow.settings = e.mid_price_percentage
        }else if(row.botSettingsvalue === 'Sl_trigger_take_profit'){
          updatedRow.settings = e.Sl_trigger_take_profit
        }else{
          updatedRow.settings = e[row.botSettingsvalue]
        }
        setValue5(row.name, updatedRow.settings, { shouldValidate: true })
        return updatedRow;
      })
      setRows(updatedRows);
    }
  }

  const updateStranglesRow = (e,index,botSettingsvalue) =>{
    if(e && e.target){
      const updatedRows = stranglesRows.map((row, i) => {
      if (i === index) {
        let newValue = e.target.value === null || e.target.value === undefined || e.target.value === '' ? '' : Math.max(Number(e.target.getAttribute('min')), Math.min(Number(e.target.getAttribute('max')), Number(e.target.value)));
        var updatedRow = { ...row, settings: newValue };
        switch (row.botSettingsvalue) {
        case 'trigger_percentage':
            setTriggerPercentage(newValue)
            break;
        case 'stop_loss_percentage':
            setStopLossPercentage(newValue);
        break;
          case 'stop_loss_index_percentage':
		        setStopLossIndexPercentage(newValue);  
        break;
          case 'min_take_profit_percentage':
            setStrangleMinTakeProfit(newValue);   
        break;
          case 'trailing_take_profit_percentage':
            setStrangleTrailTakeProfit(newValue);
        break;
          case 'take_profit_percentage':
            setTakeProfitPercentage(newValue);
        break;
          case 'Sl_trigger_take_profit':
            setSlTriggerStrangles(e.target.checked); 
        break;
          case 'combined_sl_percentage':
            setCombinedSl(newValue);
        break;
          case 'combined_trailing_profit_percentage':
            setCombinedTrailProfitPercentage(newValue);
        break;
          case 'combined_trailing_stop_price_percentage':
            setCombinedTrailSl(newValue);  
        break;
          case 'combined_take_profit_percentage':
            setCombinedProfitPercentage(newValue);
        break;
          default:
            if(row.tooltip === 'visibleMidPrize'){
              setStrangleMidPrice(newValue)
            }
            break;
        }

       setValue2(row.name, e.target.value, { shouldValidate: true })
       trigger2(row.name)
       return updatedRow
      }
      return row
     })
      setStranglesRows(updatedRows);
    }else{
      const updatedRows = stranglesRows.map((row, i) => {
        const updatedRow = { ...row};

        if (row.tooltip === 'visibleMidPrize'){
          updatedRow.settings = e.mid_price_percentage
        }else{
          updatedRow.settings = e[row.botSettingsvalue]
        }
        
        setValue2(row.name, updatedRow.settings, { shouldValidate: true })
        return updatedRow;
      })
      setStranglesRows(updatedRows);
    }
  }

  const handleWheel = (e) => {
    e.target.blur();
  };

  if (ToolTipData=="" && ToolTipData.length === 0) {
    return <div>Loading...</div>;
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="DeriSnap" breadcrumbItem="Settings" />

          <div className="checkout-tabs settings_page">
            <Row>
              <Col lg="2" sm="3">
                <Nav className="flex-column" pills>

                <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "2" })}
                      onClick={() => {
                        setactiveTab("2")
                      }}
                    >
                      <i className="mdi mdi-alpha-s-box d-block check-nav-icon mt-4 mb-2" />
                      <p className="fw-bold mb-4">Strangles Bot</p>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "10" })}
                      onClick={() => {
                        setactiveTab("10")
                      }}
                    >
                      <i className="mdi mdi-alpha-d-box d-block check-nav-icon mt-4 mb-2" />
                      <p className="fw-bold mb-4">Diagonals Bot</p>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "5" })}
                      onClick={() => {
                        setactiveTab("5")
                      }}
                    >
                      <i className="bx bx-cube d-block check-nav-icon mt-4 mb-2" />
                      <p className="fw-bold mb-4">Straddles Bot</p>
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "6" })}
                      onClick={() => {
                        setactiveTab("6")
                      }}
                    >
                       <i className="bx bx-line-chart d-block check-nav-icon mt-4 mb-2" />
                      <p className="fw-bold mb-4">Trend Following Bot</p>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "9" })}
                      onClick={() => {
                        setactiveTab("9")
                      }}
                    >
                      <i className="mdi mdi-scale-balance d-block check-nav-icon mt-4 mb-2"/> 
                      <p className="fw-bold mb-4">Global Delta Hedging</p> 
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "1" })}
                      onClick={() => {
                        setactiveTab("1")
                      }}
                    >
                      <i className="bx bxs-lock d-block check-nav-icon mt-4 mb-2" />
                      <p className="fw-bold mb-4">API Keys</p>
                    </NavLink>
                  </NavItem>

                  
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "3" })}
                      onClick={() => {
                        setactiveTab("3")
                      }}
                    >
                      <i className="bx bx-badge-check d-block check-nav-icon mt-4 mb-2" />
                      <p className="fw-bold mb-4">Deri Bot</p>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "4" })}
                      onClick={() => {
                        setactiveTab("4")
                      }}
                    >
                      <i className="bx bxl-telegram d-block check-nav-icon mt-4 mb-2" />
                      <p className="fw-bold mb-4">Telegram</p>
                    </NavLink>
                  </NavItem>
                  
                </Nav>
              </Col>
              <Col lg="10" sm="9">
                <Card className="box_4">
                  <CardBody>
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1">
                        <div className="p-4 border">
                          <CardTitle>Delta API Settings <img src={deltalogo} alt="" width="20" /></CardTitle>
                          <p className="card-title-desc">
                          Changing the key & secret will disconnect the active calls in our bot, so do not change the key & secret without support team.
                          </p>
                          <div className="alert alert-info mb-3">Make sure you have whitelisted our IP address <b>{serverIP}</b> in your API key settings </div>
                          <form key={1} onSubmit={handleSubmit(keySettingSubmit)}>
                          <FormGroup className="mb-4" row>
                            <Label
                              htmlFor="formrow-exchange-type"
                              md="2"
                              className="col-form-label"
                            >
                              Exchange Region
                            </Label>
                            <Col md="10" className="mt-2">
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="exchange_type"
                                  id="india"
                                  value="India"
                                  {...register("exchange_type", { required: true })}
                                  onChange={emptyApikey}
                                />
                                <label className="form-check-label" htmlFor="india">
                                  India
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="exchange_type"
                                  id="global"
                                  value="Global"
                                  {...register("exchange_type", { required: true })}
                                  onChange={emptyApikey}
                                />
                                <label className="form-check-label" htmlFor="global">
                                  Global
                                </label>
                              </div>
                              {errors.exchange_type && (
                                <small className="text-danger">Please select an exchange region</small>
                              )}
                            </Col>
                          </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="formrow-firstname-Input"
                                md="2"
                                className="col-form-label"
                              >
                                API Key <i className="bx bx-info-circle" id ="apikey" />
                                    <UncontrolledTooltip placement="top" target="apikey"> 
                                        {ToolTipData.api_key}
                                    </UncontrolledTooltip>
                              </Label>
                              <Col md="10">
                                <input
                                  type="text"
                                  name="api_key"
                                  className="form-control"                                  
                                  placeholder="Enter your key"
                                  {...register("api_key", { required: true})} autoComplete="off" 
                                />
                                {errors.api_key && <small className="text-danger">Please provide a api Key</small>}
                                
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="formrow-firstname-Input"
                                md="2"
                                className="col-form-label"
                              >
                                API Secret <i className="bx bx-info-circle" id ="apisecret" />
                                    <UncontrolledTooltip placement="top" target="apisecret">
                                        {ToolTipData.api_secret}
                                    </UncontrolledTooltip>
                              </Label>
                              <Col md="10">
                                <input
                                  type="text"
                                  name="api_secret"
                                  className="form-control"                                  
                                  placeholder="Enter your secret"
                                  {...register("api_secret", { required: true})}  autoComplete="off" 
                                />
                                {errors.api_secret && <small className="text-danger">Please provide a api secret</small>}
                              </Col>
                            </FormGroup> 

                           

                            <div className="text-sm-end">
                            <Button className="btn-label w-md" color="success" type="submit" disabled={form1_loader.loading}>
                              {form1_loader.loading ? <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> : ''}
                              <i className="bx bx-smile label-icon"></i> Submit
                            </Button>
                            </div>
                          </form>
                        </div>

                        {/* <div className="p-4 border">
                          <CardTitle>Binance API Settings <img src={binancelogo} alt="" width="20" /></CardTitle>
                          <p className="card-title-desc">
                          Changing the key & secret will disconnect the active calls in our bot, so do not change the key & secret without support team.
                          </p>
                          <form key={6} onSubmit={handleSubmit6(BinanceKeySettingSubmit)}>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="formrow-firstname-Input"
                                md="2"
                                className="col-form-label"
                              >
                                API Key
                              </Label>
                              <Col md="10">
                                <input
                                  type="text"
                                  name="binance_api_key"
                                  className="form-control"                                  
                                  placeholder="Enter your key"
                                  {...register6("binance_api_key", { required: true})}
                                />
                                {errors6.binance_api_key && <small className="text-danger">Please provide a api Key</small>}
                                
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="formrow-firstname-Input"
                                md="2"
                                className="col-form-label"
                              >
                                API Secret
                              </Label>
                              <Col md="10">
                                <input
                                  type="text"
                                  name="binance_api_secret"
                                  className="form-control"                                  
                                  placeholder="Enter your secret"
                                  {...register6("binance_api_secret", { required: true})}
                                />
                                {errors6.binance_api_secret && <small className="text-danger">Please provide a api secret</small>}
                              </Col>
                            </FormGroup> 
                            <div className="text-sm-end">
                            <Button className="btn-label w-md" color="success" type="submit">
                              {form1_loader.loading ? <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> : ''}
                              <i className="bx bx-smile label-icon"></i> Submit
                            </Button>
                            </div>
                          </form>
                        </div> */}
                      </TabPane>
                      <TabPane
                        tabId="2"
                        id="v-pills-payment"
                        role="tabpanel"
                        aria-labelledby="v-pills-payment-tab"
                      >
                        <div>
                          <CardTitle>Strangles Bot Settings</CardTitle>                          
                          <p className="card-title-desc"> You can also change these settings while starting a strangles bot.</p>
                          <form key={2} onSubmit={(e) => {handleStranglesSubmitClick(e)}}>
                            <Row>
                            <Col lg="3">
                                <FormGroup className="mt-4 mb-0">
                                  <Label htmlFor="formrow-firstname-Input">
                                    Quantity
                                  </Label>{" "}
                                  <i className="bx bx-info-circle" id ="quantity" />
                                    <UncontrolledTooltip placement="top" target="quantity">
                                        {ToolTipData.quantity}
                                    </UncontrolledTooltip>
                                  {/* <Slider value={quantity?quantity:1} min={1} max={1000} onChange={value => {setQuantity(value)}} /> */}
                                  <input autoComplete="off"  type="number" onWheel={handleWheel}   min={0} max={1000} value={quantity ? quantity:''} onChange={(e) => {e.target.value <= 1000 ? setQuantity(e.target.value) : setQuantity(1000)} } onBlur={(e) => setQuantity(e.target.value)} name="quantity" className="form-control" />
                                  <NavLink
                                    className="text-end"
                                    onClick={() => {
                                      setactiveTab("3")
                                    }}
                                  >
                                    {/* <i className="bx bx-badge-check d-block check-nav mt-4 mb-2" /> */}
                                   <span className="card-title-desc">To manage quantity for the admin created bots, auto bots, <span className="fw-bold">check here</span> </span>
                                  </NavLink>
                                </FormGroup>
                              </Col>
                              <Col lg="3">
                                <FormGroup className="mt-4 mb-0">
                                  <Label htmlFor="formrow-firstname-Input">
                                    Index % 
                                  </Label>{" "}
                                  <i className="bx bx-info-circle" id ="index" />
                                    <UncontrolledTooltip placement="top" target="index"> 
                                        {ToolTipData.index_percentage}
                                    </UncontrolledTooltip>
                                  {/* <Slider value={indexPercentage?indexPercentage:0} min={0} max={100} step={0.5} onChange={value => {setIndexPercentage(value)}} /> */}
                                  <input autoComplete="off"  type="number" onWheel={handleWheel}    max={100} step={0.5} value={indexPercentage} onChange={(e) => { e.target.value <= 100 ? setIndexPercentage(e.target.value) : setIndexPercentage(100)} } onBlur={(e) => e.target.value <= 100 ? setIndexPercentage(e.target.value) : setIndexPercentage(100)} name="index_percentage" className="form-control" />
                                </FormGroup>
                              </Col>
                            
                             
                              </Row>
                              <Row className="border opacity-75 bg-light ">
                              <Col lg="2">
                                <h5 className="mt-5 mb-3 font-size-15">
                                  Re-entry Settings
                                </h5>
                                </Col>
                                <Col lg="9">
                                
                                  <Row>
                              <Col lg="2">
                                <div className="mt-4 mb-3">
                                  <Label htmlFor="formrow-firstname-Input">
                                  Re-Entry
                                  {" "}
                                  <i className="bx bx-info-circle" id ="reentry_enabledreentry_enabled" />
                                    <UncontrolledTooltip placement="top" target="reentry_enabledreentry_enabled">
                                    <div className="tooltip-content">
                                          <div className="tooltip-header"><h6>Enable Re-Entry</h6></div>
                                      <hr className="m-2 p-0"/>
                                      <div className="tooltip-body">{ToolTipData.reentry_enabled}</div></div>
                                    </UncontrolledTooltip></Label>
                                    {/* <input type="checkbox" className="btn-check" id="btn-check-outlined2"  onClick={(e)=> {setDeltahedging2(e.target.checked)}}/><br></br>
                                      <label style={{width:"80px"}} className={"btn " + (Deltahedging2 === true ? "btn-outline-success" : "btn-outline-danger")} htmlFor="btn-check-outlined2" >{Deltahedging2 === true ? "Disable" : "Enable"}</label><br></br> */}
                                <div>
                             
                                      <Switch
                                      uncheckedIcon={<Offsymbol />}
                                      checkedIcon={<OnSymbol />}
                                                                
                                      onChange={(e) => setreentry_enabled(e)} 
                                      checked={reentry_enabled}
                                    />
                                    </div>
                                     </div>
                              </Col>
                              {reentry_enabled?<>
                                  <Col lg="4">
                                  <FormGroup className="mt-4 mb-0">
                                        <Label htmlFor="formrow-firstname-Input">
                                        Re-Entry Wait (H:M)
                                        </Label> {" "} 
                              <i className="bx bx-info-circle" id ="DHINTERVALSTRANGLESwait_time_reentry" />
                                    <UncontrolledTooltip placement="top" target="DHINTERVALSTRANGLESwait_time_reentry"> 
                                    <div className="tooltip-content">
                                        <div className="tooltip-header"><h6>Waiting time for Re-Entry (H : M)</h6></div>
                                    <hr className="m-2 p-0"/>
                                    <div className="tooltip-body">{ToolTipData.wait_time_reentry }</div></div>
                                    </UncontrolledTooltip> 
                                        <Flatpickr
                                          className="form-control d-block"
                                          placeholder="Select time"
                                          name="wait_time_reentry"
                                          onChange={(e) => handleDH(e,'strangleswait_time_reentry')}
                                          value={toHoursAndMinutes(strangleswait_time_reentry)} 
                                          options={{                                      
                                            enableTime: true,
                                            noCalendar: true,
                                            dateFormat: "H:i",
                                            time_24hr: true,
                                          }}
                                        />
                                    </FormGroup>  
                                  </Col>
                                  <Col lg="4">
                                  <FormGroup className="mt-4 mb-0">
                                        <Label htmlFor="formrow-firstname-Input">
                                        Close Before Exp (H:M)
                                        </Label> {" "} 
                              <i className="bx bx-info-circle" id ="time_to_close_orders_before_expiry" />
                                    <UncontrolledTooltip placement="top" target="time_to_close_orders_before_expiry"> 
                                    <div className="tooltip-content">
                                        <div className="tooltip-header"><h6>Time to Close Orders Before Expiry (H : M)</h6></div>
                                    <hr className="m-2 p-0"/>
                                    <div className="tooltip-body">{ToolTipData.time_to_close_orders_before_expiry }</div></div>
                                    </UncontrolledTooltip> 
                                        <Flatpickr
                                          className="form-control d-block"
                                          placeholder="Select time"
                                          name="wait_time_reentry"
                                          onChange={(e) => handleDH(e,'time_to_close_orders_before_expiry')}
                                          value={toHoursAndMinutes(time_to_close_orders_before_expiry)} 
                                          options={{                                      
                                            enableTime: true,
                                            noCalendar: true,
                                            dateFormat: "H:i",
                                            time_24hr: true,
                                            maxTime: "12:00"
                                          }}
                                        />
                                    </FormGroup>  
                                  </Col>
                                  </>:''}
                                  </Row>
                                 
                                  </Col>
                                  </Row>
                                  {!reentry_enabled && 
                                  <Row className="border opacity-75">
                                  <Col lg="2">
                                    <h5 className="mt-5 mb-3 font-size-15">
                                      Roll Settings
                                    </h5>
                                    </Col>
                                    <Col>
                                      
                                        <Row>
                                          <Col lg="2">
                                          <div className="mt-4 mb-3">
                                            <Label htmlFor="formrow-firstname-Input">
                                              Enable Roll
                                            {" "}
                                            <i className="bx bx-info-circle" id ="enableroll" />
                                              <UncontrolledTooltip placement="top" target="enableroll">
                                                  {ToolTipData.roll_enable}
                                              </UncontrolledTooltip></Label>
                                            <div>
                                                <Switch
                                                uncheckedIcon={<Offsymbol />}
                                                checkedIcon={<OnSymbol />}
                                                className="me-1 mb-sm-8 mb-2"                                      
                                                onChange={(e) => setRollStatus(e)} 
                                                checked={rollStatus}
                                              />
                                              </div>
                                              
                                          </div>
                                        </Col>
                                        {rollStatus ? 
                                          <>
                                        <Col lg="3">
                                        <FormGroup className="mt-4 mb-0">
                                            <Label htmlFor="formrow-firstname-Input">
                                            Roll Time (H:M)
                                            {" "}   
                                            <i className="bx bx-info-circle" id ="roll" />
                                              <UncontrolledTooltip placement="top" target="roll">
                                              <div className="tooltip-content">
                                                  <div className="tooltip-header"><h6>When to Roll? (H : M)</h6></div>
                                              <hr className="m-2 p-0"/>
                                              <div className="tooltip-body">{ToolTipData.roll_wait_time}</div></div>
                                              </UncontrolledTooltip></Label>                               
                                            <Flatpickr
                                              className="form-control d-block"
                                              placeholder="Select time"
                                              onChange={(e) => setRollTime(new Date(e).getHours()+':'+new Date(e).getMinutes()+':'+new Date(e).getSeconds())} 
                                              value={rollTime}
                                              options={{                                          
                                                enableTime: true,
                                                noCalendar: true,
                                                dateFormat: "H:i",
                                                time_24hr: true,
                                              }}
                                            />
                                          </FormGroup>
                                        </Col>
                                        <Col lg="3">
                                          <FormGroup className="mt-4 mb-0">
                                            <Label htmlFor="formrow-firstname-Input">
                                            Dist from index
                                            </Label>{" "}      
                                            <i className="bx bx-info-circle" id ="dis_index" />
                                              <UncontrolledTooltip placement="top" target="dis_index">
                                              <div className="tooltip-content">
                                                <div className="tooltip-header"><h6>Distance from index</h6></div>
                                            <hr className="m-2 p-0"/>
                                            <div className="tooltip-body">{ToolTipData.roll_index_min_distance}</div></div>
                                              </UncontrolledTooltip>                            
                                            <input  type="number" onWheel={handleWheel}  onChange={(e) => setIndexDistance(e.target.value)} onBlur={(e) => setIndexDistance(e.target.value)} name="index_distance" {...register2("index_distance", { required: true,pattern: /^(0|[1-9]\d*)(\.\d+)?$/ })} className="form-control" />
                                            {errors2.index_distance && <small className="text-danger">Please provide a valid distance from index</small>}
                                          </FormGroup>
                                        </Col>
                                        <Col lg="3">
                                      <FormGroup className="mt-4 mb-0">
                                          <Label htmlFor="formrow-firstname-Input">
                                            Premium Roll %
                                          </Label>{" "}
                                          <i className="bx bx-info-circle" id ="pre_roll" />
                                              <UncontrolledTooltip placement="top" target="pre_roll">
                                                  {ToolTipData.roll_premium_min_percentage}
                                              </UncontrolledTooltip> 
                                          {/* <Slider value={rollPercentage?rollPercentage:0} min={1} max={100} step={0.5} onChange={value => {setRollPercentage(value)}} /> */}
                                          <input  type="number" onWheel={handleWheel}    max={100} step={0.5} value={rollPercentage} onChange={(e) => { e.target.value <= 100 ?  setRollPercentage(e.target.value) : setRollPercentage(100)} } onBlur={(e) => e.target.value <= 100 ?  setRollPercentage(e.target.value) : setRollPercentage(100)} name="roll_percentage" className="form-control" />
                                        </FormGroup>
                                        </Col>
                                        </>
                                        :''}
                                      </Row>
                                      
                                  </Col>
                                  </Row>
                                    }
                                     <div  className="mb-1 mt-1" >
                                 <a href="#"
                                  onClick={() => {
                                    setStrangleAdvancedSettings(!strangleAdvancedSettings);
                                  }}
                               className="mb-3" >
                                  Advanced settings
                                </a></div>
                              {strangleAdvancedSettings ? <div>
                                <div className="panel-body table-responsive">
                                        {/* <table className="table table-bordered"> */}
                                        <Table className={"table table-bordered table align-middle table-nowrap table-check table-responsive"}>
                                          <thead>
                                          <tr style={{textAlign:'left'}}>
                                              <th>Settings</th>
                                              <th>Setting Values</th>
                                              <th>Info</th>
                                            </tr>
                                          </thead>
                                          <tbody style={{textAlign: 'left'}}>
                                          {stranglesRows.map((row, index) => (
                                          <>
                                         { index === 1 || index === 4 || index == 8 || index == 12 || index == 13 || index == 14 ? <tr  className="table-light"><td ></td><td></td><td></td></tr>:''}
                                         <tr key={`strangles-${index}`}>
                                         <td>{row.label}{ row.label === 'Waiting time to fill Qty (H : M)' || row.label === 'Automatic Market Fill' || row.label === 'Universal Stop' ? ' ' :  " %  "}<i className="bx bx-info-circle" id={row.target} onClick={(e) => { handleModel(e, row);} }/>
                                          { row.target === 'ONCLICK' ? '': <UncontrolledTooltip placement="top" target={row.target}>
                                           <div className="tooltip-content">
                                           <div className="tooltip-header"><h6>{row.orgSettingsName}</h6></div>
                                           <hr className="m-2 p-0"/>
                                           {/* <div className="tooltip-body">{}</div> */}
                                           <div style={{textAlign:"left"}} className="tooltip-body" dangerouslySetInnerHTML={{ __html: ToolTipData[row.tooltip] }} />
                                           </div>
                                            </UncontrolledTooltip>}
                                          </td>      
                                        {row.label === 'Activate Trailing' || row.label === 'Waiting time to fill Qty (H : M)' || row.label === 'Automatic Market Fill' || row.label === 'Universal Stop' ? "" : (
                                            <td>
                                            {row.label === 'Activate Trailing' ? '' : (
                                              <div className="d-flex align-items-center" style={{ width: 'auto', maxWidth: '80px' }}>
                                                <Input
                                                  type="number"
                                                  max={row.max}
                                                  step={row.step}
                                                  min={row.min}
                                                  invalid={errors2[row.name]?.message ? true : false}
                                                  {...register2(row.name, row.validation || {})}
                                                  className="form-control"
                                                  id={row.tooltip}
                                                  name={row.name}
                                                  value={row.settings}
                                                  style={{ width: '80px', backgroundImage: 'none' }}
                                                  onChange={(e) => updateStranglesRow(e, index,row.botSettingsvalue)}
                                                  onInput={(e) => updateStranglesRow(e, index,row.botSettingsvalue)}
                                                />
                                                
                                                {errors2[row.name]?.message && (
                                                  <div style={{marginLeft: '10px'}}>
                                                    <small className="text-danger">{errors2[row.name]?.message}</small>
                                                  </div>
                                                )}
                                              </div>
                                            )}
                                          </td>
                                          )}
                                        {row.label === 'Activate Trailing' ? (<><td><input
                                          type="checkbox"
                                          className="form-check-input"
                                          id="straddlesMin"
                                          checked={SlTriggerStraddles}
                                          onClick={(event) => { setSlTriggerStraddles(event.target.checked);updateStranglesRow(event, index,row.botSettingsvalue) }}
                                        />{" "}<label className="form-check-label font-size-10" htmlFor="straddlesMin">{row.check_box_label}</label><p style={{fontSize:'8px'}}>Activates trailing and take profit after stop loss hit on one leg.</p></td></>):""}
                                        
                                        {row.label === 'Waiting time to fill Qty (H : M)' ? 
                                                                                (<><td> <Flatpickr
                                                                                    style={{ width: '80px', backgroundImage: 'none' }}
                                                                                    className="form-control d-block"
                                                                                    placeholder="Select time" 
                                                                                    onChange={(e) => setQtyTime(new Date(e).getHours()+':'+new Date(e).getMinutes()+':'+new Date(e).getSeconds())} 
                                                                                    value={qtyTime} 
                                                                                    options={{
                                                                                        enableTime: true,
                                                                                        noCalendar: true,
                                                                                        dateFormat: "H:i",
                                                                                        time_24hr: true
                                                                                    }}
                                                                                /></td></>)
                                                                                : 
                                                                                row.label === 'Automatic Market Fill' ? 
                                                                                (<><td> <Switch
                                                                                    uncheckedIcon={<Offsymbol />}
                                                                                    checkedIcon={<OnSymbol />}
                                                                                    className="me-1 mb-sm-8 mb-2"
                                                                                    onChange={(e) => setAutoMarketFill(e)} 
                                                                                    checked={autoMarketFill}
                                                                                /></td></>):
                                                                                row.label === 'Universal Stop' ? 
                                                                                (<><td><Switch
                                                                                    uncheckedIcon={<Offsymbol />}
                                                                                    checkedIcon={<OnSymbol />}
                                                                                    className="me-1 mb-sm-8 mb-2"
                                                                                    onChange={(e) => setUniversalStop(e)} 
                                                                                    checked={universalStop}  
                                                                                /></td></>)                                      
                                                                                :
                                                                                '' }
                                      <td>{row.label === 'Waiting time to fill Qty (H : M)' || row.label === 'Automatic Market Fill' || row.label === 'Universal Stop' ? '' :<><i style={{ cursor: 'pointer', fontSize: '1rem', padding: '0.5rem' }} className="bx bx-detail" id={(row.target)+(row.tooltip)+(row.botSettingsvalue)} />
                                            <UncontrolledTooltip style={{maxWidth: "none",width: "auto"}} placement="top" target={(row.target)+(row.tooltip)+(row.botSettingsvalue)}>
                                              <div className="tooltip-content">
                                      <div className="tooltip-header"><h6>Details</h6></div>
                                  <hr className="m-2 p-0"/>
                                  <div className="tooltip-body" style={{textAlign:'left'}}>{row.formula}</div></div>
                                          </UncontrolledTooltip></> }
                                            </td>                    
                                    </tr>    
                                    </>
                                  ))}
                                  
                                            
                                          </tbody>
                                        </Table>
                                        
                                        {/* </table> */}
                                        </div>
                              <Row className="border opacity-75">
                                  <Col lg="2" >
                                    <h5 className="mt-5 mb-3 font-size-15">
                                    Delta Hedging Settings
                                    </h5>
                                    </Col>
                                    <Col>
                                        <Row>
                                          <Col >
                                          <div className="mt-4 mb-3">
                              <Row>
                              <Col lg="3">
                                <div  >
                                  <Label htmlFor="formrow-firstname-Input">
                                  Enable Hedging {" "}
                                  <i className="bx bx-info-circle" id ="EDH" />
                                    <UncontrolledTooltip placement="top" target="EDH">
                                    {ToolTipData.delta_hedging}
                                    </UncontrolledTooltip>
                                  </Label>
                                    {/* <input type="checkbox" className="btn-check" id="btn-check-outlined"  onClick={(e)=> {setDeltahedging(e.target.checked)}}/><br></br>
                                      <label style={{width:"80px"}} className={"btn " + (Deltahedging === true ? "btn-outline-success" : "btn-outline-danger")} htmlFor="btn-check-outlined" >{Deltahedging === true ? "Disable" : "Enable"}</label><br></br> */}
                                      
                            
                              <div>
                                      <Switch
                                      uncheckedIcon={<Offsymbol />}
                                      checkedIcon={<OnSymbol />}
                                      className="me-1 mb-sm-8 mb-2"                                      
                                      onChange={(e) => setDeltahedging(e)} 
                                      checked={Deltahedging}
                                    />
                                    </div>
                                        </div>
                                    </Col>
                              {/* </Row> */}
                              {
                                Deltahedging ? <>
                                {/* <Row> */}
                              <Col lg="3">
                                <div>
                                  <Label htmlFor="formrow-firstname-Input">
                                  Disable SL Leg {" "}
                                  <i className="bx bx-info-circle" id ="DHOSLIOL" />
                                    <UncontrolledTooltip placement="top" target="DHOSLIOL">
                                    <div className="tooltip-content">
                                        <div className="tooltip-header"><h6>Disable on SL in  a Leg</h6></div>
                                    <hr className="m-2 p-0"/>
                                    <div className="tooltip-body">{ToolTipData.delta_hedging_on_stop_loss_in_one_leg}</div></div>
                                    </UncontrolledTooltip>
                                  </Label>
                                    {/* <input type="checkbox" className="btn-check" id="btn-check-outlined2"  onClick={(e)=> {setDeltahedging2(e.target.checked)}}/><br></br>
                                      <label style={{width:"80px"}} className={"btn " + (Deltahedging2 === true ? "btn-outline-success" : "btn-outline-danger")} htmlFor="btn-check-outlined2" >{Deltahedging2 === true ? "Disable" : "Enable"}</label><br></br> */}
                                 <div>
                              
                                      <Switch
                                      uncheckedIcon={<Offsymbol />}
                                      checkedIcon={<OnSymbol />}
                                      className=" me-1 mb-sm-8 mb-2"                                      
                                      onChange={(e) => setDeltahedging2(e)} 
                                      checked={Deltahedging2}
                                    />
                                    </div>
                                    </div>
                              </Col>
                              </>:''}
                              
                              {
                                Deltahedging ? <>
                                
                              <Col lg="5">
                                  <div className="">
                                        <Label htmlFor="formrow-firstname-Input">
                                        Delta Check Interval (H:M) {" "} 
                              <i className="bx bx-info-circle" id ="DHINTERVALSTRANGLES" />
                                    <UncontrolledTooltip placement="top" target="DHINTERVALSTRANGLES"> 
                                    <div className="tooltip-content">
                                        <div className="tooltip-header"><h6>Interval time for delta checking (H : M)</h6></div>
                                    <hr className="m-2 p-0"/>
                                    <div className="tooltip-body">{ToolTipData.delta_hedging_interval}</div></div>
                                    </UncontrolledTooltip> 
                                        </Label> 
                                        <Flatpickr
                                          className="form-control d-block"
                                          placeholder="Select time"
                                          name="waitTimeForPrice"
                                          onChange={(e) => handleDH(e,'strangles')}
                                          value={toHoursAndMinutes(stranglesdeltaHedgingIntervel)} 
                                          options={{                                      
                                            enableTime: true,
                                            noCalendar: true,
                                            dateFormat: "H:i",
                                            time_24hr: true,
                                          }}
                                        />
                                    </div>  
                                  </Col>
                                  <Col lg="2">
                                <FormGroup className="mt-4">
                                    <Label htmlFor="formrow-firstname-Input">
                                    Range {" "}
                              <i className="bx bx-info-circle" id ="DHDELTARANGESTRANGLES" />
                                    <UncontrolledTooltip placement="top" target="DHDELTARANGESTRANGLES"> 
                                    <div className="tooltip-content">
                                    <div className="tooltip-header"><h6>Delta range</h6></div>
                                <hr className="m-2 p-0"/>
                                <div className="tooltip-body">{ToolTipData.delta_hedging_range}</div></div>
                                    </UncontrolledTooltip>
                                    </Label>
                                    <input autoComplete="off" type="number" onWheel={handleWheel}  inputMode="numeric"  min={0.0001}    max={100}  step=".0001" value={stranglesdeltaHedgingRange} onChange={(e) => {setstranglesDeltaHedgingRange(e.target.value),setStraddleBotSettings({ ...straddleBotSettings,delta_hedging_range:e.target.value})}} name="delta_Range" className="form-control" />
                                  </FormGroup>
                              </Col>
                              <Col lg="2">
                                <FormGroup className="mt-4 mb-0">
                                    <Label htmlFor="formrow-firstname-Input">
                                      Order type {" "}
                                    <i className="bx bx-info-circle" id ="DHORDER" />
                                    <UncontrolledTooltip placement="top" target="DHORDER">
                                      {ToolTipData.delta_hedging_order_type}
                                    </UncontrolledTooltip>  
                                    </Label>
                                    <select name="order_type" label="order_type" className="form-select" onChange={(e) => setStranglesOrderType(e.target.value)} value={stranglesOrderType}>
                                      <option value='market_order'>Market Order</option>
                                      <option value='limit_order'>Limit Order</option>
                                    </select>
                                  </FormGroup>
                              </Col>
                              <Col lg="4">
                                <FormGroup className="mt-4 mb-0">
                                   <Label htmlFor="formrow-firstname-Input">
                                   Hedge on Loss Option Only {" "}
                                    <i className="bx bx-info-circle" id ="DHORDERLoss" />
                                    <UncontrolledTooltip placement="top" target="DHORDERLoss">
                                    <div className="tooltip-content">
                                        <div className="tooltip-header"><h6>Hedge only for the option with loss</h6></div>
                                    <hr className="m-2 p-0"/>
                                    <div className="tooltip-body">{ToolTipData.hedge_only_for_the_option_with_loss}</div></div>
                                    </UncontrolledTooltip>
                                    </Label>
                                    <Col md="2">
                                    <Switch
                                    uncheckedIcon={<Offsymbol />}
                                    checkedIcon={<OnSymbol />}
                                    className="me-1 mb-sm-8 mb-2"
                                    //onColor="#626ed4"
                                    onChange={(e) => setStranglesHedgeOnlyForLoss(e)}
                                    checked={strangleshedgeOnlyForLoss}
                                  />
                                  </Col>
                                </FormGroup>
                              </Col>
                              {strangleshedgeOnlyForLoss? <Col lg="4"> 
                          <div className="mt-4">

                            <div>
                              <Label className="form-label">Min Loss for Delta Hedging{" "}
                              <i className="bx bx-info-circle" id ="DHMINLOSS" />
                                    <UncontrolledTooltip placement="top" target="DHMINLOSS"> 
                                        {ToolTipData.delta_hedging_min_loss}
                                    </UncontrolledTooltip></Label>
                                <FormGroup className="input-group mb-0 ">
                                 
                                    
                                    <select
                              className="form-control" name="hedging_min_loss_type_strangle"  id="inputGroupFileAddon04"  onChange={(e) => setMinLossTypeStrangles(e.target.value)} value={minLossTypeStrangles?minLossTypeStrangles:'percentage'} 
                            >
                              <option value="percentage">%</option>
                              <option value="points">Pts</option>
                            </select>                                  
                                    <input autoComplete="off" type="number" onWheel={handleWheel}  inputMode="numeric"   min={0} max={minLossTypeStrangles!='points'?100:100000} step={0.5} value={minLossValueStrangles?minLossValueStrangles:0} onChange={(e) => setMinLossValueStrangles(e.target.value)} name="min_loss_val_strangle" className="form-control w-50"  aria-describedby="inputGroupFileAddon04" />
                                  </FormGroup> </div></div>
                              </Col> :''}
                             

                              </> : ''  }
                              </Row>
                              
                            
                              </div>
                              </Col>
                              </Row>
                              </Col>
                              </Row>
                              </div>
                              :''
}
                            <div className="actions clearfix mt-4 text-sm-end">
                                 {
                                  errors2 && Object.keys(errors2).length > 0 ?
                                  (
                                  <span className="text-center text-danger fw-semibold">Kindly fill all the required fields above.*</span>
                                  )
                                  : ''
                                  }
                            <Button className="btn-label w-md" color="success" type="submit" disabled={form2_loader.loading}>
                              {form2_loader.loading ? <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> : ''}
                              <i className="bx bx-smile label-icon"></i> Submit
                            </Button>
                            </div></form>                                                    
                        </div>
                      </TabPane>                      
                      <TabPane tabId="3">
                        <div>
                          <CardTitle className="mb-3">Deri Bot Settings</CardTitle>
                          {/* <p className="card-title-desc">
                            Provide the details below to activate Deri bot.
                          </p> */}
                          <form key={3} onSubmit={handleSubmit3(privacySettingSubmit)}>
                            <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="formrow-firstname-Input"
                                md="3" sm="6"
                                className="col-form-label"
                              >
                               Activate Deri Bot
                              </Label>
                              <Col md="9" sm="6" className="p-0 m-0 d-block">
                                <Switch
                                uncheckedIcon={<Offsymbol />}   
                                checkedIcon={<OnSymbol />}
                                // className="me-1 mb-sm-8 mb-2"
                                //onColor="#626ed4" 
                                onChange={(e) => setAllowAdminStatus(e)} 
                                checked={allowAdminStatus} 
                              /> 
                               <p className="mt-1" style={{fontSize:"11px"}}> <i
                                      className="bx bx-info-circle"
                                    /> 
                              <span style={{marginLeft:"2px"}}>Configure Deri Bot trading strategy with limit orders, stop-loss, trailing, take profit for automated trading.</span>
                              </p>
                               {/* <p className="card-title-desc mb-0">
                               Other Settings will be based on Deri bot.
                              </p>  */}
                              </Col>
                            
                            </FormGroup>
                            {allowAdminStatus ? 
                            <>
                            <FormGroup className="mb-4" row>
                          <Col md="6">
                            <Label className="col-form-label">Trend Following Quantity</Label>
                            <input
                              type="number"
                              onWheel={handleWheel}
                              min={0}
                              max={1000}
                              value={admin_trend_following_qty ? admin_trend_following_qty : ''}
                              onChange={(e) => setAdminTrendFollowingQty(e.target.value)}
                              onBlur={(e) => setAdminTrendFollowingQty(e.target.value)}
                              name="admin_trend_following_qty"
                              className="form-control"
                            />
                          </Col>
                          <Col md="6">
                            <Label className="col-form-label">Strangle Quantity</Label>
                            <input
                              type="number"
                              onWheel={handleWheel}
                              min={0}
                              max={1000}
                              value={admin_strangle_qty ? admin_strangle_qty : ''}
                              onChange={(e) => setAdminStrangleQty(e.target.value)}
                              onBlur={(e) => setAdminStrangleQty(e.target.value)}
                              name="admin_strangle_qty"
                              className="form-control"
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup className="mb-4" row>
                          <Col md="6">
                            <Label className="col-form-label">Diagonal Quantity</Label>
                            <input
                              type="number"
                              onWheel={handleWheel}
                              min={0}
                              max={1000}
                              value={admin_diagonal_qty ? admin_diagonal_qty : ''}
                              onChange={(e) => setAdminDiagonalQty(e.target.value)}
                              onBlur={(e) => setAdminDiagonalQty(e.target.value)}
                              name="admin_diagonal_qty"
                              className="form-control"
                            />
                          </Col>
                          <Col md="6">
                            <Label className="col-form-label">Straddle Quantity</Label>
                            <input
                              type="number"
                              onWheel={handleWheel}
                              min={0}
                              max={1000}
                              value={admin_straddle_qty ? admin_straddle_qty : ''}
                              onChange={(e) => setAdminStraddleQty(e.target.value)}
                              onBlur={(e) => setAdminStraddleQty(e.target.value)}
                              name="admin_straddle_qty"
                              className="form-control"
                            />
                          </Col>
                        </FormGroup>

                          
                            </>
                            :''}
                             {/* <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="formrow-firstname-Input"
                                md="3"
                                className="col-form-label"
                              >
                               Allow Derisnap bot to create trend following order/Automatic Bot 
                              </Label>
                              <Col md="7">
                                <Switch
                                uncheckedIcon={<Offsymbol />}
                                checkedIcon={<OnSymbol />}
                                className="me-1 mb-sm-8 mb-2"
                                //onColor="#626ed4" 
                                onChange={(e) => setAllowAdminTrendStatus(e)} 
                                checked={allowAdminTrendStatus} 
                              />
                                 </Col>
                            </FormGroup> */}
                               {allowAdminTrendStatus? 
                            <>
                            {/* <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="formrow-firstname-Input"
                                md="3"
                                className="col-form-label"
                              >
                                Default Trend following qty for the Derisnap Bot/Automatic Bot
                              </Label>
                              <Col md="9">
                              <Slider value={admin_trend_following_qty?admin_trend_following_qty:1} min={1} max={1000} onChange={value => {setAdminTrendFollowingQty(value)}} />
                              <input  type="number" onWheel={handleWheel}   min={0} max={1000} value={admin_trend_following_qty ? admin_trend_following_qty:''} onChange={(e) => { setAdminTrendFollowingQty(e.target.value)} } onBlur={(e) => setAdminTrendFollowingQty(e.target.value)} name="admin_trend_following_qty" className="form-control" />
                              </Col>
                            </FormGroup> */}
                            </>
                              :'' }
                           
                            
                            <div className="text-sm-end">
                              <Button className="btn-label w-md" color="success" type="submit" disabled={form3_loader.loading}>
                                {form3_loader.loading ? <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> : ''}
                                <i className="bx bx-smile label-icon"></i> Submit
                              </Button>
                            </div> 
                          </form>
                        </div>
                      </TabPane>
                      <TabPane tabId="4">
                        <div>
                          <CardTitle>Telegram Settings</CardTitle>
                          <p className="card-title-desc">
                          To update the token, remove all characters and update.
                          </p>
                          <form key={4} onSubmit={handleSubmit4(telegramSettingSubmit)}>
                            <Row>
                                <Col lg="6">
                                  <FormGroup className="mt-4 mb-0">
                                    <Label htmlFor="formrow-firstname-Input">
                                      Token <i className="bx bx-info-circle" onClick={() => setVisible(!visible)}></i>
                                    </Label>
                                    <input
                                      type="text"
                                      name="tele_token"
                                      className="form-control"                                      
                                      placeholder="Enter your token"
                                      {...register4("tele_token", { required: true})}
                                    />
                                    {errors4.tele_token && <small className="text-danger">Please provide a token</small>}
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <FormGroup className="mt-4 mb-0">
                                    <Label htmlFor="formrow-firstname-Input">
                                      Your ID <i className="bx bx-info-circle" onClick={() => setVisibleID(!visibleID)}></i>{" "} OR Chat ID <i className="bx bx-info-circle" onClick={() => setVisibleChatID(!visibleChatID)}></i>
                                    </Label>
                                    <input
                                       type="number" onWheel={handleWheel} 
                                      name="tele_chatid"
                                      className="form-control"
                                      placeholder="Enter your ID"
                                      {...register4("tele_chatid", { required: true,minLength:9})}
                                    />
                                    <span>Note : If you want to update chat id please add - symbol (Ex : -123456)</span>
                                    <br></br> {errors4.tele_chatid && <small className="text-danger">Please provide a valid id</small>}
                                  </FormGroup>
                                </Col>
                                <CardTitle>Notify</CardTitle>
                                <Col lg="3">
                                  <FormGroup className=" mt-4 mb-0">
                                    <Label htmlFor="expirydateInput">
                                      Admin Orders Msg
                                    </Label>{" "}
                                    <i className="bx bx-info-circle" id ="AOMSG" />
                                    <UncontrolledTooltip placement="top" target="AOMSG">
                                        {ToolTipData.admin_orders_msg}
                                    </UncontrolledTooltip>
                                    <Col md="2">
                                      <Switch
                                      uncheckedIcon={<Offsymbol />}
                                      checkedIcon={<OnSymbol />}
                                      className="me-1 mb-sm-8 mb-2"                                      
                                      onChange={(e) => setAdminOrderStatus(e)} 
                                      checked={adminOrderStatus}
                                    />
                                    </Col>
                                  </FormGroup>
                                </Col>
                                <Col lg="3">
                                  <FormGroup className="mt-4 mb-0">
                                    <Label htmlFor="cvvcodeInput">
                                      Open Order Msg
                                    </Label>{" "}
                                    <i className="bx bx-info-circle" id ="OOMSG" />
                                    <UncontrolledTooltip placement="top" target="OOMSG">
                                        {ToolTipData.open_order_msg}
                                    </UncontrolledTooltip>
                                    <Col md="2">
                                      <Switch
                                      uncheckedIcon={<Offsymbol />}
                                      checkedIcon={<OnSymbol />}
                                      className="me-1 mb-sm-8 mb-2"                                      
                                      onChange={(e) => setSellOrderStatus(e)} 
                                      checked={SellOrderStatus} 
                                    />
                                    </Col>
                                  </FormGroup>
                                </Col>
                                <Col lg="3">
                                  <FormGroup className=" mt-4 mb-0">
                                    <Label htmlFor="expirydateInput">
                                      Open Position Msg
                                    </Label>{" "}
                                    <i className="bx bx-info-circle" id ="OPMSG" />
                                    <UncontrolledTooltip placement="top" target="OPMSG">
                                        {ToolTipData.open_position_msg}
                                    </UncontrolledTooltip>
                                    <Col md="2">
                                      <Switch
                                      uncheckedIcon={<Offsymbol />}
                                      checkedIcon={<OnSymbol />}
                                      className="me-1 mb-sm-8 mb-2"                                      
                                      onChange={(e) => setPositionOrderStatus(e)} 
                                      checked={PositionOrderStatus} 
                                    />
                                    </Col>
                                  </FormGroup>
                                </Col>
                                {/* <Col lg="3">
                                  <FormGroup className="mt-4 mb-0">
                                    <Label htmlFor="cvvcodeInput">
                                      Buy Order Msg
                                    </Label>
                                    <Col md="2">
                                      <Switch
                                      uncheckedIcon={<Offsymbol />}
                                      checkedIcon={<OnSymbol />}
                                      className="me-1 mb-sm-8 mb-2"
                                      onChange={(e) => setBuyOrderStatus(e)}
                                      checked={BuyOrderStatus} 
                                    />
                                    </Col>
                                  </FormGroup>
                                </Col> */}
                                <Col lg="3">
                                  <FormGroup className=" mt-4 mb-0">
                                    <Label htmlFor="expirydateInput">
                                      Close Position Msg
                                    </Label>{" "}
                                    <i className="bx bx-info-circle" id ="CPMSG" />
                                    <UncontrolledTooltip placement="top" target="CPMSG">
                                        {ToolTipData.close_position_msg}
                                    </UncontrolledTooltip>
                                    <Col md="2">
                                      <Switch
                                      uncheckedIcon={<Offsymbol />}
                                      checkedIcon={<OnSymbol />}
                                      className="me-1 mb-sm-8 mb-2"                                      
                                      onChange={(e) => setFilledOrderStatus(e)} 
                                      checked={FilledOrderStatus}
                                    />
                                    </Col>
                                  </FormGroup>
                                </Col>
                                <Col lg="3">
                                  <FormGroup className="mt-4 mb-0">
                                    <Label htmlFor="cvvcodeInput">
                                      Trigger Order in SL Msg
                                    </Label>{" "}
                                    <i className="bx bx-info-circle" id ="TOSLMSG" />
                                    <UncontrolledTooltip placement="top" target="TOSLMSG">
                                        {ToolTipData.trigger_order_in_sl_msg}
                                    </UncontrolledTooltip>
                                    <Col md="2">
                                      <Switch
                                      uncheckedIcon={<Offsymbol />}
                                      checkedIcon={<OnSymbol />}
                                      className="me-1 mb-sm-8 mb-2"                                      
                                      onChange={(e) => setTriggerOrderStatus(e)} 
                                      checked={TriggerOrderStatus} 
                                    />
                                    </Col>
                                  </FormGroup>
                                </Col>
                                <Col lg="3">
                                  <FormGroup className=" mt-4 mb-0">
                                    <Label htmlFor="expirydateInput">
                                      SL Order Msg
                                    </Label>{" "}
                                    <i className="bx bx-info-circle" id ="SLOMSG" />
                                    <UncontrolledTooltip placement="top" target="SLOMSG">
                                        {ToolTipData.sl_order_msg}
                                    </UncontrolledTooltip>
                                    <Col md="2">
                                      <Switch
                                      uncheckedIcon={<Offsymbol />}
                                      checkedIcon={<OnSymbol />}
                                      className="me-1 mb-sm-8 mb-2"                                      
                                      onChange={(e) => setSLOrderStatus(e)} 
                                      checked={SLOrderStatus} 
                                    />
                                    </Col>
                                  </FormGroup>
                                </Col>
                                <Col lg="3">
                                  <FormGroup className="mt-4 mb-0">
                                    <Label htmlFor="cvvcodeInput">
                                      Roll Msg
                                    </Label>{" "}
                                    <i className="bx bx-info-circle" id ="ROLLMSG" />
                                    <UncontrolledTooltip placement="top" target="ROLLMSG">
                                        {ToolTipData.roll_msg}
                                    </UncontrolledTooltip>
                                    <Col md="2">
                                      <Switch
                                      uncheckedIcon={<Offsymbol />}
                                      checkedIcon={<OnSymbol />}
                                      className="me-1 mb-sm-8 mb-2"                                      
                                      onChange={(e) => setRollOrderStatus(e)} 
                                      checked={RollOrderStatus} 
                                    />
                                    </Col>
                                  </FormGroup>
                                </Col>


                                <Col lg="3">
                                  <FormGroup className=" mt-4 mb-0">
                                    <Label htmlFor="expirydateInput">
                                      Take Profit Msg
                                    </Label>{" "}
                                    <i className="bx bx-info-circle" id ="TPMSG" />
                                    <UncontrolledTooltip placement="top" target="TPMSG">
                                        {ToolTipData.take_profit_msg}
                                    </UncontrolledTooltip>
                                    <Col md="2">
                                      <Switch
                                      uncheckedIcon={<Offsymbol />}
                                      checkedIcon={<OnSymbol />}
                                      className="me-1 mb-sm-8 mb-2"                                      
                                      onChange={(e) => setProfitOrderStatus(e)} 
                                      checked={ProfitOrderStatus}
                                    />
                                    </Col>
                                  </FormGroup>
                                </Col>
                                <Col lg="3">
                                  <FormGroup className="mt-4 mb-0">
                                    <Label htmlFor="cvvcodeInput">
                                      Universal Close Msg
                                    </Label>{" "}
                                    <i className="bx bx-info-circle" id ="UCMSG" />
                                    <UncontrolledTooltip placement="top" target="UCMSG">
                                        {ToolTipData.universal_close_msg}
                                    </UncontrolledTooltip>
                                    <Col md="2">
                                      <Switch
                                      uncheckedIcon={<Offsymbol />}
                                      checkedIcon={<OnSymbol />}
                                      className="me-1 mb-sm-8 mb-2"                                      
                                      onChange={(e) => setUniverselOrderStatus(e)} 
                                      checked={UniverselOrderStatus} 
                                    />
                                    </Col>
                                  </FormGroup>
                                </Col>

                                <Col lg="3">
                                  <FormGroup className=" mt-4 mb-0">
                                    <Label htmlFor="expirydateInput">
                                      Cancel Order Msg
                                    </Label>{" "}
                                    <i className="bx bx-info-circle" id ="COMSG" />
                                    <UncontrolledTooltip placement="top" target="COMSG">
                                        {ToolTipData.cancel_order_msg}
                                    </UncontrolledTooltip>
                                    <Col md="2">
                                      <Switch
                                      uncheckedIcon={<Offsymbol />}
                                      checkedIcon={<OnSymbol />}
                                      className="me-1 mb-sm-8 mb-2"                                      
                                      onChange={(e) => setCancelledOrderStatus(e)} 
                                      checked={CancelledOrderStatus} 
                                    />
                                    </Col>
                                  </FormGroup>
                                </Col>
                                <Col lg="3">
                                  <FormGroup className="mt-4 mb-0">
                                    <Label htmlFor="cvvcodeInput">
                                      Mismatch Order Msg
                                    </Label>{" "}
                                    <i className="bx bx-info-circle" id ="MOMSG" />
                                    <UncontrolledTooltip placement="top" target="MOMSG">
                                        {ToolTipData.mismatch_order_msg}
                                    </UncontrolledTooltip>
                                    <Col md="2">
                                      <Switch
                                      uncheckedIcon={<Offsymbol />}
                                      checkedIcon={<OnSymbol />}
                                      className="me-1 mb-sm-8 mb-2"                                      
                                      onChange={(e) => setStrayPosOrderStatus(e)}
                                      checked={StrayPosOrderStatus} 
                                    />
                                    </Col>
                                  </FormGroup>
                                </Col>
                                <Col lg="3">
                                  <FormGroup className="mt-4 mb-0">
                                    <Label htmlFor="cvvcodeInput">
                                      Trend Signal Msg
                                    </Label>{" "}
                                    <i className="bx bx-info-circle" id ="TSMSG" />
                                    <UncontrolledTooltip placement="top" target="TSMSG">
                                        {ToolTipData.trend_signal_msg}
                                    </UncontrolledTooltip>
                                    <Col md="2">
                                      <Switch
                                      uncheckedIcon={<Offsymbol />}
                                      checkedIcon={<OnSymbol />}
                                      className="me-1 mb-sm-8 mb-2"                                      
                                      onChange={(e) => setTrendSignalStatus(e)}
                                      checked={trendSignalStatus}
                                    />
                                    </Col>
                                  </FormGroup>
                                </Col>

                                <FormGroup className="mt-4 mb-0">
                                  <Label htmlFor="formrow-firstname-Input">
                                  Time interval to check position/open orders/qty mismatch between bot and delta exchange portal (H : M : S) <i className="bx bx-info-circle" id ="TimeInterval" />
                                    <UncontrolledTooltip placement="top" target="TimeInterval">
                                        {ToolTipData.time_interval_check_positions}
                                    </UncontrolledTooltip>
                                  </Label>
                                  <Flatpickr
                                    className="form-control d-block"
                                    placeholder="Select time"
                                    onChange={(e) => setBotTime(new Date(e).getHours()+':'+new Date(e).getMinutes()+':'+new Date(e).getSeconds())} 
                                    value={botTime}
                                    options={{
                                      enableSeconds:true,
                                      enableTime: true,
                                      noCalendar: true,
                                      dateFormat: "H:i:s",
                                      time_24hr: true,
                                    }}
                                  />
                                </FormGroup>
                              </Row> 
                              <div className="text-sm-end">
                              <Button className="btn-label w-md" color="success" type="submit" disabled={form4_loader.loading}>
                                {form4_loader.loading ? <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> : ''}
                                <i className="bx bx-smile label-icon"></i> Submit
                              </Button>
                            </div>                       
                          </form>
                        </div>
                      </TabPane>
                      <TabPane tabId="5">
                        <div>
                          <CardTitle>Straddles Bot Settings</CardTitle>
                          <p className="card-title-desc">
                            You can also change these settings while starting a straddles bot.
                          </p>
                          <form key={5} onSubmit={(e) => {handleStraddlesSubmitClick(e)}}>
                          <Row>
                          <Col lg="4">
                                <FormGroup className="mt-4 mb-0">
                                    <Label htmlFor="formrow-firstname-Input">
                                      Quantity
                                    </Label>{" "}
                                    <i className="bx bx-info-circle" id ="QUANTITY01" />
                                    <UncontrolledTooltip placement="top" target="QUANTITY01"> 
                                        {ToolTipData.quantity}
                                    </UncontrolledTooltip>
                                    {/* <Slider value={straddleBotSettings.quantity?straddleBotSettings.quantity:1} min={1} max={1000} step={1} onChange={value => {setStraddleBotSettings({ ...straddleBotSettings, quantity: value })}} /> */}
                                  <input autoComplete="off"  type="number" onWheel={handleWheel}  min={0} max={1000} step={1} value={straddleBotSettings.quantity ? straddleBotSettings.quantity : ''} onChange={(e) => { setStraddleBotSettings({ ...straddleBotSettings, quantity: Math.max(Number(e.target.min), Math.min(Number(e.target.max), Number(e.target.value))) }) }} onBlur={(e) => { setStraddleBotSettings({ ...straddleBotSettings, quantity: Math.max(Number(e.target.min), Math.min(Number(e.target.max), Number(e.target.value))) }) }} name="straddles_quantity" className="form-control" />
                                  </FormGroup>
                              </Col>
                        
                             
                                </Row>
                                <Row className="border opacity-75 bg-light">
                              <Col lg="2">
                                <h5 className="mt-5 mb-3 font-size-15">
                                  Re-entry Settings
                                </h5>
                                </Col>
                                <Col lg="9">
                                
                                  <Row>
                           
                                
                                  <Col lg="2">
                                <div className="mt-4 mb-3">
                             
                                  
                                  <Label htmlFor="formrow-firstname-Input">
                                  Re-Entry
                                  {" "}
                                  <i className="bx bx-info-circle" id ="DHOSLIOLreentry_enabled" />
                                    <UncontrolledTooltip placement="top" target="DHOSLIOLreentry_enabled">
                                    <div className="tooltip-content">
                                          <div className="tooltip-header"><h6>Enable Re-Entry</h6></div>
                                      <hr className="m-2 p-0"/>
                                      <div className="tooltip-body">{ToolTipData.reentry_enabled}</div></div>
                                    </UncontrolledTooltip></Label>
                                    {/* <input type="checkbox" className="btn-check" id="btn-check-outlined2"  onClick={(e)=> {setDeltahedging2(e.target.checked)}}/><br></br>
                                      <label style={{width:"80px"}} className={"btn " + (Deltahedging2 === true ? "btn-outline-success" : "btn-outline-danger")} htmlFor="btn-check-outlined2" >{Deltahedging2 === true ? "Disable" : "Enable"}</label><br></br> */}
                                
                            <div>
                                      <Switch
                                      uncheckedIcon={<Offsymbol />}
                                      checkedIcon={<OnSymbol />}                                 
                                      onChange={(e) => setstraddlereentry_enabled(e)} 
                                      checked={straddlereentry_enabled}
                                    />
                                     </div></div>
                              </Col>
                              {straddlereentry_enabled?<>
                                  <Col lg="4">
                                  <FormGroup className="mt-4 mb-0">
                                        <Label htmlFor="formrow-firstname-Input">
                                        Re-Entry Wait (H:M)
                                        </Label> {" "} 
                              <i className="bx bx-info-circle" id ="wait_time_reentry" />
                                    <UncontrolledTooltip placement="top" target="wait_time_reentry"> 
                                    <div className="tooltip-content">
                                        <div className="tooltip-header"><h6>Waiting time for Re-Entry (H : M) </h6></div>
                                    <hr className="m-2 p-0"/>
                                    <div className="tooltip-body">{ToolTipData.wait_time_reentry}</div></div>
                                    </UncontrolledTooltip> 
                                        <Flatpickr
                                          className="form-control d-block"
                                          placeholder="Select time"
                                          name="wait_time_reentry"
                                          onChange={(e) => handleDH(e,'straddleswait_time_reentry')}
                                          value={toHoursAndMinutes(straddleswait_time_reentry)} 
                                          options={{                                      
                                            enableTime: true,
                                            noCalendar: true, 
                                            dateFormat: "H:i",
                                            time_24hr: true,
                                          }}
                                        />
                                    </FormGroup>  
                                  </Col>
                                
                                 <Col lg="4">
                                  <FormGroup className="mt-4 mb-0">
                                        <Label htmlFor="formrow-firstname-Input">
                                        Close Before Exp (H:M)
                                        </Label> {" "} 
                              <i className="bx bx-info-circle" id ="time_to_close_orders_before_expiry" />
                                    <UncontrolledTooltip placement="top" target="time_to_close_orders_before_expiry"> 
                                    <div className="tooltip-content">
                                        <div className="tooltip-header"><h6>Time to Close Orders Before Expiry (H : M)</h6></div>
                                    <hr className="m-2 p-0"/>
                                    <div className="tooltip-body">{ToolTipData.time_to_close_orders_before_expiry }</div></div>
                                    </UncontrolledTooltip> 
                                        <Flatpickr
                                          className="form-control d-block"
                                          placeholder="Select time"
                                          name="wait_time_reentry"
                                          onChange={(e) => handleDH(e,'time_to_close_orders_before_expiry_straddles')}
                                          value={toHoursAndMinutes(time_to_close_orders_before_expiry_straddles)} 
                                          options={{                                      
                                            enableTime: true,
                                            noCalendar: true,
                                            dateFormat: "H:i",
                                            time_24hr: true,
                                            maxTime: "12:00"
                                          }}
                                        />
                                    </FormGroup>  
                                  </Col>
                                  </>:''}
                                  </Row>
                                </Col>
                                </Row>
                                <div  className="mb-1 mt-1" >
                                <a href="#"
                                  onClick={() => {
                                    setStraddleAdvancedSettings(!straddleAdvancedSettings);
                                  }}
                               className="mb-3" >
                                  Advanced settings
                                </a>
                                </div>
                                {straddleAdvancedSettings ?
                                <div>
                                <Row>
                                <div className="panel-body table-responsive">
                                        {/* <table className="table table-bordered"> */}
                                        <Table className={"table table-bordered table align-middle table-nowrap table-check table-responsive"}>
                                          <thead>
                                          <tr style={{textAlign:'left'}}>
                                              <th>Settings</th>
                                              <th>Setting Values</th>
                                              <th>Info</th>
                                            </tr>
                                          </thead>
                                         <tbody style={{textAlign: 'left'}}> 
                                         {rows.map((row, index) => (
                                          <>
                                         { index === 1 || index === 4 || index == 7 ? <tr  className="table-light"><td ></td><td></td><td></td></tr>:''}
                                         <tr key={`straddles-${index}`}>
                                         <td>{row.label}{" %  "}<i className="bx bx-info-circle" id={row.target} onClick={(e) => { handleModel(e, row);} }/>
                                          { row.target === 'ONCLICK' ? '': <UncontrolledTooltip placement="top" target={row.target}>
                                           <div className="tooltip-content">
                                           <div className="tooltip-header"><h6>{row.orgSettingsName}</h6></div>
                                           <hr className="m-2 p-0"/>
                                           <div className="tooltip-body">{ToolTipData[row.tooltip]}</div></div>
                                            </UncontrolledTooltip>}
                                          </td>      
                                        {row.label === 'Activate Trailing' ? "" : (
                                            <td>
                                            {row.label === 'Activate Trailing' ? '' : (
                                              <div className="d-flex align-items-center" style={{ width: 'auto', maxWidth: '80px' }}>
                                                <Input
                                                  type="number"
                                                  max={row.max}
                                                  step={row.step}
                                                  min={row.min}
                                                  invalid={errors5[row.name]?.message ? true : false}
                                                  {...register5(row.name, row.validation || {})}
                                                  className="form-control"
                                                  id={row.tooltip}
                                                  name={row.name}
                                                  value={row.settings}
                                                  style={{ width: '80px', backgroundImage: 'none' }}
                                                  onChange={(e) => updateStraddlesRow(e, index,row.botSettingsvalue)}
                                                  onInput={(e) => updateStraddlesRow(e, index,row.botSettingsvalue)}
                                                />
                                                
                                                {errors5[row.name]?.message && (
                                                  <div style={{marginLeft: '10px'}}>
                                                    <small className="text-danger">{errors5[row.name]?.message}</small>
                                                  </div>
                                                )}
                                              </div>
                                            )}
                                          </td>
                                          )}
                                        {row.label === 'Activate Trailing' ? (<><td><input
                                          type="checkbox"
                                          className="form-check-input"
                                          id="straddlesMin"
                                          checked={SlTriggerStraddles}
                                          onClick={(event) => { setSlTriggerStraddles(event.target.checked);updateStraddlesRow(event, index,row.botSettingsvalue) }}
                                        />{" "}<label className="form-check-label font-size-10" htmlFor="straddlesMin">{row.check_box_label}</label><p style={{fontSize:'8px'}}>Activates trailing and take profit after stop loss hit on one leg.</p></td></>):""}
                                        <td>
                                      {<><i style={{ cursor: 'pointer', fontSize: '1rem', padding: '0.5rem' }} className="bx bx-detail" id={(row.target)+(row.tooltip)+(row.botSettingsvalue)} />
                                            <UncontrolledTooltip style={{maxWidth: "none",width: "auto"}} placement="top" target={(row.target)+(row.tooltip)+(row.botSettingsvalue)}>
                                              <div className="tooltip-content">
                                      <div className="tooltip-header"><h6>Details</h6></div>
                                  <hr className="m-2 p-0"/>
                                  <div className="tooltip-body" style={{textAlign:'left'}}>{row.formula}</div></div>
                                          </UncontrolledTooltip></> }
                                            </td>                    
                                    </tr>    
                                    </>
                                  ))}
                                 </tbody>
                                </Table>
                                </div>
                                </Row>
                                <Row className="bg-light">
                                <Col lg="2">
                              <h5 className="mt-5 font-size-15">
                                  Delta Hedging Settings
                                </h5>
                              </Col>
                             <Col>
                             <Row>
                              <Col lg="3">
                                <div className="mt-4 mb-3">
                                  <Label htmlFor="formrow-firstname-Input">
                                  Delta Hedging
                                  {" "}
                                  <i className="bx bx-info-circle" id ="EDH" />
                                    <UncontrolledTooltip placement="top" target="EDH">
                                    <div className="tooltip-content">
                                        <div className="tooltip-header"><h6>Enable Delta Hedging</h6></div>
                                    <hr className="m-2 p-0"/>
                                    <div className="tooltip-body">{ToolTipData.delta_hedging}</div></div>
                                    </UncontrolledTooltip></Label>
                                   
                              <div>
                                      <Switch
                                      uncheckedIcon={<Offsymbol />}
                                      checkedIcon={<OnSymbol />}                              
                                      onChange={(e) => {setStraddlesDeltahedging(e),setStraddleBotSettings({ ...straddleBotSettings, deltahedging: e === true ? 1 : 0 })}} 
                                      checked={StraddlesDeltahedging}
                                    />
                                </div></div>
                              </Col>
                              {
                                StraddlesDeltahedging ? <>
                              <Col lg="3">
                              <div className="mt-4 mb-3">
                                  <Label htmlFor="formrow-firstname-Input">
                                  Disable SL Leg
                                  {" "}
                                  <i className="bx bx-info-circle" id ="DHOSLIOL" />
                                    <UncontrolledTooltip placement="top" target="DHOSLIOL">
                                    <div className="tooltip-content">
                                        <div className="tooltip-header"><h6>Disable on SL in a Leg</h6></div>
                                    <hr className="m-2 p-0"/>
                                    <div className="tooltip-body">{ToolTipData.delta_hedging_on_stop_loss_in_one_leg}</div></div>
                                    </UncontrolledTooltip></Label>
                                    
                                    <div>
                             
                                      <Switch
                                      uncheckedIcon={<Offsymbol />}
                                      checkedIcon={<OnSymbol />}
                                      className="me-1 mb-sm-8 mb-2"                                      
                                      onChange={(e) => {setStraddlesDeltahedging2(e),setStraddleBotSettings({ ...straddleBotSettings, Delta_Hedging_on_Stop_Loss_in_One_Leg: e === true ? 1 : 0 })}} 
                                      checked={StraddlesDeltahedging2}
                                    />
                                    </div>
                                     </div>
                              </Col> 
                            
                              
                              <Col lg="5">
                                  <FormGroup className="mt-4 mb-3">
                                        <Label htmlFor="formrow-firstname-Input">
                                        Delta Check Interval (H:M)
                                        </Label> {" "} 
                              <i className="bx bx-info-circle" id ="DHINTERVALSTRADDLES" />
                                    <UncontrolledTooltip placement="top" target="DHINTERVALSTRADDLES"> 
                                    <div className="tooltip-content">
                                        <div className="tooltip-header"><h6>Interval time for delta checking (H : M)</h6></div>
                                    <hr className="m-2 p-0"/>
                                    <div className="tooltip-body">{ToolTipData.delta_hedging_interval}</div></div>
                                    </UncontrolledTooltip> 
                                        <Flatpickr
                                          className="form-control d-block"
                                          placeholder="Select time"
                                          name="waitTimeForPrice"
                                          onChange={(e) => handleDH(e,'straddles')}
                                          value={toHoursAndMinutes(straddlesdeltaHedgingIntervel)} 
                                          options={{                                      
                                            enableTime: true,
                                            noCalendar: true,
                                            dateFormat: "H:i",
                                            time_24hr: true,
                                          }}
                                        />
                                    </FormGroup>  
                                  </Col>
                              <Col lg="2">
                                <FormGroup className="mt-4 mb-0">
                                    <Label htmlFor="formrow-firstname-Input">
                                    Range
                                    </Label>{" "}
                              <i className="bx bx-info-circle" id ="DHDELTARANGESTRADDLES" />
                                    <UncontrolledTooltip placement="top" target="DHDELTARANGESTRADDLES"> 
                                    <div className="tooltip-content">
                                        <div className="tooltip-header"><h6>Delta range</h6></div>
                                    <hr className="m-2 p-0"/>
                                    <div className="tooltip-body">{ToolTipData.delta_hedging_range}</div></div>
                                    </UncontrolledTooltip>
                                    <input autoComplete="off" type="number" onWheel={handleWheel}  inputMode="numeric"   min={0.0001} max={100}  step=".0001" value={straddlesdeltaHedgingRange} onChange={(e) => setstraddlesDeltaHedgingRange(e.target.value)} name="delta_Range" className="form-control" />
                                  </FormGroup>
                              </Col>
                              <Col lg="2">
                                <FormGroup className="mt-4 mb-0">
                                    <Label htmlFor="formrow-firstname-Input">
                                      Order type
                                    </Label>{" "}
                                    <i className="bx bx-info-circle" id ="DHDELTARANGESORDER" />
                                    <UncontrolledTooltip placement="top" target="DHDELTARANGESORDER">
                                      {ToolTipData.delta_hedging_order_type}
                                    </UncontrolledTooltip>  
                                    <select name="order_type" label="order_type" className="form-select" onChange={(e) => setStraddlesOrderType(e.target.value)} value={straddlesOrderType}>
                                      <option value='market_order'>Market Order</option>
                                      <option value='limit_order'>Limit Order</option>
                                    </select>
                                  </FormGroup>
                              </Col>
                              <Col lg="4">
                                <FormGroup className="mt-4 mb-0">
                                   <Label htmlFor="formrow-firstname-Input">
                                      Hedge on Loss Option Only
                                    {" "}
                                    <i className="bx bx-info-circle" id ="DHORDERLoss" />
                                    <UncontrolledTooltip placement="top" target="DHORDERLoss">
                                    <div className="tooltip-content">
                                        <div className="tooltip-header"><h6>Hedge only for the option with loss</h6></div>
                                    <hr className="m-2 p-0"/>
                                    <div className="tooltip-body">{ToolTipData.hedge_only_for_the_option_with_loss}</div></div>
                                    </UncontrolledTooltip></Label>
                                    <Col md="2">
                                    <Switch
                                    uncheckedIcon={<Offsymbol />}
                                    checkedIcon={<OnSymbol />}
                                    className="me-1 mb-sm-8 mb-2"
                                    //onColor="#626ed4"
                                    onChange={(e) => setStraddlesHedgeOnlyForLoss(e)}
                                    checked={straddleshedgeOnlyForLoss}
                                  />
                                  </Col>
                                </FormGroup>
                              </Col>
                              {straddleshedgeOnlyForLoss? <Col lg="3"> 
                          <div className="mt-4">

                            <div>
                              <Label className="form-label">Min Loss for Hedge</Label>{" "}
                              <i className="bx bx-info-circle" id ="DHMINLOSS" />
                                    <UncontrolledTooltip placement="top" target="DHMINLOSS"> 
                                    <div className="tooltip-content">
                                        <div className="tooltip-header"><h6>Min Loss for Delta Hedging</h6></div>
                                    <hr className="m-2 p-0"/>
                                    <div className="tooltip-body">{ToolTipData.delta_hedging_min_loss}</div></div>
                                    </UncontrolledTooltip>
                                <FormGroup className="input-group mb-0 ">
                                 
                                    
                                    <select
                              className="form-control" name="hedging_min_loss_type_straddle"  id="inputGroupFileAddon04"  onChange={(e) => setMinLossTypeStraddles(e.target.value)} value={minLossTypeStraddles?minLossTypeStraddles:'percentage'} 
                            >
                              <option value="percentage">%</option>
                              <option value="points">Pts</option>
                            </select>                                  
                                    <input autoComplete="off" type="number" onWheel={handleWheel}  inputMode="numeric"   min={0} max={minLossTypeStraddles!='points'?100:100000} step={0.5} value={minLossValueStraddles?minLossValueStraddles:0} onChange={(e) => setMinLossValueStraddles(e.target.value)} name="min_loss_val_straddle" className="form-control w-50"  aria-describedby="inputGroupFileAddon04" />
                                  </FormGroup> </div></div>
                              </Col> :''}
                             
                              </> : ''
                              }
                              
                            </Row>
                            </Col>
                             
                              </Row>
                                                    
                              </div>
                                
                                :''}
                              <div className="actions clearfix mt-4 text-sm-end">
                                 {
                                  errors5 && Object.keys(errors5).length > 0 ?
                                  (
                                  <span className="text-center text-danger fw-semibold">Kindly fill all the required fields above.*</span>
                                  )
                                  : ''
                                  }
                                <Button className="btn-label w-md" color="success" type="submit" disabled={form5_loader.loading}>
                                  {form5_loader.loading ? <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> : ''}
                                  <i className="bx bx-smile label-icon"></i> Submit
                                </Button>
                              </div>    
                               
                          </form>
                        </div>
                      </TabPane>
                      <TabPane tabId="6">
                        <div>
                        <span style={{ "float": "right" }} >
                          <a href="/help" target="_blank">Click here</a> for detailed information about each Trend following input parameter. <a href="/help" target="_blank"><span className='fas fa-question-circle'></span> </a>
                          </span>
                          <CardTitle>Trend Following Bot Settings</CardTitle>
                          <form key={5} onSubmit={handleSubmit7(trendSettingSubmit)}>
                          <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="formrow-firstname-Input"
                                md="3"
                                className="col-form-label"
                              >
                                Enable Trend Following Strategy <i className="bx bx-info-circle" id ="ETFS" />
                                    <UncontrolledTooltip placement="top" target="ETFS"> 
                                        {ToolTipData.enable_trend_following_strategy}
                                    </UncontrolledTooltip>
                              </Label>
                              <Col md="7">
                                <Switch
                                uncheckedIcon={<Offsymbol />}
                                checkedIcon={<OnSymbol />}
                                className="me-1 mb-sm-8 mb-2"
                                //onColor="#626ed4" 
                                onChange={(e) => setEnableTrendFollow(e)} 
                                checked={enableTrendFollow} 
                              />
                              </Col>
                            </FormGroup>
                        
                            <div  style={{'display': enableTrendFollow ? 'block' : 'none' }}>
                            <Row>
                            <Col lg="4">
                              <FormGroup className="mt-4 mb-0">
                                <Label htmlFor="formrow-firstname-Input"><a className='link-secondary' target="_blank" href='/help#quantity'>Quantity</a></Label>{" "}
                              <i className="bx bx-info-circle" id ="TFBSQTY" />
                                    <UncontrolledTooltip placement="top" target="TFBSQTY"> 
                                        {ToolTipData.trend_quantity}
                                    </UncontrolledTooltip>
                               
                                    <input  type="number" onWheel={handleWheel}   min={0} max={1000} value={trend_following_qty ? trend_following_qty:''} onChange={(e) => { e.target.value <= 1000 ? setTrendFollowingQty(e.target.value) : setTrendFollowingQty(1000)} } onBlur={(e) => setTrendFollowingQty(e.target.value)} name="trend_following_qty" className="form-control" />                              
                              </FormGroup>
                            </Col>
                             
                              <Col lg="4">
                                <div className="mt-4">
                                <Label className="form-label"><a className='link-secondary' target="_blank" href='/help#place_order'>Expiry</a></Label>{" "}
                                <i className="bx bx-info-circle" id ="TFBSTD" />
                                    <UncontrolledTooltip placement="top" target="TFBSTD"> 
                                        {ToolTipData.trend_day}
                                    </UncontrolledTooltip>
                                <FormGroup className="input-group mb-0 ">
                                  <select name="trend_day" label="trend_day" className="form-select" onChange={(e) => setTrendDay(e.target.value)} value={trendDay}>
                                    <option value='D1'>Today</option>
                                    <option value='D2'>Tomorrow </option>
                                    <option value='D3'>Day After Tomorrow </option>
                                    <option value='W1'>Current Week Friday </option>
                                    <option value='W2'>Next Week Friday </option>
                                    <option value='W3'>Next to Next Week Friday </option>
                                    <option value='M1'>Current Month End Friday </option>
                                    <option value='M2'>Next Month End Friday </option>
                                  </select>
                                  </FormGroup>
                                  </div>                                                       
                                </Col>
                                <Col lg="4">
                                <FormGroup className="mt-4 mb-0">
                                    <Label htmlFor="formrow-firstname-Input">
                                    <a className='link-secondary' target="_blank" href='/help#market_stop_loss_percentage'>Stop Loss %</a>
                                    {" "}
                              <i className="bx bx-info-circle" id ="TFBSSL" /></Label>
                                    <UncontrolledTooltip placement="top" target="TFBSSL"> 
                                        {ToolTipData.stop_loss}
                                    </UncontrolledTooltip>
                                 
                                    <input autoComplete="off"  type="number" onWheel={handleWheel}    max={100} step={0.5} value={trendStopLoss} onChange={(e) => {e.target.value <= 100 ?  setTrendStopLoss(e.target.value) : setTrendStopLoss(100)} } onBlur={(e) => e.target.value <= 100 ? setTrendStopLoss(e.target.value) : setTrendStopLoss(100)} name="trend_stop_loss" className="form-control" />
                                  </FormGroup>
                              </Col>
                                <a href="#"
                                  onClick={() => {
                                    setAdvancedSettings(!advancedSettings);
                                  }}
                               className="mb-3" >
                                  Advanced settings
                                </a>
                              </Row>
                              {advancedSettings ? <>
                              <Row className="border bg-light">
                              <div className="m-2 h5 card-title"><a className='link-secondary' target="_blank" href='/help#trend_indicator'> Trend Indicator </a><i className="bx bx-info-circle" id ="TFBSMTSS" />
                              <UncontrolledTooltip placement="top" target="TFBSMTSS"> 
                                          {ToolTipData.method_for_trend_signal}
                                      </UncontrolledTooltip></div>
                              <div className="row " style={{"border-bottom": "1px solid black;"}}>
                              <Col lg="2" >  
                                    <FormGroup  className="mb-4">
                                <Label
                                  htmlFor="formrow-firstname-Input"
                                  className="col-form-label"
                                >
                                    <a className='link-secondary' target="_blank" href='/help#trend_indicator'>Sig Method:</a>  <i className="bx bx-info-circle" id ="TFBSMTS" />
                                      <UncontrolledTooltip placement="top" target="TFBSMTS"> 
                                      <div className="tooltip-content">
                                           <div className="tooltip-header"><h6>Method for Trend signal</h6></div>
                                           <hr className="m-2 p-0"/>
                                           <div className="tooltip-body">{ToolTipData.method_for_trend_signal}</div>
                                         </div>
                                      </UncontrolledTooltip>
                                </Label>
                                {/* <Col> */}
                              <select name="trend_method" label="trend_method" className="form-select" onChange={(e) => handleTrendSettingsChange(e)} value={trendSettings.trend_method}> 
                                                           
                                <option value='sma'>SMA crossovers</option>
                                <option value='ema'>EMA crossovers</option>					  
                                <option value='macd'>MACD Signal Line cross </option>					  
                                <option value='supertrend'>Supertrend</option>
                            </select>
                                </FormGroup>
                                </Col>
                                
                                {!trendSettings.trend_method || trendSettings.trend_method=="sma"? <>
                                <Col lg="2" >
                                <FormGroup className="mb-4">
                                <Label
                                  htmlFor="formrow-firstname-Input"
                                  className="col-form-label"
                                >
                                <a className='link-secondary' target="_blank" href='/help#trend_indicator'>Interval</a> <i className="bx bx-info-circle" id ="TFBSINTREVAL" />
                                      <UncontrolledTooltip placement="top" target="TFBSINTREVAL"> 
                                          {ToolTipData.sma_crossover.interval}
                                      </UncontrolledTooltip>
                                </Label>
                                {/* <Col>                               */}
                                <select name="time_frame" label="time_frame" required className="form-select" value={trendSettings.time_frame} onChange={(e) => handleTrendSettingsChange(e)} > 					  
                                  <option value='1m'>1 minute</option>
                                  <option value='5m'>5 minutes</option>
                                  <option value='15m'>15 minutes</option>
                                  <option value='30m'>30 minutes</option>
                                  <option value='1h'>1 hour</option>
                                  <option value='2h'>2 hours</option>
                                  <option value='4h'>4 hours</option>
                                  <option value='1d'>1 day</option>
                                  <option value='1w'>1 week</option>
                                  <option value='1M'>1 month</option>
                                </select>
                              </FormGroup>
                              </Col>
                              <Col lg="2" >
                              <FormGroup className="mb-4">
                                <Label
                                  htmlFor="formrow-firstname-Input"
                                  className="col-form-label"
                                >
                                <a className='link-secondary' target="_blank" href='/help#trend_indicator'>Short length</a> <i className="bx bx-info-circle" id ="TFBSSORTLENGTH" />
                                      <UncontrolledTooltip placement="top" target="TFBSSORTLENGTH"> 
                                          {ToolTipData.sma_crossover.short_length}
                                      </UncontrolledTooltip>
                                </Label>
                                <input  type="number" onWheel={handleWheel}  required  min={1} step={1} value={trendSettings.short_term?trendSettings.short_term:''} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="short_term" className="form-control" />
                              </FormGroup>
                              </Col>
                              <Col lg="2" >
                              <FormGroup className="mb-4">
                                <Label
                                  htmlFor="formrow-firstname-Input"
                                  className="col-form-label"
                                >
                                <a className='link-secondary' target="_blank" href='/help#trend_indicator'>Long length</a> <i className="bx bx-info-circle" id ="TFBSLONGLENGTH" />
                                      <UncontrolledTooltip placement="top" target="TFBSLONGLENGTH"> 
                                          {ToolTipData.sma_crossover.long_length}
                                      </UncontrolledTooltip>
                                </Label>               
                                <input  type="number" onWheel={handleWheel}  required  min={1} step={1} value={trendSettings.long_term?trendSettings.long_term:''} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="long_term" className="form-control" />
                              </FormGroup>
                              </Col>
                                </> : trendSettings.trend_method=="ema"? 
                                 <>
                                <Col lg="2" >
                                 <FormGroup className="mb-4">
                                 <Label
                                   htmlFor="formrow-firstname-Input"
                                  
                                   className="col-form-label"
                                 >
                                 <a className='link-secondary' target="_blank" href='/help#trend_indicator'>Interval</a> <i className="bx bx-info-circle" id ="TFBMINTREVAL" />
                                       <UncontrolledTooltip placement="top" target="TFBMINTREVAL"> 
                                           {ToolTipData.ema_crossover.interval}
                                       </UncontrolledTooltip>
                                 </Label>                           
                                 <select name="ema_time_frame" label="ema_time_frame" required className="form-select" value={trendSettings.ema_time_frame} onChange={(e) => handleTrendSettingsChange(e)} > 					  
                                   <option value='1m'>1 minute</option>
                                   <option value='5m'>5 minutes</option>
                                   <option value='15m'>15 minutes</option>
                                   <option value='30m'>30 minutes</option>
                                   <option value='1h'>1 hour</option>
                                   <option value='2h'>2 hours</option>
                                   <option value='4h'>4 hours</option>
                                   <option value='1d'>1 day</option>
                                   <option value='1w'>1 week</option>
                                   <option value='1M'>1 month</option>
                                 </select>
                               </FormGroup>
                               </Col>
                               <Col lg="2" >
                               <FormGroup className="mb-4">
                                 <Label
                                   htmlFor="formrow-firstname-Input"
                                  
                                   className="col-form-label"
                                 >
                                 <a className='link-secondary' target="_blank" href='/help#trend_indicator'>Short length</a> <i className="bx bx-info-circle" id ="ema_short" />
                                       <UncontrolledTooltip placement="top" target="ema_short"> 
                                           {ToolTipData.ema_crossover.short_length}
                                       </UncontrolledTooltip>
                                 </Label>                         
                                 <input type="number" onWheel={handleWheel}  inputMode="numeric"  required  min={1} step={1} value={trendSettings.ema_short_term?trendSettings.ema_short_term:''} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="ema_short_term" className="form-control" />
                               </FormGroup>
                               </Col>
                               <Col lg="2" >
                               <FormGroup className="mb-4">
                                 <Label
                                   htmlFor="formrow-firstname-Input"
                               
                                   className="col-form-label"
                                 >
                                 <a className='link-secondary' target="_blank" href='/help#trend_indicator'>Long length</a> <i className="bx bx-info-circle" id ="ema_long" />
                                       <UncontrolledTooltip placement="top" target="ema_long"> 
                                           {ToolTipData.ema_crossover.long_length}
                                       </UncontrolledTooltip>
                                 </Label>                 
                                 <input type="number" onWheel={handleWheel}  inputMode="numeric"  required  min={1} step={1} value={trendSettings.ema_long_term?trendSettings.ema_long_term:''} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="ema_long_term" className="form-control" />
                               </FormGroup>
                               </Col>
                                 </>  : trendSettings.trend_method=="macd"? 
                                <>
                                <Col lg="2" >
                                <FormGroup className="mb-4">
                                <Label
                                  htmlFor="formrow-firstname-Input"
                                  className="col-form-label"
                                >
                               <a className='link-secondary' target="_blank" href='/help#trend_indicator'>Interval</a> <i className="bx bx-info-circle" id ="TFBSMACDINTERVAL" />
                                      <UncontrolledTooltip placement="top" target="TFBSMACDINTERVAL"> 
                                          {ToolTipData.macd_sign_line_cross.interval}
                                      </UncontrolledTooltip>
                                </Label>                       
                                <select name="macd_time_frame" required label="macd_time_frame" className="form-select" value={trendSettings.macd_time_frame} onChange={(e) => handleTrendSettingsChange(e)} > 					  
                                  <option value='1m'>1 minute</option>
                                  <option value='5m'>5 minutes</option>
                                  <option value='15m'>15 minutes</option>
                                  <option value='30m'>30 minutes</option>
                                  <option value='1h'>1 hour</option>
                                  <option value='2h'>2 hours</option>
                                  <option value='4h'>4 hours</option>
                                  <option value='1d'>1 day</option>
                                  <option value='1w'>1 week</option>
                                  <option value='1M'>1 month</option>
                                </select>
                              </FormGroup>
                              </Col>
                              <Col lg="2" >
                              <FormGroup className="mb-4">
                                <Label
                                  htmlFor="formrow-firstname-Input"
                                  className="col-form-label"
                                >
                                <a className='link-secondary' target="_blank" href='/help#trend_indicator'>Short length</a> <i className="bx bx-info-circle" id ="TFBSSORTL" />
                                      <UncontrolledTooltip placement="top" target="TFBSSORTL"> 
                                          {ToolTipData.macd_sign_line_cross.short_length}
                                      </UncontrolledTooltip>
                                </Label>
                                <input  type="number" onWheel={handleWheel}  required  min={1} step={1} value={trendSettings.macd_short_term?trendSettings.macd_short_term:''} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="macd_short_term" className="form-control" />
                              </FormGroup>
                              </Col>
                              <Col lg="2" >
                              <FormGroup className="mb-4">
                                <Label
                                  htmlFor="formrow-firstname-Input"
                                  className="col-form-label"
                                >
                                <a className='link-secondary' target="_blank" href='/help#trend_indicator'>Long length</a> <i className="bx bx-info-circle" id ="TFBSLONGL" />
                                      <UncontrolledTooltip placement="top" target="TFBSLONGL"> 
                                          {ToolTipData.macd_sign_line_cross.long_length}
                                      </UncontrolledTooltip>
                                </Label>                         
                                <input  type="number" onWheel={handleWheel}  required  min={1} step={1} value={trendSettings.macd_long_term?trendSettings.macd_long_term:''} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="macd_long_term" className="form-control" />
                              </FormGroup>
                                </Col>
                                <Col lg="2" >
                              <FormGroup className="mb-4">
                                <Label
                                  htmlFor="formrow-firstname-Input"
                                  className="col-form-label"
                                >
                                <a className='link-secondary' target="_blank" href='/help#trend_indicator'>Signal length</a> <i className="bx bx-info-circle" id ="TFBSSIGNALL" />
                                      <UncontrolledTooltip placement="top" target="TFBSSIGNALL"> 
                                          {ToolTipData.macd_sign_line_cross.signal_length}
                                      </UncontrolledTooltip>
                                </Label>                            
                                <input  type="number" onWheel={handleWheel}  required  min={1} step={1} value={trendSettings.macd_signal_term?trendSettings.macd_signal_term:''} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="macd_signal_term" className="form-control" />
                              </FormGroup>  
                              </Col>
                                </> 
                                : trendSettings.trend_method=="supertrend"? <>
                                 <Col lg="2" >
                                 <FormGroup className="mb-4">
                                <Label
                                  htmlFor="formrow-firstname-Input"
                                  className="col-form-label"
                                >
                                <a className='link-secondary' target="_blank" href='/help#trend_indicator'>Interval</a> <i className="bx bx-info-circle" id ="TFBSSMAINTERVAL" />
                                      <UncontrolledTooltip placement="top" target="TFBSSMAINTERVAL"> 
                                          {ToolTipData.supertrend.interval}
                                      </UncontrolledTooltip>
                                </Label>                          
                                <select name="st_time_frame" required label="st_time_frame" className="form-select" value={trendSettings.st_time_frame} onChange={(e) => handleTrendSettingsChange(e)} > 					  
                                  <option value='1m'>1 minute</option>
                                  <option value='5m'>5 minutes</option>
                                  <option value='15m'>15 minutes</option>
                                  <option value='30m'>30 minutes</option>
                                  <option value='1h'>1 hour</option>
                                  <option value='2h'>2 hours</option>
                                  <option value='4h'>4 hours</option>
                                  <option value='1d'>1 day</option>
                                  <option value='1w'>1 week</option>
                                  {/*<option value='1M'>1 month</option>*/}
                                </select>
                              </FormGroup>
                              </Col>
                              <Col lg="2" >
                              <FormGroup className="mb-4">
                                <Label
                                  htmlFor="formrow-firstname-Input"
                                  className="col-form-label"
                                >
                                <a className='link-secondary' target="_blank" href='/help#trend_indicator'>ATR length</a> <i className="bx bx-info-circle" id ="TFBSATRL" />
                                      <UncontrolledTooltip placement="top" target="TFBSATRL"> 
                                          {ToolTipData.supertrend.atr_length}
                                      </UncontrolledTooltip>
                                </Label>                             
                                <input  type="number" onWheel={handleWheel}  required  min={1} step={1} value={trendSettings.st_atr_length?trendSettings.st_atr_length:''} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="st_atr_length" className="form-control" />
                              </FormGroup>
                              </Col>
                              <Col lg="2" >
                              <FormGroup className="mb-4">
                                <Label
                                  htmlFor="formrow-firstname-Input"
                                  className="col-form-label"
                                >
                                <a className='link-secondary' target="_blank" href='/help#trend_indicator'>Factor</a> <i className="bx bx-info-circle" id ="TFBSFACTOR" />
                                      <UncontrolledTooltip placement="top" target="TFBSFACTOR"> 
                                          {ToolTipData.supertrend.factor}
                                      </UncontrolledTooltip>
                                </Label>                        
                                <input  type="number" onWheel={handleWheel}  required min={1} step={0.1} value={trendSettings.st_factor?trendSettings.st_factor:''} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="st_factor" className="form-control" />
                              </FormGroup>
                              </Col>
                              <Col lg="2" >
                              <FormGroup className="mb-4">
                                <Label
                                  htmlFor="formrow-firstname-Input"
                                  className="col-form-label"
                                >
                                 <a className='link-secondary' target="_blank" href='/help#trend_indicator'>Min ST Dist</a> <i className="bx bx-info-circle" id ="TFBSMINSD" />
                                      <UncontrolledTooltip placement="top" target="TFBSMINSD"> 
                                      <div className="tooltip-content">
                                            <div className="tooltip-header"><h6>Min supertrend distance</h6></div>
                                        <hr className="m-2 p-0"/>
                                        <div className="tooltip-body">{ToolTipData.supertrend.minimum_supertrend_distance}</div></div>
                                      </UncontrolledTooltip>
                                </Label>                          
                                <input  type="number" onWheel={handleWheel}  required min={1} step={1} value={trendSettings.st_min_distance?trendSettings.st_min_distance:''} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="st_min_distance" className="form-control" />
                              </FormGroup> 
                              </Col>
                              <Col lg="2" >
                              <FormGroup className="mb-4">
                                <Label
                                  htmlFor="formrow-firstname-Input"
                                  className="col-form-label"
                                >
                                 <a className='link-secondary' target="_blank" href='/help#trend_indicator'>Max ST Dist</a> <i className="bx bx-info-circle" id ="TFBSMAXSD" />
                                      <UncontrolledTooltip placement="top" target="TFBSMAXSD"> 
                                      <div className="tooltip-content">
                                            <div className="tooltip-header"><h6>Max supertrend distance</h6></div>
                                        <hr className="m-2 p-0"/>
                                        <div className="tooltip-body">{ToolTipData.supertrend.maximum_supertrend_distance}</div></div>
                                      </UncontrolledTooltip>
                                </Label>                         
                                <input  type="number" onWheel={handleWheel}  required min={1} step={1} value={trendSettings.st_max_distance?trendSettings.st_max_distance:''} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="st_max_distance" className="form-control" />
                              </FormGroup> 
                              </Col>
              
                                </> : '' }
                                
                                          </div> 
                                          </Row>
                                          <Row className="border opacity-75">
                                          <Col lg="2" >
                          <div className="mt-4">
                            <div>
                              <Label className="form-label"><a className='link-secondary' target="_blank" href='/help#distance_from_index'>Index Dist</a>{" "}
                              <i className="bx bx-info-circle" id ="TFBSDI" />
                                    <UncontrolledTooltip placement="top" target="TFBSDI"> 
                                    <div className="tooltip-content">
                                            <div className="tooltip-header"><h6>Distance from index</h6></div>
                                        <hr className="m-2 p-0"/>
                                        <div className="tooltip-body">{ToolTipData.distance_from_index}</div></div>
                                    </UncontrolledTooltip></Label>
                                <FormGroup className="input-group mb-0 ">
                                 
                                    
                                    <select
                              className="form-control"  id="inputGroupFileAddon04" onChange={(e) =>{setIndexDistanceType(e.target.value)} } value={indexDistanceType}
                            >
                              <option value="percentage">%</option>
                              <option value="points">Pts</option>
                            </select>
                                  { /* <Slider value={trendIndexPer?trendIndexPer:0} min={0} max={100} step={0.5} onChange={value => {setTrendIndexPer(value)}} /> */ }
                                    <input autoComplete="off"  type="number" onWheel={handleWheel}    max={indexDistanceType!='points'?100:10000} step={0.5} value={trendIndexPer} onChange={(e) => {indexDistanceType!='points' ? e.target.value <=100 ? setTrendIndexPer(e.target.value) : setTrendIndexPer(100) : e.target.value <= 1000 ? setTrendIndexPer(e.target.value) : setTrendIndexPer(1000)} } onBlur={(e) => setTrendIndexPer(e.target.value)} name="trend_index_per" className="form-control w-50"  aria-describedby="inputGroupFileAddon04" />
                                  </FormGroup> </div></div>
                              </Col>
                              <Col lg="2" >
                              <div className="mt-4">
                              <Label className="form-label"><a className='link-secondary' target="_blank" href='/help#select_side'>Select Side</a></Label>{" "}
                              <i className="bx bx-info-circle" id ="TFBSDIS" />
                                    <UncontrolledTooltip placement="top" target="TFBSDIS"> 
                                        {ToolTipData.side}
                                    </UncontrolledTooltip>
                              <FormGroup className="input-group mb-0 ">
                                <select name="trend_side" label="trend_side" className="form-select" onChange={(e) => setTrendSide(e.target.value)} value={trendSide}>
                                  <option value='sell'>Sell</option>
                                  <option value='buy'>Buy</option>
                                  {/* <option value='sell_only'>Sell</option>
                                  <option value='buy_only'>Buy</option> */}
                                  <option value='both'>Both</option>
                                </select>
                                </FormGroup>
                                </div>                                                       
                              </Col>
                              <Col lg="2" >
                                  <FormGroup className="mt-4 mb-0">
                                    <Label htmlFor="formrow-firstname-Input">
                                    <a className='link-secondary' target="_blank" href='/help#strike_count'>Strike Counts</a>
                                    </Label>{" "}
                                    <i className="bx bx-info-circle" id ="STRICKC" />
                                    <UncontrolledTooltip placement="top" target="STRICKC"> 
                                        {ToolTipData.strike_counts}
                                    </UncontrolledTooltip>
                                    <input autoComplete="off"  type="number" onWheel={handleWheel}   min={1} max={10} value={strikeCount ? strikeCount:''} onChange={(e) => { e.target.value <= 10 ?  setStrikeCount(e.target.value) : setStrikeCount(10)} } onBlur={(e) => setStrikeCount(e.target.value)} name="strike_count" className="form-control" />
                                  </FormGroup>
                              </Col>
                              <Col lg="2" >
                              <FormGroup className="mt-4 mb-0">
                                    <Label htmlFor="formrow-firstname-Input">
                                    <a className='link-secondary' target="_blank" href='/help#minimum_take_profite_percent'>Trailing TP %</a>
                                    {" "}
                                    <i className="bx bx-info-circle" onClick={() => setVisibleMin(!visibleMin)}></i></Label>
                                   
                                    <input autoComplete="off"  type="number" onWheel={handleWheel}    max={100} step={0.5} value={trendMinTakeProfit} onChange={(e) => {e.target.value <= 100 ?  setTrendMinTakeProfit(e.target.value) : setTrendMinTakeProfit(100)} } onBlur={(e) => e.target.value <= 100 ?  setTrendMinTakeProfit(e.target.value) : setTrendMinTakeProfit(100)} name="min_take_profit_percentage" className="form-control" />
                                  </FormGroup>
                              </Col>
                              <Col lg="2" >
                              <FormGroup className="mt-4 mb-0">
                                    <Label htmlFor="formrow-firstname-Input">
                                    <a className='link-secondary' target="_blank" href='/help#trailing_take_profite_percentage'>Trailing SP %</a>
                                    {" "}
                                    <i className="bx bx-info-circle" onClick={() => setVisibleTailling(!visibleTailling)}></i></Label>
                                   
                                    <input autoComplete="off"  type="number" onWheel={handleWheel}   max={100} step={0.5} value={trendTrailTakeProfit } onChange={(e) => {e.target.value <= 100 ? setTrendTrailTakeProfit(e.target.value) : setTrendTrailTakeProfit(100)} } onBlur={(e) => e.target.value <= 100 ? setTrendTrailTakeProfit(e.target.value) : setTrendTrailTakeProfit(100)} name="trailing_take_profit_percentage" className="form-control" />
                                  </FormGroup>
                              </Col>
                              <Col lg="2" >
                                <FormGroup className="mt-4 mb-0">
                                    <Label htmlFor="formrow-firstname-Input">
                                    <a className='link-secondary' target="_blank" href='/help#total_quantity_limit'>Total qty limit</a>
                                    {" "}
                              <i className="bx bx-info-circle" id ="TFBSTQL" />
                                    <UncontrolledTooltip placement="top" target="TFBSTQL"> 
                                    <div className="tooltip-content">
                                            <div className="tooltip-header"><h6>Total quantity limit</h6></div>
                                        <hr className="m-2 p-0"/>
                                        <div className="tooltip-body">{ToolTipData.total_quantity_limit}</div></div>
                                    </UncontrolledTooltip></Label>
                                    <input autoComplete="off"  type="number" onWheel={handleWheel}   min={0} max={10000} step={0.5} value={trendQuantityLimit} onChange={(e) => { setTrendQuantityLimit(e.target.value)} } onBlur={(e) => setTrendQuantityLimit(e.target.value)} name="total_quantity_limit" className="form-control" />
                                  </FormGroup>
                              </Col>
                              </Row>
                              <Row className="border  opacity-75">
                              <Col lg="2" >
                                  <FormGroup className="mt-4 mb-0">
                                      <Label htmlFor="formrow-firstname-Input">
                                      <a className='link-secondary' target="_blank" href='/help#make_limit_orders_xpercentage'>LMT Ord x% (Mid/MP):</a> <i className="bx bx-info-circle" onClick={() => setVisibleMidPrize(!visibleMidPrize)}></i>
                                      </Label>{" "}
                                     
                                      
                                      <input autoComplete="off"  type="number" onWheel={handleWheel}    max={1000} step={0.5} value={trendMidPrice} onChange={(e) => { e.target.value <= 1000 ? setTrendMidPrice(e.target.value) : setTrendMidPrice(1000)} } onBlur={(e) => e.target.value <= 1000 ? setTrendMidPrice(e.target.value) : setTrendMidPrice(100)} name="mid_price_percentage" className="form-control" />
                                  </FormGroup>
                              </Col>
                             

                              <Col lg="2" >
                                      <FormGroup className="mt-4 mb-0">
                                          <Label htmlFor="formrow-firstname-Input">
                                          <a className='link-secondary' target="_blank" href='/help#maximum_numberof_orders'>Max Ord from same TS</a>
                                            {" "}
                                         <i className="bx bx-info-circle" id ="NOOFTRADE" />
                                            <UncontrolledTooltip placement="top" target="NOOFTRADE"> 
                                            <div className="tooltip-content">
                                              <div className="tooltip-header"><h6>Maximum number of orders from same trend signal</h6></div>
                                        {/* <hr className="m-2 p-0"/> */}
                                        <div className="tooltip-body">{/* {ToolTipData.No_of_Trade} */}</div></div>
                                            </UncontrolledTooltip></Label>
                                            <input autoComplete="off"  type="number" onWheel={handleWheel}   min={0} max={10000} step={0.5} value={noOfTrade ? noOfTrade:''} onChange={(e) => { setnoOfTrade(e.target.value)} } onBlur={(e) => setnoOfTrade(e.target.value)} name="same_trend_no_of_trade" className="form-control" />
                                      </FormGroup>
                                    </Col>
                            
                          

                             
                           
                             
                              
                             
                             
                             
                             
                                    <Col lg="2" >
                                  <FormGroup className="mt-4 mb-0">
                                        <Label htmlFor="formrow-firstname-Input">
                                        <a className='link-secondary' target="_blank" href='/help#waiting_time_for_re_entry'>Re-entry Wait (H:M)</a> <i className="bx bx-info-circle" id ="TFBSTre-entries" />
                                        </Label>{" "}
                             
                                    <UncontrolledTooltip placement="top" target="TFBSTre-entries"> 
                                    <div className="tooltip-content">
                                            <div className="tooltip-header"><h6>Waiting Time  for re-entries</h6></div>
                                        <hr className="m-2 p-0"/>
                                        <div className="tooltip-body">{ToolTipData.wait_time_reentry}</div></div>
                                    </UncontrolledTooltip>                                 
                                        <Flatpickr
                                          className="form-control d-block"
                                          placeholder="Select time"
                                          onChange={(e) => setWaitTimeReentry(new Date(e).getHours()+':'+new Date(e).getMinutes()+':'+new Date(e).getSeconds())} 
                                          value={waitTimeReentry}
                                          options={{                                      
                                            enableTime: true,
                                            noCalendar: true,
                                            dateFormat: "H:i",
                                            time_24hr: true,
                                          }}
                                        />
                                    </FormGroup>  
                                  </Col> 
                          
                               
                                  <Col lg="2" >
                                  <FormGroup className="mt-4 mb-0">
                                        <Label htmlFor="formrow-firstname-Input">
                                        <a className='link-secondary' target="_blank" href='/help#waiting_time_for_re_entrie_after_stop_loss'>Re-entry Wait SL (H:M)</a> <i className="bx bx-info-circle" id ="TFBSTRASL" />
                                        </Label> {" "}
                             
                                    <UncontrolledTooltip placement="top" target="TFBSTRASL"> 
                                    <div className="tooltip-content">
                                            <div className="tooltip-header"><h6>Waiting Time  for re-entries after stop loss</h6></div>
                                        <hr className="m-2 p-0"/>
                                        <div className="tooltip-body">{ToolTipData.time_for_reentries_after_stop_loss}</div></div>
                                    </UncontrolledTooltip>                                 
                                        <Flatpickr
                                          className="form-control d-block"
                                          placeholder="Select time"
                                          onChange={(e) => setWaitTimeSL(new Date(e).getHours()+':'+new Date(e).getMinutes()+':'+new Date(e).getSeconds())} 
                                          value={waitTimeSL}
                                          options={{                                      
                                            enableTime: true,
                                            noCalendar: true,
                                            dateFormat: "H:i",
                                            time_24hr: true,
                                          }}
                                        />
                                    </FormGroup>  
                                  </Col> 
                                  <Col lg="2" >
                                  <FormGroup className="mt-4 mb-0">
                                        <Label htmlFor="formrow-firstname-Input">
                                        <a className='link-secondary' target="_blank" href='/help#waiting_timefor_entry'>Entry Wait Time (H:M)</a> <i className="bx bx-info-circle" id ="TFBSTFE" />
                                        </Label> {" "}
                             
                                    <UncontrolledTooltip placement="top" target="TFBSTFE"> 
                                    <div className="tooltip-content">
                                    <div className="tooltip-header"><h6>Waiting Time for entry</h6></div>
                                        <hr className="m-2 p-0"/>
                                        <div className="tooltip-body">{ToolTipData.time_for_entry}</div></div>
                                    </UncontrolledTooltip>                                 
                                        <Flatpickr
                                          className="form-control d-block"
                                          placeholder="Select time"
                                          onChange={(e) => setWaitTimePrice(new Date(e).getHours()+':'+new Date(e).getMinutes()+':'+new Date(e).getSeconds())} 
                                          value={waitTimePrice}
                                          options={{                                      
                                            enableTime: true,
                                            noCalendar: true,
                                            dateFormat: "H:i",
                                            time_24hr: true,
                                          }}
                                        />
                                    </FormGroup>  
                                  </Col> 
                                
                                  <Col lg="2" >
                                      <FormGroup className="mt-4 mb-0">
                                        <Label htmlFor="formrow-firstname-Input">
                                        <a className='link-secondary' target="_blank" href='/help#order_interval_for_same_trend_signal'>Ord Interval (TS) (H:M)</a>
                                        {" "}
                                        <i className="bx bx-info-circle" id ="TRADEIRTV" />
                                            <UncontrolledTooltip placement="top" target="TRADEIRTV"> 
                                            <div className="tooltip-content">
                                    <div className="tooltip-header"><h6>Order Interval for same trend signal (H : M)</h6></div>
                                        {/* <hr className="m-2 p-0"/> */}
                                        <div className="tooltip-body">
                                          {/* {ToolTipData.Duration} */}
                                          </div></div>
                                        </UncontrolledTooltip></Label>
                                        <Flatpickr
                                          className="form-control d-block"
                                          placeholder="Select time"
                                          onChange={(e) => setinterval(new Date(e).getHours()+':'+new Date(e).getMinutes()+':'+new Date(e).getSeconds())} 
                                          value={interval}
                                          options={{                                      
                                            enableTime: true,
                                            noCalendar: true,
                                            dateFormat: "H:i",
                                            time_24hr: true,
                                          }}
                                        />
                                          </FormGroup>
                                    </Col>
                                   </Row><Row className="border  opacity-75">
                                  
                                    {/* <Col lg="4">
                                      <FormGroup className="mb-4 mt-4" row>
                                        <Label
                                          htmlFor="formrow-firstname-Input"
                                          md="5"
                                          className="col-form-label"
                                        ><a className='link-secondary' href='/help#close_old_orders_on_new_signal'>Close old orders on new signal</a> <i className="bx bx-info-circle" id ="TFBSCOPOTC" />
                                    <UncontrolledTooltip placement="top" target="TFBSCOPOTC"> 
                                        {ToolTipData.Close_old_positions_trend}
                                    </UncontrolledTooltip> 
                                        </Label>
                                        <Col md="1">
                                          <Switch
                                          uncheckedIcon={<Offsymbol />}
                                          checkedIcon={<OnSymbol />}
                                          className="me-1 mb-sm-8 mb-2"
                                          //onColor="#626ed4" 
                                          onChange={(e) => setTrendClosePosition(e)} 
                                          checked={trendClosePosition} 
                                        />
                                        </Col>
                                      </FormGroup>
                                    </Col> */}
                                    
                                    <Col  lg="4">
                                      <FormGroup className="mb-4 mt-4" row>
                                        <Label
                                          htmlFor="formrow-firstname-Input"
                                          md="6"
                                          className="col-form-label"
                                        ><a className='link-secondary' target="_blank" href='/help#close_old_orders_on_trend_change'>Close Positions & Orders on Trend Change</a>    <i className="bx bx-info-circle" id ="TFBSCOPOTC1" />
                                    <UncontrolledTooltip placement="top" target="TFBSCOPOTC1"> 
                                    <div className="tooltip-content">
                                    <div className="tooltip-header"><h6>Close Existing Positions and Open Orders on Trend Change</h6></div>
                                        <hr className="m-2 p-0"/>
                                        <div className="tooltip-body">{ToolTipData.close_old_positions_trend_change}</div></div>
                                    </UncontrolledTooltip> 
                                        </Label>
                                        <Col md="1">
                                          <Switch
                                          uncheckedIcon={<Offsymbol />}
                                          checkedIcon={<OnSymbol />}
                                          className="me-1 mb-sm-8 mb-2"
                                          //onColor="#626ed4" 
                                          onChange={(e) => setTrendChangeClosePosition(e)} 
                                          checked={trendChangeClosePosition} 
                                        />
                                        </Col>
                                      </FormGroup>
                                      </Col>
                                      <Col  lg="4">
                                      <FormGroup className="mb-4 mt-4" row>
                                        <Label
                                          htmlFor="formrow-firstname-Input"
                                          md="6"
                                          className="col-form-label"
                                        ><a className='link-secondary' target="_blank" href='/help#close_old_orders_only_open_orders'>Close Orders on Trend Reversal</a>    <i className="bx bx-info-circle" id ="TFBSCOPOTC111" />
                                    <UncontrolledTooltip placement="top" target="TFBSCOPOTC111"> 
                                    <div className="tooltip-content">
                                    <div className="tooltip-header"><h6>Close Open Orders on Trend Reversal</h6></div>
                                        <hr className="m-2 p-0"/>
                                        <div className="tooltip-body">{ToolTipData.close_old_orders_only_open_orders}</div></div>
                                    </UncontrolledTooltip> 
                                        </Label>
                                        <Col md="1">
                                          <Switch
                                          uncheckedIcon={<Offsymbol />}
                                          checkedIcon={<OnSymbol />}
                                          className="me-1 mb-sm-8 mb-2"
                                          //onColor="#626ed4" 
                                          onChange={(e) => setCloseOldOrdersOnlyOpenOrders(e)} 
                                          checked={close_old_orders_only_open_orders} 
                                        />
                                        </Col>
                                      </FormGroup>
                                    </Col>
                                    
                                  </Row>
                                  
                                  
                                        </>:''}
                                  </div>
                                  
                                       
                                <div className="text-sm-end mt-2">
                                
                                <Button className="btn-label w-md" color="success" type="submit"  disabled={form7_loader.loading}>
                                  {form7_loader.loading ? <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> : ''}
                                  <i className="bx bx-smile label-icon"></i> Submit
                                </Button>
                                <div className="text-info font-size-10">Orders will be placed one minute after saving your settings. </div>
                                </div>  
                          </form>
                        </div>
                      </TabPane>
                      <TabPane tabId="10">
                        <div>
                          <CardTitle>Diagonals Bot Settings</CardTitle>
                          <p className="card-title-desc"> You can also change these settings while starting a Diagonals bot.</p>
                          <Row >
                              <Col lg="2">
                                {/* <FormGroup className="mt-4 mb-4"> */}
                                  <Label htmlFor="formrow-firstname-Input">
                                  Delta Hedging {" "}
                                  <i className="bx bx-info-circle" id ="EDHDIAGONALS" />
                                    <UncontrolledTooltip placement="top" target="EDHDIAGONALS">
                                    <div className="tooltip-content">
                                        <div className="tooltip-header"><h6>Enable Delta Hedging</h6></div>
                                    <hr className="m-2 p-0"/>
                                    <div className="tooltip-body">{ToolTipData.delta_hedging}</div></div>
                                    </UncontrolledTooltip>
                                  </Label>
                                    {/* <input type="checkbox" className="btn-check" id="btn-check-outlined"  onClick={(e)=> {setDeltahedging(e.target.checked)}}/><br></br>
                                      <label style={{width:"80px"}} className={"btn " + (Deltahedging === true ? "btn-outline-success" : "btn-outline-danger")} htmlFor="btn-check-outlined" >{Deltahedging === true ? "Disable" : "Enable"}</label><br></br> */}
                                {/* </FormGroup> */}
                              <div>
                                      <Switch
                                      uncheckedIcon={<Offsymbol />}
                                      checkedIcon={<OnSymbol />}
                                      // className="me-1 mt-4 mb-sm-8 mb-2"                                      
                                      onChange={(e) => {setDiagonalsDeltahedging(e)}} 
                                      checked={DiagonalsDeltahedging}
                                    />
                                    </div>
                             </Col>
                              {
                                DiagonalsDeltahedging ? <>
                              <Col lg="2">
                                
                                  <Label htmlFor="formrow-firstname-Input">
                                  Disable SL Leg{" "}
                                  <i className="bx bx-info-circle" id ="DHOSLIOLDIAGONALS" />
                                    <UncontrolledTooltip placement="top" target="DHOSLIOLDIAGONALS">
                                    <div className="tooltip-content">
                                      <div className="tooltip-header"><h6>Disable on SL in a Leg</h6></div>
                                  <hr className="m-2 p-0"/>
                                  <div className="tooltip-body">{ToolTipData.delta_hedging_on_stop_loss_in_one_leg}</div></div>
                                    </UncontrolledTooltip>
                                  </Label>
                                    {/* <input type="checkbox" className="btn-check" id="btn-check-outlined2"  onClick={(e)=> {setDeltahedging2(e.target.checked)}}/><br></br>
                                      <label style={{width:"80px"}} className={"btn " + (Deltahedging2 === true ? "btn-outline-success" : "btn-outline-danger")} htmlFor="btn-check-outlined2" >{Deltahedging2 === true ? "Disable" : "Enable"}</label><br></br> */}
                                      
                               
                                      <div>
                                      <Switch
                                      uncheckedIcon={<Offsymbol />}
                                      checkedIcon={<OnSymbol />}
                                                                      
                                      onChange={(e) => {setDiagonalsDeltahedging2(e)} }
                                      checked={DiagonalsDeltahedging2}
                                    />
                                    </div>
                              </Col> 
                             
                              </> : ''
                              }
                             
                              {
                              DiagonalsDeltahedging ? <>
                             
                             
                              <Col lg="3">
                                  <FormGroup className="">
                                        <Label htmlFor="formrow-firstname-Input">
                                        Delta Check Intvl (H:M)
                                        {" "} 
                              <i className="bx bx-info-circle" id ="DHINTERVALDIAGONALS" />
                                    <UncontrolledTooltip placement="top" target="DHINTERVALDIAGONALS"> 
                                    <div className="tooltip-content">
                                        <div className="tooltip-header"><h6>Interval time for delta checking (H : M)</h6></div>
                                    <hr className="m-2 p-0"/>
                                    <div className="tooltip-body">{ToolTipData.delta_hedging_interval}</div></div>
                                    </UncontrolledTooltip> </Label> 
                                        <Flatpickr
                                          className="form-control d-block"
                                          placeholder="Select time"
                                          name="waitTimeForPrice"
                                          onChange={(e) => handleDH(e,'diagonals')}
                                          value={toHoursAndMinutes(DiagonalsdeltaHedgingIntervel)} 
                                          options={{                                      
                                            enableTime: true,
                                            noCalendar: true,
                                            dateFormat: "H:i",
                                            time_24hr: true,
                                          }}
                                        />
                                    </FormGroup>  
                                  </Col>
                              <Col lg="2">
                                <FormGroup className="">
                                    <Label htmlFor="formrow-firstname-Input">
                                    Delta range
                                    </Label>{" "}
                              <i className="bx bx-info-circle" id ="DHDELTARANGEDIAGONALS" />
                                    <UncontrolledTooltip placement="top" target="DHDELTARANGEDIAGONALS"> 
                                        {ToolTipData.delta_hedging_range}
                                    </UncontrolledTooltip>
                                    <input autoComplete="off" type="number" onWheel={handleWheel}  inputMode="numeric"   min={0.0001} max={100}  step={0.0001} value={DiagonalsdeltaHedgingRange} onChange={(e) => setDiagonalsDeltaHedgingRange(e.target.value)} name="delta_Range" className="form-control" />
                                  </FormGroup>
                              </Col>
                              <Col lg="2">
                                <FormGroup >
                                    <Label htmlFor="formrow-firstname-Input">
                                      Order type
                                    </Label>{" "}
                                    <i className="bx bx-info-circle" id ="DHORDER" />
                                    <UncontrolledTooltip placement="top" target="DHORDER">
                                      {ToolTipData.delta_hedging_order_type}
                                    </UncontrolledTooltip>  
                                    <select name="order_type" label="order_type" className="form-select" onChange={(e) => setDiagonalsOrderType(e.target.value)} value={diagonalsOrderType}>
                                      <option value='market_order'>Market Order</option>
                                      <option value='limit_order'>Limit Order</option>
                                    </select>
                                  </FormGroup>
                              </Col>
                              <Col lg="3">
                                <FormGroup className="mt-4 mb-0">
                                   <Label htmlFor="formrow-firstname-Input">
                                   Hedge only for loss
                                    </Label>{" "}
                                    <i className="bx bx-info-circle" id ="DHORDERLoss" />
                                    <UncontrolledTooltip placement="top" target="DHORDERLoss">
                                    <div className="tooltip-content">
                                        <div className="tooltip-header"><h6>Hedge only for the option with loss</h6></div>
                                    <hr className="m-2 p-0"/>
                                    <div className="tooltip-body">{ToolTipData.hedge_only_for_the_option_with_loss}</div></div>
                                    </UncontrolledTooltip>
                                    <Col md="2">
                                    <Switch
                                    uncheckedIcon={<Offsymbol />}
                                    checkedIcon={<OnSymbol />}
                                    className="me-1 mb-sm-8 mb-2"
                                    //onColor="#626ed4"
                                    onChange={(e) => setDiagonalsHedgeOnlyForLoss(e)}
                                    checked={diagonalshedgeOnlyForLoss}
                                  />
                                  </Col>
                                </FormGroup>
                              </Col>
                              {diagonalshedgeOnlyForLoss? <Col lg="3"> 
                          <div className="mt-4">

                            <div>
                              <Label className="form-label">Min Loss for Hedge</Label>{" "}
                              <i className="bx bx-info-circle" id ="DHMINLOSS" />
                                    <UncontrolledTooltip placement="top" target="DHMINLOSS"> 
                                    <div className="tooltip-content">
                                        <div className="tooltip-header"><h6>Min Loss for Delta Hedging</h6></div>
                                    <hr className="m-2 p-0"/>
                                    <div className="tooltip-body">{ToolTipData.delta_hedging_min_loss}</div></div>
                                    </UncontrolledTooltip>
                                <FormGroup className="input-group mb-0 ">
                                 
                                    
                                    <select
                              className="form-control" name="hedging_min_loss_type_diagonal"  id="inputGroupFileAddon04"  onChange={(e) => setMinLossTypeDiagonals(e.target.value)} value={minLossTypeDiagonals?minLossTypeDiagonals:'percentage'} 
                            >
                              <option value="percentage">%</option>
                              <option value="points">Pts</option>
                            </select>                                  
                                    <input autoComplete="off" type="number" onWheel={handleWheel}  inputMode="numeric"   min={0} max={minLossTypeDiagonals!='points'?100:100000} step={0.5} value={minLossValueDiagonals?minLossValueDiagonals:0} onChange={(e) => setMinLossValueDiagonals(e.target.value)} name="min_loss_val_diagonal" className="form-control w-50"  aria-describedby="inputGroupFileAddon04" />
                                  </FormGroup> </div></div>
                              </Col> :''}
                             
                              </> : ''
                              }
                               </Row>
                                  <Button className="btn-label w-md float-end" color="success" type="submit" disabled={form12_loader.loading} onClick={(e) => DiagonalsSettings()}>
                              {form12_loader.loading ? <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> : ''}
                              <i className="bx bx-smile label-icon"></i> Submit
                            </Button>
                        </div> 
                      </TabPane>
                      <TabPane tabId="9">
                        <div>
                          <CardTitle>Account Level Delta Hedging Settings{" "}
                            {/* <i className="bx bx-info-circle" onClick={() => setvisibleDH(!visibleDH)}></i> */}
                            </CardTitle>
                          <Row>
                          <FormGroup className="mb-4" row>
                              <Label
                                htmlFor="formrow-firstname-Input"
                                md="4"
                                className="col-form-label"
                              >
                                Enable Account Level Delta Hedging Strategy <i className="bx bx-info-circle" id ="DHENABLE" />
                                    <UncontrolledTooltip placement="top" target="DHENABLE"> 
                                        {ToolTipData.account_level_delta_hedging_enable}
                                    </UncontrolledTooltip>
                              </Label>
                              <Col md="7">
                                <Switch
                                uncheckedIcon={<Offsymbol />}
                                checkedIcon={<OnSymbol />}
                                className="me-1 mb-sm-8 mb-2"
                                //onColor="#626ed4" 
                                onChange={(e) => setenableDeltaHedging(e)} 
                                checked={enableDeltaHedging} 
                              />
                              </Col>
                            </FormGroup>
                            {
                              enableDeltaHedging ? <>
                          <Col lg="4">
                                  <FormGroup className="mt-4 mb-0">
                                        <Label htmlFor="formrow-firstname-Input">
                                        Delta Check Interval (H:M)
                                        </Label> {" "} 
                              <i className="bx bx-info-circle" id ="DHINTERVAL" />
                                    <UncontrolledTooltip placement="top" target="DHINTERVAL"> 
                                    <div className="tooltip-content">
                                        <div className="tooltip-header"><h6>Interval time for delta checking (H : M)</h6></div>
                                    <hr className="m-2 p-0"/>
                                    <div className="tooltip-body">{ToolTipData.delta_hedging_interval}</div></div>
                                    </UncontrolledTooltip> 
                                        <Flatpickr
                                          className="form-control d-block"
                                          placeholder="Select time"
                                          name="waitTimeForPrice"
                                          onChange={(e) => handleDH(e)}
                                          value={toHoursAndMinutes(deltaHedgingIntervel)} 
                                          options={{                                      
                                            enableTime: true,
                                            noCalendar: true,
                                            dateFormat: "H:i",
                                            time_24hr: true,
                                          }}
                                        />
                                    </FormGroup>  
                                  </Col>
                              <Col lg="4">
                                <FormGroup className="mt-4 mb-0">
                                    <Label htmlFor="formrow-firstname-Input">
                                    Delta range
                                    </Label>{" "}
                              <i className="bx bx-info-circle" id ="DHDELTARANGE" />
                                    <UncontrolledTooltip placement="top" target="DHDELTARANGE"> 
                                        {ToolTipData.delta_hedging_range}
                                    </UncontrolledTooltip>
                                    <input autoComplete="off" type="number" onWheel={handleWheel}  inputMode="numeric"   min={0.001} max={100}  step=".001" value={deltaHedgingRange} onChange={(e) => setDeltaHedgingRange(e.target.value)} name="delta_Range" className="form-control" />
                                  </FormGroup>
                              </Col>
                              <Col lg="4">
                                <FormGroup className="mt-4 mb-0">
                                    <Label htmlFor="formrow-firstname-Input">
                                      Order type
                                    </Label>{" "}
                                    <i className="bx bx-info-circle" id ="DHORDER" />
                                    <UncontrolledTooltip placement="top" target="DHORDER">
                                      {ToolTipData.delta_hedging_order_type}
                                    </UncontrolledTooltip>  
                                    <select name="order_type" label="order_type" className="form-select" onChange={(e) => setOrderType(e.target.value)} value={orderType}>
                                      <option value='market_order'>Market Order</option>
                                      <option value='limit_order'>Limit Order</option>
                                    </select>
                                  </FormGroup>
                              </Col>
                              <Col lg="4">
                                <FormGroup className="mt-4 mb-0">
                                   <Label htmlFor="formrow-firstname-Input">
                                   Hedge on Loss Option Only
                                    {" "}
                                    <i className="bx bx-info-circle" id ="DHORDERLoss" />
                                    <UncontrolledTooltip placement="top" target="DHORDERLoss">
                                    <div className="tooltip-content">
                                        <div className="tooltip-header"><h6>Hedge only for the option with loss</h6></div>
                                    <hr className="m-2 p-0"/>
                                    <div className="tooltip-body"></div>{ToolTipData.hedge_only_for_the_option_with_loss}</div>
                                    </UncontrolledTooltip></Label>
                                    <Col md="2">
                                    <Switch
                                    uncheckedIcon={<Offsymbol />}
                                    checkedIcon={<OnSymbol />}
                                    className="me-1 mb-sm-8 mb-2"
                                    //onColor="#626ed4"
                                    onChange={(e) => setHedgeOnlyForLoss(e)}
                                    checked={hedgeOnlyForLoss}
                                  />
                                  </Col>
                                </FormGroup>
                              </Col>
                              {hedgeOnlyForLoss? <Col lg="3"> 
                          <div className="mt-4">

                            <div>
                              <Label className="form-label">Min Loss for Hedge</Label>{" "}
                              <i className="bx bx-info-circle" id ="DHMINLOSS" />
                                    <UncontrolledTooltip placement="top" target="DHMINLOSS"> 
                                    <div className="tooltip-content">
                                        <div className="tooltip-header"><h6>Min Loss for Delta Hedging</h6></div>
                                    <hr className="m-2 p-0"/>
                                    <div className="tooltip-body">{ToolTipData.delta_hedging_min_loss}</div></div>
                                    </UncontrolledTooltip>
                                <FormGroup className="input-group mb-0 ">
                                 
                                    
                                    <select
                              className="form-control" name="index_distance_type"  id="inputGroupFileAddon04"  onChange={(e) => setMinLossType(e.target.value)} value={minLossType?minLossType:'percentage'} 
                            >
                              <option value="percentage">%</option>
                              <option value="points">Pts</option>
                            </select>                                  
                                    <input autoComplete="off" type="number" onWheel={handleWheel}  inputMode="numeric"   min={0} max={minLossType!='points'?100:100000} step={0.5} value={minLossValue?minLossValue:0} onChange={(e) => setMinLossValue(e.target.value)} name="index_distance" className="form-control w-50"  aria-describedby="inputGroupFileAddon04" />
                                  </FormGroup> </div></div>
                              </Col> :''}
                              {/* {hedgeOnlyForLoss?
                              <Col lg="4">
                                <FormGroup className="mt-4 mb-0">
                                  <Label htmlFor="formrow-firstname-Input">
                                    Min Loss % for Delta Hedging
                                  </Label>{" "}
                                  <i className="bx bx-info-circle" id ="DHMINLOSS" />
                                    <UncontrolledTooltip placement="top" target="DHMINLOSS">
                                        {ToolTipData.delta_hedging_min_loss}
                                    </UncontrolledTooltip>
                                    <input autoComplete="off" type="number" onWheel={handleWheel}  inputMode="numeric"   min={0} max={100}  step=".001" value={minLoss} onChange={(e) => setMinLoss(e.target.value)} name="min_loss" className="form-control" />
                                  </FormGroup>
                              </Col>
                              :''} */}
                           </> : ''}
                              </Row>
                              
                                  <Button className="btn-label w-md float-end" color="success" type="submit" disabled={form11_loader.loading} onClick={(e) => DHSettings()}>
                              {form11_loader.loading ? <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> : ''}
                              <i className="bx bx-smile label-icon"></i> Submit
                            </Button>
                        </div> 
                        <div className="mt-3 mb-0 alert alert-info fade show float-end"><p style={{fontWeight:"bold",fontSize:"14px"}} >Note:</p>
                                <p >Delta hedging is a risk management strategy that aims to reduce or eliminate the potential losses associated with price movements in an underlying asset. This is achieved by taking offsetting positions in the market.</p>
                                <p >Enabling delta hedging individually for each trading strategy, such as strangles, diagonals, and straddles, means that you are applying this risk management technique separately for each of these strategies. For instance, if you have both a strangle strategy and a diagonal strategy, you would manage the risk for each of these strategies independently.</p>
                                <p >On the other hand, enabling account level delta hedging means that you are applying this risk management technique to your entire account. Rather than managing the risk for each strategy individually, you are managing the risk for your entire portfolio of strategies. This could be advantageous if you have a diverse portfolio and want to manage your overall risk, rather than the risk associated with each individual strategy</p>
                                </div>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>                
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Modal
        isOpen={visible}
        toggle={() => {
        setVisible(false);
        }}
        >
        <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          How to Create Token in Telegram
        </h5>
        <button
          type="button"
          onClick={() => {
            setVisible(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div className="modal-body">
        {parse(''+ teleMsg)}
        </div>
        <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
          setVisible(false);
          }}
          className="btn btn-secondary "
          data-dismiss="modal"
        >
          Close
        </button>                        
        </div>
      </Modal>
      <Modal
        isOpen={visibleID}
        toggle={() => {
        setVisibleID(false);
        }}
        >
        <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          How to Create Your ID in Telegram
        </h5>
        <button
          type="button"
          onClick={() => {
            setVisibleID(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div className="modal-body">
        {parse(''+ teleIDMsg)}
        </div>
        <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            setVisibleID(false);
          }}
          className="btn btn-secondary "
          data-dismiss="modal"
        >
          Close
        </button>                        
        </div>
      </Modal>

      <Modal
        isOpen={visibleChatID}
        toggle={() => {
        setVisibleChatID(false);
        }}
        >
        <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          How to Create Chat ID in Telegram
        </h5>
        <button
          type="button"
          onClick={() => {
            setVisibleChatID(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div className="modal-body">
        {parse(''+ teleChatIDMsg)}
        </div>
        <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            setVisibleChatID(false);
          }}
          className="btn btn-secondary "
          data-dismiss="modal"
        >
          Close
        </button>                        
        </div>
      </Modal>
      <Modal
        isOpen={visibleMin}
        toggle={() => {
        setVisibleMin(false);
        }}
        >
        <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          To set the Minimum profit for an order.
        </h5>
        <button
          type="button"
          onClick={() => {
            setVisibleMin(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div className="modal-body">
        {parse(''+ MintakeProfitMsg)}
        </div>
        <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            setVisibleMin(false);
          }}
          className="btn btn-secondary "
          data-dismiss="modal"
        >
          Close
        </button>                        
        </div>
      </Modal>
      <Modal
        isOpen={visibleDH}
        toggle={() => {
        setvisibleDH(false);
        }}
        >
        <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
        Account Level Delta Hedging Settings.
        </h5>
        <button
          type="button"
          onClick={() => {
            setvisibleDH(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div className="modal-body">
        {parse(''+ DeltaHedgingMsg)}
        </div>
        <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            setvisibleDH(false);
          }}
          className="btn btn-secondary "
          data-dismiss="modal"
        >
          Close
        </button>                        
        </div>
      </Modal>
      <Modal
        isOpen={visibleTailling}
        toggle={() => {
        setVisibleTailling(false);
        }}
        >
        <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          To set the Minimum profit for an order.
        </h5>
        <button
          type="button"
          onClick={() => {
            setVisibleTailling(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div className="modal-body">
        {parse(''+ TaillingTakeProfitMsg)}
        </div>
        <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            setVisibleTailling(false);
          }}
          className="btn btn-secondary "
          data-dismiss="modal"
        >
          Close
        </button>                        
        </div>
      </Modal>
      <Modal
        isOpen={visibleMidPrize}
        toggle={() => {
        setVisibleMidPrize(false);
        }}
        >
        <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
        Make Limit Orders x% from Mid/MarkPrice
        </h5>
        <button
          type="button"
          onClick={() => {
            setVisibleMidPrize(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div className="modal-body">
        {parse(''+ MidPrizeMsg)}
        </div>
        <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            setVisibleMidPrize(false);
          }}
          className="btn btn-secondary "
          data-dismiss="modal"
        >
          Close
        </button>                        
        </div>
      </Modal>
    </React.Fragment>
    
  )
}

export default Settings