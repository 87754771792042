import React, { Component } from "react";
import PropTypes from "prop-types";
import { Alert, Card, CardBody, Col, Container, Row, Label } from "reactstrap";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";

// action
import {
  apiError,
  registerUser,
  registerUserFailed,
} from "../../store/actions";

// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// import images
import profileImg from "../../assets/images/profile-img.png";
import logoImg from "../../assets/images/logo.png";
import blz_logo from "../../assets/images/blaze_logo.png";
import debounce from "lodash.debounce";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {form_loading : false};
    this.debouncedUserRegister = debounce(this.user_register, 1000);
  }

  componentDidMount() {
    this.props.apiError("");
    this.props.registerUserFailed("");
  }

  handleRegister = (get_values) => {
    this.debouncedUserRegister(get_values);
  };
 

  user_register = get_values =>{
    this.setState({form_loading : true});
    this.props.registerUser(get_values,this.props.history);
  }

  render() {
    return (
      <React.Fragment>        
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col className="col-7">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Free Register</h5>
                          <p>Get your free DeriSnap account now.</p>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profileImg} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <Link to="/">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={logoImg}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                    {this.props.registrationError && this.props.registrationError ? (
                      <Alert color="danger">{this.props.registrationError.includes("Firebase:") ? this.props.registrationError.replace("Firebase:",""):this.props.registrationError}</Alert>                      
                    ) : null}                   
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          email: "",
                          password: "",
                          first_name: "",
                          last_name : "",
                          confirm_password: "",
                          captchaToken: "",
                        }}
                        validationSchema={Yup.object().shape({
                          email: Yup.string().email("Please Enter Valid Email").required(
                            "Please Enter Your Email"
                          ),
                          password: Yup.string().required(
                            "Please Enter Valid Password"
                          ),
                          first_name: Yup.string().required(
                            "Please Enter Valid Firstname"
                          ),
                          last_name: Yup.string().required(
                            "Please Enter Valid Lastname"
                          ),
                          confirm_password: Yup.string().required(
                            "Confirm Password is required"
                          ).oneOf([Yup.ref('password'), null], 'Passwords must match'),
                          captchaToken : Yup.string().required(
                            "Please verify you are not a robot"
                          ),
                        })}
                        onSubmit={this.handleRegister}
                      >
                        {({ errors, status, touched,setFieldValue }) => (
                          <Form className="form-horizontal">

                            <div className="mb-3">
                              <Label for="first_name" className="form-label">
                                First name
                              </Label>
                              <Field
                                name="first_name"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.first_name && touched.first_name
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="first_name"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>

                            <div className="mb-3">
                              <Label for="last_name" className="form-label">
                                Last name
                              </Label>
                              <Field
                                name="last_name"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.last_name && touched.last_name
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="last_name"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>

                            <div className="mb-3">
                              <Label for="email" className="form-label">
                                Email
                              </Label>
                              <Field
                                name="email"
                                autoComplete="true"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.email && touched.email
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="mb-3">
                              <Label for="password" className="form-label">
                                Password
                              </Label>
                              <Field
                                name="password"
                                autoComplete="true"
                                type="password"
                                className={
                                  "form-control" +
                                  (errors.password && touched.password
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="password"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>

                            <div className="mb-3">
                              <Label for="confirm_password" className="form-label">
                                Confirm Password
                              </Label>
                              <Field
                                name="confirm_password"
                                autoComplete="true"
                                type="password"
                                className={
                                  "form-control" +
                                  (errors.confirm_password && touched.confirm_password
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="confirm_password"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="mb-3">
                            <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} render="explicit" onChange={(response) => { setFieldValue("captchaToken", response); }}/>                            
                            {errors.captchaToken && touched.captchaToken && (<p className="text-danger" style={{fontSize: '80%',color:'#f46a6a'}}>{errors.captchaToken}</p>)}
                            </div>
                            <div className="mt-4 d-grid">
                              <button
                                className="btn btn-primary btn-block"
                                type="submit"
                                disabled ={this.state.form_loading}
                              >
                                {this.state.form_loading && !this.props.registrationError ? <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> : ''}
                                Register
                              </button>
                            </div>

                            {/* <div className="mt-4 text-center">
                              <p className="mb-0">
                                By registering you agree to the Skote{" "}
                                <Link to="#" className="text-primary">
                                  Terms of Use
                                </Link>
                              </p>
                            </div> */}
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    Already have an account ?{" "}
                    <Link to="/login" className="fw-medium text-primary">
                      {" "}
                      Login
                    </Link>{" "}
                  </p>
                  <p>
                    © {new Date().getFullYear()} DeriSnap.
                    Developed @ <a target="_blank" rel="noreferrer" href="https://blaze.ws"><img src={blz_logo} style={{width:'11px'}} ></img></a>
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Register.propTypes = {
  apiError: PropTypes.any,  
  registerUser: PropTypes.func,
  registerUserFailed: PropTypes.any,
  registrationError: PropTypes.any,
  user: PropTypes.object,
};

const mapStateToProps = state => {
  const { user, registrationError, loading } = state.Account;
  return { user, registrationError, loading };
};

export default connect(mapStateToProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(Register);
