import React, { Component } from "react";
import PropTypes from "prop-types";
import {  Collapse } from "reactstrap";
import { Link, withRouter } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import api from '../../services/api.service';
import firebase from 'firebase/compat/app'
import "firebase/compat/firestore";
import Switch from "react-switch";
import { toast } from "react-toastify";

class Navbar extends Component {
  constructor(props) {
    super(props);
    var user = localStorage.getItem('user')
    if(user){
     user = JSON.parse(user)
    }
    this.state = {
      user:user,
      allowAdminStatus :user.profile && user.profile.is_allow_admin_bot ? user.profile.is_allow_admin_bot:false,
      // loading:true,
      admin_strangle_quantity:user.profile && user.profile.admin_strangle_quantity ? user.profile.admin_strangle_quantity:1,
      admin_diagonal_quantity:user.profile && user.profile.admin_diagonal_quantity ? user.profile.admin_diagonal_quantity:1,
      admin_straddle_quantity:user.profile && user.profile.admin_straddle_quantity ? user.profile.admin_straddle_quantity:1,
      is_allow_admin_trending_bot:user.profile && user.profile.is_allow_admin_trending_bot ? user.profile.is_allow_admin_trending_bot:1,
      admin_trend_following_qty:user.profile && user.profile.admin_trend_following_qty ? user.profile.admin_trend_following_qty:1
    };
  }

  componentDidMount() {
    let matchingMenuItem = null;
    const ul = document.getElementById("navigation");
    const items = ul.getElementsByTagName("a");
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
    this.get_stray_orders();
    // this.get_profile()
  }

  activateParentDropdown = item => {
    item.classList.add("active");
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add("active"); // li
              const parent7 = parent6.parentElement;
              if (parent7) {
                parent7.classList.add("active"); // li 
              }
            }
          }
        }
      }
    }
    return false;
  };

  setAllowAdminStatus = (value) => {
    this.setState({ allowAdminStatus: value });
    this.privacySettingSubmit(value)
  };

  get_stray_orders = async()=>{
    firebase.auth();
    
    await api({ contentType: true, auth: true }).get('/delta/get-delta-positions').then((response) => {
      let res=response.data.response.data[0];
      if(typeof res !== 'undefined'){
      this.setState({stray_position_order : res.stray_positions});
      }else{
        this.setState({stray_position_order : ""});
      }
    }).catch((err) => {
      console.log(err);
  });
    // const get_balance = firebase.firestore().collection("users").doc(this.uid).collection("profile").doc("positions-info");
    // await get_balance.onSnapshot((doc) => {
    //     let item = doc.data();
    //     this.strayorders= item;
    //     this.setState({stray_position_order : this.strayorders.stray_positions});        
    // });    
  }

  get_profile =async() => { await api({ contentType: true, auth: true }).get('/user/profile')
          .then(res => {
              if(res.data.response)
              {
                const profile_details = res.data.response.user;
                // this.setState({ allowAdminStatus: profile_details.is_allow_admin_bot == 1? true : false });
                this.setState((prev) => ({
                  ...prev,
                  loading: false,
                  allowAdminStatus: profile_details.is_allow_admin_bot == 1? true : false,
                  admin_trend_following_qty:profile_details.admin_trend_following_qty !== undefined ? profile_details.admin_trend_following_qty : 1,
                  admin_strangle_quantity: profile_details.admin_strangle_quantity !== undefined ? profile_details.admin_strangle_quantity : 1,
                  admin_diagonal_quantity: profile_details.admin_diagonal_quantity !== undefined ? profile_details.admin_diagonal_quantity : 1,
                  admin_straddle_quantity: profile_details.admin_straddle_quantity !== undefined ? profile_details.admin_straddle_quantity : 1,
                  is_allow_admin_trending_bot: profile_details.is_allow_admin_trending_bot ? profile_details.is_allow_admin_trending_bot : 1
                }));
              }
          })}


    privacySettingSubmit = async(value)=>{
      toast.dismiss();
        try{
         
          const bodyParams = {
            is_allow_admin_bot : value ? 1 : 0,
            admin_strangle_quantity : this.state.admin_strangle_quantity,
            admin_diagonal_quantity : this.state.admin_diagonal_quantity,
            admin_straddle_quantity : this.state.admin_straddle_quantity,
            is_allow_admin_trending_bot: value?1:0,
            admin_trend_following_qty: this.state.admin_trend_following_qty
          }
          await api({ contentType: true, auth: true }).post('/delta/update-privacy-settings', bodyParams).then((res) => { 
            toast.success(`Deri Bot ${this.state.allowAdminStatus? "enabled" : "disabled"} successfully` );

            if(this.state.user && this.state.user.profile) {

            const updatedUser = {
              ...this.state.user,
              profile: {
                ...this.state.user.profile,
                is_allow_admin_bot: value ? 1 : 0,
                admin_strangle_quantity: this.state.admin_strangle_quantity,
                admin_diagonal_quantity: this.state.admin_diagonal_quantity,
                admin_straddle_quantity: this.state.admin_straddle_quantity,
                is_allow_admin_trending_bot: value ? 1 : 0,
                admin_trend_following_qty: this.state.admin_trend_following_qty,
              }
            };

              this.setState(
                {
                  ...this.state, // Spread the existing state to keep other properties unchanged
                  user: updatedUser, // Update only the user object
                },
                () => {
                  localStorage.setItem("user", JSON.stringify(updatedUser)); // Ensure localStorage gets the latest user data
                }
              );
            }
         
          }).catch(err => {  
            // handleError(err);       
            // throw err
          })
        }
        catch(err)
        {
            // throw err
        }
      }

 Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 10,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        No
      </div>
    );
  };
  
 OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 10,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        Yes
      </div>
    );
  };



  render() {
    return (
      <React.Fragment>
        <div className="topnav">
          <div className="container-fluid">
            <nav
              className="navbar navbar-light navbar-expand-lg topnav-menu "
              id="navigation"
            >
              <Collapse
                isOpen={this.props.menuOpen}
                className="navbar-collapse"
                id="topnav-menu-content" 
              >
                <ul className="navbar-nav w-100">
                  <li>
                <Link to="dashboard" className="">
                  <i className="bx bx-home-circle" />
                  <span>{this.props.t("Dashboard")}</span>
                </Link>
              </li>
              <li>
                <Link to="strangles" className="">
                  <i className="mdi mdi-alpha-s-box" />
                  <span>{this.props.t("Strangles")}</span>
                </Link>
              </li>

              <li>
                <Link to="diagonals" className="">
                  <i className="mdi mdi-alpha-d-box" />
                  <span>{this.props.t("Diagonals")}</span>
                </Link>
              </li>

              <li>
                <Link to="straddles" className="">
                  <i className="bx bx-cube" />
                  <span>{this.props.t("Straddles")}</span>
                </Link>
              </li>
              <li>
                <Link to="trend-following" className="">
                  <i className="mdi mdi-alpha-t-box" />
                  <span>{this.props.t("Trend Following")}</span>
                </Link>
              </li>
              <li>
                <Link to="delta-hedging" className="">
                  <i className="mdi mdi-scale-balance" />
                  <span>{this.props.t("Delta Hedging")}</span>
                </Link>
              </li>
              <li>
                <Link to="transaction" className="">
                  <i className="bx bx-history" />
                  <span>{this.props.t("Transactions")}</span>
                </Link>
              </li>
              {this.state.stray_position_order && this.state.stray_position_order > 0 ?
              <li>
                <Link to="stray-positions" className="text-danger">
                  <i className="mdi mdi-alert-outline text-danger" />
                  <span>{this.props.t("Stray Positions")}
                  <span className="font-size-10"> ({this.state.stray_position_order})</span>
                  </span>
                </Link>
              </li> : ''}
              <li>
                <Link to="settings" className="">
                  <i className="bx bx-cog" />
                  <span>{this.props.t("Settings")}</span>
                </Link>
              </li>

              {/* <li>
                <Link to="settings" className="mr-0">
                  <i className="bx bx-cog" />
                  <span>{this.props.t("Deri bot")}</span>
                </Link>
              </li> */}


              {/* {
                this.state.loading ? null : */}
              <li className="help-url d-flex gap-2">
                  <Link to="settings?deri-bot">
                        {/* <i className="bx bx-help-circle me-2" />{" "} */}
                        {/* {this.props.t("Deri bot")} */}
                      <span className="text-success"> {this.state.allowAdminStatus?"Disable":"Enable"} Deri Bot </span>
                        
                    </Link>
                    <Switch
                                uncheckedIcon={this.Offsymbol()}
                                checkedIcon={this.OnSymbol()}
                                // className="me-1 mb-sm-8 mb-2"
                                height={18}
                                width={40}
                                //onColor="#626ed4" 
                               onChange={(e) => this.setAllowAdminStatus(e)}
                                checked={this.state.allowAdminStatus} 
                              />
                </li>
              {/* } */}

                {/* <li className="nav-item dropdown">
                    <Link className="nav-link dropdown-toggle arrow-none" to="/ccxt/settings">
                      <i className="bx bx-cog me-2" />
                      {this.props.t("Settings")}
                    </Link>
                </li>
                <li className="nav-item dropdown">
                    <Link className="nav-link dropdown-toggle arrow-none" to="/ccxt/wallet">
                      <i className="bx bx-money me-2" />
                      {this.props.t("Wallet")}
                    </Link>
                </li> 

                <li className="nav-item dropdown">
                    <Link className="nav-link dropdown-toggle arrow-none" to="/ccxt/orders/active">
                      <i className="bx bx-transfer me-2" />
                      {this.props.t("Active Orders")}
                    </Link>
                </li>


                <li className="nav-item dropdown">
                    <Link className="nav-link dropdown-toggle arrow-none" to="/ccxt/orders/completed">
                      <i className="bx bx-history me-2" />
                      {this.props.t("Completed Orders")}
                    </Link>
                </li> */}

                {/* <li className="nav-item dropdown">
                    <Link className="nav-link dropdown-toggle arrow-none" to="/#"
                      onClick={e => {
                        e.preventDefault();
                        this.setState({ ordersState: !this.state.ordersState });
                      }}>
                      <i className="bx bx-transfer me-2" />
                      {this.props.t("Orders")}
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: this.state.ordersState,
                      })}
                    >
                      <Link to="/ccxt/orders/active" className="dropdown-item">
                        {this.props.t("Active")}
                      </Link>
                      <Link to="/ccxt/orders/completed" className="dropdown-item">
                        {this.props.t("Completed")}
                      </Link>
                       </div>
                </li>                */}
                </ul>
              </Collapse>
            </nav>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Navbar.propTypes = {
  location: PropTypes.object,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Navbar));