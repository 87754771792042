import Breadcrumbs from 'components/Common/Breadcrumb'
import { deleteUserExchange, updateCCXTExchangeConfig, updateCCXTExchangeStatus, updateExchangeSl } from 'helpers/ccxt_helper';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Card, CardBody, CardTitle, Col, Container, Form, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row, UncontrolledTooltip } from 'reactstrap'
import api from '../../../helpers/api_helper';
import { getExchangeName } from 'services/common.service';
import { useFormik } from 'formik';
import * as Yup from "yup";
import parse from 'html-react-parser';
import { CredentialsSetupMSG } from 'helpers/help-msg';
import ExchangeForm from '../Settings/ExchangeForm';
import Switch from "react-switch";
import firebase from "firebase/compat/app";
import axios from 'axios';

function CcxtExchange() {
    document.title = "Spot Trade - Exchanges";
    const currentUser = JSON.parse(localStorage.getItem('user'));
    const [loading, setLoading] = useState(true);
    const [ccxtAllExchanges, setCCXTAllExchanges] = useState([]);
    const [selectedExchange, setSelectedExchange] = useState("")
    const [ccxtUserExchanges, setCCXTUserExchanges] = useState([]);
    // const [pairsList, setPairsList] = useState([]);
    const [visible, setVisible] = useState(false);
    const [selectNewExchange, setSelectNewExchange] = useState("")
    const [formLoader1, setFormLoader1] = useState(false);
    const [editExchange, setEditExchange] = useState(false);
    const [CredentialSetup, setCredentialSetup] = useState(false);
    const [editExchangeValues, setEditExchangeValues] = useState({});
    const [ToolTipData, setToolTipData] = useState([]);
    const [CredentialsSetupMsg, setCredentialsSetupMsg] = useState();
    const [formLoader2, setFormLoader2] = useState({});
    const [exchangeStatuses, setExchangeStatuses] = useState({});
    const [deleteVisible, setDeleteVisible] = useState(false)
    const [IsLoadingDelete, setIsLoadingDelete] = useState(false);
    const firestoreDb = firebase.firestore();
    const apiUrl = `${process.env.REACT_APP_API_URL}/static/helptexts.json`;
    let uid = currentUser.uid;



    useEffect(() => {
        getuserExchanges();
    }, [])

    useEffect(() => {
        try {
            const gettoottipData = async () => {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/static/helptexts.json`)
                setToolTipData(JSON.parse(JSON.stringify(response.data)))
            }
            gettoottipData()
        } catch (error) {
            console.log(error)
        }

    }, [apiUrl])



    const getuserExchanges = async () => {
        setLoading(true);
        await api({ contentType: true, auth: true })
            .post("/ccxt/getUserExchange/")
            .then((res) => {

                setCCXTUserExchanges(res.data.response.exchanges);
                setCCXTAllExchanges(res.data.response.all_exchanges);
                setExchangeStatuses(res.data.response.exchangeStatuses)
                // setPairsList(res.data.response.pairsList)
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                handleError(err)
                console.log(err);
            });
    };

    const handleError = (err) => {
        if (err && err.response && err.response.data && err.response.data.message) {
            toast.error(err.response.data.message, { toastId: err.response.data.message });
        } else if (err.message) {
            toast.error(err.message, { toastId: err.message });
        } else {
            toast.error(err, { toastId: err });
        }
    }


    const validation = useFormik({
        initialValues: {
            api_key: '',
            api_secret: ''
        },
        validationSchema: Yup.object({
            api_key: Yup.string().required("Please Enter Your API Key"),
            api_secret: Yup.string().required("Please Enter Your API Secret")
        }),
        onSubmit: async (values) => {
            if (editExchange) {
                setFormLoader1(true);
                if (await validateActiveOrders(selectNewExchange)) {
                    if (!selectNewExchange) {
                        toast.error('No exchange selected', { toastId: 'no_exchange_selected' });
                        setFormLoader1(false);
                    }

                    // Add Exchange
                    const bodyParams = {
                        exchange_id: selectNewExchange,
                        appKey: values.api_key,
                        appSecret: values.api_secret
                    }

                    await updateCCXTExchangeConfig(bodyParams).then(async (res) => {
                        toast.success("API key settings updated");
                        validation.resetForm();
                        setTimeout(() => {
                            setFormLoader1(false);
                            setVisible(false);
                            getuserExchanges();
                        }, 2000)
                    }).catch(err => {
                        setFormLoader1(false);
                        handleError(err);
                    })
                } else {
                    setFormLoader1(false);
                    toast.error(`There are some orders that are active now for ${selectNewExchange}. Please close those`);
                }
            }
            else {

                setFormLoader1(true);
                if (!selectNewExchange) {
                    toast.error('No exchange selected', { toastId: 'no_exchange_selected' });
                    setFormLoader1(false);
                }

                // add exchange
                const bodyParams = {
                    exchange_id: selectNewExchange,
                    appKey: values.api_key,
                    appSecret: values.api_secret
                }
                await updateCCXTExchangeConfig(bodyParams).then(async (res) => {
                    toast.success("A new exchange added");
                    validation.resetForm();
                    setTimeout(() => {
                        setFormLoader1(false);
                        setVisible(false);
                        getuserExchanges();
                    }, 2000)
                }).catch(err => {
                    setFormLoader1(false);
                    handleError(err);
                })
            }
        },
    })

    const handleSettingsSubmit = async (values, index) => {
        // Handle form submission here
        const bodyParams = {
            exchange_id: values.exchange_id,
            stop_loss: values.stop_loss,
        }
        await updateExchangeSl(bodyParams).then(async (res) => {
            if (res.data.message == "Stop loss updated successfully") {
                await getuserExchanges();
                toast.success(res.data.message);
            } else {
                toast.error(res.data.message);
            }
        }).catch(err => {
            handleError(err);
        })
    };

    const Offsymbol = () => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >
                {" "}
                No
            </div>
        );
    };
    const OnSymbol = () => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >
                {" "}
                Yes
            </div>
        );
    };

    const changeExchangeStatus = async (exchange_id, e) => {

        try {
            setFormLoader2({ ...formLoader2, [exchange_id]: true });
            if (await validateActiveOrders(exchange_id)) {

                setExchangeStatuses({ ...exchangeStatuses, [exchange_id]: e });

                // Add Exchange
                const bodyParams = {
                    exchange_id: exchange_id,
                    status: e ? "active" : "disabled"
                }

                await updateCCXTExchangeStatus(bodyParams).then(async (res) => {
                    toast.success("Staus updated successfully");
                    setFormLoader2({ ...formLoader2, [exchange_id]: false });
                }).catch(err => {
                    setFormLoader2({ ...formLoader2, [exchange_id]: false });
                    setExchangeStatuses({ ...exchangeStatuses, [exchange_id]: !e });
                    handleError(err);
                })
            }
            else {
                setFormLoader2({ ...formLoader2, [exchange_id]: false });
                toast.error(`There are some orders that are active now for ${exchange_id}. Please close those`);
            }
        } catch (e) {
            setFormLoader2({ ...formLoader2, [exchange_id]: false });
            setExchangeStatuses({ ...exchangeStatuses, [exchange_id]: !e });
            handleError(e)
        }

    }


    const validateActiveOrders = async (exchange_id) => {
        try {
            const firestoreDbUser = firestoreDb.collection("users").doc(uid);
            const ordersRef = firestoreDbUser.collection("ccxt_orders").orderBy("created_at", "desc");
            const querySnapshot = await ordersRef.get(); // Use `get()` for a one-time fetch
            let items = [];
            querySnapshot.forEach((doc) => {
                let item = doc.data();
                if (item.exchange_id === exchange_id) {
                    item.id = doc.id;
                    items.push(item);
                }
            });
            if (items.length == 0) {
                return true;
            } else {
                return false;
            }
            // setOrderData(items); // Uncomment this if you want to update state
        } catch (error) {
            console.error("Error fetching orders:", error);
            return false;
        }
    };


    const handleDeleteExchange = async (data) => {
        try {
            setIsLoadingDelete(true);
            if (await validateActiveOrders(data)) {
                let bodyParams = { exchange_id: data }
                await deleteUserExchange(bodyParams).then(async (res) => {
                    if (res.data.message == "Exchange removed Successfully") {
                        toast.success("Exchange Deleted Successfully");
                        await getuserExchanges();
                        setDeleteVisible(false)
                        setIsLoadingDelete(false);
                    } else {
                        toast.error(res.data.message);
                        setIsLoadingDelete(false);
                    }
                })
            } else {
                setIsLoadingDelete(false);
                setDeleteVisible(false)
                toast.error(`There are some orders that are active now for ${data}. Please close those`);
            }
        } catch (e) {
            setIsLoadingDelete(false);
            setDeleteVisible(false)
            handleError(e);
        }
    }


    return (
        <div className="page-content">

            <Container fluid>

                <Breadcrumbs title="DeriSnap" breadcrumbItem="Exchanges" />

                <Row>
                    <Col xl={12}>
                        <Card className="border">
                            <CardBody>
                                <Row>
                                    <Label
                                        htmlFor="horizontal-firstname-Input"
                                        className="col-sm-2 col-form-label"
                                    >
                                        Add Exchange
                                    </Label>

                                    <Col sm={4}>
                                        <select className="form-select" value={selectedExchange} onChange={(e) => { setSelectedExchange(e.target.value) }}>
                                            <option value="">Select Exchange</option>
                                            {ccxtAllExchanges.map((exchange, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <option value={exchange.id}> {exchange.name} </option>
                                                    </React.Fragment>
                                                )
                                                // })
                                            })}
                                        </select>
                                    </Col>

                                    <Col sm={2}>
                                        {selectedExchange ? (
                                            <button className="btn btn-primary me-1" onClick={() => {
                                                let is_added = ccxtUserExchanges.find(item => item.exchange_id === selectedExchange);
                                                if (!is_added) {
                                                    setVisible(!visible);
                                                    setSelectNewExchange(selectedExchange);
                                                    setEditExchange(false);
                                                    setEditExchangeValues({});
                                                } else {
                                                    toast.error('Exchange already added', { toastId: 'exchange_already_added' })
                                                }
                                            }}>
                                                <i className="mdi mdi-plus me-1"></i> Add Exchange
                                            </button>
                                        ) : ""
                                        }
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <div className="equal-height">

                    {(!loading && ccxtUserExchanges && ccxtUserExchanges.length ?
                        ccxtUserExchanges.map((exchange, index) => {
                            return (

                                <div key={index} className="card" style={{ boxShadow: "none", position: "relative" }}>

                                    <Card style={{ boxShadow: "none", marginBottom: "100px" }}>
                                        <CardTitle className="px-4 pt-4">
                                            <a href={exchange.details.urls && exchange.details.urls.www ? exchange.details.urls.www : "#"} target={exchange.details.urls && exchange.details.urls.www ? "self" : ""}>{exchange.details.name}</a>

                                            <button className="btn btn-danger  btn-sm float-end mx-2 mdi mdi-delete" onClick={() => {
                                                if (currentUser.email == "demoderisnap@gmail.com" && exchange.exchange_id == "binance_us_paper_trade") {
                                                    return;
                                                }
                                                setSelectNewExchange(exchange.exchange_id);
                                                setDeleteVisible(true);
                                            }} disabled={currentUser.email == "demoderisnap@gmail.com" && exchange.exchange_id == "binance_us_paper_trade"}></button>
                                            <button className="btn btn-success  btn-sm float-end mx-2"

                                                onClick={() => {
                                                    if (currentUser.email == "demoderisnap@gmail.com" && exchange.exchange_id == "binance_us_paper_trade") {
                                                        return;
                                                    }
                                                    setVisible(!visible);
                                                    setEditExchange(true);
                                                    setSelectNewExchange(exchange.exchange_id);
                                                    setEditExchangeValues({
                                                        appKey: exchange.display_apikey,
                                                        appSecret: exchange.display_api_secret
                                                    })
                                                }} disabled={currentUser.email == "demoderisnap@gmail.com" && exchange.exchange_id == "binance_us_paper_trade"}>Update Key</button>
                                        </CardTitle>

                                        <CardBody>
                                            <Row>
                                                <Label
                                                    htmlFor="formrow-firstname-Input"
                                                    md="4"
                                                    className="col-form-label"
                                                >
                                                    API Key
                                                </Label>
                                                <Col md="8">
                                                    <Label
                                                        htmlFor="formrow-firstname-Input"
                                                        md="5"
                                                        className="input-border-less"
                                                    >{exchange.display_apikey}</Label>

                                                </Col>
                                            </Row>
                                            <FormGroup className="" row>
                                                <Label
                                                    htmlFor="formrow-firstname-Input"
                                                    md="4"
                                                    className="col-form-label"
                                                >
                                                    API Secret
                                                </Label>
                                                <Col md="8">
                                                    <Label
                                                        htmlFor="formrow-firstname-Input"
                                                        md="5"
                                                        disabled
                                                        className="input-border-less"
                                                    >{exchange.display_api_secret}</Label>

                                                </Col>
                                            </FormGroup>
                                            <FormGroup className="" row>
                                                <Label
                                                    htmlFor="formrow-firstname-Input"
                                                    md="4"
                                                    className="col-form-label"
                                                >
                                                    Enabled
                                                </Label>
                                                <Col md="8">
                                                    <Switch
                                                        uncheckedIcon={<Offsymbol />}
                                                        checkedIcon={<OnSymbol />}
                                                        className="me-1 mb-sm-8 mb-2"
                                                        onChange={(e) => {
                                                            changeExchangeStatus(exchange.exchange_id, e);
                                                        }}
                                                        checked={exchangeStatuses[exchange.exchange_id]}
                                                    />
                                                    {formLoader2[exchange.exchange_id] ? <span><i className="ml-2 bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> Processing... </span> : ''}
                                                </Col>
                                            </FormGroup>
                                            {exchange.exchange_id !== 'binance_us_paper_trade' ? <ExchangeForm
                                                key={index}
                                                item={exchange}
                                                index={index}
                                                handleSubmit={handleSettingsSubmit}
                                            /> : ""}


                                        </CardBody>

                                    </Card>

                                    <Row className='pair-list'>
                                        {exchange.trading_pair.length ?
                                            <div className="col-lg-12 p-0">
                                                <Label className="col-form-label p-0">

                                                    {exchange.trademode === 1 ? (
                                                        <>
                                                            <span className="badge badge-soft-info font-size-12">{exchange.trading_pair.length}</span>
                                                            {` ${exchange.trading_pair.length === 1 ? 'Pair Enabled' : 'Pairs Enabled'}`}
                                                        </>
                                                    ) : (
                                                        <>
                                                            <span className="badge badge-soft-info font-size-12">{exchange.trading_pair.length}</span>
                                                            {` ${exchange.trading_pair.length === 1 ? 'Pair Added' : 'Pairs Added'}`}
                                                        </>
                                                    )}



                                                </Label>{" "}
                                                {
                                                    exchange.trading_pair.map((pair, index) =>
                                                    (
                                                        <>
                                                            <span>{pair.data.display_text}{exchange.trading_pair.length - 1 !== index ? ", " : " "}</span>
                                                        </>
                                                    ))
                                                }

                                            </div>
                                            :
                                            exchange.trademode === 1 ?
                                            <div className="col-lg-12">
                                                <Label className="col-form-label">
                                                    <span className="badge badge-soft-info font-size-12">
                                                        No Pair Enabled
                                                    </span>
                                                </Label>
                                            </div>
                                            :
                                            <div className="col-lg-12">
                                            <Label className="col-form-label">
                                                <span className="badge badge-soft-info font-size-12">
                                                    No Pair Added
                                                </span>
                                            </Label>
                                            </div>
                                        }
                                    </Row>

                                </div>
                            )
                        })
                        :
                        loading ?
                            null
                            :
                            null
                    )}
                </div>



                {loading ?
                    <Card>
                        <Col xl={12}>
                            <div className="p-4 border text-center">
                                <h6 className="text-muted">Exchanges Loading...</h6>
                            </div>
                        </Col>
                    </Card>
                    :
                    ccxtUserExchanges.length == 0 ?
                        <Card>
                            <Col xl={12}>
                                <div className="p-4 border text-center">
                                    <h6 className="text-muted">No Exhange added</h6>
                                </div>
                            </Col>
                        </Card>
                        : null
                }


            </Container>

            <Modal isOpen={visible} toggle={() => { setVisible(false), validation.resetForm() }} centered>
                <ModalHeader toggle={() => { setVisible(false), validation.resetForm() }} tag="h4" className="text-capitalize">
                    {editExchange ? "Update " + getExchangeName(selectNewExchange) + " API Keys" : getExchangeName(selectNewExchange) + " API keys setup"}{" "}{editExchange ? '' : <i className="far fa-question-circle" onClick={() => {
                        setCredentialSetup(!CredentialSetup), setCredentialsSetupMsg(CredentialsSetupMSG(selectNewExchange))
                    }} id="Credentials_Setup" />}
                </ModalHeader>
                <ModalBody>
                    {editExchange ?
                        <p className="text-muted">Changing the key & secret will disconnect the active calls in our bot, so do not change the key & secret without support team.</p>
                        :
                        ""
                    }
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >
                        <Row>
                            <Col className="col-12">
                                <div className="mb-3">
                                    <Label className="form-label">API Key <i className="bx bx-info-circle" id="API_Key" />
                                        <UncontrolledTooltip placement="top" target="API_Key">
                                            {ToolTipData.ccxt_api_key}
                                        </UncontrolledTooltip>
                                    </Label>
                                    <Input
                                        name="api_key"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        defaultValue={(editExchangeValues && editExchangeValues.appKey) ? editExchangeValues.appKey : validation.values.api_key || ""}
                                        invalid={
                                            validation.touched.api_key && validation.errors.api_key ? true : false
                                        }
                                        autoComplete="off"
                                    />
                                    {validation.touched.api_key && validation.errors.api_key ? (
                                        <FormFeedback type="invalid">{validation.errors.api_key}</FormFeedback>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label">API Secret <i className="bx bx-info-circle" id="API_Secret" />
                                        <UncontrolledTooltip placement="top" target="API_Secret">
                                            {ToolTipData.ccxt_api_secret}
                                        </UncontrolledTooltip>
                                    </Label>
                                    <Input
                                        name="api_secret"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        defaultValue={(editExchangeValues && editExchangeValues.appSecret) ? editExchangeValues.appSecret : validation.values.api_secret || ""}
                                        invalid={
                                            validation.touched.api_secret && validation.errors.api_secret ? true : false
                                        }
                                        autoComplete="off"
                                    />
                                    {validation.touched.api_secret && validation.errors.api_secret ? (
                                        <FormFeedback type="invalid">{validation.errors.api_secret}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="text-end">
                                    <button
                                        type="submit"
                                        className={formLoader1 ? "btn btn-success save-user disabled" : "btn btn-success save-user"}
                                    >
                                        {formLoader1 ? <span><i className="ml-2 bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> Processing... </span> : 'Save'}
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>


            <Modal
                isOpen={CredentialSetup}
                toggle={() => {
                    setCredentialSetup(false);
                }}
                style={{ width: "100%" }}
                className="modal-lg"
            >
                <div className="modal-header" >
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        Please Follow the Steps to Generate API Keys.
                    </h5>
                    <button
                        type="button"
                        onClick={() => {
                            setCredentialSetup(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    {parse('' + CredentialsSetupMsg)}
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => {
                            setCredentialSetup(false);
                        }}
                        className="btn btn-secondary "
                        data-dismiss="modal"
                    >
                        Close
                    </button>
                </div>
            </Modal>


            <Modal isOpen={deleteVisible} toggle={() => { setDeleteVisible(false) }} centered>
                <ModalHeader toggle={() => { setDeleteVisible(false) }} tag="h4" className="text-capitalize">
                    {"Delete " + getExchangeName(selectNewExchange) + " API Keys"}
                </ModalHeader>
                <ModalBody>
                    {/* {deleteExchange ? */}
                    <p className="text-muted">Are you sure you want to delete {getExchangeName(selectNewExchange)} API Keys?</p>
                    {/* :
                        ""
                      } */}
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleDeleteExchange(selectNewExchange);
                        }}
                    >
                        <Row>
                            <Col className="col-12">

                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="text-end">
                                    <button
                                        type="submit"
                                        className={IsLoadingDelete ? "btn btn-danger save-user disabled" : "btn btn-danger save-user"}
                                    >
                                        {IsLoadingDelete ? <span><i className="ml-2 bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> Processing... </span> : 'Delete'}
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>

        </div>
    )
}

export default CcxtExchange