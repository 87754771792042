import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { toast } from "react-toastify";
// import firebase from "firebase/compat/app";
import "react-rangeslider/lib/index.css";
import "flatpickr/dist/themes/material_blue.css";
import {
  getAllCCXTUserExchanges,
  getCCXTUserPairBalance,
} from "helpers/ccxt_helper";
import { Link } from "react-router-dom";
const Wallet = () => {
  document.title = "Spot Trade - Wallet";
  const [formLoader1, setFormLoader1] = useState(true);
  const handleError = (err) => {
    if (err && err.response && err.response.data && err.response.data.message) {
      toast.error(err.response.data.message, {
        toastId: err.response.data.message,
      });
    } else if (err.message) {
      toast.error(err.message, { toastId: err.message });
    } else {
      toast.error(err, { toastId: err });
    }
  };

  const [ccxtUserExchanges, setCCXTUserExchanges] = useState([]);
  const [ccxtUserBalances, setCCXTUserBalances] = useState({});

  useEffect(() => {
    getUserExchanges();
  }, []);

  const getUserExchanges = async () => {
    await getAllCCXTUserExchanges()
      .then((res) => {
        setCCXTUserExchanges(res.data.response.exchanges);
        if(res.data.response.exchanges && !res.data.response.exchanges.length){
          setFormLoader1(false)
        }
      })
      .catch((e) => {
        setFormLoader1(false);
        handleError(e);
      });
  };

  useEffect(() => {
    if (ccxtUserExchanges.length) {
      getCCXTUserBalances();
    }
  }, [ccxtUserExchanges]);

  const getCCXTUserBalances = async () => {
    let exchange_id = document.getElementById("select_exchange").value;
    await getCCXTUserPairBalance(exchange_id)
      .then((res) => {
        if(res.data.response.message == "pairs Added"){
          setCCXTUserBalances(res.data.response.data);
          setFormLoader1(false);
        }else{
          setCCXTUserBalances({});
          setFormLoader1(false)
        }
      })
      .catch((e) => {
        setFormLoader1(false);
        setCCXTUserBalances({});
        handleError(e);
      });
  };

  const handleChange = async (e) => {
     setFormLoader1(true)
     getCCXTUserBalances();
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="DeriSnap" breadcrumbItem="Wallet" />

          <Row>
            {ccxtUserExchanges.length ? (
                <Col xl={12}>
                  <Card className="border box_4">
                    <CardBody>
                      <Row>
                        <Col md={4}>
                          <select
                            id="select_exchange"
                            disabled={formLoader1 ? true : false}
                            className="form-select"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          >
                            {ccxtUserExchanges.map((exchange, index) => (
                              <React.Fragment key={index}>
                                <option value={exchange.exchange_id}>
                                  {" "}
                                  {exchange.details.name}{" "}
                                </option>
                              </React.Fragment>
                            ))}
                          </select>
                        </Col>
                        <Col md={4} className="fs-4 mt-2">
                          {ccxtUserBalances.total_USDT ? (
                            <p className="mb-0">
                              Total USDT :&nbsp;{" "}
                              <b>
                                {Number(ccxtUserBalances.total_USDT).toFixed(4)}
                              </b>
                            </p>
                          ) : null}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
            ) : (
              ""
            )}
            <Col xl={12}>
              <Card style={{ minHeight: "92%" }} className="box_4">
                <CardBody>
                  <Row>
                    {
                    formLoader1 ? (
                      <Row>
                        <Col xl={12}>
                          <div className="p-4 border text-center">
                            <h6 className="text-muted">Loading...</h6>
                          </div>
                        </Col>
                      </Row>
                    ) :
                    ccxtUserExchanges.length == 0 ? (
                      <>
                        <Col xl={12}>
                          <div className="p-4 border text-center">
                            <h6 className="text-muted">
                              No Exchange Added. Please Go to{" "}
                              <Link to="/ccxt/exchanges">Settings.</Link>{" "}
                            </h6>
                          </div>
                        </Col>
                      </>
                    ) : ccxtUserBalances.wallet &&
                      Object.keys(ccxtUserBalances.wallet).length ? (
                      Object.keys(ccxtUserBalances.wallet).map(
                        (value, index) => {
                          return (
                            <Col key={index} lg="3">
                              <div className="border p-3 rounded mt-4">
                                <div className="d-flex align-items-center mb-3">
                                  <div className="avatar-xs me-3">
                                    <span className="avatar-title rounded-circle bg-info text-white font-size-18">
                                      <i className="mdi mdi-circle" />
                                    </span>
                                  </div>
                                  <h5 className="font-size-14 mb-0">{value}</h5>
                                </div>

                                <Row>
                                  <div className="col-lg-6">
                                    <div className="mt-3">
                                      <p>Wallet</p>
                                      <h4
                                        className={
                                          value.total > 0
                                            ? "text-green"
                                            : "text-red"
                                        }
                                      >
                                        {ccxtUserBalances.wallet[value].total
                                          ? Number(
                                              ccxtUserBalances.wallet[value]
                                                .total
                                            ).toFixed(5)
                                          : "0.00"}
                                      </h4>
                                    </div>
                                  </div>
                                  <div className="col-lg-6 align-self-end">
                                    <div className="float-end mt-3">
                                      <p>Available</p>
                                      <h4
                                        className={
                                          ccxtUserBalances.wallet[value].free >
                                          0
                                            ? "text-green"
                                            : "text-red"
                                        }
                                      >
                                        {ccxtUserBalances.wallet[value].free
                                          ? Number(
                                              ccxtUserBalances.wallet[value]
                                                .free
                                            ).toFixed(5)
                                          : "0.00"}
                                      </h4>
                                    </div>
                                  </div>
                                </Row>
                              </div>
                            </Col>
                          );
                        }
                      )
                    ) : (
                      <Col xl={12}>
                        <div className="p-4 border text-center">
                          <h6 className="text-muted">
                            No Pairs Found. Please Go to{" "}
                            <Link to="/ccxt/trade">
                              Pairs Settings.
                            </Link>
                          </h6>
                        </div>
                      </Col>
                    )}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Wallet;
