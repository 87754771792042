import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardTitle, Col, Container, Label, Row, Table } from 'reactstrap';
import api from '../../../helpers/api_helper';
import StackedColumnChart from "../Chart/StackedColumnChart"
import Breadcrumbs from 'components/Common/Breadcrumb';
import { getSettingsDateTimeFormat } from 'services/common.service';


function Dashboard() {
  document.title = "Spot Trade - Dashboard";
  const [filterDate, setFilterDate] = useState("7");
  const [loading, setLoading] = useState(true);
  const [topList, setTopList] = useState({})
  const [filter_symbole, setfilter_symbole] = useState('all')
  const [viewGraph, setviewGraph] = useState('SelectedExchange');
  const [symbolfilterhide, setsymbolfilterhide] = useState(false)
  const [filter_dropdown, setfilter_dropdown] = useState([])
  const [summary, setSummary] = useState({});
  const [ccxtUserExchanges, setCCXTUserExchanges] = useState([]);
  const date = new Date();
  const [Currency, setCurrency] = useState([])
  const [totalPNL, setTotalPNL] = useState({ total_gain: 0, total_lose: 0 })
  const [exchangeRate, setExchangeRate] = useState([])

  useEffect(() => {
    getuserExchanges();
  }, [])

  useEffect(() => {
    let { sDate, eDate } = getMonday();
    setSummary({})
    fetchPNLData(sDate, eDate, viewGraph);

  }, [viewGraph, filterDate, filter_symbole]);

  let list = []
  function onlyUnique(value, index, array) {
    if (list.includes(value.data.symbol) === false) {
      list.push(value.data.symbol)
      setsymbolfilterhide(false)
    }
  }

  const getuserExchanges = async () => {
    await api({ contentType: true, auth: true })
      .post("/ccxt/getUserExchange/",{only_exchange:true})
      .then((res) => {
        setCCXTUserExchanges(res.data.response.exchanges);

        // setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const convert = (str) => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  const aggregateData = (graph_data) => {
    const groupedData = {};

    graph_data.forEach(item => {
      const { time, pnl_data, org_pnl, org_currency, currency } = item;

      if (!groupedData[time]) {
        groupedData[time] = { pnl_data: 0, org_pnl: 0, org_currency, currency, time };
      }

      groupedData[time].pnl_data += parseFloat(pnl_data);
      groupedData[time].org_pnl += parseFloat(org_pnl);
    });

    // Convert the grouped data object back to an array
    return Object.values(groupedData).map(item => ({
      ...item,
      pnl_data: item.pnl_data.toString(),
      org_pnl: item.org_pnl.toString()
    }));
  };

  const sumByCurrency = (graph_data) => {
    const result = {};

    graph_data.forEach(item => {
      const { currency, pnl_data } = item;

      if (!result[currency]) {
        result[currency] = 0;
      }

      result[currency] += parseFloat(pnl_data);
    });

    return result;
  };

  const fetchPNLData = async (startdate, enddate, exchange_id) => {

    const datestart = new Date(startdate);
    const dateend = new Date(enddate);
    let graph_data = '';
    var startdate1;
    var enddate1;
    var pnls = {};
    let cur = [];
    let sevendays_pnl = 0
    let stocks = {};
    let today_date = date.getDate().toString().padStart(2, '0')
    let current_mon = (date.getMonth() + 1).toString().padStart(2, '0')
    let today_pnl = 0.00;
    let currency = [];
    startdate1 = (new Date(startdate));

    startdate1.setDate(datestart.getDate()) - 1

    enddate1 = (new Date(enddate));

    enddate1.setDate(dateend.getDate())

    var time = new Date();

    let converted_start_date = new Date(convert(time));

    converted_start_date.setHours(0, 0, 0, 0);

    let converted_end_date2 = new Date(convert(time));

    converted_end_date2.setHours(23, 59, 59, 999);


    var start = ((`${new Date(startdate1).getFullYear()}-${(new Date(startdate1).getMonth() + 1).toString().length === 1 ? '0' + (new Date(startdate1).getMonth() + 1) : new Date(startdate1).getMonth() + 1}-${startdate1.getDate().toString().length === 1 ? '0' + startdate1.getDate() : startdate1.getDate()}${converted_start_date.toISOString().slice(10, -1)}z`).toString())

    var end = ((`${new Date(enddate1).getFullYear()}-${(new Date(enddate1).getMonth() + 1).toString().length === 1 ? '0' + (new Date(enddate1).getMonth() + 1) : new Date(enddate1).getMonth() + 1}-${enddate1.getDate().toString().length === 1 ? '0' + enddate1.getDate() : enddate1.getDate()}${converted_end_date2.toISOString().slice(10, -1)}z`).toString())


    let dates;
    var body = {
      filterStartDate: start,
      filterEndDate: end,
      filter_exchange: exchange_id
    }
    await api({ contentType: true, auth: true }).post(exchange_id === 'SelectedExchange' ? '/CCXT/ccxt-completed-orders-history' : '/CCXT/ccxt-completed-orders-history-1', body).then(async (res) => {
      var Result = res.data.response.data;
      if (filter_symbole === "all") {
        Result.filter(onlyUnique)
        setfilter_dropdown(list)
      }



      let orders_info = [];

      const datas = Result.reduce((acc, obj) => {
        if (obj.data.status === 'closed') {


          obj.data.created_at = getSettingsDateTimeFormat(obj.data.created_at);



          const [day, month, year] = obj.data.created_at.split(" ")[0].split('/');

          let key;
          if (filterDate == "this_quarter" || filterDate == "last_quarter") {
            key = `${year}-${month}`
          } else {
            key = `${year}-${month}-${day}`;
          }

          if (!acc[key]) {
            acc[key] = []; // Initialize an array if it doesn’t exist for this key
          }
          acc[key].push(obj.data); // Add the object to the array
        }
        return acc;
      }, {});

      const keys = Object.keys(datas);

      // Reverse the array of keys
      const reversedKeys = keys.reverse();
      for (let key of reversedKeys) {
        orders_info = datas[key];

        let pnl = [];
        let totalpnl = {};
        let todayPnl = {};
        if (filter_symbole === 'all') {
          orders_info = orders_info
        }
        else if (filter_symbole !== 'all') {
          orders_info = orders_info.filter(order => order.symbol === filter_symbole);
        }

        let total_pnl = orders_info.filter(order => order.status === 'closed').reduce((sum, item) => {
          if (item.pnl_value && item.pnl_value !== "") {

            if (stocks.hasOwnProperty(item.exchange_id) && stocks[item.exchange_id].hasOwnProperty(item.symbol)) {
              if (item.pnl_value >= 0) {
                stocks[item.exchange_id][item.symbol].gain_pnl += item.pnl_value;
              } else {
                stocks[item.exchange_id][item.symbol].lose_pnl += item.pnl_value;
              }
            } else {
              if (item.pnl_value >= 0) {

                stocks[item.exchange_id] = stocks[item.exchange_id] ? stocks[item.exchange_id] : {};
                stocks[item.exchange_id][item.symbol] = { gain_pnl: item.pnl_value, lose_pnl: 0, quote: item.settings.quote, exchange: item.exchange_id }
              } else {
                // stocks[item.exchange_id] = {};
                stocks[item.exchange_id] = stocks[item.exchange_id] ? stocks[item.exchange_id] : {};
                stocks[item.exchange_id][item.symbol] = { gain_pnl: 0, lose_pnl: item.pnl_value, quote: item.settings.quote, exchange: item.exchange_id }
              }
            }

            // }
            if (totalpnl[item.settings.quote]) {
              totalpnl[item.settings.quote] = (Number(totalpnl[item.settings.quote]) + parseFloat(item.pnl_value ? Number(item.pnl_value).toFixed(6) : 0)).toFixed(4);
              if (currency.includes(item.settings.quote) === false) {
                currency.push(item.settings.quote)
              }
              // if (cur.includes(item.settings.quote) === false){
              //   cur.push(item.settings.quote)
              // }
              pnls[item.settings.quote] = (parseFloat(pnls[item.settings.quote]) + parseFloat(item.pnl_value ? Number(item.pnl_value).toFixed(6) : 0)).toFixed(4)
            } else {
              totalpnl[item.settings.quote] = parseFloat(item.pnl_value ? Number(item.pnl_value).toFixed(6) : 0).toFixed(4);
              if (currency.includes(item.settings.quote) === false) {
                currency.push(item.settings.quote)
              }
              // if (cur.includes(item.settings.quote) === false){
              //   cur.push(item.settings.quote)
              // }
              pnls[item.settings.quote] = (parseFloat(pnls[item.settings.quote] !== undefined ? pnls[item.settings.quote] : 0) + parseFloat(item.pnl_value ? Number(item.pnl_value).toFixed(6) : 0)).toFixed(4)
            }
          }
          if (item.created_at !== undefined) {


            let created_at = item.created_at.split(" ")[0].split('/');
            let created_mon = created_at[1] ? created_at[1] : ""
            dates = created_at[0] ? created_at[0] : "";

            if (dates == today_date && current_mon == created_mon) {
              if (todayPnl[item.settings.quote]) {
                todayPnl[item.settings.quote] = (Number(todayPnl[item.settings.quote]) + parseFloat(item.pnl_value ? Number(item.pnl_value).toFixed(6) : 0)).toFixed(4);
              } else {
                todayPnl[item.settings.quote] = parseFloat(item.pnl_value ? Number(item.pnl_value).toFixed(6) : 0).toFixed(4);
              }
            }
          }
          return Number(sum) + Number(pnl);

        }, 0);
        pnl.push(totalpnl)

        if (pnl.length > 0 && dates !== undefined) {

          for (let i = 0; i < currency.length; i++) {
            let currencyval = currency[i]
            let totalpnlval = totalpnl[currency[i]]
            let org_pnl = totalpnlval
            let org_currency = currencyval

            // console.log("sevendays_pnl not convert",sevendays_pnl)
            let exchangeRateval
            if (totalpnl[currency[i]] !== undefined) {

              if (currency[i] !== "USDT") {
                if (exchangeRate[currencyval]) {

                  exchangeRateval = exchangeRate[currencyval]
                } else {

                  await api({ contentType: true, auth: true }).post('/CCXT/get-exchage-rate', { from: currency[i], to: 'USDT' }).then(async (res) => {

                    if (res.data.response && res.data.response.data && res.data.response.data.rate !== '') {
                      exchangeRateval = res.data.response.data.rate

                      exchangeRate[currencyval] = exchangeRateval
                      setExchangeRate(exchangeRate)
                    }
                  });
                }
                // console.log("exchangeRateval",exchangeRateval)
                if (exchangeRateval) {

                  // console.log("currency beforeremove",currency)

                  totalpnlval = totalpnl[currency[i]] * Number(exchangeRateval)

                  sevendays_pnl = Number(sevendays_pnl) + (Number(pnls[currencyval]) * Number(exchangeRateval))

                  currencyval = "USDT"

                } else {
                  totalpnlval = totalpnl[currency[i]]

                  sevendays_pnl = Number(sevendays_pnl) + (Number(pnls[currencyval]))
                }
              } else {
                sevendays_pnl = Number(sevendays_pnl) + Number(pnls[currencyval])
              }
            }
            let time_date;
            if (filterDate == "this_quarter" || filterDate == "last_quarter") {
              let key_date = new Date(key);
              time_date = key_date.toString().slice(4, 7) + " " + key_date.getFullYear();
            } else {
              time_date = `${dates}/${(new Date(key).getMonth() + 1).toString().length === 1 ? '0' + (new Date(key).getMonth() + 1) : new Date(key).getMonth() + 1}`
            }
            if (graph_data.length == 0) {
              if (totalpnlval !== undefined) {
                graph_data = ('[{ "pnl_data": "' + totalpnlval + '","org_pnl":"' + org_pnl + '","org_currency":"' + org_currency + '","currency":"' + currencyval + '","time":"' + time_date + '"}');
              }
            } else {
              if (totalpnlval !== undefined) {
                graph_data = graph_data + (',{ "pnl_data": "' + totalpnlval + '","org_pnl":"' + org_pnl + '","org_currency":"' + org_currency + '","currency":"' + currencyval + '","time":"' + time_date + '"}');
              }
            }

            const graph_data_val = aggregateData(JSON.parse(graph_data + ']'));

            const summedPnlByCurrency = sumByCurrency(JSON.parse(graph_data + ']'));

            if (Object.keys(todayPnl).length && todayPnl[currency[i]] !== undefined) {
              if (currency[i] != "USDT") {
                today_pnl = Number(today_pnl) + Number(todayPnl[currency[i]]) * Number(exchangeRate[currency[i]]);
              } else {
                today_pnl = Number(today_pnl) + Number(todayPnl[currency[i]]);
              }
              delete todayPnl[currency[i]];
            }

            setSummary({
              ...summary,
              currency: currencyval,
              total_pnl: today_pnl,
              _7days_pnl: summedPnlByCurrency,
              graph_data: graph_data_val
            });
          }
        }
      }


    })

    setCurrency(cur)


    if (Object.keys(stocks).length > 0) {
      let gainers = {};
      let losers = {};
      let total_gain = 0
      let total_lose = 0
      for (let exchange in stocks) {

        for (let symbol in stocks[exchange]) {

          if (stocks[exchange][symbol].quote != "USDT") {
            stocks[exchange][symbol].gain_pnl = stocks[exchange][symbol].gain_pnl * exchangeRate[stocks[exchange][symbol].quote]
            stocks[exchange][symbol].lose_pnl = stocks[exchange][symbol].lose_pnl * exchangeRate[stocks[exchange][symbol].quote]
          }
          gainers[exchange] = gainers[exchange] ? gainers[exchange] : {}
          gainers[exchange][symbol] = stocks[exchange][symbol];
          total_gain += stocks[exchange][symbol].gain_pnl
          losers[exchange] = losers[exchange] ? losers[exchange] : {}
          losers[exchange][symbol] = stocks[exchange][symbol];
          total_lose += stocks[exchange][symbol].lose_pnl;
        }
      }


      let gainersArray = [], losersArray = [];

      Object.keys(gainers).forEach((exchange) => {
        const sortedData = Object.entries(gainers[exchange])
          .map(([symbol, details]) => ({ symbol, ...details })) // Convert to array with symbol
          .sort((a, b) => b.gain_pnl - a.gain_pnl); // Sort by gain_pnl in descending order

        gainersArray.push(...sortedData);
      });

      Object.keys(losers).forEach((exchange) => {
        const sortedData = Object.entries(losers[exchange])
          .map(([symbol, details]) => ({ symbol, ...details })) // Convert to array with symbol
          .sort((a, b) => a.lose_pnl - b.lose_pnl); // Sort by lose_pnl in ascending order

        losersArray.push(...sortedData);
      });


      setTotalPNL({ total_gain: total_gain, total_lose: total_lose })
      setTopList({ gainers: gainersArray, losers: losersArray })
    } else {
      setTotalPNL({ total_gain: 0, total_lose: 0 })
      setTopList({})
    }


    // setTimeout(() => {
      setLoading(false)
    // }, 2000)
  }

  const handleChartFilter = (e) => {
    setLoading(true);
    setFilterDate(e.target.value);
    setTopList({})
  }


  function getStartAndEndOfWeek(date) {
    const dayOfWeek = date.getDay();
    const start = new Date(date);
    if (filterDate == "last") {
      start.setDate(date.getDate() - (dayOfWeek === 0 ? 8 : dayOfWeek + 8));
    } else {
      start.setDate(date.getDate() - (dayOfWeek === 0 ? 1 : dayOfWeek + 1));
    }
    const end = new Date(start);
    end.setDate(start.getDate() + 7);
    return { start, end };
  }

  function getStartAndEndOfMonth(date) {
    let start, end;
    if (filterDate == "this_month") {
      start = new Date(date.getFullYear(), date.getMonth(), 1);
      start = new Date(start - 1);
      end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    } else {
      start = new Date(date.getFullYear(), date.getMonth() - 1, 1);
      start = new Date(start - 1);
      end = new Date(date.getFullYear(), date.getMonth(), 0);
    }
    return { start, end };
  }

  function getCurrentAndLastQuarterDates(now) {
    const currentMonth = now.getMonth();
    let currentYear = now.getFullYear();

    // Determine the current quarter
    let currentQuarter = Math.floor(currentMonth / 3) + 1;
    let startMonth, endMonth, start, end;

    // Get the current quarter's start and end dates
    // const thisQuarter = getQuarterStartAndEnd(currentYear, currentQuarter);
    if (filterDate == "last_quarter") {
      if (currentQuarter === 1) {
        // Last quarter was Q4 of the previous year
        currentQuarter = 4;
        currentYear = currentYear - 1;
      } else {
        currentQuarter = currentQuarter - 1;
      }
    }

    switch (currentQuarter) {
      case 1:
        startMonth = 0;
        endMonth = 2;
        break;
      case 2:
        startMonth = 3;
        endMonth = 5;
        break;
      case 3:
        startMonth = 6;
        endMonth = 8;
        break;
      case 4:
        startMonth = 9;
        endMonth = 11;
        break;
    }

    start = new Date(currentYear, startMonth, 1);
    start = new Date(start - 1);
    end = new Date(currentYear, endMonth + 1, 0);

    return { start, end };
  }


  function getMonday() {
    let sDate, eDate
    let d = new Date();

    if (filterDate == "last") {

      const lastWeek = getStartAndEndOfWeek(d);
      sDate = lastWeek.start;
      eDate = lastWeek.end;
    } else if (filterDate == "this") {
      const thisWeek = getStartAndEndOfWeek(d);
      sDate = thisWeek.start;
      eDate = thisWeek.end;
    }
    else if (filterDate == "this_month") {
      const thisMonth = getStartAndEndOfMonth(d);
      sDate = thisMonth.start;
      eDate = thisMonth.end;
    } else if (filterDate == "last_month") {
      const lastMonth = getStartAndEndOfMonth(d);
      sDate = lastMonth.start;
      eDate = lastMonth.end;
    } else if (filterDate == "this_quarter") {
      const thisQuarter = getCurrentAndLastQuarterDates(d);
      sDate = thisQuarter.start;
      eDate = thisQuarter.end;
    } else if (filterDate == "last_quarter") {
      const lastQuarter = getCurrentAndLastQuarterDates(d);
      sDate = lastQuarter.start;
      eDate = lastQuarter.end;
    } else {
      let diff = d.getDate() - filterDate;
      var currentMonday = new Date(d.setDate(diff));
      sDate = `${currentMonday.getFullYear()}-${(currentMonday.getMonth() + 1).toString().length === 1 ? '0' + (currentMonday.getMonth() + 1) : currentMonday.getMonth() + 1}-${(currentMonday.getDate()).toString().length === 1 ? '0' + (currentMonday.getDate()) : currentMonday.getDate()}`
      eDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().length === 1 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1}-${(date.getDate()).toString().length === 1 ? '0' + (date.getDate()) : date.getDate()}`
    }
    return { sDate, eDate };
  }


  return (
    // <>
    <div className="page-content">

      <Container fluid>

        <Breadcrumbs title="DeriSnap" breadcrumbItem="Dashboard" />

        <Row>
          <Col xl={12}>
            <Card className="border">
              <CardTitle className='mx-3 mt-2'>PNL Analytics</CardTitle>
              <CardBody>
                <Row>

                  <Col sm={4}>
                    <Label
                      htmlFor="horizontal-firstname-Input"
                      className="col-form-label"
                    >
                      Filter By
                    </Label>
                    <select id="filter_by" defaultValue={filterDate} disabled={loading} className="form-select symbole_filter" onChange={(e) => { handleChartFilter(e) }}>
                      <option value="7">Last 7 Days</option>
                      <option value="this">This Week</option>
                      <option value="last">Last Week</option>
                      <option value="30">Last 30 Days</option>
                      <option value="this_month">This Month</option>
                      <option value="last_month">Last Month</option>
                      <option value="this_quarter">This Quarter</option>
                      <option value="last_quarter">Last Quarter</option>
                    </select>
                  </Col>

                  <Col sm={4}>
                    <Label
                      htmlFor="horizontal-firstname-Input"
                      className="col-form-label"
                    >
                      Select Exchange
                    </Label>
                    <select id="select_exchange_graph" defaultValue="SelectedExchange" disabled={loading} className="form-select symbole_filter" onChange={async (e) => { setfilter_symbole('all'); setviewGraph(e.target.value); setsymbolfilterhide(true); setTopList({}); setLoading(true); }}>
                      <option value="SelectedExchange" selected={true}>Select Exchange</option>
                      {ccxtUserExchanges.map((exchange, index) => {
                        return (
                          <React.Fragment key={index}>
                            <option value={exchange.exchange_id} > {exchange.exchange_name} </option>
                          </React.Fragment>
                        )
                      })}
                    </select>
                  </Col>
                  {!symbolfilterhide ? <>

                    <Col md={4}>
                      <Label
                        htmlFor="horizontal-firstname-Input"
                        className="col-form-label"
                      >
                        Select Symbol
                      </Label>
                      <select id="select_symbole_graph" defaultValue="all" disabled={loading} className="form-select symbole_filter" onChange={async (e) => { setfilter_symbole(e.target.value) }}>
                        <option value="all" selected={"all" == filter_symbole}>All</option>
                        {filter_dropdown.map((exchange, index) => {
                          return (
                            <React.Fragment key={index}>
                              <option value={exchange} selected={exchange == filter_symbole}> {exchange} </option>
                            </React.Fragment>
                          )
                        })}
                      </select>
                    </Col>
                  </> : ""
                  }
                </Row>


                {Object.keys(summary).length !== 0 ?
                  <div className="d-sm-flex flex-wrap justify-content-between align-items-center mt-4">
                    <h4 className="card-title">{filterDate == "7" ? "Last 7 day's" : filterDate == "30" ? "Last 30 day's" : filterDate == "this" ? "This Week's" : filterDate == "last" ? "Last Week's" : filterDate == "this_month" ? "This Month's" : filterDate == "last_month" ? "Last Month's" : filterDate == "this_quarter" ? "This Quarter's" : filterDate == "last_quarter" ? "Last Quarter's" : ""} PNL :
                      {summary._7days_pnl && !loading ? (
                        Object.keys(summary._7days_pnl).map((currency, index, arr) => (
                          <span key={currency}>
                            <span className={parseFloat(summary._7days_pnl[currency]) < 0 ? "text-danger" : "text-success"}>
                              &nbsp;{parseFloat(summary._7days_pnl[currency]).toFixed(4)} {currency}
                            </span>
                            {index < arr.length - 1 && ", "}
                          </span>
                        ))
                      ) : (
                        <span className="text-danger">&nbsp;NA</span>
                      )}
                    </h4>
                    <div className="float-end" >
                      <h4 className="card-title" >Today&apos;s PNL :
                        {summary.total_pnl && !loading ? (<span className={parseFloat(summary.total_pnl) < 0 ? "text-danger" : "text-success"}>&nbsp;{summary && summary.total_pnl ? parseFloat(summary.total_pnl).toFixed(4) : 0} USDT</span>) : <span className="text-danger"> NA</span>} </h4>
                    </div>
                  </div>
                  : ""}
                {!loading && Object.keys(summary).length !== 0 ?
                  <StackedColumnChart className="chartAreaWrapper" periodData={[summary.graph_data]} dataColors='["--bs-primary", "--bs-warning", "--bs-success"]' currency={Currency} />
                  : !loading && Object.keys(summary).length === 0 ? <Col xl={12}>
                    <div className="p-4 border text-center">
                      <h6 className="text-muted">No Data Found</h6> </div>
                  </Col> : <Col xl={12}>
                    <div className="p-4 border text-center">
                      <h6 className="text-muted">Loading...</h6>
                    </div>
                  </Col>
                }

                {
                  !loading && Object.keys(topList).length > 0 ?

                    <Row className='mt-4'>
                      <Col xl={12}>
                        <Row>
                          <Col xl={6}>
                            <h4 className="card-title text-success">Gainers</h4>
                            {Object.keys(topList.gainers).length > 0 ?
                              <div className="table-responsive">
                                <Table striped bordered variant="dark">
                                  <thead>
                                    <tr>
                                      <th>Exchange</th>
                                      <th>Symbol</th>
                                      <th>PNL in USDT</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {Object.entries(topList.gainers).map(([index, { symbol, gain_pnl, exchange }]) => (
                                      gain_pnl > 0 &&
                                      <tr key={index}>
                                        <td>{exchange}</td>
                                        <td>{symbol}</td>
                                        <td className="text-success">{gain_pnl.toFixed(4)}</td>
                                      </tr>
                                    ))}
                                    <tr key="total_gain">
                                      <td></td>
                                      <td>Total Gain</td>
                                      <td className="text-success">{totalPNL.total_gain.toFixed(4)}</td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </div> : <div className="p-4 border text-center">
                                <h6 className="text-muted">No Data Found</h6> </div>}
                          </Col>
                          <Col xl={6}>
                            <h4 className="card-title text-danger">Losers</h4>
                            {Object.keys(topList.losers).length > 0 ?
                              <div className="table-responsive">
                                <Table striped bordered variant="dark">
                                  <thead>
                                    <tr>
                                      <th>Exchange</th>
                                      <th>Symbol</th>
                                      <th>PNL in USDT</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {Object.entries(topList.losers).map(([index, { symbol, lose_pnl, exchange }]) => (
                                      lose_pnl < 0 &&
                                      <tr key={index}>
                                        <td>{exchange}</td>
                                        <td>{symbol}</td>
                                        <td className="text-danger">{lose_pnl.toFixed(4)}</td>
                                      </tr>
                                    ))}
                                    <tr key="total_lose">
                                      <td></td>
                                      <td>Total Loss</td>
                                      <td className="text-danger">{totalPNL.total_lose.toFixed(4)}</td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </div>
                              : <div className="p-4 border text-center">
                                <h6 className="text-muted">No Data Found</h6> </div>}
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    : null
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

    </div>
  )
}

export default Dashboard