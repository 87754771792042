import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";

import {
  Col,
  Container,
  Row,
  Button,
  Card,
  CardBody,
  CardTitle,
  Input,
  Table,
  FormGroup,
  Label,
  Tooltip,
  Spinner,
  UncontrolledTooltip,
} from "reactstrap";
import Switch from "react-switch";
// import { toast } from "react-toastify";
import api from "../../../helpers/api_helper";
import { toast } from "react-toastify";
import Breadcrumbs from "components/Common/Breadcrumb";

const BeginnerTrade = (props) => {
  firebase.auth();
  const firestoreDb = firebase.firestore();

  const [userExchanges, setUserExchanges] = useState([]);

  const [loading, setLoading] = useState(true);

  const [pairDetails, setPairDetails] = useState({});

  const ActiveOrdersforpair = async (exchange_id, symbol_id) => {
    const firestoreDbUser = firestoreDb.collection("users").doc(props.uid);
    const ordersRef = firestoreDbUser
      .collection("ccxt_orders")
      .where("exchange_id", "==", exchange_id)
      .where("symbol", "==", symbol_id)
    let orders_info = [];
    
   await ordersRef.get().then((querySnapshot) => {
      console.log("Total Orders:", querySnapshot.size); // Correct way to get count
      querySnapshot.forEach((doc) => {
        orders_info.push(doc.data());
      });
    }).catch((error) => {
      return false;
    });
    // ordersRef.onSnapshot((querySnapshot) => {
    //   //console.log(querySnapshot)
    //   querySnapshot.forEach((doc) => {
    //     let item = doc.data();
    //     orders_info.push(item.exchange_id);
    //   });
    // });
    if (orders_info.length > 0) {
      toast.error(
        `There are some orders that are active now for ${symbol_id}. Please close those`
      );
      return false;
    } else {
      return true;
    }
  };

  const getuserExchanges = async () => {
    await api({ contentType: true, auth: true })
      .post("/ccxt/getUserExchanges/")
      .then((res) => {
        // console.log("res.dataaaaaaaa....", res);
        setUserExchanges(res.data.response.exchanges);
        let pairsList = res.data.response.pairsList;
        if (pairsList.length) {
          pairsList.forEach((element) => {
            let settings = JSON.parse(element.data.settings);
            setPairDetails((prevState) => ({
              ...prevState,
              [element.data.exchange_id]: {
                ...prevState[element.data.exchange_id],
                [element.data.symbol]: {
                  // ...prevState[exchange_id]?.[symbol],
                  percentage: settings.quantity ? settings.quantity : 10,
                  status: element.data.status,
                  loadin: false,
                },
              },
            }));
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getuserExchanges();
  }, []);

  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        No
      </div>
    );
  };

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        Yes
      </div>
    );
  };

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const shownBaseIds = new Set(); // To track displayed baseIds]

  // const capitalizeFirstLetter = (str) => {
  //   return str.charAt(0).toUpperCase() + str.slice(1);
  // };

  const handleSave = async (pair_value, id, exchange_id, pair_id) => {
    toast.dismiss();
   let activeOrder = await ActiveOrdersforpair(exchange_id, pair_value);


   if(!activeOrder){
    return;
   }


    if (pairDetails[exchange_id] && pairDetails[exchange_id][pair_id]) {
      setPairDetails((prevState) => ({
        ...prevState,
        [exchange_id]: {
          ...prevState[exchange_id],
          [pair_id]: {
            ...prevState[exchange_id]?.[pair_id],
            loading: true,
          },
        },
      }));

      let bodyParams = {
        id,
        exchange_id: exchange_id,
        pair_id: pair_id,
        ...pairDetails[exchange_id][pair_id],
      };

      if (
        !bodyParams.percentage ||
        bodyParams.percentage < 0.1 ||
        bodyParams.percentage > 100
      ) {
        toast.error("percentage must be 0.1 to 100");
        setPairDetails((prevState) => ({
          ...prevState,
          [exchange_id]: {
            ...prevState[exchange_id],
            [pair_id]: {
              ...prevState[exchange_id]?.[pair_id],
              loading: false,
            },
          },
        }));
        return;
        // bodyParams.percentage = 10;
      }

      await api({ contentType: true, auth: true })
        .post("/ccxt/updateBeginnerPair/", bodyParams)
        .then((res) => {
          if (res.data.message == "sucess") {
            toast.success(
              `${pair_value} Pair updated successfully for exchange ${exchange_id}`
            );
          } else {
            toast.error(`No pairs found`);
          }
          setTimeout(() => {
            setPairDetails((prevState) => ({
              ...prevState,
              [exchange_id]: {
                ...prevState[exchange_id],
                [pair_id]: {
                  ...prevState[exchange_id]?.[pair_id],
                  loading: false,
                },
              },
            }));
          }, 2000);
          // getuserExchanges();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error("Enable status to save");
      setPairDetails((prevState) => ({
        ...prevState,
        [exchange_id]: {
          ...prevState[exchange_id],
          [pair_id]: {
            ...prevState[exchange_id]?.[pair_id],
            loading: false,
          },
        },
      }));
    }
  };

  const handleStatusChange = (e, exchange_id, symbol) => {
    setPairDetails((prevState) => ({
      ...prevState,
      [exchange_id]: {
        ...prevState[exchange_id],
        [symbol]: {
          ...prevState[exchange_id]?.[symbol],
          status: e ? 1 : 0,
        },
      },
    }));
  };

  const handlePercentageChange = (e, exchange_id, symbol) => {
    toast.dismiss();

    if (e.target.value < 1 || e.target.value > 100) {
      toast.error("Percentage must be 0.1 to 100");
      // return;
    }

    setPairDetails((prevState) => ({
      ...prevState,
      [exchange_id]: {
        ...prevState[exchange_id],
        [symbol]: {
          ...prevState[exchange_id]?.[symbol],
          percentage: e.target.value,
        },
      },
    }));
  };

  return (
    <Container fluid>

      <Breadcrumbs title="DeriSnap" breadcrumbItem="Trade" />

      {/* <Row className="mb-3 align-items-center px-2">
        <Col className="">
          <h2>Beginner Trade</h2>
          <p>
            Manage your exchanges, view wallet balances, and enable trading
            pairs to trade.
          </p>
        </Col>
        <Col className="d-flex justify-content-end">
          <button
            type="button"
            className="btn btn-success font-size-14"
            style={{ padding: "14px" }}
            onClick={() => props.changeTradeMode(2)}
          >
            Switch to Advanced Trade
          </button>
        </Col>
      </Row> */}
      <Card className="border">
        <CardBody>
          <Row className="mb-3">
            <Col>
              <h3>Beginner Trade</h3>
              <p>
              Enable the pairs, and let Deri Bot handle the trade settings for you.
              </p>
            </Col>
            <Col className="d-flex justify-content-end align-items-start">
              <button
                type="button"
                className="btn btn-success font-size-14"
                style={{ padding: "14px" }}
                onClick={() => props.changeTradeMode(2)}
              >
                Switch to Advanced Trade
              </button>
            </Col>
          </Row>
        </CardBody>
      </Card>

      {!loading ? (
        <Row>
          <Col lg="12">
            {userExchanges.map((exchange) => (
              <div key={exchange.exchange_id} className="mt-3 py-4 px-2">


                <h4 style={{ display: "flex", alignItems: "center", gap: "2px" }}>
                  <i className="bx bx-circle" />
                  <span style={{ marginTop: "0px" }}>{exchange.exchange_name}</span>
                </h4>


                {exchange.trading_pair.length > 0 ? (
                  <>
                    <h6 className="mt-4">Wallet Balance</h6>

                    <Row>
                      {exchange.trading_pair.map((item, index) => {
                        if (shownBaseIds.has(item.data.baseId)) {
                          return null; // Skip rendering if baseId was already shown
                        }

                        shownBaseIds.add(item.data.baseId);

                        return (
                          <React.Fragment key={index}>
                            <Col sm="4">
                              <Card>
                                <CardBody>
                                  <CardTitle tag="h6">
                                    {item.data.baseId}
                                  </CardTitle>
                                  <p>
                                    Available:{" "}
                                    {exchange.exchange_balances &&
                                      exchange.exchange_balances["free"] &&
                                      exchange.exchange_balances["free"][
                                      item.data.baseId
                                      ]
                                      ? exchange.exchange_balances["free"][
                                      item.data.baseId
                                      ]
                                      : 0}
                                  </p>
                                </CardBody>
                              </Card>
                            </Col>
                            {!shownBaseIds.has(item.data.quoteId) ? (
                              <Col sm="4">
                                <Card>
                                  <CardBody>
                                    <CardTitle tag="h6">
                                      {item.data.quoteId}
                                    </CardTitle>
                                    <p>
                                      Available:{" "}
                                      {exchange.exchange_balances &&
                                        exchange.exchange_balances["free"] &&
                                        exchange.exchange_balances["free"][
                                        item.data.quoteId
                                        ]
                                        ? exchange.exchange_balances["free"][
                                        item.data.quoteId
                                        ]
                                        : 0}
                                    </p>
                                  </CardBody>
                                </Card>
                              </Col>
                            ) : null}
                            {shownBaseIds.add(item.data.quoteId)}
                          </React.Fragment>
                        );
                      })}
                      {shownBaseIds.clear()}
                    </Row>
                  </>
                ) : (
                  <div className="pt-2">No pairs added by Deri Bot.</div>
                )}
                {exchange.trading_pair.length > 0 ? (
                  <div className="table-responsive">
                    <Table className="table-nowrap align-middle mb-0">
                      <thead>
                        <tr>
                          <th scope="col">Trading Pair</th>
                          <th scope="col">
                            <span id="percentageTooltip">
                              Portion (Wallet Balance)
                            </span>
                            <Tooltip
                              placement="top"
                              isOpen={tooltipOpen}
                              target="percentageTooltip"
                              toggle={() => setTooltipOpen(!tooltipOpen)}
                            >
                              Set the portion of your wallet balance to allocate
                              for each trading pair.
                            </Tooltip>
                          </th>
                          <th scope="col">Start Trade</th>
                          <th scope="col">Actions</th>{" "}
                          <th className="text-center"> Info</th>
                          {/* New column for the Save button */}
                        </tr>
                      </thead>
                      <tbody>
                        {exchange.trading_pair.length > 0 ? (
                          exchange.trading_pair.map((pair) => (
                            <tr key={pair.id}>
                              <td>{pair.data.id}</td>

                              <td>
                                <div className="d-flex flex-column">
                                  {/* <div className="d-flex"> */}

                                  <FormGroup
                                    className="input-group mb-0 per-input"
                                    style={{
                                      width: "40%",
                                      marginBottom: "0rem",
                                    }}
                                  >
                                    <Input
                                      type="number"
                                      min="0"
                                      max="100"
                                      step="1"
                                      // defaultValue={10}
                                      value={
                                        pairDetails?.[exchange.exchange_id]?.[
                                          pair.data.symbol
                                        ]?.percentage
                                      }
                                      placeholder="Enter percentage"
                                      onChange={(e) =>
                                        handlePercentageChange(
                                          e,
                                          exchange.exchange_id,
                                          pair.data.symbol
                                        )
                                      }
                                    />
                                    <Label className="input-group-text">
                                      %
                                    </Label>
                                  </FormGroup>

                                  {/* </div> */}
                                  <div className="d-flex align-items-center text-muted mt-1">
                                    <i
                                      className="bx bx-info-circle"
                                      id="TFBMINTREVAL"
                                    />
                                    <small className="text-muted mx-1">
                                      {(() => {
                                        const quoteCurrency = pair.data.quoteId;
                                        const balance =
                                          (exchange.exchange_balances &&
                                            exchange.exchange_balances[
                                            "free"
                                            ]?.[quoteCurrency]) ||
                                          0;
                                        const percentage =
                                          pairDetails?.[exchange.exchange_id]?.[
                                            pair.data.symbol
                                          ]?.percentage || 0;
                                        const amountToUse = (
                                          (percentage / 100) *
                                          balance
                                        ).toFixed(2);

                                        return `Use ${amountToUse} ${quoteCurrency} balance to trade this pair.`;
                                      })()}
                                    </small>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <FormGroup row>
                                  <Col md="8">
                                    <Switch
                                      uncheckedIcon={<Offsymbol />}
                                      checkedIcon={<OnSymbol />}
                                      onChange={(e) =>
                                        handleStatusChange(
                                          e,
                                          exchange.exchange_id,
                                          pair.data.symbol
                                        )
                                      }
                                      checked={
                                        pairDetails?.[exchange.exchange_id]?.[
                                          pair.data.symbol
                                        ]?.status || false
                                      }
                                    />
                                  </Col>
                                </FormGroup>
                              </td>
                              <td className="p-0 m-0 ">
                                {pairDetails?.[exchange.exchange_id]?.[
                                  pair.data.symbol
                                ]?.loading ? (
                                  <Spinner
                                    className="text-center"
                                    animation="border"
                                    size="sm"
                                  />
                                ) : (
                                  <Button
                                    style={{ marginTop: "-14px" }}
                                    size="sm"
                                    color="primary"
                                    onClick={() =>
                                      handleSave(
                                        pair.data.id,
                                        pair.id,
                                        exchange.exchange_id,
                                        pair.data.symbol
                                      )
                                    }
                                  >
                                    Save Changes
                                  </Button>
                                )}
                              </td>
                              <td className="text-center">

                                <span style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "-10px" }}>
                                  <i
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "1rem"
                                    }}
                                    className="bx bx-detail"
                                    id={`tooltip-${pair.id}`}
                                  />
                                </span>

                                <UncontrolledTooltip
                                  style={{ maxWidth: "none", width: "auto" }}
                                  placement="top"
                                  target={`tooltip-${pair.id}`}
                                >
                                  <div className="tooltip-content">
                                    <div className="tooltip-header">
                                      <h6>Settings</h6>
                                    </div>
                                    <hr className="m-2 p-0" />
                                    <div
                                      className="tooltip-body"
                                      style={{ textAlign: "left" }}
                                    >
                                      {Object.entries(
                                        JSON.parse(pair.data.settings)
                                      ).map(([key, value]) =>
                                        key === "min_take_profit_percentage" || key === "trailing_take_profit_percentage" || key === "stop_loss" ? (
                                          <p key={key}>
                                            <b>{key === "min_take_profit_percentage" ? "Minimum Take Profit Percentage" : key === "trailing_take_profit_percentage" ? "Trailing Take Profit Percentage" : key === "stop_loss" ? "Stop Loss percentage" : key === "waiting_time_for_next_order_trailing_profit" ? "waiting Time for Next Order After Trailing Profit" : key === "waiting_time_for_next_order_stop_loss" ? "Waiting Time for Next Order Stop Loss" : ""}:</b> {value}
                                          </p>
                                        ) : null
                                      )}

                                      {
                                        (() => {
                                          const trendSettings = JSON.parse(pair.data.Trend_settings);
                                          return Object.entries(trendSettings).map(([key, value]) =>
                                            key === "trend_method" ? (
                                              <>
                                                <p>
                                                  <b>{key === "trend_method" ? "Trend Method" : ""}:</b> {value}

                                                </p>
                                                <p key={key}>
                                                  <b>{key === "trend_method" ? "Time Frame" : ""}:</b> {trendSettings[key] == "sma" ? trendSettings["time_frame"] : trendSettings[key] == "ema" ? trendSettings["ema_time_frame"] : trendSettings[key] == "macd" ? trendSettings["macd_time_frame"] : trendSettings[key] == "supertrend" ? trendSettings["st_time_frame"] : ""}

                                                </p>
                                              </>
                                            ) : null
                                          );
                                        })()
                                      }
                                    </div>
                                  </div>
                                </UncontrolledTooltip>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="4" className="text-center">
                              No pairs Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                ) : null}
              </div>
            ))}
          </Col>
        </Row>
      ) : (
        <>
          <div className="p-4 text-center">
            <h6 className="text-muted"> Loading...</h6>
          </div>
        </>
      )}
    </Container>

  );
};

export default BeginnerTrade;
