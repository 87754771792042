import React, { useEffect, useState } from 'react';
import { Alert, Col, Container, Spinner } from 'reactstrap';
import { toast } from 'react-toastify';
import api from '../../../helpers/api_helper';
import BeginnerOption from '../Beginners';
import BeginnerTrade from '../Beginners/BeginnerTrade';
import Trade from './Trade';

function Index() {
    document.title = "Spot Trade";
    const currentUser = JSON.parse(localStorage.getItem('user'));
    const uid = currentUser?.uid || "";
    
    const [tradeLoading, setTradeLoading] = useState(true);
    const [tradeStatus, setTradeStatus] = useState(2);
    const [tradeMode, setTradeMode] = useState(0);

    useEffect(() => {
        const getSettings = async () => {
            try {
                const res = await api({ contentType: true, auth: true }).get('/user/profile');
                const user = res.data.response?.user;
                let trademode =user.trademode? user.trademode:0
                // if (user?.trade_status !== undefined) {
                    setTradeStatus(user.trade_status);
                    setTradeMode(trademode);
                // } else {
                //     setTradeStatus(0);
                // }
            } catch (e) {
                setTradeStatus(0);
                handleError(e);
            } finally {
                setTradeLoading(false);
            }
        };
        getSettings();
    }, []);

    const handleError = (err) => {
        const errorMessage = err?.response?.data?.message || err.message || "An error occurred";
        toast.error(errorMessage, { toastId: errorMessage });
    };

    const changeTradeMode = async (mode) => {
        // setTradeLoading(true);
        try {
            const res = await api({ contentType: true, auth: true }).post('/ccxt/changeCCXTTrademode/', { uid, trademode: mode });
            if (res.data.message === "trade mode updated") {
                setTradeMode(res.data.response.trademode);
            } else {
                toast.error(`Ensure all trading pairs are disabled in ${mode == 1 ? "Advanced Trade":"Beginner Trade"}`);
            }
        } catch (err) {
            handleError(err);
        } finally {
            // setTradeLoading(false);
        }
    };

    if (tradeLoading) return <div className="page-content text-center">Loading...</div>;

    return (
        <div className="page-content">
            {tradeStatus === 1 ? (
                tradeMode === 0 ? <BeginnerOption changeTradeMode={changeTradeMode} /> :
                tradeMode === 1 ? <BeginnerTrade changeTradeMode={changeTradeMode} uid={uid} /> :
                tradeMode === 2 ? <Trade changeTradeMode={changeTradeMode} uid={uid}/> : null
            ) : (
                <Container fluid>
                    <Col lg="6" sm="3" className="mx-auto">
                        <Alert color="danger">
                            <i className="mdi mdi-alert-outline me-2"></i>
                            <strong>Account disabled</strong>
                            <br />Your account is disabled. Please contact the administrator to enable your account.
                        </Alert>
                    </Col>
                </Container>
            )}
        </div>
    );
}

export default Index;