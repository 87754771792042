import Breadcrumbs from 'components/Common/Breadcrumb'
import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardTitle, Col, Container, NavLink, Label,Badge, NavItem, Row, TabContent, Table, TabPane, Tooltip,Pagination,
    PaginationItem,
    PaginationLink } from 'reactstrap'
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import classnames from "classnames";
import { formatUTCDate,getHedgingStateStyle ,getSettingsDateTimeFormat,timeFormat} from "../../services/common.service";
import api from '../../services/api.service';
import {  map, round } from "lodash";
import { last } from 'lodash';
const Deltahedging = (props) => {
    document.title="Delta Hedging - Future Orders";
    const [orderData, setOrderData] = useState([])
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState(1)
    const [ttop1, setttop1] = useState(false);
    const [historyloading, setHistoryloading] = useState(false);
    const [transactionData, setTransactionData] = useState([]);
    const [TotalPNL, setTotalPNL] = useState();
    const [settingsData,setSettingsData] = useState([]);
    const [FulltransactionData, setFullTransactionData] = useState([]);
    const [settingloading, setSettingloading] = useState(false);
    const [exchangeType, setExchangeType] = useState(1)
    const [loading, setLoading] = useState({
        loading: false,
        Table_load : false
    });
    const currentUser = JSON.parse(localStorage.getItem('user'));
      let uid = currentUser.uid;
    const [popovertop, setpopovertop] = useState(false);
    const convert = (str) => {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }
    const [ttop, setttop] = useState(false);
    let defaultStartDate = new Date(convert(new Date().setDate(new Date().getDate() - 1)));
    let defaultEndDate = new Date(convert(new Date()));
    
    defaultStartDate.setHours(0, 0, 0, 0)
    defaultEndDate.setHours(23, 59, 59, 999)

    const [filterStartDate, setFilterStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 1)).toISOString());
    const [filterEndDate, setFilterEndDate] = useState(new Date(new Date()).toISOString());
    
    const [activeOrderTab, setActiveOrderTab] = useState("1");
    const toggleOrderTab = tab => {
        setpopovertop({});
        if (activeOrderTab !== tab) {
            setActiveOrderTab(tab);
            if (tab == 2) {
                setFilterStartDate(defaultStartDate);
                setFilterEndDate(defaultEndDate);
            }
        }
    };
    const HandlePagetransactions = (page = 1) => {
        // setTransactionData([]); 
        let list = [];
        let startindex = ((page-1) * 50)
        let endindex = ((page) * 50)
        FulltransactionData.map((trans, index)=>{
            if (((index) >= startindex )&& (index+1 <=  (endindex-1))){
                list.push(trans) 
            }
        })

        setTransactionData(list);
    }
    const handleCreatedDateChange = (selectedDates, datestr) => {
        if (selectedDates && selectedDates.length) {
            let converted_date = new Date(convert(selectedDates[0]));
            converted_date.setHours(0, 0, 0, 0);
            setFilterStartDate(converted_date.toISOString());
            if (selectedDates[1]) {
                let converted_end_date = new Date(convert(selectedDates[1]));
                converted_end_date.setHours(23, 59, 59, 999);
                setFilterEndDate(converted_end_date.toISOString());
            }
        } else {
            setFilterStartDate(defaultStartDate);
            setFilterEndDate(defaultEndDate);
        }
    }
    const getTransactions = async () => {
        setTotalPNL(0)
        setHistoryloading(true)
         var body =  {
             uid : uid,
             filterStartDate : filterStartDate,
             filterEndDate : filterEndDate
           }
         await api({ contentType: true, auth: true }).post('/delta-hedging/delta-hedging-history-data',body).then(async(res)  => {
             var data = res.data.response.data;
             setExchangeType(res.data.response.type)
             if (data == null || data.length === 0){
                    setTransactionData([])
                    setFullTransactionData([])
                    setHistoryloading(false)
             }else{
             const items = []; let last_order_with_pnl=0;
             var totalPNL = 0;
             data.forEach((doc) => {
                 let item = doc.data;
                 item.last_order_with_pnl=!item.closed_by_manual  && item.meta_data.pnl && item.meta_data.pnl ==0?last_order_with_pnl:0;
                 items.push(item)
                 last_order_with_pnl = item.meta_data  && item.meta_data.pnl && item.meta_data.pnl !=0 ? item.id : 0;
                 totalPNL += item.meta_data && item.meta_data.pnl &&  item.meta_data.pnl !=0 ? parseFloat(item.meta_data.pnl) : 0;
                 
             });
             let uniqueids = []
             const all_trends = items.reduce(function (row, obj) {
                 uniqueids.push(obj.trend_signal_id)
                 let indexval = uniqueids.indexOf(obj.trend_signal_id)
                 row[indexval] = row[indexval] || [];
                 row[indexval].push(obj);
                 return row;
             }, []);
             setFullTransactionData(all_trends);
             setTransactionData(all_trends);
             setTotalPNL(totalPNL)
            //  let Pagecount =  all_trends.length / 50
            //  let page = all_trends.length % 50
            //  if (page !== 0 && round(Pagecount) == parseInt(Pagecount)){
            //      setTotalPage(round(Pagecount) + 1)
            //  }else{
            //      setTotalPage(round(Pagecount))
            //  }
            //  if (Pagecount == 0 && page == 0){
            //      setTotalPage(1)
            //  }
             setHistoryloading(true)
            }
         });
        //  HandlePagetransactions();
         setHistoryloading(false)
     }
     const getSettings= async () => {
        setSettingloading(true);
        await api({ contentType: true, auth: true }).get('/delta-hedging/delta-hedging-settings').then((response) => {
            let res = response.data.response.data[0];
            
            setSettingsData(res);
            setSettingloading(false);
        }).catch((err) => {
            console.log(err);
        });
    }
    const getActiveOrders = async () => {
        setLoading({ ...loading, Table_load: true });
        await api({ contentType: true, auth: true }).get('/delta-hedging/delta-hedging-active-orders').then((response) => {
            let res = response.data.response.data;
            //console.log('res ==> ',response.data.response.data);
            setOrderData(res);
            setLoading({ ...loading, Table_load: false });
        }).catch((err) => {
            console.log(err);
        });
    }
    
    useEffect(() => {
        getActiveOrders();
        getTransactions();
        getSettings()
    }, []);
    useEffect(() => {
        getTransactions();
    },[filterStartDate,filterEndDate])
  return (
    <React.Fragment>
        <div className="page-content">
            <Container fluid={true}>
                <Breadcrumbs  title="Delta Hedging" breadcrumbItem="Delta Hedging" />
                <Row className='mb-10'>
                {!settingloading ?
            <Col>
                { settingsData && settingsData.data && (settingsData.data.delta_hedging_enable)? (
            <span className="float-end" >You can change the settings for account level delta hedging <a href="/settings?hedging" target="_blank">here</a></span>): (
                <span className="float-end text-danger" >Account level delta hedging is in disabled status. You can change the settings <a href="/settings?hedging" target="_blank">here</a></span>)}
            </Col>:''}
            </Row>
            <Row>
                    <Col lg="12">
                        <Card className="box_4">
                                <CardBody>
                                    <Row>
                                        <Col lg="6">
                                                <h4 className="card-title mb-3" >Futures Orders</h4>
                                                
                                        </Col>
                                        {!settingloading ?
                                            <Col lg="6">
                                                {  settingsData && settingsData.data && (settingsData.data.delta_hedging_enable) && settingsData.summary && (settingsData.summary.sum_of_options_delta || settingsData.summary.sum_of_futures_delta)? (
                                                    <span style={{ "float": "right" }} >
                                                    Total Delta in Options: <span className={settingsData.summary.sum_of_options_delta >0 ? "text-success" : "text-danger"}>{settingsData.summary.sum_of_options_delta}</span> ({settingsData.summary.total_options} positions), Total Delta in Futures: <span className={settingsData.summary.sum_of_futures_delta >0 ? "text-success" : "text-danger"}>{settingsData.summary.sum_of_futures_delta}</span>
                                                    {settingsData && settingsData.data && (settingsData.data.delta_hedging_enable)? <><div  className='font-size-10'>Your Settings<i className="mdi mdi-arrow-right
    ms-1 text-primary "></i> <span  className='text-secondary'>Delta Range: <span className='text-info'>{settingsData.data.delta_hedging_range}</span> , Interval time for delta checking:  <span className='text-info'>{timeFormat(settingsData.data.delta_hedging_interval)}</span>, Order Type: <span className='text-info'>{settingsData.data.delta_hedging_order_type}</span> , 
    Hedge only for the option with loss: <span className='text-info'>{settingsData.data.hedge_only_for_loss? 'Yes ('+settingsData.data.hedging_min_loss_value+' '+ (settingsData.data.hedging_min_loss_type=='percentage'?'%':'pnts')+')': 'No'}</span>
    </span></div>
    
    </> 
    : '' }
                                                    </span>
                                                ) : (settingsData && settingsData.data && !settingsData.data.delta_hedging_enable)?
                                                <>
                                                {settingsData.strategy_delta_summary && settingsData.strategy_delta_summary.all_delta && settingsData.strategy_delta_summary.all_delta? <><div  style={{ "float": "right" }}>Total Delta in Futures: <span  className={settingsData.strategy_delta_summary.all_delta.sum_of_futures_delta >0 ? "text-success" : "text-danger"}>{settingsData.strategy_delta_summary.all_delta.sum_of_futures_delta?settingsData.strategy_delta_summary.all_delta.sum_of_futures_delta:0}</span> , Total Delta in Options Strategy:  <span  className={settingsData.strategy_delta_summary.all_delta.sum_of_options_delta >0 ? "text-success" : "text-danger"}>{settingsData.strategy_delta_summary.all_delta.sum_of_options_delta}</span> {(settingsData.strategy_delta_summary && settingsData.strategy_delta_summary.all_delta && (settingsData.strategy_delta_summary.diagonals_options_delta || settingsData.strategy_delta_summary.strangles_options_delta|| settingsData.strategy_delta_summary.straddles_options_delta))?<><div className='text-secondary font-size-10'>
                                                    {settingsData.strategy_delta_summary.diagonals_options_delta!==undefined ?"Delta in Diagonals: "+settingsData.strategy_delta_summary.diagonals_options_delta : ""}
                                                    {settingsData.strategy_delta_summary.all_delta.diagonals_count!==undefined? " ("+settingsData.strategy_delta_summary.all_delta.diagonals_count+" Positions)":''}
                                                    {settingsData.strategy_delta_summary.diagonals_options_delta==undefined ? "" : settingsData.strategy_delta_summary.strangles_options_delta!==undefined || settingsData.strategy_delta_summary.straddles_options_delta!==undefined ? <><br /></> : ""}

                                                    {settingsData.strategy_delta_summary.strangles_options_delta!==undefined ?"Delta in Strangles: "+settingsData.strategy_delta_summary.strangles_options_delta: "" }
                                                    {settingsData.strategy_delta_summary.strangles_options_delta!==undefined && settingsData.strategy_delta_summary.all_delta.strangles_count!==undefined? " ("+settingsData.strategy_delta_summary.all_delta.strangles_count+" Positions)":''}
                                                    {settingsData.strategy_delta_summary.strangles_options_delta==undefined? "" : settingsData.strategy_delta_summary.straddles_options_delta!==undefined ? <><br /></> : ""}

                                                    {settingsData.strategy_delta_summary.straddles_options_delta!==undefined ?"Delta in Straddles: "+settingsData.strategy_delta_summary.straddles_options_delta:"" }
                                                    {settingsData.strategy_delta_summary.straddles_options_delta!==undefined && settingsData.strategy_delta_summary.all_delta.straddles_count!==undefined? " ("+settingsData.strategy_delta_summary.all_delta.straddles_count+" Positions)":''}
                                                    </div></>:''} </div></> : '' }</>
                                               
                                                :''
                                                }
                                            </Col>
                                            : ''}
                                    </Row>
                                    <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                                    <NavItem>
                                            <NavLink
                                                className={classnames({
                                                    active: activeOrderTab === "1",
                                                })}
                                                onClick={() => {
                                                    toggleOrderTab("1");
                                                }}
                                            >
                                                Open Order
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({
                                                    active: activeOrderTab === "2",
                                                })}
                                                onClick={() => {
                                                    toggleOrderTab("2");
                                                }}
                                            >
                                                History
                                            </NavLink>
                                        </NavItem>
                                    </ul>
                                    <TabContent activeTab={activeOrderTab} className="p-3">
                                        <TabPane tabId="1" id="all-order">
                                            <div className="table-responsive">
                                                {/*********/}
                                                <Table className={"table table-bordered table align-middle table-nowrap table-check "}>
                                                    <thead >
                                                        <tr>
                                                        <th>Side</th>
                                                        <th>Symbol</th>
                                                        <th>Sold/Bought Price</th>
                                                        <th>Qty</th>
                                                        <th>Order Id</th>
                                                        <th>Status</th>
                                                        <th>Created Date</th> 
                                                        </tr>
                                                    </thead>
                                                    { loading.Table_load === true ? 
                                                     (
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan={13} style={{ textAlign: "center" }}>
                                                                    Loading...
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    ) : orderData && orderData.length > 0 ? (
                                                        orderData.map((item, index) => {
                                                            var item = item.data
                                                            let indexs = (index+1)%2
                                                            return (
                                                                <thead key={index}>
                                                                <tr  style={{color:"#8a90a0"}}className={"trow-hover "+ (indexs === 0 ? 'table-light': '')}>
                                                                <td>
                                                                <Badge
                                                                    color={item.side =='buy' ? "success" : "danger"}
                                                                    style={{ "textTransform": "uppercase" }}
                                                                >
                                                                    {item.side}
                                                                </Badge></td>
                                                                <td>
                                                                <a href={process.env.REACT_APP_DELTA_LINK+'app/futures/trade/BTC/'+item.product_symbol} target="_blank" rel="noreferrer" >
                                                                <Badge
                                                                    color={item.side =='buy' ? "success" : "danger"}
                                                                    style={{ "textTransform": "uppercase" }}
                                                                >
                                                                    {item.product_symbol}
                                                                </Badge>
                                                                </a>
                                                                </td>
                                                                <td>
                                                                    <h6 style={{paddingTop:"5px"}} className={item.side =='buy' ?  "text-success" : "text-danger"}>{item.entry_price ? Number(item.entry_price).toFixed(2) : item.limit_price ? Number(item.limit_price).toFixed(2) : "..."}</h6>
                                                                </td>
                                                                <td>{item.size} (cont)</td>
                                                               <td>{item.id}</td>
                                                                <td><span dangerouslySetInnerHTML={{ __html: getHedgingStateStyle(item, "history") }}></span></td>
                                                                <td>{item.created_at ? getSettingsDateTimeFormat(item.created_at) : "..."}</td>
                                                                </tr>
                                                            </thead>
                                                                               
                                                    ) 
                                                })) : (
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan={13} style={{ textAlign: "center" }}>
                                                                    No orders found
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    )}
                                                </Table>
                                                {/*********/}
                                            </div>
                                        </TabPane>
                                        <TabPane tabId="2" id="processing">
                                            <Card className="border">
                                                <CardBody>
                                                    <Row>
                                                        <Col xxl={1} lg={6} className="d-flex align-items-center">
                                                            <h5><b>Filter</b></h5>
                                                        </Col>
                                                        <Col xxl={2} lg={6}>
                                                            <Label htmlFor="Created">Created date</Label>
                                                            <Flatpickr className="form-control d-block"
                                                                placeholder="Created Date"
                                                                options={{
                                                                    mode: "range",
                                                                    dateFormat: "d/m/Y",
                                                                    defaultDate: [filterStartDate, filterEndDate]
                                                                }} 
                                                                onChange={handleCreatedDateChange}
                                                            />
                                                        </Col>
                                                        <Col   className="justify-content-end">
                                                           <Pagination count={1} className="pagination pagination-rounded justify-content-end mt-3 mb-4 pb-1">
                                                             <PaginationItem disabled={page === 1}>
                                                               <PaginationLink
                                                                 previous
                                                                 to="#"
                                                                 onClick={() => handlePageClick(page - 1)}
                                                               />
                                                             </PaginationItem>
                                                             <div className="d-flex align-items-center mx-1"><div className="avatar-xs">
                                                             <a className="avatar-title rounded-circle text-white font-size-12" style={{backgroundColor:"#1464FC"}}>
                                                                {page}
                                                             </a>
                                                             </div></div>
                                                             <PaginationItem disabled={page === totalPage}>
                                                               <PaginationLink
                                                                 next
                                                                 to="#"
                                                                 onClick={() => handlePageClick(page + 1)}
                                                               />
                                                             </PaginationItem>
                                                           </Pagination>
                                                         </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                            <div className="table-responsive">
                                                {/*********/}
                                                {filterStartDate && filterEndDate ? <p className="fw-bold">Total PNL for the data from {formatUTCDate(filterStartDate)} to {formatUTCDate(filterEndDate)} : <span className={TotalPNL > 0 ? 'text-success' : 'text-danger'}> {TotalPNL !== undefined && TotalPNL !== 0 ? (parseFloat(TotalPNL).toFixed(6) +`${exchangeType === 1 ? " USD" : " USDT"}`) : 'N/A'}</span></p>:''}
                                                <Table  className="table table-bordered table align-middle table-nowrap table-check ">
                                                <thead>
                                               <tr>
                                                    <th>Side</th>
                                                    <th>Symbol</th>
                                                    <th>Sold/Bought Price</th>
                                                    <th>Qty</th>
                                                    <th>Order ID</th>
                                                    <th>Status</th>
                                                    <th>PNL</th>
                                                    <th>Created Date</th>
                                                        
                                                </tr>
                                                </thead>
                                                    {
                                                        !historyloading ? 
                                                        transactionData.length > 0 ? (
                                                            transactionData[0].map((item, index) => {
                                                                item = item
                                                                var indexs = (index+1)%2
                                                                return (
                                                                    <thead key={index}>
                                                                        <tr  style={{color:"#8a90a0"}}className={"trow-hover "+ (indexs === 0 ? 'table-light': '')}>
                                                                        <td>
                                                                        <Badge
                                                                            color={item.side === "buy" ? "success" : "danger"}
                                                                            style={{ "textTransform": "uppercase" }}
                                                                        >
                                                                            {item.side}
                                                                        </Badge></td>
                                                                        <td>
                                                                        <a href={process.env.REACT_APP_DELTA_LINK+'app/futures/trade/BTC/'+item.product_symbol} target="_blank" rel="noreferrer" >
                                                                        <Badge
                                                                            color={item.side === "buy" ? "success" : "danger"}
                                                                            style={{ "textTransform": "uppercase" }}
                                                                        >
                                                                            {item.product_symbol}
                                                                        </Badge></a></td>
                                                                        <td>
                                                                            <h6 style={{paddingTop:"5px"}} className={item.side === "buy" ? "text-success" : "text-danger"}>{item.average_fill_price ? Number(item.average_fill_price).toFixed(2) :  item.limit_price ? Number(item.limit_price).toFixed(2) :"..."}</h6>
                                                                        </td>
                                                                        <td>{item.size} (cont)</td>
                                                                        <td>{item.id}
                                                                        {item.last_order_with_pnl!=0?(
                                                                        <div className='text-info font-size-10'>Closed Position by order {item.last_order_with_pnl}</div>):
                                                                        item.closed_by_manual && item.closed_by_manual==1?(<div className='text-info font-size-10'>Closed Position by manual</div>):
                                                                        ''}
                                                                        </td>
                                                                        <td><span dangerouslySetInnerHTML={{ __html: getHedgingStateStyle(item, "history") }}></span></td>
                                                                        <td><small title={item.meta_data && item.meta_data.pnl && item.meta_data.pnl !== '...' ? item.meta_data.pnl : ''}  className={item.meta_data.pnl > 0 ? "text-success" : "text-danger"}>{ item.meta_data && item.meta_data!='' && item.meta_data.pnl !== '...' ? parseFloat(item.meta_data.pnl).toFixed(4) + `${exchangeType === 1 ? " USD" : " USDT"}` : '...'}</small></td>
                                                                        <td>{item.created_at ? getSettingsDateTimeFormat(item.created_at) : "..."}</td>
                                                                        </tr>
                                                                    </thead>
                                                                )
                                                            })):(
                                                                <tbody>
                                                                    <tr>
                                                                        <td colSpan={8} style={{ "textAlign": "center" }}>
                                                                            No orders found
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            )
                                                    : <tbody>
                                                    <tr>
                                                        <td colSpan={8} style={{ textAlign: "center" }}>
                                                            Loading...
                                                        </td>
                                                    </tr>
                                                </tbody>}
                                                </Table>
                                                {/*********/}
                                            </div>
                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    </React.Fragment>
  )
}

export default Deltahedging

{/* <Table className={"table table-bordered table align-middle table-nowrap table-check "}>
<thead>
    <tr>
        <th>Side</th>
        <th>Symbol</th>
        <th>Sold/Bought Price</th>
        <th>Qty</th>
        <th>Order ID</th>
        <th>Status</th>
        <th className="text-decoration-underline" >
            <Tooltip
                placement="top"
                isOpen={ttop1}
                target="TooltipTop1"
                toggle={() => {
                    setttop1(!ttop1);
                }}
            >
                Booked PNL = sell price - buy price
            </Tooltip>
            <span id="TooltipTop1">Booked PNL</span>
        </th>

    </tr>
</thead>
{ 
historyloading === true ? 
 (
    <tbody>
        <tr>
            <td colSpan={7} style={{ textAlign: "center" }}>
                Loading...
            </td>
        </tr>
    </tbody>
) : transactionData && transactionData.length > 0 ? (
    transactionData[0].forEach((row, index) => {
        // let indexs = (index+1)%2
        // console.log('row ==> 2222222' ,transactionData.length,historyloading,row,row.product_symbol, row.average_fill_price, row.size, row.id, row.state, row.meta_data.pnl); 
        // return (
        var a = (`<tbody key=${index}>
            <tr  key=${index}>
                <td>${row.side}</td>
                <td>${row.product_symbol}</td>
                <td>${row.average_fill_price}</td>
                <td>${row.size}</td>
                <td>${row.id}</td>
                <td>${row.state}</td>
                <td>${row.meta_data.pnl}</td>
            </tr>
            </tbody>`)
            console.log('a ==> ',a);
            return a
        // )
                })
) : (
    <tbody>
        <tr>
            <td colSpan={7} style={{ "textAlign": "center" }}>
                No orders found
            </td>
        </tr>
    </tbody>
)}
</Table> */}