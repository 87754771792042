import PropTypes from "prop-types";
import React, { Component } from "react";
import { Alert, Button, Card, CardBody, Col, Container, Row, Label } from "reactstrap";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import avatar from "../../assets/images/users/user.png";
// actions
import { editProfile, resetProfileFlag } from "../../store/actions";
//import TimezoneSelect from 'react-timezone-select'
import TimezoneSelect, { allTimezones } from "react-timezone-select";


class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = { email: "", first_name: "",last_name:"",selectedTimezone:Intl.DateTimeFormat().resolvedOptions().timeZone };
    //this.changeTimezone = this.changeTimezone.bind(this);
  }

  componentDidMount() {    
    if (localStorage.getItem("user")) {
      const obj = JSON.parse(localStorage.getItem("user"));
        this.setState({          
          first_name: obj.profile.first_name ? obj.profile.first_name:'',
          last_name:obj.profile.last_name ? obj.profile.last_name : '',
          email: obj.email ? obj.email : '',
          selectedTimezone:obj.profile.time_zone ? obj.profile.time_zone : Intl.DateTimeFormat().resolvedOptions().timeZone
        });
    }
    
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, ss) {
    if (this.props !== prevProps) {
      const obj = JSON.parse(localStorage.getItem("user"));
        this.setState({
          first_name: obj.profile.first_name ? obj.profile.first_name:'',
          last_name:obj.profile.last_name ? obj.profile.last_name : '',
          email: obj.email ? obj.email : '',
        });      
      setTimeout(() => {
        this.props.resetProfileFlag();
      }, 2000);
    }
  }

  

  // changeTimezone(e)
  // {
  //   console.log('eeeeee....',e.value)

    

  //   this.setState({ selectedTimezone : e.value});

  // }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumb title="DeriSnap" breadcrumbItem="Profile" />

            <Row>
              <Col lg="12">
                {/* {this.props.error && this.props.error ? (
                  <Alert color="danger">{this.props.error}</Alert>
                ) : null}
                {this.props.success && this.props.success ? (
                  <Alert color="success">{this.props.success}</Alert>
                ) : null} */}

                <Card>
                  <CardBody>
                    <div className="d-flex">
                      <div className="me-3">
                        <img
                          src={avatar}
                          alt=""
                          className="avatar-md rounded-circle img-thumbnail"
                        />
                      </div>
                      <div className="align-self-center flex-1">
                        <div className="text-muted">
                          <h5 className="text-capitalize">{this.state.first_name +' '+ this.state.last_name}</h5>
                          <p className="mb-1">{this.state.email}</p>                          
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <h4 className="card-title mb-4">Change My Details</h4>

            <Card>
              <CardBody>
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    first_name: (this.state && this.state.first_name) || "",
                    last_name: (this.state && this.state.last_name) || "",
                    password: "",
                    confirm_password: "",                    
                  }}
                  validationSchema={Yup.object().shape({
                    first_name: Yup.string().required(
                      "Please Enter Your First name"
                    ),
                    last_name: Yup.string().required(
                      "Please Enter Your Last name"
                    ),
                    password: Yup.string(),
                   confirm_password: Yup.string().when('password', (password, field) =>password ? field.required('Confirm Password is required').oneOf([Yup.ref('password')], 'Confirm Passwords must match') : field),
                  })}
                  onSubmit={values => {
                    values.time_zone = this.state.selectedTimezone;
                    this.props.editProfile(values);
                  }}
                >
                  {({ errors, status, touched }) => (
                    <Form className="form-horizontal">
                      <div className="mb-3">
                        <Label for="first_name" className="form-label">
                          First name
                        </Label>
                        <Field
                          name="first_name"
                          type="text"
                          className={
                            "form-control" +
                            (errors.first_name && touched.first_name
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="first_name"
                          component="div"
                          className="invalid-feedback"
                        />                        
                      </div>

                      <div className="mb-3">
                        <Label for="last_name" className="form-label">
                          Last name
                        </Label>
                        <Field
                          name="last_name"
                          type="text"
                          className={
                            "form-control" +
                            (errors.last_name && touched.last_name
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="last_name"
                          component="div"
                          className="invalid-feedback"
                        />                        
                      </div>

                      <div className="mb-3">
                        <Label for="password" className="form-label">
                          Password
                        </Label>
                        <Field
                          name="password"
                          autoComplete="true"
                          type="password"
                          className={
                            "form-control" +
                            (errors.password && touched.password
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>

                      <div className="mb-3">
                        <Label for="confirm_password" className="form-label">
                          Confirm Password
                        </Label>
                        <Field
                          name="confirm_password"
                          autoComplete="true"
                          type="password"
                          className={
                            "form-control" +
                            (errors.confirm_password && touched.confirm_password
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="confirm_password"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>



                      <div className="mb-3">
                        <Label for="confirm_password" className="form-label">
                          TimeZone
                        </Label>
                        <TimezoneSelect
                          value={this.state.selectedTimezone}
                          onChange={(e)=>this.setState({ selectedTimezone : e.value})}
                          timezones={{
                            ...allTimezones,
                            "America/Lima": "Pittsburgh",
                            "Europe/Berlin": "Frankfurt"
                          }}
                        />
                        
                      </div>
                      {
                        this.state.email == "demoderisnap@gmail.com" ?
                        null
                     :  <div className="text-center mt-4">
                          <Button type="submit" color="danger">
                            Update
                          </Button>
                        </div>
                      }

                    </Form>
                    
                  )}
                </Formik>
                    {
                    this.state.email == "demoderisnap@gmail.com" ?
                    <div className="text-center mt-4">
                        <Button  color="danger" disabled>
                          Update
                        </Button>
                      </div>
                      :null
                      }
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

UserProfile.propTypes = {
  editProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
  resetProfileFlag: PropTypes.func,
};

const mapStateToProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStateToProps, { editProfile, resetProfileFlag })(UserProfile)
);
