import React, { useState } from "react";
import { Col, Container, Row, Button, Card, CardBody, CardTitle } from "reactstrap";

const BeginnerOption = (props) => {
  const [selectedMode, setSelectedMode] = useState(1);
  const [beginnerTrade, setBeginnerTrade] = useState(false);
  // const navigate = useHistory();

  const handleModeSelection = (mode) => {
    // props.changeTradeMode(mode);
    setSelectedMode(mode);
  };

  const handleSubmit = () => {
    if (selectedMode) {
      // alert(`You have selected: ${selectedMode} Mode`);
      // setBeginnerTrade(true);
      // navigate.push("/ccxt/beginner/trade");
      props.changeTradeMode(selectedMode);
    } else {
      alert("Please select a trading level before starting.");
    }
  };

  return (
    <>
   { !beginnerTrade ?
      // <div className="page-content">
        <Container className="my-5">
          <Row className="text-center">
            <Col lg="12">
              <h1>Hi there,</h1>
              <p style={{ fontSize: "20px" }}>
                Select the trading level that aligns with your experience.
              </p>
              <div className="d-flex justify-content-center mt-4">
                {/* Beginner Card */}
                <Card
                  className={`me-3`}
                  style={{ width: "18rem", cursor: "pointer",border:`${selectedMode === 1 ?" 2px solid #1464FC":""}` }}
                  onClick={() => handleModeSelection(1)}
                >
                  <CardBody className="text-center">
                    <CardTitle tag="h5">Beginner</CardTitle>
                    <p className="card-text">Ideal for new traders, simple strategies.</p>
                    {selectedMode === 1 && (
                      <i className="mdi mdi-check-circle-outline text-primary"></i>
                    )}
                  </CardBody>
                </Card>

                {/* Intermediate Card */}
                <Card
                  style={{ width: "18rem", cursor: "pointer",border:`${selectedMode === 2 ?" 2px solid #34C38F":""}` }}
                  onClick={() => handleModeSelection(2)}
                >
                  <CardBody className="text-center">
                    <CardTitle tag="h5">Advanced</CardTitle>
                    <p className="card-text">For experienced traders, advanced tools.</p>
                    {selectedMode === 2 && (
                      <i className="mdi mdi-check-circle-outline text-success"></i>
                    )}
                  </CardBody>
                </Card>
              </div>
              <div className="mt-4">
                <Button color="secondary" style={{ padding:"10px 20px",fontSize:"14px"}} onClick={handleSubmit}>
                  Start
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      // </div>
      :<></>
}

    </>
  );
};

export default BeginnerOption;
