import React from "react";
import { Redirect } from "react-router-dom";

// User profile
import UserProfile from "../pages/Authentication/UserProfile";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Homepage from "../pages/Homepage/index";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import NotFound from "../pages/NotFound/index";
import Strangles from "../pages/Strangles/index";
import Trends from "../pages/Trends/index";
import Transaction from "../pages/Transaction/index";
import Settings from "../pages/Settings/Settings";
import Trendview from "../pages/Trendview/index";
import CCXTTrendview from "../pages/ccxt/TradingView/TradingView"
import Helppage from "../pages/HelpPage/index"
import CcxtSettings from "../pages/ccxt/Settings/Settings";
// Dashboard
import Dashboard from "../pages/Dashboard/index";
import CcxtDashboard from "../pages/ccxt/Dashboard/Dashboard";
// import DeltaSetup from "pages/Authentication/DeltaSetup";
import KeySetup from "pages/Authentication/KeySetup";
import Diagonals from "pages/Diagonals";
import Straypositions from "pages/Straypositions";
import AdminSession from "pages/AdminSession";
import Straddles from "pages/Straddles";
import Wallet from "pages/ccxt/wallet";
import Orders from "pages/ccxt/ActiveOrders";
import CompletedOrders from "pages/ccxt/CompletedOrders"; 
import Deltahedging from "pages/Deltahedging/Deltahedging";
import BotSelect from "pages/Bot-Select/BotSelect";
import CcxtExchange from "pages/ccxt/Exchanges/CcxtExchange";
// import Trade from "pages/ccxt/Trade/Trade";
import Index from "pages/ccxt/Trade/Index";
const authProtectedRoutes = [
  { path: "/delta-setup", component: BotSelect, isNonAuthLayout: true },
  { path: "/key-setup", component: KeySetup},
  { path: "/dashboard", component: Dashboard },
  { path: "/diagonals", component: Diagonals },
  { path: "/straddles", component: Straddles },

  //profile
  { path: "/profile", component: UserProfile },
  { path: "/strangles", component: Strangles },
  { path: "/transaction", component: Transaction },
  { path: "/settings", component: Settings },
  { path: "/stray-positions", component: Straypositions },
  { path: "/trendview", component: Trendview },
  { path: "/delta-hedging", component: Deltahedging},
  { path: "/ccxt/trendview", component: CCXTTrendview,changeLayout: true  },
  { path: "/trend-following", component: Trends },
  { path: "/ccxt/dashboard", component: CcxtDashboard, changeLayout: true },
  { path: "/ccxt/exchanges", component: CcxtExchange, changeLayout: true },
  { path: "/ccxt/trade", component: Index, changeLayout: true },
  // { path: "/ccxt/settings", component: CcxtSettings, changeLayout: true },
  // { path: "/ccxt/settings?pairs", component: CcxtSettings, changeLayout: true },
  { path: "/help", component: Helppage, isNonAuthLayout: true},
  { path: "/ccxt/wallet", component: Wallet, changeLayout:true },
  { path: "/ccxt/orders/active", component: Orders, changeLayout:true },
  { path: "/ccxt/orders/completed", component: CompletedOrders, changeLayout:true },
  { path: "/ccxt/profile", component: UserProfile, changeLayout: true},
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/ccxt/dashboard" /> },  
  { path: "*", component: NotFound, isNonAuthLayout: true },  
 
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },  
  { path: "/home", component: Homepage }, 
  { path: "/admin-session", component: AdminSession }
];

export { authProtectedRoutes, publicRoutes };