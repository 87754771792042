import PropTypes from "prop-types";
import React, { Component, useEffect } from "react";
import "react-drawer/lib/react-drawer.css";

import { connect } from "react-redux";
import { Row, Col } from "reactstrap";

import { Link } from "react-router-dom";

// Reactstrap
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

// Import menuDropdown
// import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
// import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

// import megamenuImg from "../../assets/images/megamenu-img.png";

import logo from "../../assets/images/logo-color.png";
import logoLightPng from "../../assets/images/full-logo-light.png";
import logoLightSvg from "../../assets/images/logo_light.png";
import logoDark from "../../assets/images/full-logo-dark.png";

// import images
// import github from "../../assets/images/brands/github.png";
// import bitbucket from "../../assets/images/brands/bitbucket.png";
// import dribbble from "../../assets/images/brands/dribbble.png";
// import dropbox from "../../assets/images/brands/dropbox.png";
// import mail_chimp from "../../assets/images/brands/mail_chimp.png";
// import slack from "../../assets/images/brands/slack.png";

//i18n
import { withTranslation } from "react-i18next";

// Redux Store
import { toggleRightSidebar } from "../../store/actions";
import firebase from "firebase/compat/app";
import MarketPrice from "components/MarketPrice";
import RightSidebar from "components/CommonForBoth/RightSidebar";

import {layoutModeTypes} from '../../constants/layout'
import { changeLayout,changeLayoutMode } from "../../store/actions";
import api from "../../services/api.service"
import moment from "moment";

const getUserName = () => {
  if (localStorage.getItem("user")) {
    const obj = JSON.parse(localStorage.getItem("user"))
    return obj;
  }
}
async function UpdateProfileData(data){
  let data2 = {theme_mode : data}
  // document.getElementById("preloader").style.display = "block";
  //     document.getElementById("status").style.display = "block";

  // setTimeout(function () {
  //       document.getElementById("preloader").style.display = "none";
  //       document.getElementById("status").style.display = "none";
  // }, 1000);
  let user = JSON.parse(localStorage.getItem("user"));
  user.profile.theme_mode = data2.theme_mode;
  localStorage.setItem("user", JSON.stringify(user));   
  firebase.auth();
  const firestoreDb = firebase.firestore(); 
  let updateData = data2
  updateData.updated_at = moment().toISOString();
  // const firestoreDb1 = firestoreDb.collection("users").doc(userData.uid);  
	// const result = firestoreDb1.collection("profile").doc('data').update(updateData);

  let send_update_data = {
    uid: user.uid,
    data: updateData
}
  const result =  await api({ contentType: true, auth: true }).post('/user/update-user',send_update_data)
}

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearch: false,
      open: false,
      position: "right",
      // userBalance:'',
      active_orders:'',
      filled_orders:'',
      dia_active_orders:'',
      dia_filled_orders:'',
      straddle_active_orders:'',
      straddle_filled_orders:'',
      trend_active_orders:'',
      trend_filled_orders:'',
      user_details:'',
      layoutModeType: this.props.layoutModeType,
    };
    this.changeLayoutMode = this.changeLayoutMode.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
  }

  componentDidMount() {
    this.get_running_orders();
    const currentUser = JSON.parse(localStorage.getItem('user'));
    const userData = getUserName();   
    if (userData) {
      this.setState({ user_details:currentUser,name: userData && userData.profile? userData.profile.first_name:'' ,layoutModeType:this.state.layoutModeType })
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.success !== this.props.success) {
      const userData = getUserName();
      const currentUser = JSON.parse(localStorage.getItem('user'));
      if (userData) {        
        this.setState({ user_details:currentUser,name: userData && userData.profile? userData.profile.first_name:'' })
      }
    }
    if (prevProps !== this.props) {
      this.setState({ 
        layoutModeType:this.state.layoutModeType ,
      })
    }
  }
  /**
   * Toggle sidebar
   */
  toggleMenu() {
    this.props.toggleMenuCallback();
  }

  /**
   * Toggles the sidebar
   */
  toggleRightbar() {
    this.props.toggleRightSidebar();
  }

  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }
  changeLayoutMode(e) {
    if (e.target.checked) {
      this.props.changeLayoutMode(e.target.value);
      this.setState({ layoutModeType: e.target.value });
    }
  }
  get_running_orders = async()=>{
    firebase.auth();
    const firestoreDb = firebase.firestore();
    const currentUser = JSON.parse(localStorage.getItem('user'));
    let uid = currentUser.uid;
    // var firestoreDb1=firestoreDb.collection("users").doc(uid);
    // const ordersRefRunning = firestoreDb1.collection("strangle_orders").where('status', '==', 'open');
    // const ordersRefFilled = firestoreDb1.collection("strangle_orders").where('status', '==', 'closed').where('direction','==','sell');
    // const diagonal_open_order = firestoreDb1.collection("diagonal_orders").where('status', '==', 'open');
    // const diagonal_position_order = firestoreDb1.collection("diagonal_orders").where('status', '==', 'closed');    
    // const straddle_open_order = firestoreDb1.collection("straddle_orders").where('status', '==', 'open');
    // const straddle_position_order = firestoreDb1.collection("straddle_orders").where('status', '==', 'closed').where('bot_status','==','active');
    // const trend_open_order = firestoreDb1.collection("trend_orders").where('status', '==', 'open');
    // const trend_position_order = firestoreDb1.collection("trend_orders").where('status', '==', 'closed');
    // const get_balance = firestoreDb1.collection("balance_details");
    const strangle_open_order = await firestoreDb.collection("users").doc(uid).collection("strangle_orders").where('status', '==', 'open').get().then(stra_open_order => {
      this.setState({active_orders: stra_open_order.size})
    });
    const strangle_position_order = await firestoreDb.collection("users").doc(uid).collection("strangle_orders").where('status', '==', 'closed').where('direction', '==', 'sell').get().then(stra_position_order => {
      this.setState({filled_orders:stra_position_order.size})
    });
    const diagonal_open_order = await firestoreDb.collection("users").doc(uid).collection("diagonal_orders").where('status', '==', 'open').get().then(dia_open_order => {
      this.setState({dia_active_orders:dia_open_order.size})
     });
    const diagonal_position_order = await firestoreDb.collection("users").doc(uid).collection("diagonal_orders").where('status', '==', 'closed').get().then(dia_position_order => {
      this.setState({dia_filled_orders:dia_position_order.size})
    });
    const straddle_open_order = await firestoreDb.collection("users").doc(uid).collection("straddle_orders").where('status', '==', 'open').get().then(straddle_open_order => {
      this.setState({straddle_active_orders:straddle_open_order.size})
    });
    const straddle_position_order = await firestoreDb.collection("users").doc(uid).collection("straddle_orders").where('status', '==', 'closed').where('bot_status', '==', 'active').get().then(straddle_position_order => {
      this.setState({straddle_filled_orders:straddle_position_order.size}) 
    });
    const trend_open_order = await firestoreDb.collection("users").doc(uid).collection("trend_orders").where('status', '==', 'open').get().then(trend_open_order => {
      this.setState({trend_active_orders:trend_open_order.size})
     });
    const trend_position_order = await firestoreDb.collection("users").doc(uid).collection("trend_orders").where('status', '==', 'closed').get().then(trend_position_order => {
      this.setState({trend_filled_orders:trend_position_order.size})
    });
  //   get_balance.onSnapshot((querySnapshot) => {
  //     let item = []
  //     querySnapshot.forEach((doc) => {
  //       item = doc.data();        
  //     })
  //     this.setState({userBalance:item})
  // })  

    // ordersRefRunning.onSnapshot((querySnapshot) => {
    //   this.setState({active_orders:0})
    //   querySnapshot.docChanges().forEach((change) => {
    //   this.setState({active_orders:querySnapshot.size})
    //   });
    // });    
    // ordersRefFilled.onSnapshot((querySnapshot) => {
    //   this.setState({filled_orders:0})
    //   querySnapshot.docChanges().forEach((doc) => {      
    //   this.setState({filled_orders:querySnapshot.size})
    //   });            
    // });

    // diagonal_open_order.onSnapshot((querySnapshot) => {
    //   this.setState({dia_active_orders:0})
    //   querySnapshot.docChanges().forEach((doc) => {
    //   this.setState({dia_active_orders:querySnapshot.size})
    //   });
    // });
    // diagonal_position_order.onSnapshot((querySnapshot) => {
    //   this.setState({dia_filled_orders:0})
    //   querySnapshot.docChanges().forEach((doc) => {
    //   this.setState({dia_filled_orders:querySnapshot.size})
      
    //   });
    // });

    // straddle_open_order.onSnapshot((querySnapshot) => {
    //   this.setState({straddle_active_orders:0})
    //   querySnapshot.docChanges().forEach((doc) => {
    //   this.setState({straddle_active_orders:querySnapshot.size})
    //   });
    // });
    // straddle_position_order.onSnapshot((querySnapshot) => {
    //   this.setState({straddle_filled_orders:0})
    //   querySnapshot.docChanges().forEach((doc) => {
    //   this.setState({straddle_filled_orders:querySnapshot.size})      
    //   });
    // });
    // trend_open_order.onSnapshot((querySnapshot) => {
    //   this.setState({trend_active_orders:0})
    //   querySnapshot.docChanges().forEach((doc) => {
    //   this.setState({trend_active_orders:querySnapshot.size})
    //   });
    // });
    // trend_position_order.onSnapshot((querySnapshot) => {
    //   this.setState({trend_filled_orders:0})
    //   querySnapshot.docChanges().forEach((doc) => {
    //   this.setState({trend_filled_orders:querySnapshot.size})
      
    //   });
    // });

  }
    
  render() {
    return (
      <React.Fragment>
        <header id="page-topbar">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box">
                <Link to="/ccxt/dashboard" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={logo} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoDark} alt="" height="17" />
                  </span>
                </Link>

                <Link to="/ccxt/dashboard" className="logo logo-light">
                  <span className="logo-sm">
                    <img src={logoLightSvg} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoLightPng} alt="" height="29" />
                  </span>
                </Link>
              </div>

              <button
                type="button"
                onClick={this.toggleMenu}
                className="btn btn-sm px-3 font-size-16 header-item"
                id="vertical-menu-btn"
              >
                <i className="fa fa-fw fa-bars"></i>
              </button>

              {/* <form className="app-search d-none d-lg-block">
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={this.props.t("Search") + "..."}
                  />
                  <span className="bx bx-search-alt"></span>
                </div>
              </form> */}

                {/* web view */}
                {this.state.user_details.is_deltakey_updated ?
                <Dropdown
                className="dropdown-mega d-none d-lg-block ms-2"
                isOpen={this.state.megaMenuDrp}
                toggle={() => {
                  this.setState({ megaMenuDrp: !this.state.megaMenuDrp });
                }}
              >
               <div className="d-flex flex-row">
                
                <DropdownToggle className="btn header-item text-white bg-danger" caret tag="button">
                <span className="fw-bold">Index Price</span>
                  <span className="d-block"><MarketPrice /></span>
                </DropdownToggle>
                {/* <DropdownToggle className="btn header-item bg-soft text-white bg-warning" style={{minWidth:'130.11px'}} caret tag="button">
                  <span className="fw-bold">BTC Wallet : </span><span>{parseFloat(this.state.userBalance ? this.state.userBalance.btc_wallet : 0).toFixed(2)}</span><br></br>
                  <span className="fw-bold" style={{"marginRight":'9px'}}>BTC Avail : </span><span> {parseFloat(this.state.userBalance ? this.state.userBalance.btc_balance : 0).toFixed(2)}</span>
                </DropdownToggle>
                <DropdownToggle className="btn header-item bg-soft text-white bg-success" style={{minWidth:'137.69px'}} caret tag="button">                  
                  <span className="fw-bold">USDT Wallet : </span><span> {parseFloat(this.state.userBalance ? this.state.userBalance.usdt_wallet : 0).toFixed(2)}</span><br></br>
                  <span className="fw-bold" style={{"marginRight":'10px'}}>USDT Avail : </span><span> {parseFloat(this.state.userBalance ? this.state.userBalance.usdt_balance : 0).toFixed(2)}</span>
                </DropdownToggle> 
                <DropdownToggle className="btn header-item bg-soft text-white bg-primary" style={{minWidth:'127.78px'}} caret tag="button">                  
                  <span className="fw-bold">ETH Wallet : </span><span>  {parseFloat(this.state.userBalance ? this.state.userBalance.eth_wallet : 0).toFixed(2)}</span><br></br>
                  <span className="fw-bold" style={{"marginRight":'9px'}}>ETH Avail : </span><span> {parseFloat(this.state.userBalance ? this.state.userBalance.eth_balance : 0).toFixed(2)}</span>
                </DropdownToggle> */}
                <DropdownToggle className="btn header-item text-white bg-warning" caret tag="button">
                <span className="fw-bold">Strangles</span><br></br>
                  {/* {this.props.t("Strangles")}<br></br> */}
                  <span>Open : {this.state.active_orders ? this.state.active_orders : 0}</span>
                  <span style={{'marginLeft':'1px'}}> Position : {this.state.filled_orders ? this.state.filled_orders : 0}</span>
                </DropdownToggle>
                <DropdownToggle className="btn header-item text-white bg-success" caret tag="button">
                  <span className="fw-bold">Diagonals</span><br></br>                  
                  <span>Open : {this.state.dia_active_orders ? this.state.dia_active_orders : 0}</span>
                  <span style={{'marginLeft':'1px'}}> Position : {this.state.dia_filled_orders ? this.state.dia_filled_orders : 0}</span>
                </DropdownToggle>
                <DropdownToggle className="btn header-item bg-soft text-white bg-black" caret tag="button">
                  <span className="fw-bold">Straddles</span><br></br>                  
                  <span>Open : {this.state.straddle_active_orders ? this.state.straddle_active_orders : 0}</span>
                  <span style={{'marginLeft':'1px'}}> Position : {this.state.straddle_filled_orders ? this.state.straddle_filled_orders : 0}</span>
                </DropdownToggle>
                <DropdownToggle className="btn header-item bg-soft text-white bg-colored" caret tag="button">
                  <span className="fw-bold">Trend Following</span><br></br>                  
                  <span>Open : {this.state.trend_active_orders ? this.state.trend_active_orders : 0}</span>
                  <span style={{'marginLeft':'1px'}}> Position : {this.state.trend_filled_orders ? this.state.trend_filled_orders : 0}</span>
                </DropdownToggle>
                </div>
              </Dropdown> 
              :''}

                {/* mobile view */}
               {/* <Dropdown
                className="dropdown-mega d-inline-block d-lg-none ms-2"
                isOpen={this.state.megaMenuDrp}
                toggle={() => {
                  this.setState({ megaMenuDrp: !this.state.megaMenuDrp });
                }}
              >
                <DropdownToggle className="btn header-item" caret tag="button">
                  {" "}
                  {this.props.t("Mega Menu")}{" "}
                  <i className="mdi mdi-chevron-down"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-megamenu">
                  <Row>
                    <Col sm={8}>
                      <Row>
                        <Col md={4}>
                          <h5 className="font-size-14 mt-0">
                            {this.props.t("UI Components")}
                          </h5>
                          <ul className="list-unstyled megamenu-list">
                            <li>
                              <Link to="#">{this.props.t("Lightbox")}</Link>
                            </li>
                            <li>
                              <Link to="#">{this.props.t("Range Slider")}</Link>
                            </li>
                            <li>
                              <Link to="#">{this.props.t("Sweet Alert")}</Link>
                            </li>
                            <li>
                              <Link to="#">{this.props.t("Rating")}</Link>
                            </li>
                            <li>
                              <Link to="#">{this.props.t("Forms")}</Link>
                            </li>
                            <li>
                              <Link to="#">{this.props.t("Tables")}</Link>
                            </li>
                            <li>
                              <Link to="#">{this.props.t("Charts")}</Link>
                            </li>
                          </ul>
                        </Col>                        
                      </Row>
                    </Col>                    
                  </Row>                   
                </DropdownMenu>
              </Dropdown>  */}
            </div>
            <div className="d-flex flex-row">
              {/* <div className="dropdown d-inline-block d-lg-none ms-2">
                <button
                  onClick={() => {
                    this.setState({ isSearch: !this.state.isSearch });
                  }}
                  type="button"
                  className="btn header-item noti-icon"
                  id="page-header-search-dropdown"
                >
                  <i className="mdi mdi-magnify"></i>
                </button>
                <div
                  className={
                    this.state.isSearch
                      ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                      : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                  }
                  aria-labelledby="page-header-search-dropdown"
                >
                  <form className="p-3">
                    <div className="form-group m-0">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search ..."
                          aria-label="Recipient's username"
                        />
                        <div className="input-group-append">
                          <button className="btn btn-primary" type="submit">
                            <i className="mdi mdi-magnify"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div> */}

              {/* <LanguageDropdown /> */}

              {/* <Dropdown
                className="d-none d-lg-inline-block ms-1"
                isOpen={this.state.socialDrp}
                toggle={() => {
                  this.setState({ socialDrp: !this.state.socialDrp });
                }}
              >
                <DropdownToggle
                  className="btn header-item noti-icon"
                  tag="button"
                >
                  <i className="bx bx-customize"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-lg dropdown-menu-end">
                  <div className="px-lg-2">
                    <Row className="no-gutters">
                      <Col>
                        <Link className="dropdown-icon-item" to="#">
                          <img src={github} alt="Github" />
                          <span>GitHub</span>
                        </Link>
                      </Col>
                      <Col>
                        <Link className="dropdown-icon-item" to="#">
                          <img src={bitbucket} alt="bitbucket" />
                          <span>Bitbucket</span>
                        </Link>
                      </Col>
                      <Col>
                        <Link className="dropdown-icon-item" to="#">
                          <img src={dribbble} alt="dribbble" />
                          <span>Dribbble</span>
                        </Link>
                      </Col>
                    </Row>

                    <Row className="no-gutters">
                      <Col>
                        <Link className="dropdown-icon-item" to="#">
                          <img src={dropbox} alt="dropbox" />
                          <span>Dropbox</span>
                        </Link>
                      </Col>
                      <Col>
                        <Link className="dropdown-icon-item" to="#">
                          <img src={mail_chimp} alt="mail_chimp" />
                          <span>Mail Chimp</span>
                        </Link>
                      </Col>
                      <Col>
                        <Link className="dropdown-icon-item" to="#">
                          <img src={slack} alt="slack" />
                          <span>Slack</span>
                        </Link>
                      </Col>
                    </Row>
                  </div>
                </DropdownMenu>
              </Dropdown> */}
              <div className="d-flex flex-row">
              <div className="dropdown d-lg-none d-xl-block ms-1">              
              <Link className="btn btn-success" to="/ccxt/dashboard">
                   <i className="mdi mdi-swap-horizontal"></i>
                  {/* Switch to Spot */}
            </Link>
                <button
                  type="button"
                  onClick={this.toggleFullscreen}
                  className="btn header-item noti-icon"
                  data-toggle="fullscreen"
                >
                  <i className="bx bx-fullscreen"></i>
                </button>
              </div>
              <label className=""  htmlFor="flexSwitchCheckDefault" role="button" style={{fontWeight:'normal',width:"1px",paddingTop:"23px"}}>{this.props.layoutModeType === layoutModeTypes.DARK ? <i  className="bx bx-sun " style={{fontSize: "22px"}}/> : <i className="bx bx-moon " style={{fontSize: "22px",top:"5px"}}/>}{" "}</label>
              <input
                    type="radio"
                    className="invisible"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      fontSize: 12,
                      color: "white",
                      backgroundColor: "#536BDF",
                      paddingRight: 2,
                      width:"20px"
                      // ,height:"20px",paddingTop:"20px"
                    }}
                    id="flexSwitchCheckDefault"
                    name="radioDark"
                    value={this.props.layoutModeType === layoutModeTypes.DARK || this.state.layoutModeType === layoutModeTypes.DARK ? layoutModeTypes.LIGHT : layoutModeTypes.DARK}
                    onClick={(e)=> {UpdateProfileData(e.target.value)}}
                    checked={this.props.layoutModeType }
                    onChange={this.changeLayoutMode}
                  />
              {/* <NotificationDropdown /> */}
              <ProfileMenu /> 

              {/* <div className="dropdown d-inline-block">
                <button
                  onClick={() => {
                    this.toggleRightbar();
                  }}
                  type="button"
                  className="btn header-item noti-icon right-bar-toggle"
                >
                  <i className="bx bx-cog bx-spin"></i>
                </button>
              </div> */}
              </div>
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

Header.propTypes = {
  t: PropTypes.any,
  toggleMenuCallback: PropTypes.any,
  showRightSidebar: PropTypes.any,
  layoutModeType : PropTypes.any,
  changeLayoutMode : PropTypes.func,
  toggleRightSidebar: PropTypes.func,
};

const mapStatetoProps = state => {
  const { layoutType,layoutModeType, showRightSidebar } = state.Layout;
  return { layoutType,layoutModeType, showRightSidebar };
};

export default connect(mapStatetoProps, { toggleRightSidebar,changeLayoutMode })(
  withTranslation()(Header)
);
