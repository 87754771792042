import Breadcrumbs from 'components/Common/Breadcrumb'
import React, { useEffect, useState } from 'react'
import { Alert, Button, Card, CardBody, CardTitle, Col, Container, Form, FormGroup, Label, Modal, ModalBody, ModalHeader, Row, Spinner, Table, UncontrolledTooltip } from 'reactstrap'
import api from '../../../helpers/api_helper';
import Select from "react-select"
import { toast } from 'react-toastify';
import { getAllUserExchangePairs, getUserExchangePairs } from 'helpers/ccxt_helper';
import { getExchangeName, getSettingsDateTimeFormat, trendFormValidation } from 'services/common.service';
import firebase from "firebase/compat/app";
import Switch from "react-switch";
import Flatpickr from "react-flatpickr";
import TradingView from '../TradingView/TradingView';
import axios from 'axios';


function Trade(props) {
    // document.title = "Spot Trade";
    const firestoreDb = firebase.firestore();
    // const currentUser = JSON.parse(localStorage.getItem('user'));
    let uid = props.uid;
    const [viewPair, setviewPair] = useState("All pairs");
    const [addPairVisible, setAddPairVisible] = useState(false);
    const [ccxtUserExchanges, setCCXTUserExchanges] = useState([]);
    const [pairErrors, setPairErrors] = useState({
        exchange_error: '',
        pair_error: '',
    });
    const [spotPairs, setSpotPairs] = useState([]);
    const [pairExchange, setPairExchange] = useState('');
    const [SelectedSpotPair, setSelectedSpotPair] = useState({});
    const [formLoader8, setFormLoader8] = useState(false);
    const [formLoader6, setFormLoader6] = useState(false);
    const [form6_loader, setForm6Loader] = useState({ loading: false });
    const [pairListLoading, setPairListLoading] = useState(true);
    const [isDeleteconfirmpopup, setisDeleteconfirmpopup] = useState(false);
    const [PairId, setPairId] = useState();
    const [ShowWarning, setShowWarning] = useState(false);
    const [loader, setloader] = useState(false);
    const [pairsList, setPairsList] = useState([]);
    const [ccxtUserBalances, setCCXTUserBalances] = useState({});
    const [copiedSettings, setCopiedSettings] = useState('');
    const [settingsvisible, setSettingsvisible] = useState(false);
    const [settingspair, setSettingspair] = useState('Trading Pair')
    const [pairOrderSettings, setPairOrderSettings] = useState({ stop_loss_type: "percentage" });
    const [TrendFollowSettings, setTrendFollowSettings] = useState(false);
    const [Trendfollowpair, setTrendFollowpair] = useState('Trading Pair')
    const [DefaultColorSetting, setDefaultColorSetting] = useState({})
    const [pasteSettingsModal, setPasteSettingsModal] = useState(false)
    const [indexPrices, setIndexPrices] = useState({});
    const [pastePair, setPastePair] = useState({})
    const [ToolTipData, setToolTipData] = useState([]);
    const [form7_loader, setForm7Loader] = useState({ loading: false });
    const [ExchnageId, setExchnageId] = useState();
    const [Pair, setPair] = useState();
    // const [pairStatus, setPairStatus] = useState({});
    // const [pairTrendFollow, setPairTrendFollow] = useState({});
    const [formLoader7, setFormLoader7] = useState({});
    const apiUrl = `${process.env.REACT_APP_API_URL}/static/helptexts.json`;
    const [balance_errors, setBalanceErrors] = useState([]);
    const [tradeStatus, setTradeStatus] = useState("2");
    const [balanceLoading, setBalanceLoading] = useState(false);
    const [balanceLoad, setBalanceLoad] = useState(false);
    const [settingsLoad, setSettingsLoad] = useState(false);



    const [trendSettings, setTrendSettings] = useState({
        trend_method: "sma",
        short_term: 9,
        long_term: 20,
        time_frame: "15m",
        st_time_frame: "15m",
        macd_time_frame: "15m",
        ema_time_frame: "15m",
        macd_long_term: 26,
        macd_short_term: 12,
        macd_signal_term: 9,
        ema_short_term: 9,
        ema_long_term: 21,
        st_atr_length: 10,
        st_factor: 2,
        trading_type: "short_term"
    })

    // const getSettings = async () => {
    //     try {
    //         await api({ contentType: true, auth: true }).get('/user/profile')
    //             .then(res => {
    //                 if (res.data.response && res.data.response.user && res.data.response.user.trade_status) {
    //                     setTradeStatus(res.data.response.user.trade_status);
    //                     setTradeMode(res.data.response.trademode);

    //                 } else {
    //                     setTradeStatus(0);
    //                 }
    //             });

    //     } catch (e) {
    //         setTradeStatus(0);
    //         handleError(e);
    //     }
    // }


    // useEffect(() => {
    //     getSettings();
    // }, [])



    useEffect(() => {
        // if (tradeStatus == 1) {
        getuserExchanges();
        // } else {
        //     setPairsList([]);
        //     setCCXTUserExchanges([])
        //     setPairListLoading(false)
        // }
    }, [])


    useEffect(() => {

        if (balanceLoad) {
            // console.log("called");
            getuserExchanges(false);
        }

    }, [viewPair])



    useEffect(() => {
        try {
            const gettoottipData = async () => {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/static/helptexts.json`)
                setToolTipData(JSON.parse(JSON.stringify(response.data)))
            }
            gettoottipData()
        } catch (error) {
            console.log(error)
        }

    }, [apiUrl])




    const getuserExchanges = async (load = true) => {

        setPairListLoading(true);

        if (viewPair == "All pairs") {
            getAllUserExchangePairs().then((res) => {
                if (res.data) {
                    let markets = res.data.response.data;
                    setPairsList(markets);
                    setCCXTUserExchanges(res.data.response.all_config)
                    setPairListLoading(false);
                    if (load) {
                        setBalanceLoading(true);
                        setupExchangesBalance()
                    }
                }
            }).catch(e => {
                setPairsList([])
                setPairListLoading(false);
                setBalanceLoading(false);
                handleError(e)
            });
        } else {
            getUserExchangePairs(viewPair).then((res) => {
                if (res.data) {
                    let markets = res.data.response.data;
                    setPairsList(markets);
                    setPairListLoading(false);
                    if (load) {
                        setBalanceLoading(true);
                        setupExchangesBalance()
                    }
                }
            }).catch(e => {
                setPairsList([])
                setPairListLoading(false);
                setBalanceLoading(false);
                handleError(e)
            });
        }
    }


    const setupExchangesBalance = async () => {
        await api({ contentType: true, auth: true }).post('/ccxt/get-all-exchanges-pairs', { "only_balance": true }).then(async (res) => {
            if (res.data.httpCode && res.data.httpCode.message == "No exchange configs found") {
                setBalanceLoading(false);
                setCCXTUserBalances({});
                setIndexPrices({});
                return;
            }
            let balance = res.data.response.balance;
            let indexinfo = res.data.response.indexinfo;
            setIndexPrices(indexinfo);
            setCCXTUserBalances(balance);
            setBalanceLoading(false);
            get_user_all_pairs_balance_error();
        }).catch(e => {
            setBalanceLoading(false);
            setCCXTUserBalances({});
            setIndexPrices({});
            // throw e
        });
    }

    const get_user_all_pairs_balance_error = async () => {
        try {
            let balance_errors = []
            const balance_error_symbols = [];
            var dat = pairsList;
            if (dat !== null) {
                for (let i = 0; i < dat.length; i++) {
                    var data = dat[i].data;
                    let data_settings = data.settings ? JSON.parse(data.settings) : ''
                    if (data_settings && data_settings.quantity_type !== 'balance_per' && data_settings.quantity_type !== 'balance_per_base' && data_settings.quantity > 0 && (data.status == '1' || data.status == 'true')) {
                        let balance = ccxtUserBalances[data.exchange_id]

                        // let base_balance = balance[data.base]['free']
                        let quote_balance = balance[data.quote] ? balance[data.quote]['free'] : undefined;

                        if (!quote_balance) {
                            balance_error_symbols.push(data.exchange_id + data.quote)
                            balance_errors.push(getExchangeName(data.exchange_id) + " - " + data.quote + " available balance is " + quote_balance + "  which is below the required amount of " + indexPrices[data.id] * data_settings.quantity + " " + data.quote)
                            setBalanceErrors(balance_errors)
                            continue;
                        }

                        if (indexPrices[data.id] * data_settings.quantity > quote_balance && !balance_error_symbols.includes(data.exchange_id + data.quote)) {
                            balance_error_symbols.push(data.exchange_id + data.quote)
                            balance_errors.push(getExchangeName(data.exchange_id) + " - " + data.quote + " available balance is " + quote_balance + "  which is below the required amount of " + indexPrices[data.id] * data_settings.quantity + " " + data.quote)
                            setBalanceErrors(balance_errors)
                        }
                    } else {
                        let balance = ccxtUserBalances[data.exchange_id]

                        let quote_balance = balance[data.quote] ? balance[data.quote]['free'] : undefined;
                        if (!quote_balance) {
                            balance_error_symbols.push(data.exchange_id + data.quote)
                            balance_errors.push(getExchangeName(data.exchange_id) + " - " + data.quote + " don't have balance to place orders")
                            setBalanceErrors(balance_errors)
                            continue;
                        }

                        // let quote_balance = balance[data.quote]['free']
                        // let base_balance = balance[data.base]['free']

                        if (data_settings.quantity_type == 'balance_per' && quote_balance <= 0 && data_settings.quantity > 0 && (data.status == '1' || data.status == 'true')) {

                            if (!balance_error_symbols.includes(data.exchange_id + data.quote)) {
                                balance_error_symbols.push(data.exchange_id + data.quote)
                                balance_errors.push(getExchangeName(data.exchange_id) + " - " + data.quote + " don't have balance to place orders")
                            }
                            setBalanceErrors(balance_errors)
                        }
                        if (data_settings.quantity_type == 'balance_per_base' && quote_balance <= 0 && data_settings.quantity > 0 && (data.status == '1' || data.status == 'true')) {

                            if (!balance_error_symbols.includes(data.exchange_id + data.quote)) {
                                balance_error_symbols.push(data.exchange_id + data.quote)

                                balance_errors.push(getExchangeName(data.exchange_id) + " - " + data.quote + " don't have balance to place orders")
                            }
                            setBalanceErrors(balance_errors)
                        }
                    }
                }
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    const handleTrendSettingsChange = (e) => {
        setTrendSettings({
            ...trendSettings,
            [e.target.name]: e.target.value
        })
        trendSettings[e.target.name] = e.target.value;
        if (e.target.name == "trading_type" || e.target.name == "trend_method") {
            //need to change the default values for trendSettings

            let jsonData = [
                {
                    "trading_type": "short_term",
                    "TIMEFRAME": "15m",
                    "EMA": "9,21",
                    "SMA": "9,20",
                    "SUPERTREND": "10,2",
                    "MACD": "12,26,9"
                },
                {
                    "trading_type": "medium_term",
                    "TIMEFRAME": "4h",
                    "EMA": "20,50",
                    "SMA": "21,55",
                    "SUPERTREND": "20,3",
                    "MACD": "12,26,9"
                },
                {
                    "trading_type": "long_term",
                    "TIMEFRAME": "1d",
                    "EMA": "50,200",
                    "SMA": "50,200",
                    "SUPERTREND": "30,9",
                    "MACD": "24,52,18"
                }
            ]
            const termData = jsonData.find(item => item.trading_type === trendSettings.trading_type);
            if (trendSettings.trend_method == "sma") {
                trendSettings.short_term = termData.SMA.split(',')[0]
                trendSettings.long_term = termData.SMA.split(',')[1]
                trendSettings.time_frame = termData.TIMEFRAME
            } else if (trendSettings.trend_method == "ema") {
                trendSettings.ema_short_term = termData.EMA.split(',')[0]
                trendSettings.ema_long_term = termData.EMA.split(',')[1]
                trendSettings.ema_time_frame = termData.TIMEFRAME

            } else if (trendSettings.trend_method == "supertrend") {
                trendSettings.st_atr_length = termData.SUPERTREND.split(',')[0]
                trendSettings.st_factor = termData.SUPERTREND.split(',')[1]
                trendSettings.st_time_frame = termData.TIMEFRAME

            } else if (trendSettings.trend_method == "macd") {
                trendSettings.macd_short_term = termData.MACD.split(',')[0]
                trendSettings.macd_long_term = termData.MACD.split(',')[1]
                trendSettings.macd_signal = termData.MACD.split(',')[2]
                trendSettings.macd_time_frame = termData.TIMEFRAME
            }
        }
        setTrendSettings(trendSettings)
        setDefaultColorSetting({
            ...DefaultColorSetting,
            [e.target.name]: e.target.value
        })
    }


    const handleError = (err) => {
        if (err && err.response && err.response.data && err.response.data.message) {
            toast.error(err.response.data.message, { toastId: err.response.data.message });
        } else if (err.message) {
            toast.error(err.message, { toastId: err.message });
        } else {
            toast.error(err, { toastId: err });
        }
    }

    const CreateOrder = async (exchangedata, index, Side) => {
        try {

            let pairdata = exchangedata.data;
            let pairOrderSettings = pairdata.settings ? JSON.parse(pairdata.settings) : {};
            pairdata['side'] = Side;
            pairdata['trading_pair_db_id'] = exchangedata.id;
            pairdata['signal_data'] = exchangedata.signal_data;
            pairdata['signal_data']['trend_signal_id'] = exchangedata.trend_signal_id
            let balance = ccxtUserBalances[pairdata.exchange_id];
            let base_balance = balance[pairdata.base] && balance[pairdata.base]['free'] ? balance[pairdata.base]['free'] : 0
            let quote_balance = balance[pairdata.quote] && balance[pairdata.quote]['free'] ? balance[pairdata.quote]['free'] : 0
            if (pairOrderSettings.quantity_type !== "balance_per" && pairOrderSettings.quantity_type !== "balance_per_base") {
                if (pairOrderSettings.quantity > base_balance && Side == 'sell') {
                    toast.error("Insufficient balance on your base currency " + pairdata.base)
                    setForm7Loader({
                        loading: false,
                    });
                    return false
                }
                if (indexPrices[pairdata.id] * pairOrderSettings.quantity > quote_balance && Side == 'buy') {

                    toast.error("Insufficient balance on your quote currency " + pairdata.quote)
                    setForm7Loader({
                        loading: false,
                    });
                    return false
                }
            }
            // return false
            setFormLoader7({ ...formLoader7, [index]: true });
            await api({ contentType: true, auth: true }).post('/ccxt/create-order', pairdata).then(async (res) => {
                if (res.status === 200) {
                    toast.success("Order created successfully");
                    setFormLoader7({
                        ...formLoader7,
                        [index]: false,
                    })
                }
            }).catch((err) => {
                handleError(err);
                setFormLoader7({
                    ...formLoader7,
                    [index]: false,
                })
            })
        } catch (e) {
            console.log(e);
            handleError(e);
            setFormLoader7({
                ...formLoader7,
                [index]: false,
            })
        }
    }

    const changePairStatus = async (settingspair, exchange_id, pair_id, statusval) => {

        try {
            if (statusval == true) {
                let pairOrderSettings = settingspair.settings ? JSON.parse(settingspair.settings) : {};
                if (typeof pairOrderSettings.quantity == "undefined") {
                    toast.warning("Please add your order settings to initiate trading")
                }
                let balance = ccxtUserBalances[exchange_id];
                if (!balance) {
                    toast.error("Balance not found");
                    return;
                }
                let base_balance = balance[settingspair.base] ? balance[settingspair.base]['free'] : "0"
                let quote_balance = balance[settingspair.quote] ? balance[settingspair.quote]['free'] : "0"
                if (pairOrderSettings.quantity > base_balance && pairOrderSettings.quantity_type !== "balance_per" && pairOrderSettings.quantity_type !== "balance_per_base") {
                    toast.warning("Insufficient balance on your base currency " + settingspair.base)
                    setForm7Loader({
                        loading: false,
                    });
                }
                if (indexPrices[settingspair.id] * pairOrderSettings.quantity > quote_balance && pairOrderSettings.quantity_type !== "balance_per" && pairOrderSettings.quantity_type !== "balance_per_base") {
                    toast.warning("Insufficient balance on your quote currency " + settingspair.quote)
                    setForm7Loader({
                        loading: false,
                    });
                }
                if (pairOrderSettings.quantity_type == "balance_per" && quote_balance == 0) {
                    toast.warning("Please note you don't have balance on your quote currency " + settingspair.quote)
                    setForm7Loader({
                        loading: false,
                    });
                }
                if (pairOrderSettings.quantity_type == "balance_per_base" && base_balance == 0) {
                    toast.warning("Please note you don't have balance on your base currency " + settingspair.base)
                    setForm7Loader({
                        loading: false,
                    });
                }
            }
            // setPairStatus({ ...pairStatus, [pair_id]: statusval });
            let bodyParams = {
                "exchange_id": exchange_id,
                "symbol_id": pair_id,
                "status": statusval
            }
            await api({ contentType: true, auth: true }).post('/ccxt/update-exchange-pair', bodyParams).then(async (res) => {
                // var exchangeid = document.getElementById('select_exchange_pair').value;
                // await getSeparetePairsList(exchangeid,"no_load");
                setPairsList((prev) =>
                    prev.map(pair =>
                        pair.data.exchange_id == exchange_id && pair.data.id === pair_id
                            ? { ...pair, data: { ...pair.data, status: statusval } }
                            : pair
                    )
                );

                toast.success("Pair status updated successfully");
            }).catch((err) => {
                handleError(err);
            })
        } catch (e) {
            handleError(e);
        }
    }

    // const handlePairOrderSettingsChange = (e) => {
    //     setPairOrderSettings({
    //         ...pairOrderSettings,
    //         [e.target.name]: e.target.value
    //     })


    // }

    const handlePairOrderSettingsChange = (e) => {
        const { name, value, type, checked } = e.target;
        const numValue = parseInt(value, 10); // Convert value to a number if needed

        setPairOrderSettings((prevSettings) => {
            if (type === "checkbox") {
                const currentValues = prevSettings[name] || []; // Get existing array or default to empty

                return {
                    ...prevSettings,
                    [name]: checked
                        ? [...currentValues, numValue] // Add value if checked
                        : currentValues.filter((item) => item !== numValue), // Remove if unchecked
                };
            } else {
                return {
                    ...prevSettings,
                    [name]: value, // Handle other input types (text, select, etc.)
                };
            }
        });
    };

    const DeleteExchangePairs = async (exchange_id, symbol) => {
        setloader(true);
        try {
            var ActiveOrderListcount = await validateActiveOrders(Pair, exchange_id);
            if (ActiveOrderListcount === true) {
                setloader(false)
                return false
            } else if (ActiveOrderListcount === false) {
                var bodyParams = { exchange_id: exchange_id, symbol_id: symbol }
                await api({ contentType: true, auth: true }).post('/ccxt/Delete-single-pairs', bodyParams).then(async (res) => {
                    let response = res;
                    if (response.status !== 200) {
                        toast.error("Something went wrong");
                    } else {
                        //   var exchangeid = document.getElementById('select_exchange_pair').value;
                        //   await getSeparetePairsList(exchangeid,"no_load");
                        await getuserExchanges(false);
                        setloader(false)
                        setisDeleteconfirmpopup(false)
                        toast.success("Pair deleted successfully");
                    }
                });
            }
        } catch (e) {
            console.log(e, "errrr");
            setloader(false)
            setisDeleteconfirmpopup(false)
            handleError(e);
        }
    }

    const addNewPair = async () => {
        setFormLoader6(true);
        if (checkValidation()) {
            let bodyParams = {
                "exchange_id": pairExchange,
                "symbol_id": SelectedSpotPair?.value,
                "is_add_pair": 1,
                "symbol": SelectedSpotPair?.value
            }
            await api({ contentType: true, auth: true }).post('/ccxt/add-ccxt-pairs', bodyParams).then(async (res) => {

                // let indexinfo = indexPrices;
                // await api({ contentType: true, auth: true }).post('/ccxt/getTicker', bodyParams).then((res) => {
                //     let ticker = res.data.response;
                //     let current_index_price = ticker.close ? ticker.close : ticker.info.spot_price ? ticker.info.spot_price : '';
                //     indexinfo[bodyParams.symbol_id] = Number(current_index_price)
                //     setIndexPrices(indexinfo);
                // });
                // setFormLoader6(false);
                // setAddPairVisible(false)
                setPairExchange("");
                setPairListLoading(true)
                // // await setupCCXTUserExchanges();
                // if (viewPair == "SelectExchange") {
                //     await getAllUserExchangePairs().then((res) => {
                //         if (res.data) {
                //             let markets = res.data.response.data;
                //             setSeparatePairs(markets);
                //             setTimeout(() => {
                //                 setPairListLoading(false);
                //             }, 2000)
                //         }
                //     })
                // } else {
                //     await getUserExchangePairs(viewPair).then((res) => {
                //         if (res.data) {
                //             let markets = res.data.response.data;
                //             setSeparatePairs(markets);
                //             setTimeout(() => {
                //                 setPairListLoading(false);
                //             }, 2000)
                //         }
                //     })
                // }
                toast.success("Pair added successfully");
                setFormLoader6(false);
                setAddPairVisible(false)
                getuserExchanges();

            }).catch((err) => {
                handleError(err);
                setFormLoader6(false);
                setAddPairVisible(false)
                // setPairListLoading(false);
            })
        } else {
            setFormLoader6(false);
        }
    }

    const handleOrderInterval = (e, name) => {
        var time = e[0]
        var hours = time.getHours();
        var minutes = time.getMinutes();
        var H_M = toSeconds(hours, minutes, 0);
        setPairOrderSettings({
            ...pairOrderSettings,
            [name]: H_M
        })

    }

    const toSeconds = (hours, minutes, seconds) => {
        return hours * 3600 + minutes * 60 + seconds;
    }

    const updatePairOrderSettings = async () => {

        try {
            // setForm7Loader({
            //     loading: true,
            // });
            if (!pairOrderSettings.fill_type || pairOrderSettings.fill_type == '') {
                toast.error("Please select fill type")
                setForm7Loader({
                    loading: false,
                });
                return false
            }
            if (pairOrderSettings.fill_type == "wait_and_fill" && (!pairOrderSettings.price_distance_percentage || pairOrderSettings.price_distance_percentage == "")) {
                toast.error("Please enter price distance % for wait and fill")
                setForm7Loader({
                    loading: false,
                });
                return false
            }
            if (!pairOrderSettings.quantity || pairOrderSettings.quantity == "") {
                toast.error("Please enter quantity")
                setForm7Loader({
                    loading: false,
                });
                return false
            }


            let balance = ccxtUserBalances[settingspair.exchange_id];
            if (balance && balance[settingspair.base] && balance[settingspair.quote]) {
                let base_balance = balance[settingspair.base]['free']
                let quote_balance = balance[settingspair.quote]['free']
                if (pairOrderSettings.quantity > base_balance && pairOrderSettings.quantity_type !== "balance_per" && pairOrderSettings.quantity_type !== "balance_per_base") {
                    toast.warning("Please note you have insufficient balance on your base currency " + settingspair.base)
                    setForm7Loader({
                        loading: false,
                    });
                    //return false
                }
                if (pairOrderSettings.quantity_type == "balance_per" && quote_balance == 0) {
                    toast.warning("Please note you don't have balance on your quote currency " + settingspair.quote)
                    setForm7Loader({
                        loading: false,
                    });
                }
                if (pairOrderSettings.quantity_type == "balance_per_base" && base_balance == 0) {
                    toast.warning("Please note you don't have balance on your base currency " + settingspair.base)
                    setForm7Loader({
                        loading: false,
                    });
                }
                if (indexPrices && indexPrices.length > 0 && indexPrices[settingspair.id] && indexPrices[settingspair.id] * pairOrderSettings.quantity > quote_balance && pairOrderSettings.quantity_type !== "balance_per" && pairOrderSettings.quantity_type !== "balance_per_base") {
                    toast.warning("Please note you have insufficient balance on your quote currency " + settingspair.quote)
                    setForm7Loader({
                        loading: false,
                    });
                    //return false
                }
            }

            let exchange_id = settingspair.exchange_id;
            let pair_id = settingspair.id;
            let bodyParams = pairOrderSettings;
            if (pairOrderSettings && pairOrderSettings.order_side && (pairOrderSettings.order_side == "sell" || pairOrderSettings.order_side == "buy")) {
                //remove unwanted setting from bodyParams
                delete bodyParams.min_take_profit_percentage;
                delete bodyParams.stop_loss;
                delete bodyParams.trailing_take_profit_percentage;
                delete bodyParams.waiting_time_for_next_order_stop_loss;
                delete bodyParams.waiting_time_for_next_order_trailing_profit;

            }
            bodyParams.pair_id = pair_id;
            bodyParams.exchange_id = exchange_id;

            await api({ contentType: true, auth: true }).post('/ccxt/update-pair-order-settings', bodyParams).then(async (res) => {
                if (res.status === 200) {

                    //   var exchangeid = document.getElementById('select_exchange_pair').value;
                    //   await getSeparetePairsList(exchangeid,"no_load");
                    toast.success("Settings updated");
                    setSettingsvisible(false)
                }
                setForm7Loader({
                    loading: false,
                });
                getuserExchanges(false);
                // pairSettings[pair_id].settings = bodyParams;
                // setPairSettings(pairSettings);
                // TrendFollowSettingspair[pair_id].settings = bodyParams;
                // setTrendFollowSettingspair(TrendFollowSettingspair)
            }).catch((err) => {
                throw err
            })

        }
        catch (error) {
            setForm7Loader({
                loading: false,
            });
            error.response && toast.error(error.response.data.message);

        }
    }


    const copySettings = (exchange_id, pair, trendsettings, ordersettings) => {
        let copiedSettingsval = {
            exchange_id: exchange_id,
            pair: pair,
            trendsettings: trendsettings ? JSON.parse(trendsettings) : '',
            ordersettings: ordersettings ? JSON.parse(ordersettings) : '',
        };
        setCopiedSettings(copiedSettingsval);
    };

    const handlePairExchange = async (e) => {
        setFormLoader8(true)
        setSpotPairs([]);
        setSelectedSpotPair({})
        let exchange_id = e.target.value
        if (exchange_id != "") {
            await api({ contentType: true, auth: true }).get('/ccxt/get-spot-symbols/' + exchange_id).then((res) => {
                setPairListLoading(false);
                //setSpotPairs
                const symbolsListResponse = res.data.response.symbols;
                if (symbolsListResponse.length) {
                    const result = symbolsListResponse.map(item => {
                        return {
                            label: item.id.replace('_', '/'),
                            value: item.id
                        }
                    })
                    setPairExchange(exchange_id)
                    setSpotPairs(result);
                    setFormLoader8(false)
                } else {
                    toast.error("No pairs Available");
                    setFormLoader8(false)
                    setPairExchange("");
                    setAddPairVisible(false)
                }
            }).catch((err) => {
                toast.error("No pairs Available");
                setFormLoader8(false)
                setPairExchange("");
                setAddPairVisible(false)
                handleError(err);
            })
        } else {
            setPairExchange("");
            setSpotPairs([]);
            setFormLoader8(false)
        }
    }

    const updateNewTrendSettings = async () => {
        try {


            trendSettings['display_text'] = Trendfollowpair.display_text
            trendSettings['exchange_id'] = Trendfollowpair.exchange_id
            let bodyParams = trendSettings;
            let errmsg = trendFormValidation(trendSettings)
            if (errmsg != "") {
                throw toast.error(errmsg)
            }
            await api({ contentType: true, auth: true }).post('/ccxt/update-trend-settings', bodyParams).then(async (res) => {
                setForm6Loader({
                    loading: false,
                });
                // var exchangeid = document.getElementById('select_exchange_pair').value;
                // await getSeparetePairsList(exchangeid,"no_load");
                toast.success("Trend following settings updated");
                setTrendFollowSettings(false)
                getuserExchanges(false);
            }).catch(err => {
                throw err
            })

        }
        catch (error) {
            setForm6Loader({
                loading: false,
            });

            error.response && error.response.data && error.response.data.message && toast.error(error.response.data.message);

        }
    }


    const handleSelect = (selectedList, selectedItem) => {
        setSelectedSpotPair(selectedList);
    }

    const checkValidation = () => {
        let valid = true;
        if (pairExchange == "" && (Object.keys(SelectedSpotPair).length == 0 || SelectedSpotPair.value != "")) {
            setPairErrors({ exchange_error: "Select Exchange", pair_error: "Select Pair" })
            valid = false
        } else if (pairExchange == "") {
            setPairErrors({ pair_error: "", exchange_error: "Select Exchange" })
            valid = false
        } else if (Object.keys(SelectedSpotPair).length == 0 || SelectedSpotPair.value == "") {
            setPairErrors({ exchange_error: "", pair_error: "Select Pair" })
            valid = false
        } else {
            setPairErrors({ exchange_error: "", pair_error: "" })
        }
        return valid;
    }

    function toHoursAndMinutes(totalSeconds) {
        const totalMinutes = Math.floor(totalSeconds / 60);

        const seconds = totalSeconds % 60;
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;

        return hours + ":" + minutes;
    }

    const validateActiveOrders = async (Symbol, exchange_id) => {
        try {
            const firestoreDbUser = firestoreDb.collection("users").doc(uid);
            const ordersRef = firestoreDbUser.collection("ccxt_orders").orderBy("created_at", "desc");
            const querySnapshot = await ordersRef.get(); // Use `get()` for a one-time fetch
            const items = [];
            querySnapshot.forEach((doc) => {
                let item = doc.data();
                if (item.symbol === Symbol && item.exchange_id === exchange_id) {
                    item.id = doc.id;
                    items.push(item);
                }
            });
            if (items.length == 0) {
                return false;
            } else {
                return true
            }
            // setOrderData(items); // Uncomment this if you want to update state
        } catch (error) {
            console.log(error);
            return false;
        }
    };

    const TrendFollowSettings1 = ({ trendSettingsInfo }) => {
        const trendSettings = JSON.parse(trendSettingsInfo.Trend_settings);
        // Helper function to format each setting
        const formatSetting = (label, value) => (
            <span>
                {label}: <b className="text-info">{value}</b>
            </span>
        );
        // Function to render settings based on the trend method
        const renderSettingsByMethod = (method) => {
            switch (method) {
                case 'sma':
                    return [
                        trendSettings.trend_method && formatSetting('Trend Method', 'SMA'),
                        trendSettings.time_frame && formatSetting('Interval', trendSettings.time_frame),
                        trendSettings.short_term && formatSetting('Short Length', trendSettings.short_term),
                        trendSettings.long_term && formatSetting('Long Length', trendSettings.long_term)
                    ].filter(Boolean); // Filter out any undefined or null values
                case 'ema':
                    return [
                        trendSettings.trend_method && formatSetting('Trend Method', 'EMA'),
                        trendSettings.ema_time_frame && formatSetting('Interval', trendSettings.ema_time_frame),
                        trendSettings.ema_short_term && formatSetting('Short Length', trendSettings.ema_short_term),
                        trendSettings.ema_long_term && formatSetting('Long Length', trendSettings.ema_long_term)
                    ].filter(Boolean); // Filter out any undefined or null values
                case 'macd':
                    return [
                        trendSettings.trend_method && formatSetting('Trend Method', 'MACD'),
                        trendSettings.macd_time_frame && formatSetting('Interval', trendSettings.macd_time_frame),
                        trendSettings.macd_short_term && formatSetting('MACD Short Length', trendSettings.macd_short_term),
                        trendSettings.macd_long_term && formatSetting('MACD Long Length', trendSettings.macd_long_term),
                        trendSettings.macd_signal_term && formatSetting('MACD Signal Length', trendSettings.macd_signal_term)
                    ].filter(Boolean); // Filter out any undefined or null values
                case 'supertrend':
                    return [
                        trendSettings.trend_method && formatSetting('Trend Method', 'Supertrend'),
                        trendSettings.st_time_frame && formatSetting('Interval', trendSettings.st_time_frame),
                        trendSettings.st_atr_length && formatSetting('ATR Length', trendSettings.st_atr_length),
                        trendSettings.st_factor && formatSetting('Factor', trendSettings.st_factor),
                        trendSettings.st_min_distance && trendSettings.st_min_distance.trim() !== '' && formatSetting('Minimum Supertrend Distance', trendSettings.st_min_distance),
                        trendSettings.st_max_distance && trendSettings.st_max_distance.trim() !== '' && formatSetting('Maximum Supertrend Distance', trendSettings.st_max_distance)
                    ].filter(Boolean); // Filter out any undefined or null values
                default:
                    return [];
            }
        };

        // Render the settings based on the trend method in the data
        const settingsToDisplay = renderSettingsByMethod(trendSettings.trend_method);
        // Helper function to split settings into chunks
        const orderSettingArray = (arr, size) => {
            const result = [];
            for (let i = 0; i < arr.length; i += size) {
                result.push(arr.slice(i, i + size));
            }
            return result;
        };
        const orderSettings = orderSettingArray(settingsToDisplay, 5);
        return orderSettings.length > 0 ? (
            orderSettings.map((order, index) => (
                <p key={index} className="text-muted text-secondary font-size-11 mb-1">
                    {order.map((setting, i) => (
                        <span key={i}>
                            {setting}
                            {i < order.length - 1 ? ' | ' : ''}
                        </span>
                    ))}
                </p>
            ))
        ) : (
            ''
        );
    };

    const timeFormat = (seconds) => {
        var totalNumberOfSeconds = seconds;
        var hours = parseInt(totalNumberOfSeconds / 3600);
        var minutes = parseInt((totalNumberOfSeconds - (hours * 3600)) / 60);
        var seconds = Math.floor((totalNumberOfSeconds - ((hours * 3600) + (minutes * 60))));
        if (seconds > 0) {
            var result = (hours < 10 ? "0" + hours : hours) + "h " + (minutes < 10 ? "0" + minutes : minutes) + "m " + (seconds < 10 ? "0" + seconds : seconds) + "s";
        } else {
            var result = (hours < 10 ? "0" + hours : hours) + "h " + (minutes < 10 ? "0" + minutes : minutes) + "m";
        }
        return result;
    }

    const arrangeSettingsArray = (settinginfo) => {
        const formatSetting = (label, value) => (
            <span>
                {label}: <b className="text-info">{value}</b>
            </span>
        );

        const settingsArray = [
            settinginfo && settinginfo.quantity && formatSetting('Quantity', `${(settinginfo.quantity || settinginfo.qty)}${settinginfo.quantity_type == "balance_per" || settinginfo.quantity_type == "balance_per_base" ? "% of margin" : ""}`),
            settinginfo && settinginfo.fill_type && formatSetting('Fill type', settinginfo.fill_type === 'instant_fill' ? 'Instant Fill' : 'Wait and Fill'),
            settinginfo && settinginfo.price_distance_percentage && settinginfo.fill_type !== 'instant_fill' && formatSetting('Price Distance % for Wait and Fill', settinginfo.price_distance_percentage),
            settinginfo && settinginfo.stop_loss && formatSetting('Market Stop Loss', `${settinginfo.stop_loss}${settinginfo.stop_loss_type === "points" ? 'Pts' : "%"}`),
            settinginfo && settinginfo.min_take_profit_percentage && formatSetting('Minimum Take Profit%', settinginfo.min_take_profit_percentage),
            settinginfo && settinginfo.trailing_take_profit_percentage && formatSetting('Trailing Take Profit%', settinginfo.trailing_take_profit_percentage),
            settinginfo && settinginfo.waiting_time_for_next_order_stop_loss && formatSetting('Waiting Time for Order after Stop Loss', timeFormat(settinginfo.waiting_time_for_next_order_stop_loss)),
            settinginfo && settinginfo.waiting_time_for_next_order_trailing_profit && formatSetting('Waiting Time for Order after Trailing Take Profit', timeFormat(settinginfo.waiting_time_for_next_order_trailing_profit)),
            settinginfo && settinginfo.order_side && formatSetting('Order Side', settinginfo.order_side),
            settinginfo && settinginfo.order_side === "sell" && settinginfo.min_sell_price && formatSetting('Minimum Sell Price', settinginfo.min_sell_price),
            settinginfo && settinginfo.order_side === "buy" && settinginfo.max_buy_price && formatSetting('Maximum Buy Price', settinginfo.max_buy_price)
        ].filter(Boolean);


        const orderSettingArray = (arr, size) => {
            const result = [];
            for (let i = 0; i < arr.length; i += size) {
                result.push(arr.slice(i, i + size));
            }
            return result;
        };

        const orderSettings = orderSettingArray(settingsArray, 5);
        return orderSettings;
    }

    const Offsymbol = () => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >
                {" "}
                No
            </div>
        );
    };
    const OnSymbol = () => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >
                {" "}
                Yes
            </div>
        );
    };

    const pasteSettings = (exchange_id, pair, pair_id) => {
        if (copiedSettings) {
            setPastePair({
                exchange_id: exchange_id, pair_id: pair_id,
                pair: pair
            })
            setPasteSettingsModal(true)
        }
    }

    const pasteApply = async (pairinfo) => {
        try {
            if (copiedSettings.ordersettings) {
                let bodyParams = copiedSettings.ordersettings;
                bodyParams.pair_id = pairinfo.pair_id;
                bodyParams.exchange_id = pairinfo.exchange_id;

                await api({ contentType: true, auth: true }).post('/ccxt/update-pair-order-settings', bodyParams).then(async (res) => {
                    if (res.status === 200) {

                        // var exchangeid = document.getElementById('select_exchange_pair').value;
                        // await getSeparetePairsList(exchangeid, "no_load");
                    }
                }).catch(err => {
                    throw err
                })
            }
            if (copiedSettings.trendsettings) {
                let trendSettingsval = copiedSettings.trendsettings
                trendSettingsval['display_text'] = pairinfo.pair
                trendSettingsval['exchange_id'] = pairinfo.exchange_id
                await api({ contentType: true, auth: true }).post('/ccxt/update-trend-settings', trendSettingsval).then(async (res) => {
                    // var exchangeid = document.getElementById('select_exchange_pair').value;
                    // await getSeparetePairsList(exchangeid, "no_load");

                }).catch(err => {
                    throw err
                })
            }
            getuserExchanges(false);
            setPasteSettingsModal(false);
            toast.success("Settings updated successfully");
        } catch (err) {
            console.log("paste error", err)
            toast.error("There is something went wrong when applying to copied changes, try again later")
        }finally{
            setSettingsLoad(false);
        }
    }

    const renderTrendSettings = () => {
        const { trend_method, short_term, long_term, time_frame, st_time_frame, macd_time_frame, ema_time_frame, ema_short_term, ema_long_term, exchange_id, display_text, macd_short_term, macd_long_term, macd_signal_term, st_atr_length, st_factor, st_max_distance, st_min_distance } = copiedSettings.trendsettings;

        switch (trend_method) {
            case 'sma':
                return (
                    <>
                        <p>Trend Method: <b>SMA</b><br />
                            Short Term: <b>{short_term}</b><br />
                            Long Term: <b>{long_term}</b><br />
                            Time Frame: <b>{time_frame}</b></p>
                    </>
                );
            case 'ema':
                return (
                    <>
                        <p>Trend Method: <b>EMA</b><br />
                            Short Term: <b>{ema_short_term}</b><br />
                            Long Term: <b>{ema_long_term}</b><br />
                            Time Frame: <b>{ema_time_frame}</b></p>
                    </>
                )
            case 'macd':
                return (
                    <>
                        <p>Trend Method: <b>MACD</b><br />
                            MACD Short Term: <b>{macd_short_term}</b><br />
                            MACD Long Term: <b>{macd_long_term}</b><br />
                            MACD Signal Term: <b>{macd_signal_term}</b><br />
                            Time Frame: <b>{macd_time_frame}</b></p>
                    </>
                );
            case 'supertrend':
                return (
                    <>
                        <p>Trend Method: <b>Supertrend</b><br />
                            ATR length: <b>{st_atr_length}</b><br />
                            Factor: <b>{st_factor}</b><br />
                            Minimum supertrend distance:<b>{st_min_distance}</b><br />
                            Maximum supertrend distance:<b>{st_max_distance}</b><br />
                            Time Frame: <b>{st_time_frame}</b></p>
                    </>
                )
            // Add cases for other trend methods if any
            default:
                return <p>No valid trend method selected.</p>;
        }
    };



    // const handleExchangeChange = (e) => {
    //     setviewPair(e.target.value) 
    //     getuserExchanges(false);
    // }

    return (
        // tradeStatus == 1 ?

        <>
            <Container fluid>
                <Breadcrumbs title="DeriSnap" breadcrumbItem="Trade" />


                {balance_errors.length > 0 ?
                    <Alert color="danger">
                        <i className="mdi mdi-alert-outline me-2"></i><strong>Insufficient Funds Alert</strong><br />
                        {balance_errors.map((item, index) => {
                            return (
                                <React.Fragment key={index}>
                                    {item}<br />
                                </React.Fragment>
                            )
                        }
                        )}
                        <br />
                        To avoid any disruptions or order placement problems, we recommend maintaining a suitable balance in your account.
                    </Alert>
                    : ''}



                <Row>
                    {/* <Col xl={12} className='p-0'> */}
                    <Card className="border">
                        <CardBody>
                            <Row className="mb-3 align-items-center">
                                <Col>
                                    <h3>Advanced Trade</h3>
                                    {/* <p>Manage your exchanges, view wallet balances, and select trading pairs to trade.</p> */}
                                </Col>
                                <Col className="d-flex justify-content-end">
                                    <button type="button" className="btn btn-success font-size-14" style={{ padding: "14px" }} onClick={() => props.changeTradeMode(1)}>
                                        Switch to Beginner Trade
                                    </button>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Label
                                        htmlFor="horizontal-firstname-Input"
                                        className="col-form-label"
                                    >
                                        Filter Pairs By Exchange
                                    </Label>
                                    <select id="select_exchange_pair" selected={true} value={viewPair} className="form-select" onChange={async (e) => { setviewPair(e.target.value); setBalanceLoad(true) }}>
                                        <option value="All pairs" >All Pairs</option>
                                        {ccxtUserExchanges.map((exchange, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <option value={exchange.details.id} > {exchange.details.name} </option>
                                                </React.Fragment>
                                            )
                                        })}
                                    </select>
                                </Col>
                                <Col md={6} className="d-flex align-items-end justify-content-sm-end mt-3">
                                    <button className="btn btn-secondary mx-sm-5" onClick={() => { setAddPairVisible(true) }}><i className="bx bx-plus align-middle"></i>&nbsp;Add Pair</button>
                                </Col>

                            </Row>
                        </CardBody>
                    </Card>
                    {/* </Col> */}
                </Row>


                <Row>
                    <Card className="border p-3 rounded mt-4"> <CardTitle className="px-0">{viewPair != "All pairs" ? getExchangeName(viewPair) : 'All Pairs'}</CardTitle>

                        <div className="table-responsive mt-2">
                            <Table className="table-nowrap align-middle mb-0">
                                <thead>
                                    <tr>
                                        <th scope="col" >Exchange</th>
                                        <th scope="col" className="col-md-6">Pair</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Settings</th>
                                        <th scope="col">Create Order</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !pairListLoading && pairsList && pairsList.length ? (
                                            pairsList.map((exchangedata, index) => {
                                                let exchange = exchangedata.data;
                                                let settinginfo = exchange.settings ? JSON.parse(exchange.settings) : {};
                                                let settinginfo2 = exchange
                                                let TrendFollowSettingsinfo = exchange;
                                                let pairStatus = exchange.status == 1 ? true : false;
                                                return (
                                                    <tr key={index}>
                                                        <th>{getExchangeName(exchange.exchange_id)}</th>
                                                        <th scope="row">
                                                            <div className="d-flex align-items-center">
                                                                <div >
                                                                    <span className="text-primary">{exchange.display_text} <a className="text-danger font-size-14" onClick={() => {
                                                                        var id = exchangedata.data.symbol
                                                                        var exchange_id = exchangedata.data.exchange_id
                                                                        setisDeleteconfirmpopup(true)
                                                                        setExchnageId(exchange_id)
                                                                        setPairId(id)
                                                                        setShowWarning(false);
                                                                        setPair(exchangedata.data.id)
                                                                    }}><i className="bx bx-trash"></i></a></span>

                                                                    {settinginfo && settinginfo.quantity > 0 ? (
                                                                        arrangeSettingsArray(settinginfo).map((order, index) => (
                                                                            <p key={index} className="text-muted text-secondary font-size-11 mb-1">
                                                                                {order.map((setting, i) => (
                                                                                    <span key={i}>
                                                                                        {setting}{i < order.length - 1 ? ' | ' : ''}
                                                                                    </span>
                                                                                ))}
                                                                            </p>
                                                                        ))
                                                                    ) : (
                                                                        <div className="text-warning font-size-11">Please Add Order Settings</div>
                                                                    )}
                                                                    {TrendFollowSettingsinfo.Trend_settings && Object.keys(JSON.parse(TrendFollowSettingsinfo.Trend_settings)).length > 0 ? (
                                                                        <TrendFollowSettings1 trendSettingsInfo={TrendFollowSettingsinfo} />
                                                                    ) : (
                                                                        <div className="text-warning font-size-11">Please Add Trend Settings</div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="d-flex align-items-center mt-1"><div className="avatar-xs me-3">
                                                                <FormGroup className="" row>

                                                                    <Col md="8">
                                                                        <Switch
                                                                            uncheckedIcon={<Offsymbol />}
                                                                            checkedIcon={<OnSymbol />}
                                                                            onChange={async (e) => {
                                                                                try {
                                                                                    const hasActiveOrders = await validateActiveOrders(exchange.id, exchange.exchange_id);
                                                                                    setShowWarning(hasActiveOrders);
                                                                                    if (hasActiveOrders) {
                                                                                        toast.error(`Please ensure all open orders for ${exchange.display_text} are closed before disabling this pair.`);
                                                                                    } else {
                                                                                        changePairStatus(exchange, exchange.exchange_id, exchange.id, e);
                                                                                    }
                                                                                } catch (error) {
                                                                                    console.error("Error during validation or submission:", error);
                                                                                }

                                                                            }}
                                                                            checked={pairStatus}
                                                                            disabled={!ccxtUserBalances[exchange.exchange_id] && balanceLoading ? true : false}
                                                                        />
                                                                    </Col>
                                                                </FormGroup>
                                                            </div>
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <ul className="list-unstyled hstack gap-1 mb-0">
                                                                <li title="Order Config">
                                                                    <a className="btn btn-sm btn-soft-primary" onClick={() => {
                                                                        // let selected_exchange = document.getElementById('select_exchange').value;
                                                                        // let is_added = ccxtUserExchanges.find(item => item.exchange_id === selected_exchange);
                                                                        // if (!is_added) {
                                                                        setSettingsvisible(!settingsvisible);
                                                                        setSettingspair(exchange);

                                                                        let orderSettings =
                                                                            settinginfo2.settings
                                                                                ? JSON.parse(
                                                                                    settinginfo2.settings
                                                                                )
                                                                                : {
                                                                                    fill_type: "instant_fill",
                                                                                    order_side: "both",
                                                                                    exit_on: [1]
                                                                                };
                                                                        if (!orderSettings.exit_on && !orderSettings.fill_type) {
                                                                            orderSettings = {
                                                                                ...orderSettings,
                                                                                exit_on: [1]
                                                                            };
                                                                        }
                                                                        if (!orderSettings.fill_type) {
                                                                            orderSettings = {
                                                                                ...orderSettings,
                                                                                fill_type: "instant_fill",
                                                                            };
                                                                        }

                                                                        if (!orderSettings.order_side) {
                                                                            orderSettings = {
                                                                                ...orderSettings,
                                                                                order_side: "both",
                                                                            };
                                                                        }




                                                                        // setSettingspair(exchange);
                                                                        setPairOrderSettings(orderSettings);

                                                                        // setPairOrderSettings(settinginfo2 && settinginfo2.settings ? JSON.parse(settinginfo2.settings) : { fill_type: 'instant_fill', order_side: "both" });
                                                                        // getPairIndex(exchange)
                                                                        setShowWarning(false);
                                                                        // } else {
                                                                        //     toast.error('Exchange already added', { toastId: 'exchange_already_added' })
                                                                        // }
                                                                    }}><i className="bx bx-cog font-size-18"></i></a></li>
                                                                <li title="Strategy Config"><a className="btn btn-sm btn-soft-info" onClick={() => {
                                                                    // let selected_exchange = document.getElementById('select_exchange').value;
                                                                    // let is_added = ccxtUserExchanges.find(item => item.exchange_id === selected_exchange);
                                                                    // if (!is_added) {
                                                                    setTrendFollowSettings(!TrendFollowSettings);
                                                                    setTrendFollowpair(exchange);
                                                                    setTrendSettings(TrendFollowSettingsinfo && TrendFollowSettingsinfo.Trend_settings !== undefined ? JSON.parse(TrendFollowSettingsinfo.Trend_settings) : {
                                                                        trend_method: "sma",
                                                                        short_term: 9,
                                                                        long_term: 20,
                                                                        time_frame: "15m",
                                                                        st_time_frame: "15m",
                                                                        macd_time_frame: "15m",
                                                                        ema_time_frame: "15m",
                                                                        exchange_id: exchange.exchange_id,
                                                                        ema_long_term: 21,
                                                                        ema_short_term: 9,
                                                                        macd_long_term: 26,
                                                                        macd_short_term: 12,
                                                                        trading_type: "short_term"
                                                                    });
                                                                    setShowWarning(false);
                                                                    setDefaultColorSetting(TrendFollowSettingsinfo && TrendFollowSettingsinfo.Trend_settings !== undefined ? JSON.parse(TrendFollowSettingsinfo.Trend_settings) : {})
                                                                    // } else {
                                                                    //     toast.error('Exchange already added', { toastId: 'exchange_already_added' })
                                                                    // }
                                                                }}><i className="bx bx-line-chart font-size-18"></i></a></li>
                                                                {(settinginfo2 && settinginfo2.settings !== undefined) || (TrendFollowSettingsinfo && TrendFollowSettingsinfo.Trend_settings !== undefined) ?

                                                                    <li title="Copy Config"><a className="btn btn-sm btn-soft-secondary" onClick={() => copySettings(exchange.exchange_id, exchange.display_text, TrendFollowSettingsinfo.Trend_settings, settinginfo2.settings)}><i className="bx bx-copy-alt  font-size-18"></i></a></li>
                                                                    : ''
                                                                }

                                                                {copiedSettings && (copiedSettings['exchange_id'] !== exchange.exchange_id || copiedSettings['pair'] !== exchange.display_text) ?
                                                                    <li title="Paste Config"><a className="btn btn-sm btn-soft-warning" onClick={() => { setShowWarning(false); pasteSettings(exchange.exchange_id, exchange.display_text, exchange.id, TrendFollowSettingsinfo.Trend_settings, settinginfo2.settings) }}><i className="bx bx-paste  font-size-18"></i></a></li>
                                                                    : ''}

                                                            </ul>

                                                        </th>
                                                        <th>

                                                            {
                                                                balanceLoading ?
                                                                    <>
                                                                        <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                            <i className="ml-2 bx bx-hourglass bx-spin font-size-10 align-middle me-2"></i>
                                                                        </span>
                                                                    </>
                                                                    : !ccxtUserBalances[exchange.exchange_id] && !balanceLoading ? <>
                                                                        <span className='text-danger'>Unable to retrieve exchange balance</span>
                                                                    </> : ccxtUserBalances && Object.keys(ccxtUserBalances).length !== 0 ? (
                                                                        exchangedata.signal_data &&
                                                                            exchangedata.signal_data.signal &&
                                                                            settinginfo2.settings &&
                                                                            pairStatus == 1 ? (
                                                                            exchangedata.signal_data.signal === 'uptrend' &&
                                                                                settinginfo.quantity_type !== "balance_per_base" ? (
                                                                                <>
                                                                                    <button
                                                                                        className={
                                                                                            formLoader7[settinginfo2.exchange_id + settinginfo2.id]
                                                                                                ? "btn btn-success save-user disabled"
                                                                                                : "btn btn-success save-user font-size-10"
                                                                                        }
                                                                                        title={
                                                                                            "Create buy order based on " +
                                                                                            exchangedata.signal_data.signal +
                                                                                            " signal received at " +
                                                                                            getSettingsDateTimeFormat(exchangedata.signal_data.updated_at)
                                                                                        }
                                                                                        onClick={() => {
                                                                                            let selected_exchange = document.getElementById('select_exchange_pair').value;
                                                                                            CreateOrder(exchangedata, settinginfo2.exchange_id + settinginfo2.id, 'buy');
                                                                                        }}
                                                                                    >
                                                                                        {formLoader7[settinginfo2.exchange_id + settinginfo2.id] ? (
                                                                                            <span>
                                                                                                <i className="ml-2 bx bx-hourglass bx-spin font-size-10 align-middle me-2"></i>
                                                                                            </span>
                                                                                        ) : (
                                                                                            'Buy '
                                                                                        )}
                                                                                        {settinginfo.quantity_type === "balance_per"
                                                                                            ? 'For ' +
                                                                                            parseFloat(
                                                                                                (ccxtUserBalances[settinginfo2.exchange_id] &&
                                                                                                    ccxtUserBalances[settinginfo2.exchange_id][settinginfo2.quote] &&
                                                                                                    ccxtUserBalances[settinginfo2.exchange_id][settinginfo2.quote]['free']
                                                                                                    ? ccxtUserBalances[settinginfo2.exchange_id][settinginfo2.quote]['free']
                                                                                                    : 0) *
                                                                                                settinginfo.quantity /
                                                                                                100
                                                                                            ).toFixed(4) +
                                                                                            ' ' +
                                                                                            settinginfo2.quote
                                                                                            : settinginfo.quantity + ' ' + settinginfo2.base}
                                                                                    </button>
                                                                                </>
                                                                            ) : (
                                                                                exchangedata.signal_data.signal === 'downtrend' && settinginfo.quantity_type !== "balance_per" && (
                                                                                    <>
                                                                                        <button
                                                                                            className={
                                                                                                formLoader7[settinginfo2.exchange_id + settinginfo2.id]
                                                                                                    ? "btn btn-danger save-user disabled"
                                                                                                    : "btn btn-danger save-user font-size-10"
                                                                                            }
                                                                                            title={
                                                                                                "Create sell order based on " +
                                                                                                exchangedata.signal_data.signal +
                                                                                                " signal received at " +
                                                                                                getSettingsDateTimeFormat(exchangedata.signal_data.updated_at)
                                                                                            }
                                                                                            onClick={() => {

                                                                                                CreateOrder(exchangedata, settinginfo2.exchange_id + settinginfo2.id, 'sell');
                                                                                            }}
                                                                                        >
                                                                                            {formLoader7[settinginfo2.exchange_id + settinginfo2.id] ? (
                                                                                                <span>
                                                                                                    <i className="ml-2 bx bx-hourglass bx-spin font-size-10 align-middle me-2"></i>
                                                                                                </span>
                                                                                            ) : (
                                                                                                'Sell '
                                                                                            )}
                                                                                            {settinginfo.quantity_type === "balance_per_base"
                                                                                                ? '' +
                                                                                                parseFloat(
                                                                                                    (ccxtUserBalances[settinginfo2.exchange_id] &&
                                                                                                        ccxtUserBalances[settinginfo2.exchange_id][settinginfo2.base] &&
                                                                                                        ccxtUserBalances[settinginfo2.exchange_id][settinginfo2.base]['free']
                                                                                                        ? ccxtUserBalances[settinginfo2.exchange_id][settinginfo2.base]['free']
                                                                                                        : 0) *
                                                                                                    settinginfo.quantity /
                                                                                                    100
                                                                                                ).toFixed(4) +
                                                                                                ' ' +
                                                                                                settinginfo2.base
                                                                                                : settinginfo.quantity + ' ' + settinginfo2.base}
                                                                                        </button>
                                                                                    </>
                                                                                )
                                                                            )
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    ) : <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                        <i className="ml-2 bx bx-hourglass bx-spin font-size-10 align-middle me-2"></i>
                                                                    </span>}

                                                        </th>
                                                    </tr>



                                                )
                                            })
                                        )

                                            : pairListLoading ?
                                                <tr><td colSpan={6}>
                                                    <Col xl={12}>
                                                        <div className="p-4 border text-center">
                                                            <h6 className="text-muted">Loading...</h6>
                                                        </div>
                                                    </Col>
                                                </td></tr>
                                                : <tr><td colSpan={6}>
                                                    <Col xl={12}>
                                                        <div className="p-4 border text-center">
                                                            <h6 className="text-muted">No Pairs Added</h6>
                                                        </div>
                                                    </Col>
                                                </td></tr>

                                    }
                                </tbody>
                            </Table></div> </Card>
                </Row>

            </Container>

            <Modal isOpen={addPairVisible} toggle={() => { setAddPairVisible(false); setPairErrors({ exchange_error: "", pair_error: "" }); setSpotPairs([]); setPairExchange(''); setSelectedSpotPair({}); }} centered>
                <ModalHeader toggle={() => { setAddPairVisible(false); setPairErrors({ exchange_error: "", pair_error: "" }); setSpotPairs([]); setPairExchange(''); setSelectedSpotPair({}) }} tag="h4" className="text-capitalize">
                    Add Pair
                </ModalHeader>
                <ModalBody>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            addNewPair();
                        }}
                    >
                        <Row>
                            <Col className="col-12">
                                <div className="mb-3">
                                    <Label className="form-label">Select Exchange
                                    </Label>
                                    <select id="select-exchange-pair" name="pair_exchange_id" value={pairExchange} selected={true} className="form-select" onChange={async (e) => { handlePairExchange(e) }}>
                                        <option value="" >Select Exchange</option>
                                        {ccxtUserExchanges.map((exchange, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <option value={exchange.details?.id} > {exchange.details?.name}</option>
                                                </React.Fragment>
                                            )
                                        })}
                                    </select>

                                    {pairErrors.exchange_error ? (
                                        <div style={{ color: "#f46a6a", width: "100%", marginTop: "0.25rem", fontSize: "80%" }}>{pairErrors.exchange_error}</div>
                                    ) : null}
                                </div>

                                {formLoader8 ? <div className="d-flex justify-content-center">
                                    <Spinner animation="border" size="sm" />
                                </div> : null}
                                {
                                    pairExchange != "" && !formLoader8 ?
                                        <div className="mb-3">
                                            <Label className="form-label">Select Pair
                                            </Label>
                                            <Select id="select-add-pair" placeholder="Add new pair" name="pair_symbol" onChange={handleSelect} options={spotPairs} classNamePrefix="select2-selection"></Select>
                                            {pairErrors.pair_error ? (
                                                <div style={{ color: "#f46a6a", width: "100%", marginTop: "0.25rem", fontSize: "80%" }}>{pairErrors.pair_error}</div>
                                            ) : null}
                                        </div>
                                        : null
                                }

                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="text-end">
                                    <button
                                        type="submit"
                                        className={formLoader6 ? "btn btn-success save-user disabled" : "btn btn-success save-user"}
                                    >
                                        {formLoader6 ? <span><i className="ml-2 bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> Processing... </span> : 'Save'}
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>


            <Modal isOpen={isDeleteconfirmpopup} toggle={() => { setisDeleteconfirmpopup(false) }} centered>
                <ModalHeader toggle={() => { setisDeleteconfirmpopup(false) }} tag="h4" className="text-capitalize">
                    {"Delete " + (PairId ? PairId.replace('_', '/') : "") + " Pair"}
                </ModalHeader>
                <ModalBody>
                    <p className="text-muted">Are you sure you want to delete {PairId ? PairId.replace('_', '/') : ""} Pair?</p>
                    <Form
                        onSubmit={async (e) => {
                            e.preventDefault();
                            try {
                                // const hasActiveOrders = await validateActiveOrders(Pair, ExchnageId); // Await validation
                                // console.log(hasActiveOrders)
                                // setShowWarning(hasActiveOrders);
                                // if (hasActiveOrders) {
                                //     // toast.error("Please Close All Active Orders For This Pair");
                                // } else {
                                DeleteExchangePairs(ExchnageId, PairId)
                                // setloader(true)
                                // return false;
                                // }
                            } catch (error) {
                                console.error("Error during validation or submission:", error);
                            }
                        }}
                    >
                        {
                            ShowWarning ? <div> <div className="alert alert-danger" style={{ fontSize: "10px" }} role="alert">
                                <strong>Warning!</strong> Please ensure all open orders for {PairId ? PairId.replace('_', '/') : ""} are closed before deleting.
                            </div></div> : ''
                        }
                        <Row>
                            <Col className="col-12">

                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="text-end">
                                    <button
                                        type="submit"
                                        className={loader ? "btn btn-danger save-user disabled" : "btn btn-danger save-user"}
                                    >
                                        {loader ? <span><i className="ml-2 bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> Processing... </span> : 'Delete'}
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>


            <Modal isOpen={settingsvisible} toggle={() => { setSettingsvisible(false) }} centered>
                <ModalHeader toggle={() => { setSettingsvisible(false) }} tag="h4" className="text-capitalize">Settings for <span className="text-primary">{settingspair.display_text}</span> Pair </ModalHeader>
                <ModalBody>

                    <form key={6} onSubmit={async (e) => {
                        e.preventDefault(); // Prevent default form submission behavior
                        setForm7Loader({
                            loading: true,
                        });
                        try {
                            const hasActiveOrders = await validateActiveOrders(settingspair.id, settingspair.exchange_id); // Await validation
                            setShowWarning(hasActiveOrders);
                            if (hasActiveOrders) {
                                setForm7Loader({
                                    loading: false,
                                });
                                toast.error("Please Close All Active Orders For This Pair");
                            } else {
                                updatePairOrderSettings(); // Proceed with the form submission
                            }
                        } catch (error) {
                            setForm7Loader({
                                loading: false,
                            });
                            console.error("Error during validation or submission:", error);
                        }
                    }}>
                        <div >

                            <Row>
                                <Col>
                                    <FormGroup className="mb-4" >
                                        <Label
                                            htmlFor="formrow-firstname-Input"
                                            md="3"
                                            className="col-form-label"
                                        >
                                            Fill type <i className="bx bx-info-circle" id="Fill_type1" /><UncontrolledTooltip placement="top" target="Fill_type1">{ToolTipData.ccxt_trading_fill_type}</UncontrolledTooltip>
                                        </Label>
                                        <select name="fill_type" label="fill_type" className="form-select" onChange={(e) => handlePairOrderSettingsChange(e)} value={pairOrderSettings && pairOrderSettings.fill_type ? pairOrderSettings.fill_type : 'instant_fill'}>
                                            <option value=''>Select Fill Type</option>
                                            <option value='instant_fill'>Instant Fill (Market order)</option>
                                            <option value='wait_and_fill'>Wait and Fill (Limit order)</option>
                                        </select>

                                    </FormGroup>
                                </Col>
                            </Row>
                            {
                                pairOrderSettings.fill_type === 'wait_and_fill' ?
                                    <Row>
                                        <Col>
                                            <FormGroup className="mb-0">
                                                <Label htmlFor="formrow-firstname-Input">
                                                    Price Distance % for Wait and Fill (Limit Order) <i className="bx bx-info-circle" id="Price_distance" /><UncontrolledTooltip placement="top" target="Price_distance">{ToolTipData.ccxt_price_distance}</UncontrolledTooltip>
                                                </Label>
                                                <input autoComplete="off" type="number" value={pairOrderSettings && pairOrderSettings.price_distance_percentage ? pairOrderSettings.price_distance_percentage : ''} onChange={(e) => handlePairOrderSettingsChange(e)} onBlur={(e) => handlePairOrderSettingsChange(e)} name="price_distance_percentage" className="form-control" />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    : ''
                            }
                            <Row>
                                <Col>
                                    <Label htmlFor="formrow-firstname-Input">
                                        Quantity <span className="fw-bold" style={{ fontSize: "10px" }}>
                                            {/* ( {parseFloat(pairOrderSettings.quantity === 0 ? 0.000 : 0.001*pairOrderSettings.quantity)}{" "}{settingspair.base} )*/}
                                        </span>
                                        <i className="bx bx-info-circle" id="Quantity" /><UncontrolledTooltip placement="top" target="Quantity">{ToolTipData.ccxt_quantity}</UncontrolledTooltip>
                                    </Label>
                                    <FormGroup className="input-group mb-0 ">


                                        <select
                                            className="form-control" id="inputGroupFileAddon05" name="quantity_type" onChange={(e) => handlePairOrderSettingsChange(e)} value={pairOrderSettings && pairOrderSettings.quantity_type ? pairOrderSettings.quantity_type : ''}
                                        >
                                            <option value="order_qty">Order Qty</option>
                                            <option value="balance_per">{settingspair.quote} Margin</option>
                                            <option value="balance_per_base">{settingspair.base} Margin</option>
                                        </select>
                                        <input autoComplete="off" step="any" type="number" value={pairOrderSettings && pairOrderSettings.quantity ? pairOrderSettings.quantity : ''} onChange={(e) => handlePairOrderSettingsChange(e)} onBlur={(e) => handlePairOrderSettingsChange(e)} name="quantity" className="form-control  w-50" min={settingspair.product_specs && settingspair.product_specs.min_order_size ? settingspair.product_specs.min_order_size : settingspair.limits && settingspair.limits.amount ? settingspair.limits.amount.min : ''} max={settingspair.product_specs && settingspair.product_specs.max_order_size ? settingspair.product_specs.max_order_size : settingspair.limits && settingspair.limits.amount ? settingspair.limits.amount.max : ''} />
                                        <Label className="input-group-text" >{!pairOrderSettings.quantity_type || pairOrderSettings.quantity_type == "order_qty" ? settingspair.base : "%"}</Label>
                                    </FormGroup>
                                    {indexPrices[settingspair.id] ? (
                                        <p
                                            className="mb-0"
                                            style={{ position: "absolute", right: "12px", bottom: "-1px", fontSize: "12px" }}
                                        >
                                            <span className="fw-bold">
                                                {!pairOrderSettings.quantity_type || pairOrderSettings.quantity_type === "order_qty"
                                                    ? pairOrderSettings.quantity > 0
                                                        ? parseFloat(
                                                            pairOrderSettings.quantity === 0
                                                                ? 0.000
                                                                : parseFloat(indexPrices[settingspair.id] * pairOrderSettings.quantity).toFixed(4)
                                                        )
                                                        : "-"
                                                    : pairOrderSettings.quantity_type === "balance_per"
                                                        ? parseFloat(
                                                            (ccxtUserBalances[settingspair.exchange_id] &&
                                                                ccxtUserBalances[settingspair.exchange_id][settingspair.quote] &&
                                                                ccxtUserBalances[settingspair.exchange_id][settingspair.quote]['free']
                                                                ? ccxtUserBalances[settingspair.exchange_id][settingspair.quote]['free']
                                                                : 0) *
                                                            pairOrderSettings.quantity /
                                                            100
                                                        ).toFixed(4)
                                                        : pairOrderSettings.quantity_type === "balance_per_base" ? parseFloat(
                                                            (ccxtUserBalances[settingspair.exchange_id] &&
                                                                ccxtUserBalances[settingspair.exchange_id][settingspair.base] &&
                                                                ccxtUserBalances[settingspair.exchange_id][settingspair.base]['free']
                                                                ? ccxtUserBalances[settingspair.exchange_id][settingspair.base]['free']
                                                                : 0) *
                                                            pairOrderSettings.quantity /
                                                            100
                                                        ).toFixed(4)
                                                            : "-"
                                                }
                                                {" "}
                                            </span>
                                            {pairOrderSettings.quantity_type === "balance_per_base" ? settingspair.base : settingspair.quote}
                                        </p>
                                    ) : (
                                        ''
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup className="mb-4" >
                                        <Label
                                            htmlFor="formrow-firstname-Input"
                                            md="3"
                                            className="col-form-label"
                                        >
                                            Select Side <i className="bx bx-info-circle" id="order_side" /><UncontrolledTooltip placement="top" target="order_side">{ToolTipData.ccxt_order_side}</UncontrolledTooltip>
                                        </Label>
                                        <select name="order_side" label="order_side" className="form-select" onChange={(e) => handlePairOrderSettingsChange(e)} value={pairOrderSettings && pairOrderSettings.order_side}>
                                            <option value='both'>Both Short & Long</option>
                                            <option value='short'>Short</option>
                                            <option value='long'>Long</option>
                                            <option value='sell'>Sell</option>
                                            <option value='buy'>Buy</option>
                                        </select>

                                    </FormGroup>
                                </Col>
                            </Row>



                            {pairOrderSettings && pairOrderSettings.order_side && pairOrderSettings.order_side !== "sell" && pairOrderSettings.order_side !== "buy" ? <>
                                <Row>
                                    <Col>
                                        <div className="">

                                            <div>
                                                <Label className="form-label">Stop Loss <i className="bx bx-info-circle" id="Stop_loss" /><UncontrolledTooltip placement="top" target="Stop_loss">{ToolTipData.ccxt_stop_loss}</UncontrolledTooltip> </Label>
                                                <FormGroup className="input-group mb-0 ">


                                                    <select
                                                        className="form-control" name="stop_loss_type" id="inputGroupFileAddon04" onChange={(e) => handlePairOrderSettingsChange(e)} value={pairOrderSettings && pairOrderSettings.stop_loss_type}
                                                    >
                                                        <option value="percentage">%</option>
                                                        <option value="points">Pts</option>
                                                    </select>
                                                    <input autoComplete="off" type="number" min={0} max={pairOrderSettings && pairOrderSettings.stop_loss_type != 'points' ? 100 : 10000} step={0.1} value={pairOrderSettings && pairOrderSettings.stop_loss ? pairOrderSettings.stop_loss : ''} onChange={(e) => handlePairOrderSettingsChange(e)} onBlur={(e) => handlePairOrderSettingsChange(e)} name="stop_loss" className="form-control w-50" aria-describedby="inputGroupFileAddon04" />
                                                </FormGroup> </div></div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup className="mb-0">
                                            <Label htmlFor="formrow-firstname-Input">
                                                Trailing Take Profit  % <i className="bx bx-info-circle" onClick={() => setVisibleMin(!visibleMin)}></i>
                                            </Label>
                                            <input autoComplete="off" type="number" value={pairOrderSettings && pairOrderSettings.min_take_profit_percentage ? pairOrderSettings.min_take_profit_percentage : ''} onChange={(e) => handlePairOrderSettingsChange(e)} onBlur={(e) => handlePairOrderSettingsChange(e)} name="min_take_profit_percentage" className="form-control" />
                                            {(settingspair.maker && settingspair.taker) ?
                                                <div className="text-info font-size-10">Taker Fee: <b>{Number(settingspair.maker) * 100}%</b>, Maker Fee: <b>{Number(settingspair.taker) * 100}%</b></div>
                                                : ''}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup className="mb-0">
                                            <Label htmlFor="formrow-firstname-Input">
                                                Trailing Stop Price % <i className="bx bx-info-circle" onClick={() => setVisibleTailling(!visibleTailling)}></i>
                                            </Label>
                                            <input autoComplete="off" type="number" value={pairOrderSettings && pairOrderSettings.trailing_take_profit_percentage ? pairOrderSettings.trailing_take_profit_percentage : ''} onChange={(e) => handlePairOrderSettingsChange(e)} onBlur={(e) => handlePairOrderSettingsChange(e)} name="trailing_take_profit_percentage" className="form-control" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </> : ''}
                            {pairOrderSettings && pairOrderSettings.order_side && pairOrderSettings.order_side == "sell" ? <>
                                <Row>
                                    <Col>
                                        <FormGroup className="mb-0">
                                            <Label htmlFor="formrow-firstname-Input">
                                                Minimum Sell Price <i className="bx bx-info-circle" id="min_sell_price_tar" /><UncontrolledTooltip placement="top" target="min_sell_price_tar">{ToolTipData.ccxt_min_sell_price}</UncontrolledTooltip>
                                            </Label>
                                            <input autoComplete="off" type="number" value={pairOrderSettings && pairOrderSettings.min_sell_price ? pairOrderSettings.min_sell_price : ''} onChange={(e) => handlePairOrderSettingsChange(e)} onBlur={(e) => handlePairOrderSettingsChange(e)} name="min_sell_price" className="form-control" />

                                        </FormGroup>
                                    </Col>
                                </Row></> : ''}
                            {pairOrderSettings && pairOrderSettings.order_side && pairOrderSettings.order_side == "buy" ? <>
                                <Row>
                                    <Col>
                                        <FormGroup className="mb-0">
                                            <Label htmlFor="formrow-firstname-Input">
                                                Maximum Buy Price <i className="bx bx-info-circle" id="max_buy_price_tar" /><UncontrolledTooltip placement="top" target="max_buy_price_tar">{ToolTipData.ccxt_max_buy_price}</UncontrolledTooltip>
                                            </Label>
                                            <input autoComplete="off" type="number" value={pairOrderSettings && pairOrderSettings.max_buy_price ? pairOrderSettings.max_buy_price : ''} onChange={(e) => handlePairOrderSettingsChange(e)} onBlur={(e) => handlePairOrderSettingsChange(e)} name="max_buy_price" className="form-control" />

                                        </FormGroup>
                                    </Col>
                                </Row></> : ''}
                            {pairOrderSettings && pairOrderSettings.order_side && pairOrderSettings.order_side !== "sell" && pairOrderSettings.order_side !== "buy" ? <>
                                <Row>
                                    <Col>

                                        <FormGroup className="mb-0">
                                            <Label htmlFor="formrow-firstname-Input">
                                                Waiting Time for Order after Stop Loss (H : M) <i className="bx bx-info-circle" id="WTstoploss" />
                                                <UncontrolledTooltip placement="top" target="WTstoploss">
                                                    {ToolTipData.intervalfororder_stop_loss}
                                                </UncontrolledTooltip>
                                            </Label>
                                            <Flatpickr
                                                className="form-control d-block"
                                                placeholder="Select time"
                                                name="waiting_time_for_next_order_stop_loss"
                                                onChange={(e) => handleOrderInterval(e, 'waiting_time_for_next_order_stop_loss')}
                                                value={pairOrderSettings && pairOrderSettings.waiting_time_for_next_order_stop_loss ? toHoursAndMinutes(pairOrderSettings.waiting_time_for_next_order_stop_loss) : ''}
                                                options={{
                                                    enableTime: true,
                                                    noCalendar: true,
                                                    dateFormat: "H:i",
                                                    time_24hr: true,
                                                }}
                                            />

                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup className="mb-0">
                                            <Label htmlFor="formrow-firstname-Input">
                                                Waiting Time for Order after Trailing Take Profit (H : M) <i className="bx bx-info-circle" id="WTtakeprofit" />
                                                <UncontrolledTooltip placement="top" target="WTtakeprofit">
                                                    {ToolTipData.intervalfororder_trailing_take_profit}
                                                </UncontrolledTooltip>
                                            </Label>
                                            <Flatpickr
                                                className="form-control d-block"
                                                placeholder="Select time"
                                                name="waiting_time_for_next_order_trailing_profit"
                                                onChange={(e) => handleOrderInterval(e, 'waiting_time_for_next_order_trailing_profit')}
                                                value={pairOrderSettings && pairOrderSettings.waiting_time_for_next_order_trailing_profit ? toHoursAndMinutes(pairOrderSettings.waiting_time_for_next_order_trailing_profit) : ''}
                                                options={{
                                                    enableTime: true,
                                                    noCalendar: true,
                                                    dateFormat: "H:i",
                                                    time_24hr: true,
                                                }}
                                            />

                                        </FormGroup>
                                    </Col>

                                </Row>



                            </> : ''}

                            {pairOrderSettings && pairOrderSettings.order_side && pairOrderSettings.order_side !== "sell" && pairOrderSettings.order_side !== "buy" ?

                                <Row>
                                    <FormGroup className="mb-4">
                                        {/* <Label className="col-form-label d-block">Exit on</Label> */}
                                        <Col className="mt-2">
                                            <div className="form-check form-check-inline">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    name="exit_on"
                                                    value={1}
                                                    onChange={handlePairOrderSettingsChange}
                                                    checked={pairOrderSettings.exit_on?.includes(1)}
                                                />
                                                <label className="form-check-label">Exit on Trend Change</label>
                                            </div>
                                            {/* <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="exit_on"
                                                        value={2}
                                                        onChange={handlePairOrderSettingsChange}
                                                        checked={pairOrderSettings.exit_on?.includes(2)}
                                                    />
                                                    <label className="form-check-label">Trailing Profit</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="exit_on"
                                                        value={3}
                                                        onChange={handlePairOrderSettingsChange}
                                                        checked={pairOrderSettings.exit_on?.includes(3)}
                                                    />
                                                    <label className="form-check-label">Stop Loss</label>
                                                </div> */}
                                        </Col>
                                    </FormGroup>
                                </Row> : null}

                        </div>

                        {
                            ShowWarning ? <div> <div className="alert alert-danger" role="alert">
                                <strong>Warning!</strong> Please ensure all open orders for {settingspair.display_text} are closed before updating the settings.
                            </div></div> : ''
                        }
                        <div className="text-sm-end">
                            <Button className="btn-label w-md" color="success" type="submit" disabled={form7_loader.loading}>
                                {form7_loader.loading ? <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> : ''}
                                <i className="bx bx-smile label-icon"></i> Submit
                            </Button>
                        </div>
                    </form>

                </ModalBody>

            </Modal>

            <Modal
                isOpen={TrendFollowSettings}
                toggle={() => {
                    setTrendFollowSettings(false);
                }}
                style={{ width: "100%" }}
                className="modal-xl"
            >
                <ModalHeader toggle={() => { setTrendFollowSettings(false) }} tag="h4" className="text-capitalize">Trend Following Settings For <span className="text-primary">{Trendfollowpair.display_text}</span> Pair </ModalHeader>
                <div className="modal-body">
                    <ModalBody >
                        <form key={6} onSubmit={async (e) => {
                            e.preventDefault(); // Prevent default form submission behavior
                            setForm6Loader({
                                loading: true,
                            });
                            try {
                                const hasActiveOrders = await validateActiveOrders(Trendfollowpair.id, Trendfollowpair.exchange_id); // Await validation
                                setShowWarning(hasActiveOrders);
                                if (hasActiveOrders) {
                                    setForm6Loader({
                                        loading: false,
                                    });
                                    // toast.error("Please Close All Active Orders For This Pair");
                                } else {
                                    updateNewTrendSettings(); // Proceed with the form submission
                                }
                            } catch (error) {
                                setForm6Loader({
                                    loading: false,
                                });
                                console.error("Error during validation or submission:", error);
                            }
                        }}>
                            <div >
                                <FormGroup className="mb-4" row>
                                    <Label
                                        htmlFor="formrow-firstname-Input"
                                        md="4"
                                        className="col-form-label"
                                    >
                                        Trading type: <i className="bx bx-info-circle" id="trading_type" />
                                        <UncontrolledTooltip placement="top" target="trading_type">
                                            {
                                                <>Please select the type of trading you prefer:<br />- Short term - INTRADAY:<br />This option is suitable for traders who aim to complete their trades within a single day.<br />- Medium term - SWING:<br />Choose this option if you prefer holding your trades for a few days to a few weeks.<br />- Long term - INVESTMENT:<br />Select this option if you have a long-term investment approach and plan to hold your trades for an extended period</>
                                            }
                                        </UncontrolledTooltip>
                                    </Label>
                                    <Col md="5">
                                        <select name="trading_type" label="trading_type" className="form-select" onChange={(e) => handleTrendSettingsChange(e)} value={trendSettings.trading_type}>

                                            <option value='short_term'>Short term - INTRADAY</option>
                                            <option value='medium_term'>Medium term - SWING</option>
                                            <option value='long_term'>Long term - INVESTMENT</option>
                                        </select>
                                    </Col>
                                </FormGroup>
                                <FormGroup className="mb-4" row>
                                    <Label
                                        htmlFor="formrow-firstname-Input"
                                        md="4"
                                        className="col-form-label"
                                    >
                                        Method for Trend signal: <i className="bx bx-info-circle" id="Method_for_Trend_signal" />
                                        <UncontrolledTooltip placement="top" target="Method_for_Trend_signal">
                                            {ToolTipData.method_for_trend_signal}
                                        </UncontrolledTooltip>
                                    </Label>
                                    <Col md="5">
                                        <select name="trend_method" label="trend_method" className="form-select" onChange={(e) => handleTrendSettingsChange(e)} value={trendSettings.trend_method}>

                                            <option value='sma'>SMA crossovers</option>
                                            <option value='ema'>EMA crossovers</option>
                                            <option value='macd'>MACD Signal Line cross</option>
                                            <option value='supertrend'>Supertrend</option>
                                        </select>
                                    </Col>
                                </FormGroup>

                                {!trendSettings.trend_method || trendSettings.trend_method == "sma" ? <>

                                    <FormGroup className="mb-4" row>
                                        <Label
                                            htmlFor="formrow-firstname-Input"
                                            md="4"
                                            className="col-form-label"
                                        >
                                            Interval <i className="bx bx-info-circle" id="Interval1" />
                                            <UncontrolledTooltip placement="top" target="Interval1">
                                                {ToolTipData?.sma_crossover?.interval}
                                            </UncontrolledTooltip>
                                        </Label>
                                        <Col md="8">
                                            <select name="time_frame" label="time_frame" required className="form-select" style={DefaultColorSetting.time_frame ? {} : { color: "gray" }} value={trendSettings.time_frame ? trendSettings.time_frame : "15m"} onChange={(e) => handleTrendSettingsChange(e)} >
                                                <option value='1m'>1 minute</option>
                                                <option value='5m'>5 minutes</option>
                                                <option value='15m'>15 minutes</option>
                                                <option value='30m'>30 minutes</option>
                                                <option value='1h'>1 hour</option>
                                                <option value='2h'>2 hours</option>
                                                <option value='4h'>4 hours</option>
                                                <option value='1d'>1 day</option>
                                                <option value='1w'>1 week</option>
                                                <option value='1M'>1 month</option>
                                            </select>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup className="mb-4" row>
                                        <Label
                                            htmlFor="formrow-firstname-Input"
                                            md="4"
                                            className="col-form-label"
                                        >
                                            Short length <i className="bx bx-info-circle" id="Short_length" />
                                            <UncontrolledTooltip placement="top" target="Short_length">
                                                {ToolTipData?.sma_crossover?.short_length}
                                            </UncontrolledTooltip>
                                        </Label>
                                        <Col md="8">
                                            <input type="number" required min={1} step={1} style={DefaultColorSetting.short_term ? {} : { color: "gray" }} value={trendSettings.short_term ? trendSettings.short_term : 9} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="short_term" className="form-control" />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup className="mb-4" row>
                                        <Label
                                            htmlFor="formrow-firstname-Input"
                                            md="4"
                                            className="col-form-label"
                                        >
                                            Long length <i className="bx bx-info-circle" id="Long_length" />
                                            <UncontrolledTooltip placement="top" target="Long_length">
                                                {ToolTipData?.sma_crossover?.long_length}
                                            </UncontrolledTooltip>
                                        </Label>
                                        <Col md="8">
                                            <input type="number" required min={1} step={1} style={DefaultColorSetting.long_term ? {} : { color: "gray" }} value={trendSettings.long_term ? trendSettings.long_term : 20} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="long_term" className="form-control" />
                                        </Col>
                                    </FormGroup>
                                </> : trendSettings.trend_method == "ema" ?
                                    <>

                                        <FormGroup className="mb-4" row>
                                            <Label
                                                htmlFor="formrow-firstname-Input"
                                                md="4"
                                                className="col-form-label"
                                            >
                                                Interval <i className="bx bx-info-circle" id="TFBMINTREVAL" />
                                                <UncontrolledTooltip placement="top" target="TFBMINTREVAL">
                                                    {ToolTipData.ema_crossover.interval}
                                                </UncontrolledTooltip>
                                            </Label>
                                            <Col md="8">
                                                <select name="ema_time_frame" label="ema_time_frame" required className="form-select" style={DefaultColorSetting.ema_time_frame ? {} : { color: "gray" }} value={trendSettings.ema_time_frame ? trendSettings.ema_time_frame : "15m"} onChange={(e) => handleTrendSettingsChange(e)} >
                                                    <option value='1m'>1 minute</option>
                                                    <option value='5m'>5 minutes</option>
                                                    <option value='15m'>15 minutes</option>
                                                    <option value='30m'>30 minutes</option>
                                                    <option value='1h'>1 hour</option>
                                                    <option value='2h'>2 hours</option>
                                                    <option value='4h'>4 hours</option>
                                                    <option value='1d'>1 day</option>
                                                    <option value='1w'>1 week</option>
                                                    <option value='1M'>1 month</option>
                                                </select>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup className="mb-4" row>
                                            <Label
                                                htmlFor="formrow-firstname-Input"
                                                md="4"
                                                className="col-form-label"
                                            >
                                                Short length <i className="bx bx-info-circle" id="ema_short" />
                                                <UncontrolledTooltip placement="top" target="ema_short">
                                                    {ToolTipData.ema_crossover.short_length}
                                                </UncontrolledTooltip>
                                            </Label>
                                            <Col md="8">
                                                <input type="number" inputMode="numeric" required min={1} step={1} style={DefaultColorSetting.ema_short_term ? {} : { color: "gray" }} value={trendSettings.ema_short_term ? trendSettings.ema_short_term : 9} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="ema_short_term" className="form-control" />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup className="mb-4" row>
                                            <Label
                                                htmlFor="formrow-firstname-Input"
                                                md="4"
                                                className="col-form-label"
                                            >
                                                Long length <i className="bx bx-info-circle" id="ema_long" />
                                                <UncontrolledTooltip placement="top" target="ema_long">
                                                    {ToolTipData.ema_crossover.long_length}
                                                </UncontrolledTooltip>
                                            </Label>
                                            <Col md="8">
                                                <input type="number" inputMode="numeric" required min={1} step={1} style={DefaultColorSetting.ema_long_term ? {} : { color: "gray" }} value={trendSettings.ema_long_term ? trendSettings.ema_long_term : 21} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="ema_long_term" className="form-control" />
                                            </Col>
                                        </FormGroup>
                                    </> : trendSettings.trend_method == "macd" ?
                                        <>

                                            <FormGroup className="mb-4" row>
                                                <Label
                                                    htmlFor="formrow-firstname-Input"
                                                    md="4"
                                                    className="col-form-label"
                                                >
                                                    Interval <i className="bx bx-info-circle" id="Interval" />
                                                    <UncontrolledTooltip placement="top" target="Interval">
                                                        {ToolTipData.macd_sign_line_cross.interval}
                                                    </UncontrolledTooltip>
                                                </Label>
                                                <Col md="8">
                                                    <select name="macd_time_frame" required label="macd_time_frame" className="form-select" style={DefaultColorSetting.macd_time_frame ? {} : { color: "gray" }} value={trendSettings.macd_time_frame} onChange={(e) => handleTrendSettingsChange(e)} >
                                                        <option value='1m'>1 minute</option>
                                                        <option value='5m'>5 minutes</option>
                                                        <option value='15m'>15 minutes</option>
                                                        <option value='30m'>30 minutes</option>
                                                        <option value='1h'>1 hour</option>
                                                        <option value='2h'>2 hours</option>
                                                        <option value='4h'>4 hours</option>
                                                        <option value='1d'>1 day</option>
                                                        <option value='1w'>1 week</option>
                                                        <option value='1M'>1 month</option>
                                                    </select>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup className="mb-4" row>
                                                <Label
                                                    htmlFor="formrow-firstname-Input"
                                                    md="4"
                                                    className="col-form-label"
                                                >
                                                    Short length <i className="bx bx-info-circle" id="Short_length" />
                                                    <UncontrolledTooltip placement="top" target="Short_length">
                                                        {ToolTipData.macd_sign_line_cross.short_length}
                                                    </UncontrolledTooltip>
                                                </Label>
                                                <Col md="8">
                                                    <input type="number" required min={1} step={1} style={DefaultColorSetting.macd_short_term ? {} : { color: "gray" }} value={trendSettings.macd_short_term ? trendSettings.macd_short_term : 12} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="macd_short_term" className="form-control" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup className="mb-4" row>
                                                <Label
                                                    htmlFor="formrow-firstname-Input"
                                                    md="4"
                                                    className="col-form-label"
                                                >
                                                    Long length <i className="bx bx-info-circle" id="Long_length" />
                                                    <UncontrolledTooltip placement="top" target="Long_length">
                                                        {ToolTipData.macd_sign_line_cross.long_length}
                                                    </UncontrolledTooltip>
                                                </Label>
                                                <Col md="8">
                                                    <input type="number" required min={1} step={1} style={DefaultColorSetting.macd_long_term ? {} : { color: "gray" }} value={trendSettings.macd_long_term ? trendSettings.macd_long_term : 26} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="macd_long_term" className="form-control" />
                                                </Col>
                                            </FormGroup>

                                            <FormGroup className="mb-4" row>
                                                <Label
                                                    htmlFor="formrow-firstname-Input"
                                                    md="4"
                                                    className="col-form-label"
                                                >
                                                    Signal length <i className="bx bx-info-circle" id="Signal_length" />
                                                    <UncontrolledTooltip placement="top" target="Signal_length">
                                                        {ToolTipData.macd_sign_line_cross.signal_length}
                                                    </UncontrolledTooltip>
                                                </Label>
                                                <Col md="8">
                                                    <input type="number" required min={1} step={1} style={DefaultColorSetting.macd_signal_term ? {} : { color: "gray" }} value={trendSettings.macd_signal_term ? trendSettings.macd_signal_term : 9} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="macd_signal_term" className="form-control" />
                                                </Col>
                                            </FormGroup>
                                        </>
                                        : trendSettings.trend_method == "supertrend" ? <>
                                            <FormGroup className="mb-4" row>
                                                <Label
                                                    htmlFor="formrow-firstname-Input"
                                                    md="4"
                                                    className="col-form-label"
                                                >
                                                    Interval <i className="bx bx-info-circle" id="Interval" />
                                                    <UncontrolledTooltip placement="top" target="Interval">
                                                        {ToolTipData.supertrend.interval}
                                                    </UncontrolledTooltip>
                                                </Label>
                                                <Col md="8">
                                                    <select name="st_time_frame" required label="st_time_frame" className="form-select" style={DefaultColorSetting.st_time_frame ? {} : { color: "gray" }} value={trendSettings.st_time_frame} onChange={(e) => handleTrendSettingsChange(e)} >
                                                        <option value='1m'>1 minute</option>
                                                        <option value='5m'>5 minutes</option>
                                                        <option value='15m'>15 minutes</option>
                                                        <option value='30m'>30 minutes</option>
                                                        <option value='1h'>1 hour</option>
                                                        <option value='2h'>2 hours</option>
                                                        <option value='4h'>4 hours</option>
                                                        <option value='1d'>1 day</option>
                                                        <option value='1w'>1 week</option>
                                                        {/*<option value='1M'>1 month</option>*/}
                                                    </select>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup className="mb-4" row>
                                                <Label
                                                    htmlFor="formrow-firstname-Input"
                                                    md="4"
                                                    className="col-form-label"
                                                >
                                                    ATR length <i className="bx bx-info-circle" id="ATR_length" />
                                                    <UncontrolledTooltip placement="top" target="ATR_length">
                                                        {ToolTipData.supertrend.atr_length}
                                                    </UncontrolledTooltip>
                                                </Label>
                                                <Col md="8">
                                                    <input type="number" required min={1} step={1} style={DefaultColorSetting.st_atr_length ? {} : { color: "gray" }} value={trendSettings.st_atr_length ? trendSettings.st_atr_length : 10} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="st_atr_length" className="form-control" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup className="mb-4" row>
                                                <Label
                                                    htmlFor="formrow-firstname-Input"
                                                    md="4"
                                                    className="col-form-label"
                                                >
                                                    Factor <i className="bx bx-info-circle" id="Factor" />
                                                    <UncontrolledTooltip placement="top" target="Factor">
                                                        {ToolTipData.supertrend.factor}
                                                    </UncontrolledTooltip>
                                                </Label>
                                                <Col md="8">
                                                    <input type="number" required min={1} step={0.1} style={DefaultColorSetting.st_factor ? {} : { color: "gray" }} value={trendSettings.st_factor ? trendSettings.st_factor : 2} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="st_factor" className="form-control" />
                                                </Col>
                                            </FormGroup>
                                            {/* <h5 className="mt-5 mb-3 font-size-15">
                                  Supertrend distance for BTC/USDT trading pair
                                </h5> */}
                                            {/* <div className="p-4 border"> */}
                                            <FormGroup className="mb-4" row>
                                                <Label
                                                    htmlFor="formrow-firstname-Input"
                                                    md="4"
                                                    className="col-form-label"
                                                >
                                                    Minimum supertrend distance <i className="bx bx-info-circle" id="Minimum_supertrend_distance" />
                                                    <UncontrolledTooltip placement="top" target="Minimum_supertrend_distance">
                                                        {ToolTipData.supertrend.minimum_supertrend_distance}
                                                    </UncontrolledTooltip>
                                                </Label>
                                                <Col md="8">
                                                    <input type="number" step={0.00001} style={DefaultColorSetting.st_min_distance ? {} : { color: "gray" }} value={trendSettings.st_min_distance ? trendSettings.st_min_distance : ''} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="st_min_distance" className="form-control" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup className="mb-4" row>
                                                <Label
                                                    htmlFor="formrow-firstname-Input"
                                                    md="4"
                                                    className="col-form-label"
                                                >
                                                    Maximum supertrend distance <i className="bx bx-info-circle" id="Maximum_supertrend_distance" />
                                                    <UncontrolledTooltip placement="top" target="Maximum_supertrend_distance">
                                                        {ToolTipData.supertrend.maximum_supertrend_distance}
                                                    </UncontrolledTooltip>
                                                </Label>
                                                <Col md="8">
                                                    <input type="number" step={1} style={DefaultColorSetting.st_max_distance ? {} : { color: "gray" }} value={trendSettings.st_max_distance ? trendSettings.st_max_distance : ''} onChange={(e) => handleTrendSettingsChange(e)} onBlur={(e) => handleTrendSettingsChange(e)} name="st_max_distance" className="form-control" />
                                                </Col>
                                            </FormGroup>
                                            {/* </div> */}
                                        </> : ''}
                                <FormGroup className="mb-4" row>

                                    <TradingView data={`${(Trendfollowpair.exchange_id === 'binance_us_paper_trade' || "tradeogre" ? 'binanceus' : Trendfollowpair.exchange_id)}:${Trendfollowpair.display_text},${trendSettings.trend_method},${trendSettings.time_frame ? trendSettings.time_frame : ""},${trendSettings.short_term ? trendSettings.short_term : 9},${trendSettings.long_term ? trendSettings.long_term : 20},${trendSettings.ema_time_frame ? trendSettings.ema_time_frame : ""},${trendSettings.ema_short_term ? trendSettings.ema_short_term : 9},${trendSettings.ema_long_term ? trendSettings.ema_long_term : 21},${trendSettings.macd_time_frame ? trendSettings.macd_time_frame : ""},${trendSettings.macd_short_term ? trendSettings.macd_short_term : 12},${trendSettings.macd_long_term ? trendSettings.macd_long_term : 26},${trendSettings.macd_signal_term ? trendSettings.macd_signal_term : 9},${trendSettings.st_time_frame ? trendSettings.st_time_frame : ""},${trendSettings.st_atr_length ? trendSettings.st_atr_length : 10},${trendSettings.st_factor ? trendSettings.st_factor : 2},${trendSettings.st_min_distance ? trendSettings.st_min_distance : ""},${trendSettings.st_max_distance ? trendSettings.st_max_distance : ""}`} />

                                </FormGroup>
                            </div>
                            {
                                ShowWarning ? <div> <div className="alert alert-danger" role="alert">
                                    <strong>Warning!</strong> Please ensure all open orders for {Trendfollowpair.display_text} are closed before updating the Trend settings.
                                </div></div> : ''
                            }
                            <div className="text-sm-end mt-3">
                                <Button className="btn-label w-md" color="success" type="submit" disabled={form6_loader.loading}>
                                    {form6_loader.loading ? <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i> : ''}
                                    <i className="bx bx-smile label-icon"></i> Submit
                                </Button>
                            </div>
                        </form>
                    </ModalBody>
                </div>

            </Modal>

            <Modal
                isOpen={pasteSettingsModal}
                toggle={() => {
                    setPasteSettingsModal(false);
                }}
                style={{ width: "100%" }}
                className="modal-lg"
            >
                <div className="modal-header" >
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        Are you sure want to apply the settings for <b className="text-primary">{pastePair.pair}</b>?
                    </h5>
                    <button
                        type="button"
                        onClick={() => {
                            setPasteSettingsModal(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ width: '48%' }} className="border-end">
                            <h5>Order Settings</h5>
                            {
                                copiedSettings.ordersettings && copiedSettings.ordersettings.quantity ? (
                                    <p className="text-muted text-info font-size-11">
                                        Quantity: <b>{copiedSettings.ordersettings.quantity || copiedSettings.ordersettings.qty}{copiedSettings.ordersettings.quantity_type == "balance_per" ? "% of available quote currency margin" : ""}{copiedSettings.ordersettings.quantity_type == "balance_per_base" ? "% of available base currency margin" : ""}</b>
                                        {copiedSettings.ordersettings.fill_type && copiedSettings.ordersettings.fill_type.length !== 0 &&
                                            <> <br /> Fill type: <b>{copiedSettings.ordersettings.fill_type === 'instant_fill' ? 'Instant Fill' : 'Wait and Fill'}</b></>
                                        }
                                        {copiedSettings.ordersettings.price_distance_percentage && copiedSettings.ordersettings.fill_type !== 'instant_fill' &&
                                            <> <br /> Price Distance % for Wait and Fill: <b>{copiedSettings.ordersettings.price_distance_percentage}</b></>
                                        }
                                        {copiedSettings.ordersettings.stop_loss && copiedSettings.ordersettings.stop_loss.length !== 0 &&
                                            <> <br /> Market Stop Loss: <b>{copiedSettings.ordersettings.stop_loss}{copiedSettings.ordersettings.stop_loss_type === "points" ? 'Pts' : "%"}</b></>
                                        }
                                        {copiedSettings.ordersettings.min_take_profit_percentage &&
                                            <> <br /> Minimum Take Profit%: <b>{copiedSettings.ordersettings.min_take_profit_percentage}</b></>
                                        }
                                        {copiedSettings.ordersettings.trailing_take_profit_percentage &&
                                            <> <br /> Trailing Take Profit%: <b>{copiedSettings.ordersettings.trailing_take_profit_percentage}</b></>
                                        }
                                        {copiedSettings.ordersettings.waiting_time_for_next_order_stop_loss &&
                                            <> <br /> Waiting Time for Order after Stop Loss: <b>{timeFormat(copiedSettings.ordersettings.waiting_time_for_next_order_stop_loss)}</b></>
                                        }
                                        {copiedSettings.ordersettings.waiting_time_for_next_order_trailing_profit &&
                                            <> <br /> Waiting Time for Order after Trailing Take Profit: <b>{timeFormat(copiedSettings.ordersettings.waiting_time_for_next_order_trailing_profit)}</b></>
                                        }
                                        {copiedSettings.ordersettings.order_side &&
                                            <> <br /> Order Side: <b>{copiedSettings.ordersettings.order_side}</b></>
                                        }
                                        {copiedSettings.ordersettings.order_side === "sell" && copiedSettings.ordersettings.min_sell_price &&
                                            <> <br /> Minimum Sell Price: <b>{copiedSettings.ordersettings.min_sell_price}</b><br /></>
                                        }
                                        {copiedSettings.ordersettings.order_side === "buy" && copiedSettings.ordersettings.max_buy_price &&
                                            <> <br /> Maximum Buy Price: <b>{copiedSettings.ordersettings.max_buy_price}</b><br /></>
                                        }
                                    </p>
                                ) : <div className="text-warning font-size-11">No Order Settings available</div>
                            }
                        </div>

                        <div style={{ width: '48%' }}>
                            <h5>Trend Settings</h5>
                            {copiedSettings.trendsettings ? <div className="font-size-11">{renderTrendSettings()}</div> : <div className="text-warning font-size-11">No Trend Settings available</div>}
                        </div>
                    </div>
                    {
                        ShowWarning ? <div> <div className="alert alert-danger" role="alert">
                            <strong>Warning!</strong> Please ensure all open orders for {pastePair.pair} are closed before updating the settings.
                        </div></div> : ''
                    }
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={async () => {
                            try {
                                setSettingsLoad(true);
                                const hasActiveOrders = await validateActiveOrders(pastePair.pair_id, pastePair.exchange_id); // Await validation
                                setShowWarning(hasActiveOrders);
                                // console.log(hasActiveOrders, ' hasActiveOrders', pastePair.pair_id);
                                if (hasActiveOrders) {
                                    // toast.error(`Please ensure all open orders for ${pastePair.pair} are closed before updating the settings`);
                                } else {
                                    pasteApply(pastePair); // Call pasteApply only if there are no active orders
                                }
                            } catch (error) {
                                setSettingsLoad(false);
                                console.error("Error during validation or submission:", error);
                                toast.error("An error occurred while validating orders. Please try again.");
                            }
                        }}

                        className="btn btn-primary"
                        disabled={settingsLoad}
                    // data-dismiss="modal"
                    >
                        {settingsLoad ? (
                            <span>
                                <i className="ml-2 bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>{" "}
                                Processing...{" "}
                            </span>
                        ) : (
                            "Apply Settings"
                        )}
                    </button>
                    <button
                        type="button"
                        onClick={() => {
                            setPasteSettingsModal(false);
                        }}
                        className="btn btn-secondary "
                        data-dismiss="modal"
                    >
                        Cancel
                    </button>
                </div>
            </Modal>


        </>
        // :
        // tradeStatus == 0 ?
        //     <React.Fragment>
        //         <div className="page-content">
        //             <Container fluid>
        //                 <Col lg="6" sm="3" style={{ "margin": "0px auto" }}><Alert color="danger">
        //                     <i className="mdi mdi-alert-outline me-2"></i><strong>Account disabled</strong><br />
        //                     Your account is disabled. Please contact the administrator to enable your account
        //                 </Alert></Col>
        //             </Container>
        //         </div>
        //     </React.Fragment>
        //     : ''
    )
}

export default Trade